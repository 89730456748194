import React, {
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { ApolloQueryResult } from "@apollo/client";
import { IncidentCategory } from "../../../apollo/incidents/interfaces";
import { useGetIncidentCategoriesForSelect } from "../../../apollo/incidents/queries";

interface IncidentCategorySearchboxProps {
  onChange?: (event: React.ChangeEvent<any>) => void;
  onCategoriesLoad?: (categories: IncidentCategory[]) => void;
  onOptionChange?: (category: IncidentCategory | null) => void;
  error?: string | null;
  name?: string;
  label?: string;
  value?: IncidentCategory | null | string;

}

export type RefetchFunction = (
  reset?: boolean
) => Promise<ApolloQueryResult<{ incidentCategories: IncidentCategory[] }>>;

const useIncidentCategorySearchbox = (
  onChange?: (event: React.ChangeEvent<any>) => void,
  name?: string,
  onOptionChange?: (category: IncidentCategory | null) => void,
  onCategoriesLoad?: (categories: IncidentCategory[]) => void,
 
) => {
  const {
    categories,
    categoriesError,
    categoriesLoading,
    refetch,
  } = useGetIncidentCategoriesForSelect({
    onCompleted: (data) => {
      if (onCategoriesLoad) {
        onCategoriesLoad(data.incidentCategories);
      }
    },
  });

  const onSearchChange = (
    e: React.ChangeEvent<{}>,
    category: IncidentCategory | null
  ) => {
    if (onChange && name) {
      const synteticEvent = {
        ...e,
        target: {
          value: category?.id,
          name,
        },
      };
      onChange(synteticEvent);
    }

    if (onOptionChange) {
      onOptionChange(category);
    }
  };

  const reloadCategories = useCallback(
    async (reset?: boolean) => {
      console.log('reloading')
      const result = await refetch();
      if(result && result.data) {
        const {
          data: { incidentCategories },
        } = result;
        if (reset && incidentCategories.length && onOptionChange) {
          onOptionChange(incidentCategories[0]);
        }
      }
     
      return result;
    },
    [refetch, onOptionChange]
  );

  return {
    model: {
      categories,
    },
    commands: {
      onSearchChange,
    },
    refMethods:  {
      reloadCategories
    }
  };
};

export interface IncidentCategorySearchboxRef {
  reloadCategories: RefetchFunction
}

const IncidentCategorySearchbox = forwardRef<
  IncidentCategorySearchboxRef,
  IncidentCategorySearchboxProps
>(
  (
    {
      onOptionChange,
      error,
      onChange,
      name,
      label,
      onCategoriesLoad,
      value,

    },
    ref
  ) => {
    const {
      model: { categories },
      commands: { onSearchChange },
      refMethods
    } = useIncidentCategorySearchbox(
      onChange,
      name,
      onOptionChange,
      onCategoriesLoad,

    );

    useImperativeHandle(ref, () => (refMethods));

    const getValue = () => {
      if (typeof value === "string") {
        const category = categories?.find((c) => c.id === value);
        return category ? category : null;
      }
      return value;
    };
    return (
      <Autocomplete
        // id="combo-box-demo"
        // disableClearable={true}
        options={categories ? categories : []}
        getOptionLabel={(option) => option.name}
        onChange={onSearchChange}
        //   groupBy={(c) => (c.tenant ? st.tenant.name : "")}
        value={getValue()}
        fullWidth
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.id === value.id;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : "Incident Categories"}
            variant="outlined"
            error={!!error}
            helperText={error}
            fullWidth
          />
        )}
      />
    );
  }
);

export default IncidentCategorySearchbox;
