import React from "react";
import { DataGrid, GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { useGetRegularValetAgreements } from "../../../apollo/valetAgreements/query";
import { ServiceRoute } from "../../../apollo/serviceRoutes/interfaces";
import Link from "../../../navigation/Link";
import { User } from "../../../apollo/users/interfaces";
import moment from "moment";
import { Paper } from "@material-ui/core";

const getRoute = (params: GridValueGetterParams) => {
  const route = params.getValue(params.id, "route") as ServiceRoute;

  return route;
};
const getProperty = (params: GridValueGetterParams) => {
  return getRoute(params).property;
};

const getColumns = (valetId: string): GridColumns => [
  {
    field: "id",
    headerName: "View",
    
    flex: 0.7,
    resizable: false,
    hideSortIcons: true,
    sortable: false,
    filterable: false,
    renderCell: params => {
      return (
        <Link to={`/dashboard/valets/${valetId}/agreements/${params.value}`}>
          View
        </Link>
      );
    }
  },
  {
    field: "property",
    flex: 1,
    headerName: "Property",
    renderCell: params => {
      const property = getProperty(params);
      return (
        <Link to={`/dashboard/properties/${property.id}`}>{params.value}</Link>
      );
    },
    valueGetter: params => getProperty(params).name,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      getProperty(cellParams1 as GridValueGetterParams).name.localeCompare(
        getProperty(cellParams2 as GridValueGetterParams).name
      )
  },
  {
    field: "routeId",
    flex: 0.8,
    headerName: "Route",
    renderCell: params => {
      const route = getRoute(params);

      return (
        <Link to={`/dashboard/serviceRoutes/${route.id}/`}>{params.value}</Link>
      );
    },
    valueGetter: params => getRoute(params).routeCode,

    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      getRoute(cellParams1 as GridValueGetterParams).routeCode.localeCompare(
        getRoute(cellParams2 as GridValueGetterParams).routeCode
      )
  },
  {
    field: "supervisor",
    flex: 1,
    headerName: "Supervisor",
    renderCell: params => {
      const supervisor = params.value as User;
      if (!supervisor) return "Not Specified";
      return (
        <Link to={`/dashboard/users/${supervisor.id}/`}>{supervisor.name}</Link>
      );
    }
  },

  {
    flex: 0.7,
    field: "status",
    headerName: "Status"
  },
  {
    flex: 0.7,
    field: "currentTerm",
    headerName: "Term"
  },
  {
    flex: 1,
    field: "startDate",
    headerName: "Start Date",

    renderCell: params => {
      return moment(params.value as string).format("ll");
    }
  },
  {
    flex: 1,
    field: "endDate",
    headerName: "End Date",

    renderCell: params => {
      return moment(params.value as string).format("ll");
    }
  },
  {
    flex: 1,
    field: "isMissingSignatures",
    headerName: "Missing Signatures?",

    renderCell: params => {
      return params.value ? "Yes" : "No";
    }
  }
];

interface RegularAgreementsTableProps {
  valetId: string;
}
const RegularAgreementsTable: React.FC<RegularAgreementsTableProps> = ({
  valetId
}) => {
  const { loading, error, regularAgreements } = useGetRegularValetAgreements(
    valetId
  );
  return (
    <Paper elevation={0}  style={{ height: 250, width: "100%", }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            columns={getColumns(valetId)}
            rows={regularAgreements || []}
            loading={loading}
          />
        </div>
      </div>
    </Paper>
  );
};

export default RegularAgreementsTable;
