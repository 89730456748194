import React from "react";
import { CustomerFormValues, CustomerFormProps } from "./interfaces";
import { Customer } from "../../../apollo/customers/interfaces";
import AddressForm, {
  getAddressFormInitialValues,
  getAddressFormValidationSchema,
} from "../../../shared/components/AddressForm";
import {
  Grid,
  TextField,
  Typography,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import * as Yup from "yup";

export const getCustomerFormInitialValues = (
  customer?: Customer
): CustomerFormValues => {
  return {
    id: customer?.id,
    firstName: customer ? customer.firstName : "",
    gender: customer ? customer.gender : "M",
    lastName: customer ? customer.lastName : "",
    mainPhone: customer ? customer.mainPhone : "",
    subTenantId: customer ? customer.subTenantId : "",
    emergencyPhone: customer ? customer.emergencyPhone : "",
    email: customer ? customer.email : "",
    address: getAddressFormInitialValues(customer?.address),
  };
};

export const getCustomerFormValidationSchema = (requireSubTenant: boolean) => {
  const requiredText = "This field is required.";
  return Yup.object<CustomerFormValues>({
    subTenantId: requireSubTenant
      ? Yup.string().required(requiredText)
      : Yup.string().optional(),
    address: getAddressFormValidationSchema(true).required(),
    firstName: Yup.string().required(requiredText),
    lastName: Yup.string().required(requiredText),
    gender: Yup.string().oneOf(["M", "F", "O"]).required(requiredText),
    mainPhone: Yup.string().required(requiredText),
    emergencyPhone: Yup.string().optional(),
    email: Yup.string().optional(),
  });
};
const CustomerForm: React.FC<CustomerFormProps> = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  renderSubTenantSelect,
  isStandalone,
  renderSubmitButton,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Basic Information
          </Typography>
        </Grid>
        {renderSubTenantSelect && (
          <Grid item xs={12}>
            <SubTenantSearchBox
              onChange={handleChange}
              error={errors?.subTenantId}
              value={values.subTenantId}
              name="subTenantId"
              label="Sub-Franchise*"
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="First Name*"
            name="firstName"
            onChange={handleChange}
            // onBlur={onInputBlur}
            value={values.firstName}
            helperText={errors?.firstName}
            error={!!errors?.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Last Name*"
            name="lastName"
            onChange={handleChange}
            // onBlur={onInputBlur}
            value={values.lastName}
            helperText={errors?.lastName}
            error={!!errors?.lastName}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Email"
            name="email"
            onChange={handleChange}
            // onBlur={onInputBlur}
            value={values.email}
            helperText={errors?.email}
            error={!!errors?.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Main Phone Number*"
            name="mainPhone"
            onChange={handleChange}
            // onBlur={onInputBlur}
            value={values.mainPhone}
            helperText={errors?.mainPhone}
            error={!!errors?.mainPhone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Emergency Phone Number"
            name="emergencyPhone"
            onChange={handleChange}
            // onBlur={onInputBlur}
            value={values.emergencyPhone}
            helperText={errors?.emergencyPhone}
            error={!!errors?.emergencyPhone}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={values.gender}
              onChange={handleChange}
            >
              <FormControlLabel value="F" control={<Radio />} label="Female" />
              <FormControlLabel value="M" control={<Radio />} label="Male" />
              <FormControlLabel value="O" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
        </Grid> */}
      </Grid>
      <Divider style={{ margin: "30px 0px 10px 0px" }} />
      <AddressForm
        
        title="Address Information"
        handleChange={handleChange}
        values={values.address}
        errors={errors?.address}
        setFieldValue={setFieldValue}
        parentForm="address"
        loadMapApi={false}
      />
      {!!renderSubmitButton && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {renderSubmitButton}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CustomerForm;
