import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Paper,
  Grid,
  Button,
  Switch,
  Typography,
  Box,
  FormControlLabel
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import FormikStepForm, {
  FormikStep
} from "../../shared/components/FormikStepForm";
import DateSelectionStep, {
  getDateSelectionValidationSchema
} from "./steps/DateSelectionStep";
import SigningStep, {
  getSigningStepValidationSchema
} from "./steps/SigningStep";
import { FormValues } from "./steps/interfaces";
import TypeSelectionStep, {
  getTypeSelectionValidationSchema
} from "./steps/TypeSelectionStep";
import { useParams, useHistory } from "react-router-dom";

import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import {
  ValetContractType,
  CreateValetAgreement
} from "../../apollo/valetAgreements/interface";
import { loadAgreementsValues } from "./steps/utils";
import FreeWritingStep, {
  getFreeWritingStepValidationSchema
} from "./steps/FreeWritingStep";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { ApolloError } from "@apollo/client";
import { useSubmitAgreement } from "../../apollo/valetAgreements/mutation";
import LoadingPage from "../Home/LoadingPage";
import Link from "../../navigation/Link";
import { useGetAgreementValet } from "../../apollo/valetAgreements/query";
import { useFormik } from "formik";
import { Valet } from "../../apollo/valets/interfaces";
import CoverSelectionStep, {
  getCoverValidationSchema
} from "./steps/CoverFields";
import RatesStep, { getRatesFormValidationSchema } from "./steps/RatesStep";

export const getMappedValues = (values: FormValues): CreateValetAgreement => {
  return {
    id: values.id,
    type: values.type,
    status: values.status,
    currentTerm: values.currentTerm,
    startDate: values.startDate,
    endDate: values.endDate,
    extraData: values.extraData,
    routeId: values.routeId,
    contractorId: values.contractorId,
    valetSignature: values.valetSignature,
    supervisorSignature: values.supervisorSignature,
    valetSsn: values.valetSsn,
    dailyPayRate: values.dailyPayRate,
    monthlyPayRate: values.monthlyPayRate,
    bonus: values.bonus,
    metadata: {
      workingDays: values.workingDays
      // coveredValetId: values.type === "COVER" ? values.contractorId : undefined,
      // tempUser:
      //   values.type === "COVER" && !values.coverValetId
      //     ? {
      //         ...values.coverValetUser,
      //         roleId: undefined,
      //         roles: [],
      //         address: values.coverValetAddress
      //       }
      //     : undefined
    }
  };
};
const useValetAgreementFormPage = () => {
  const { valetId, agreementId } = useParams<{
    valetId: string;
    agreementId: string;
  }>();
  const [activeStep, setActiveStep] = useState(0);
  const [currentType, setCurrentType] = useState<ValetContractType>("REGULAR");
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const history = useHistory();
  const onSubmitSuccess = () => {
    sendSuccessMessage("Agreement submitted successfully.");
    history.push("/dashboard/valets/" + valetId);
  };
  const onSubmitError = (err: ApolloError) => {
    sendErrorMessage("Something went wrong submitting the agreement.");
    console.warn(err);
  };

  const { submitAgreement, submitAgreementLoading } = useSubmitAgreement(
    onSubmitSuccess,
    onSubmitError
  );
  const onFormSubmit = async (values: FormValues) => {
    const mappedValues = getMappedValues(values);
    submitAgreement({ variables: { agreement: mappedValues } });
  };
  const { valet, loading, agreement } = useGetAgreementValet(
    valetId,
    agreementId
  );
  const initialValues: FormValues = useMemo(
    () => loadAgreementsValues(valetId, currentType, agreement, valet),
    [valet, agreement, valetId, currentType]
  );
  // const { values } = useFormik<FormValues>({
  //   initialValues,
  //   onSubmit: onFormSubmit
  // });
  useEffect(() => {
    if (agreement) {
      setCurrentType(agreement.type);
      console.log("setting step");
      setActiveStep(3);
    }
  }, [agreement, setActiveStep, setCurrentType]);
  return {
    model: {
      valet,
      valetLoading: loading,
      submitLoading: submitAgreementLoading,
      initialValues,
      currentType,
      activeStep,
      agreement,
      valetId
    },
    commands: {
      onTypeChange: setCurrentType,
      onFormSubmit,
      onStepChange: setActiveStep
    }
  };
};

const ValetAgreementFormPage: React.FC = () => {
  const {
    model: {
      initialValues,
      agreement,
      valetLoading,
      submitLoading,
      currentType,
      activeStep,
      valet,
      valetId
    },
    commands: { onTypeChange, onFormSubmit, onStepChange }
  } = useValetAgreementFormPage();

  const hasSingleSignature = () => {
    if (!agreement) return false;

    return !!agreement.supervisorSignature || !!agreement.valetSignature;
  };
  const isSigned = () => {
    if (!agreement) return false;

    return !!agreement.supervisorSignature && !!agreement.valetSignature;
  };

  if (valetLoading) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <LoadingBackdrop open={submitLoading} />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Valet", link: "/dashboard/valets" },
          { label: "Agreement" }
        ]}
      />
      <PageHeader title="Valet Agreement" />

      <Paper>
        <Container>
          {isSigned() ? (
            <AgreementView
              agreementSrc={agreement?.generatedFile?.url}
              valetId={valet ? valet.id : ""}
            />
          ) : (
            <FormikStepForm
              activeStep={activeStep}
              onStepChange={onStepChange}
              initialValues={initialValues}
              onSubmit={onFormSubmit}
              validateOnChange={false}
              disableBack={hasSingleSignature()}
            >
              <FormikStep
                label="Valet Review & Ageement Type"
                validationSchema={getTypeSelectionValidationSchema()}
              >
                <TypeSelectionStep
                  valet={valet}
                  onTypeChange={onTypeChange}
                  currentType={currentType}
                />
              </FormikStep>
              {/* {currentType === "COVER" && (
                <FormikStep
                  label="Cover Valet"
                  validationSchema={getCoverValidationSchema()}>
                <CoverSelectionStep currentType={currentType} valetId={valetId} onUserCreated={() => onStepChange(activeStep + 1)} />
                </FormikStep>
              )} */}
              <FormikStep
                label="Rates"
                validationSchema={getRatesFormValidationSchema()}
              >
                <RatesStep />
              </FormikStep>
              <FormikStep
                label="Dates and Times"
                validationSchema={getDateSelectionValidationSchema()}
              >
                <DateSelectionStep />
              </FormikStep>
              <FormikStep
                label="Extra Information"
                validationSchema={getFreeWritingStepValidationSchema()}
              >
                <FreeWritingStep />
              </FormikStep>

              <FormikStep
                label="Preview and Signing"
                validationSchema={getSigningStepValidationSchema()}
              >
                <SigningStep agreement={agreement} />
              </FormikStep>
            </FormikStepForm>
          )}
        </Container>
      </Paper>
    </Container>
  );
};

interface AgreementViewProps {
  agreementSrc?: string;
  valetId: string;
}

const AgreementView: React.FC<AgreementViewProps> = ({
  agreementSrc,
  valetId
}) => {
  return (
    <Grid container spacing={2} style={{ paddingBottom: 20 }}>
      <Grid item xs={12}>
        <img src={agreementSrc} style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12} justify="center" container>
        <Link to={"/dashboard/valets/" + valetId}>
          <Button variant="contained" color="primary">
            {" "}
            Go to valet profile
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ValetAgreementFormPage;
