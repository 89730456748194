import React from 'react'
import { Container, CircularProgress, Grid } from '@material-ui/core'

const LoadingPage : React.FC = () => {
    return (
        <Container style={{height: '100%', width: '100%'}}>
            <Grid container alignItems="center" justify="center"  style={{height: '100%'}}>
                <CircularProgress size={100}/>
            </Grid>
          
        </Container>
    )
}

export default LoadingPage
