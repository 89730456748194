import React, { useEffect, useCallback } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { Role } from "../../../apollo/roles/interfaces";
import { GET_ROLES_SIMPLE } from "../../../apollo/roles/queries";
import { ApolloQueryResult } from "@apollo/client";

interface RoleSearchboxProps {
  onChange?: (event: React.ChangeEvent<any>) => void;
  onRolesLoad?: (roles: Role[]) => void;
  onOptionChange?: (role: Role | null) => void;
  error?: string | null;
  name?: string;
  label?: string;
  value?: Role | null | string;
  fetchMoreRef?: (ref: LoadRolesFunction) => void;
}

export type LoadRolesFunction = (
  reset?: boolean
) => Promise<ApolloQueryResult<{ roles: Role[] }>>;
const useRoleSearchbox = (
  onChange?: (event: React.ChangeEvent<any>) => void,
  name?: string,
  onOptionChange?: (role: Role | null) => void,
  onRolesLoad?: (roles: Role[]) => void,
  fetchMoreRef?: (ref: LoadRolesFunction) => void
) => {
  const { data, fetchMore } = useQuery<{ roles: Role[] }>(GET_ROLES_SIMPLE, {
    onCompleted: (data) => {
      if (onRolesLoad) {
        onRolesLoad(data.roles);
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const onSearchChange = (e: React.ChangeEvent<{}>, role: Role | null) => {
    if (onChange && name) {
      const synteticEvent = {
        ...e,
        target: {
          value: role?.id,
          name,
        },
      };
      onChange(synteticEvent);
    }

    if (onOptionChange) {
      onOptionChange(role);
    }
  };

  const reloadRoles = useCallback(
    async (reset?: boolean) => {
      const result = await fetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return fetchMoreResult;
        },
      });
      if (result && result.data) {
        const {
          data: { roles },
        } = result;
        if (reset && roles.length && onOptionChange) {
          onOptionChange(roles[0]);
        }
      }

      return result;
    },
    [fetchMore, onOptionChange]
  );
  useEffect(() => {
    if (fetchMoreRef) fetchMoreRef(reloadRoles);
  }, [fetchMoreRef, reloadRoles]);
  return {
    model: {
      roles: data?.roles,
    },
    commands: {
      onSearchChange,
    },
  };
};
const RoleSearchbox: React.FC<RoleSearchboxProps> = ({
  onOptionChange,
  error,
  onChange,
  name,
  label,
  onRolesLoad,
  value,
  fetchMoreRef,
}) => {
  const {
    model: { roles },
    commands: { onSearchChange },
  } = useRoleSearchbox(
    onChange,
    name,
    onOptionChange,
    onRolesLoad,
    fetchMoreRef
  );

  const getValue = () => {
    if (typeof value === "string") {
      const role = roles?.find((role) => role.id === value);
      return role ? role : null;
    }
    return value;
  };
  return (
    <Autocomplete
      // id="combo-box-demo"
      // disableClearable={true}
      options={roles ? roles : []}
      getOptionLabel={(option) => option.name}
      onChange={onSearchChange}
      value={getValue()}
      fullWidth
      getOptionSelected={(option, value) => {
        if (!value) return false;
        return option.id === value.id;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : "Roles"}
          variant="outlined"
          error={!!error}
          helperText={error}
          fullWidth
        />
      )}
    />
  );
};

export default RoleSearchbox;
