import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { TimeOffRequest } from "./interfaces";

export const TIME_OFF_REQUEST_TABLE = gql`
  query getTimeOffRequestsTable(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String # $subTenantId: String
  ) {
    timeOffRequestTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
    ) {
      nodes {
        createdAt
        valet
        valetId
        subTenantId
        tenantId
        subTenant
        tenant
        id
        statusChangeDate
        coverValet
        coverValetId
        status
      }
    }
  }
`;

const GET_REQUEST_FOR_FORM = gql`
  query getRequestForForm($id: String!) {
    timeOffRequest(id: $id) {
      id
      valetId
      coverValetId
      startDate
      endDate
    }
  }
`;

export const useGetTimeOffRequestForForm = (id?: string) => {
  const [get, { data, error }] = useLazyQuery<
    { timeOffRequest: TimeOffRequest },
    { id: string }
  >(GET_REQUEST_FOR_FORM);

  useEffect(() => {
    if (data?.timeOffRequest || error) return;

    if (id) {
      get({ variables: { id } });
    }
    return () => {};
  }, [id, get, data, error]);

  return {
    foundRequest: data?.timeOffRequest,
    foundRequestError: error
  };
};

const GET_REQUEST_VIEW = gql`
  query getRequestView($id: String!) {
    timeOffRequest(id: $id) {
      id
      status
      supervisor {
        id
        firstName
        lastName
      }
      valet {
        id
        name
      }
      coverValet {
        id
        name
      }
      startDate
      endDate
    }
  }
`;

export const useGetTimeOffRequestView = (id: string) => {
  const { data, error } = useQuery<
    { timeOffRequest: TimeOffRequest },
    { id: string }
  >(GET_REQUEST_VIEW, { variables: { id } });

  return {
    request: data?.timeOffRequest,
    requestError: error
  };
};
