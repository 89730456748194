import React from "react";
import {
  Container,
  Typography,
  Breadcrumbs,
  makeStyles,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import Link from "../../navigation/Link";
import UsersTable from "./components/UsersTable";
import useAbility from "../../shared/hooks/useAbility";

const useStyles = makeStyles((theme) => ({
  createButtonContainer: {
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      marginBottom: theme.spacing(0),
    },
  },
}));

const UsersList: React.FC = () => {
  const classes = useStyles();
  const ability = useAbility();

  return (
    <Container>
      <Box marginBottom={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/dashboard">Home</Link>
          <Typography color="textPrimary">Users</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container>
        <Grid item md={10} sm={8} xs={12}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Grid>

        <Grid
          item
          md={2}
          sm={4}
          xs={12}
          container
          className={classes.createButtonContainer}
          alignItems="center"
        >
          {ability.can("create", "Users") && (
            <Link to="/dashboard/users/create">
              <Button variant="contained" color="primary">
                Create User
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
      <UsersTable canDelete={ability.can("delete", "Users")} renderFilter={ability.can('manage', 'SubTenants')} />
    </Container>
  );
};

export default UsersList;
