import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Field, FieldInputProps, FormikProps, FieldMetaProps } from "formik";
import { WorkingDay } from "../../pages/Agreements/CustomerSteps/interfaces";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  grouped: {
    width: "100%",
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));
const workingDays: WorkingDay[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
interface WorkingDayFieldsProps<V = any, FormValues = any> {
  lowerCaseValue?: boolean;
  field: FieldInputProps<V>;
  form: FormikProps<FormValues>;
  meta?: FieldMetaProps<V>;
  label?: string;
}
const WorkingDayFields: React.FC<WorkingDayFieldsProps> = ({
  lowerCaseValue,
  meta,
  field,
  label,
  form,
  ...props
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    form.setFieldValue(field.name, value);
  };
  return (
    <FormControl error={!!form.errors[field.name]} fullWidth component="fieldset">
      <FormLabel
        component="label"
        className={classes.label}
        htmlFor="workingDays"
      >
        {label}
      </FormLabel>
      <ToggleButtonGroup
        orientation={matchesSm ? "vertical" : "horizontal"}
        value={field.value}
        onChange={handleChange}
        classes={{ grouped: classes.grouped }}
        id="workingDays"
      >
        {workingDays.map((day) => (
          <ToggleButton
            key={day}
            value={lowerCaseValue ? day.toLowerCase() : day}
          >
            {day}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormHelperText>{form.errors[field.name]}</FormHelperText>
    </FormControl>
  );
};

export default WorkingDayFields;
