import React from "react";
import { DrawingMapProps } from "./DrawingMap";
import { Area } from "./interfaces";
import { Polygon, InfoWindow } from "@react-google-maps/api";
import { Typography } from "@material-ui/core";

interface AreaPolygonProps
  extends Pick<DrawingMapProps, "color" | "useAreaNames"> {
  area: Area;
  altName?: string;
  onRightClick?: () => void;
}
const AreaPolygon: React.FC<AreaPolygonProps> = ({
  area,
  color,
  onRightClick,
  useAreaNames,
  altName
}) => {
  return (
    <React.Fragment>
      <Polygon
        options={{
          editable: false,
          fillColor: area.color ? area.color : color,
          strokeColor: area.color ? area.color : color
        }}
        onRightClick={(e: any) => {
          if (e.ub) e.ub.preventDefault();

          if (onRightClick) onRightClick();
          // if (onAreaRemoved) onAreaRemoved(i, area);
          // mapsPolygons.splice(i, 1);
          // console.log("right");
          // setMenuAnchor({ x: e.ub.pageX, y: e.ub.pageY, item: i });
        }}
        paths={area.points.map(p => ({
          lat: p.latitude,
          lng: p.longitude
        }))}
      />
      <InfoWindow
        options={{}}
        position={{
          lat: area.points[0].latitude,
          lng: area.points[0].longitude
        }}
      >
        <Typography>{useAreaNames ? area.name : altName}</Typography>
      </InfoWindow>
    </React.Fragment>
  );
};

export default AreaPolygon;
