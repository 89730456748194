import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
  Grid
} from "@material-ui/core";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { SupplyItem } from "../../../apollo/supplyRequests/interfaces";
import * as Yup from "yup";

export interface SupplyRequestFormLineItem {
  item: SupplyItem;
  variety?: string;
  quantity: number;
}

interface AddItemFormValues {
  variety?: string;
  quantity: number;
}

interface AddItemFormModalProps {
  item?: SupplyItem;
  open: boolean;
  onClose: () => void;
  onSubmit: (selection: SupplyRequestFormLineItem) => void;
}

const getInitialValues = (item?: SupplyItem): AddItemFormValues => {
  return {
    quantity: 1,
    variety: item && item.varieties?.length ? item.varieties[0] : undefined
  };
};

const getValidationSchema = () =>
  Yup.object<AddItemFormValues>({
    quantity: Yup.number()
      .required("This field is required.")
      .integer()
  });

const AddItemFormModal: React.FC<AddItemFormModalProps> = ({
  onSubmit: onSubmitProp,
  open,
  onClose,
  item
}) => {
  const onSubmit = (values: AddItemFormValues) => {
    if (item) onSubmitProp({ ...values, item: item });
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Adding Item</DialogTitle>
      <Formik onSubmit={onSubmit} initialValues={getInitialValues(item)}>
        {({ submitForm }) => (
          <>
            <DialogContent>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText>Item: {item?.name}</DialogContentText>
              </Grid>

              <Grid item xs={12}>
                {!!item?.varieties?.length && (
                  <Field
                    fullWidth
                    autoFocus
                    name="variety"
                    label="Variety"
                    component={TextField}
                    select
                    variant="outlined"
                  >
                    {item?.varieties.map((v, i) => (
                      <MenuItem key={v + i} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </Field>
                )}
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  autoFocus
                  label="Quantity"
                  name="quantity"
                  type="number"
                  component={TextField}
                  select
                  variant="outlined"
                >
                  {[0,1,2,3].map((v, i) => (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submitForm} color="primary">
                Add
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddItemFormModal;
