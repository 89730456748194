import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Button,
  Fade,
  CircularProgress,
  Typography
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";

import { FormValues } from "./CustomerSteps/interfaces";

import { useParams, useHistory } from "react-router-dom";

import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import { CustomerContractType } from "../../apollo/agreements/interface";
import { getUnitStep, loadAgreementsValues } from "./CustomerSteps/utils";

import useSnackbar from "../../shared/hooks/useSnackbar";
import { ApolloError } from "@apollo/client";
import {
  useExternalCreateCustomerAgreement,
  useSubmitAgreement
} from "../../apollo/agreements/mutation";
import {
  useGetCustomerAgreementPreviewLazy,
  useGetCustomerWithAgreement,
  useGetExternalCustomerAgreement,
  useGetPropertyWithAgreement
} from "../../apollo/agreements/query";
import LoadingPage from "../Home/LoadingPage";
import Link from "../../navigation/Link";
import { Field, Form, Formik, useFormikContext } from "formik";
import ErrorPage from "../../shared/components/ErrorPage";
import { Alert } from "@material-ui/lab";
import * as Yup from "yup";
import SignatureField from "../../shared/components/SignatureField";
import { TextField } from "formik-material-ui";
import useDebounce from "../../shared/hooks/useDebounce";

const ExternalCustomerAgreementFormPage: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();

  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();

  const {
    agreement,
    loading,
    error,
    refetch
  } = useGetExternalCustomerAgreement(hash);


  const {
    submitAgreement,
    submitAgreementLoading,
    submitSuccess,
    submitError
  } = useExternalCreateCustomerAgreement();

  const onFormSubmit = async (values: FormValues) => {
    submitAgreement({ variables: { agreement: values } });
  };

  useEffect(() => {
    if (submitSuccess) {
      sendSuccessMessage("Agreement submitted successfully.");
      refetch();
    }
  }, [submitSuccess]);

  useEffect(() => {
    if (submitError) {
      sendErrorMessage("Something went wrong submitting the agreement.");
      console.warn(submitError);
    }
  }, [submitError]);

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (!agreement) {
    return <LoadingPage />;
  }

  return (
    <Container style={{ marginTop: 30 }}>
      <LoadingBackdrop open={submitAgreementLoading} />
      <Typography variant="h2" color="primary" style={{marginBottom: 20, width: '100%', textAlign: 'center'}}>
        Disposal Valet System
      </Typography>
      {/* <PageHeader title="Customer Agreement" /> */}

      <Paper style={{ minHeight: "70vh" }}>
        <Container>
          {agreement.customerSignature ? (
            <AgreementView agreementSrc={agreement.generatedFile?.url} />
          ) : (
            <Formik
              onSubmit={onFormSubmit}
              initialValues={loadAgreementsValues(
                agreement.propertyId,
                agreement.type,
                agreement
              )}
            >
              <Form style={{ width: "100%" }}>
                <ExternalCustomerAgreementFormInputs />
              </Form>
            </Formik>
          )}
        </Container>
      </Paper>
    </Container>
  );
};

const ExternalCustomerAgreementFormInputs: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  const customerName = useDebounce(values.customerName, 1000);
  const {
    getPreview,
    agreementPreview,
    previewError,
    previewLoading
  } = useGetCustomerAgreementPreviewLazy(true);

  useEffect(() => {
    getPreview({ variables: { agreement: values } });
  }, [values.customerSignature, customerName]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} container justify="center">
        <Fade in={!!agreementPreview && !previewLoading} unmountOnExit={true}>
          <img
            src={agreementPreview}
            style={{ width: "100%" }}
            alt="agreement-preview"
          />
        </Fade>

        {previewLoading && <CircularProgress size={50} />}
        {previewError && (
          <Alert severity="error">
            Someting went wrong trying to generate the preview!
          </Alert>
        )}
      </Grid>

      <Grid item xs={12} sm={10} md={8}>
        <Field
          component={SignatureField}
          name="customerSignature"
          title="Property Manager Signature"
          createLabel="Sign"
          buttonProps={{ fullWidth: true }}
        />
      </Grid>

      <Grid item xs={12} sm={10} md={8}>
        <Field
          component={TextField}
          name="customerName"
          label="Property Manager Name"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={10} md={8}>
        <Button
          type="submit"
          disabled={!values.customerSignature || !values.customerName}
        >
          Complete
        </Button>
      </Grid>
    </Grid>
  );
};
interface AgreementViewProps {
  agreementSrc?: string;
  customerId?: string;
  propertyId?: string;
}

const AgreementView: React.FC<AgreementViewProps> = ({ agreementSrc }) => {
  return (
    <Grid container spacing={2} style={{ paddingBottom: 20 }}>
      <Grid item xs={12}>
        <img src={agreementSrc} style={{ width: "100%" }} />
      </Grid>
    </Grid>
  );
};

export default ExternalCustomerAgreementFormPage;
