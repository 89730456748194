import React, { useMemo, useState } from "react";
import {
  Container,
  Paper,
  Box,
  Grid,
  Typography,
  colors,
  Chip,
  makeStyles,
  createStyles,
  IconButton,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useHistory, useParams } from "react-router-dom";
import Map, { MapApiWrapper, VanillaMap } from "../../shared/components/Map";
import DrawingMap from "../../shared/components/Map/DrawingMap";
import ErrorPage from "../../shared/components/ErrorPage";
import { useGetServiceRouteView } from "../../apollo/serviceRoutes/queries";
import LoadingPage from "../Home/LoadingPage";
import InfoCard from "../../shared/components/InfoCard";
import moment from "moment";
import {
  MoreVertRounded,
  EditRounded,
  DeleteRounded
} from "@material-ui/icons";
import Menu from "../../shared/components/Menu";
import {
  ServiceReport,
  isRegularRoute,
  isSpecialRoute,
  ServiceRoute
} from "../../apollo/serviceRoutes/interfaces";
import { getAreasFromRoutes } from "./utils";
import Link from "../../navigation/Link";
import ColorDot from "../../shared/components/ColorDot";
import { Area } from "../../shared/components/Map/interfaces";
import ServiceReportsTable from "./components/ServiceReportsTable";
import { capitalize } from "lodash";
import DeleteModal from "../../shared/components/DeleteModal";
import {
  DELETE_SERVICE_ROUTES,
  useAssignDefaultValet
} from "../../apollo/serviceRoutes/mutations";
import ValetSelectModal, {
  ValetSelectItem,
  VanillaSelectModal
} from "../Valets/components/NewValetSelectModal";
import { ValetTableView } from "../../apollo/valets/interfaces";
import useAbility from "../../shared/hooks/useAbility";
import SubscriptionButton from "../../shared/components/SubscriptionButton";

const reasons = {
  HOLIDAY: "Holiday",
  RESCHEDULE: "Rescheduled of a route",
  BAD_WEATHER: "Bad Weather",
  OTHER: "Not specified"
};
const useStyles = makeStyles(theme =>
  createStyles({
    chipContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5)
      }
    },
    areasContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  })
);
const useServiceRoutePage = () => {
  const history = useHistory();
  const { routeId } = useParams<{ routeId: string }>();
  const [showValetModal, setShowValetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { assignDefaultValet } = useAssignDefaultValet();
  const [selectedDefaultValet, setSelectedDefaultValet] = useState<
    ValetSelectItem | undefined
  >();

  const onDefaultValetChange = (valet: ValetSelectItem) => {
    setSelectedDefaultValet(valet);
    assignDefaultValet(routeId, valet.id);
  };

  const onDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const onEditDefaultValetClick = () => {
    setShowValetModal(true);
  };

  const onCloseValetModal = () => {
    setShowValetModal(false);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    history.push("/dashboard/serviceRoutes");
  };

  const { route, loadingRoute, loadingRouteError } = useGetServiceRouteView(
    routeId
  );

  const onEditClick = () => {
    history.push(
      `/dashboard/serviceRoutes/${
        route?.id
      }/edit?type=${route?.type.toLowerCase()}`
    );
  };
  return {
    model: {
      route,
      loadingRoute,
      loadingRouteError,
      showDeleteModal,
      showValetModal,
      selectedDefaultValet
    },
    commands: {
      onEditDefaultValetClick,
      onCloseValetModal,
      onDeleteClick,
      onEditClick,
      onDeleteModalClose,
      onDeleteSuccess,
      onDefaultValetChange
    }
  };
};

const ServiceRoutePage: React.FC = ({}) => {
  const {
    model: {
      loadingRoute,
      loadingRouteError,
      route,
      showDeleteModal,
      showValetModal,
      selectedDefaultValet
    },
    commands: {
      onDeleteClick,
      onEditClick,
      onDeleteModalClose,
      onDeleteSuccess,
      onEditDefaultValetClick,
      onCloseValetModal,
      onDefaultValetChange
    }
  } = useServiceRoutePage();
  const ability = useAbility();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const canManage =
    !route?.isShared || (route.isShared && ability.can("manage", "SubTenants"));

  const areas = useMemo<Area[]>(() => {
    if (route) {
      const neighborAreas = getAreasFromRoutes(route.neighborRoutes);
      return [...route?.areas, ...neighborAreas];
    }
    return [];
  }, [route]);

  if (loadingRouteError) return <ErrorPage />;

  if (!route) return <LoadingPage />;

  return (
    <Container>
      <VanillaSelectModal
        subTenantId={route.subTenant.id}
        open={showValetModal}
        onClose={onCloseValetModal}
        initialValue={route.defaultValet?.id}
        onValetChange={onDefaultValetChange}
      />
      <DeleteModal
        onClose={onDeleteModalClose}
        ids={[route.id]}
        onSuccess={onDeleteSuccess}
        mutation={DELETE_SERVICE_ROUTES}
        title="Delete service route"
        description="Are you sure you want to delete this route? All its data will be lost and this action can not be undone."
        open={showDeleteModal}
      />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Service Routes", link: "/dashboard/serviceRoutes/" },
          { label: "Service Route" }
        ]}
      />
      <PageHeader
        title={
          <div>
            <div style={{ width: "100%" }}>
              {getStatusChip(route?.todaysReport)}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h4">
                {route.type === "REGULAR" ? "Regular" : "Special"} Service Route
              </Typography>

              {canManage && ability.can("update", "ServiceRoutes") && (
                <Menu
                  anchor={<MoreVertRounded />}
                  items={[
                    {
                      label: "Edit",
                      icon: <EditRounded />,

                      onClick: onEditClick
                    },
                    {
                      onClick: onDeleteClick,
                      label: "Delete",
                      icon: <DeleteRounded />
                    }
                  ]}
                />
              )}
            </div>
            <SubscriptionButton id={route.id} type="ServiceRoutes" />
            {!canManage && (
              <Typography variant="h6" color="textSecondary">
                Belongs to the "{route.subTenant.name}" Sub-Franchise
              </Typography>
            )}
          </div>
        }
      />

      <Grid
        container
        spacing={3}
        alignItems="stretch"
        style={{ marginBottom: 30 }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <InfoCard color={colors.red[300]}>
            <Typography>Route Code</Typography>
            <Typography variant="h6" gutterBottom>
              {route.routeCode}
            </Typography>
            <Typography>Property Name</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={`/dashboard/properties/${route?.property.id}`}>
                {route?.property.name}
              </Link>
            </Typography>

            <Typography>Property Address</Typography>
            <Typography variant="h6" gutterBottom>
              {route?.property.address.formatted}
            </Typography>

            <Typography>Route Color</Typography>
            <Typography variant="h6" gutterBottom>
              <ColorDot color={route?.routeColor || "red"} />
            </Typography>
          </InfoCard>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <InfoCard color={colors.green[300]}>
            {isRegularRoute(route.extraData) && (
              <>
                <Typography>Monthly Route Pay</Typography>
                <Typography variant="h6" gutterBottom>
                  ${route.monthlyRoutePay.toFixed(2)}
                </Typography>
                <Typography>Daily Route Pay</Typography>
                <Typography variant="h6" gutterBottom>
                  ${route.dailyRoutePay.toFixed(2)}
                </Typography>
              </>
            )}
            {isSpecialRoute(route.extraData) && (
              <>
                <Typography>Route Pay</Typography>
                <Typography variant="h6" gutterBottom>
                  ${route?.dailyRoutePay.toFixed(2)}
                </Typography>
              </>
            )}

            <Box display="flex" alignItems="center">
              <Typography>Default Valet </Typography>

              <IconButton onClick={onEditDefaultValetClick}>
                <EditRounded />
              </IconButton>
            </Box>
            <Typography variant="h6" gutterBottom>
              {selectedDefaultValet
                ? selectedDefaultValet.name
                : route?.defaultValet
                ? route?.defaultValet.name
                : "Not set."}
            </Typography>
            {ability.can("manage", "SubTenants") && (
              <>
                <Typography>Sub-Franchise</Typography>
                <Typography variant="h6" gutterBottom>
                  {route.subTenant.name}
                </Typography>
              </>
            )}
          </InfoCard>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <InfoCard color={colors.blue[300]}>
            <Typography>Start Time</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(route.startTime).format("LT")}
            </Typography>

            <Typography>Off Property Time</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(route.offPropertyTime).format("LT")}
            </Typography>

            {isRegularRoute(route.extraData) && (
              <>
                <Typography>Working Days</Typography>
                <div className={classes.chipContainer}>
                  {route?.extraData.workingDays.map(day => (
                    <Chip key={day} label={day} />
                  ))}
                </div>
              </>
            )}

            {isSpecialRoute(route.extraData) && (
              <>
                {route.extraData.isRecurrent ? (
                  <>
                    <Typography>Runs every</Typography>
                    <Typography variant="h6" gutterBottom>
                      {route.extraData.recurrentDays &&
                        capitalize(route.extraData.recurrentDays[0])}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>Scheduled for </Typography>
                    {route.extraData.scheduledDates?.map(date => (
                      <Typography variant="h6" gutterBottom>
                        {moment(date.date).format("LL")}
                      </Typography>
                    ))}
                    {route.extraData.isMakeUp && route.extraData.makeUpReason && (
                      <>
                        <Typography>Reason </Typography>
                        <Typography variant="h6" gutterBottom>
                          {reasons[route.extraData.makeUpReason]}
                        </Typography>
                      </>
                    )}
                    <Typography>Completed </Typography>
                    <Typography variant="h6" gutterBottom>
                      {route.extraData.completedDate
                        ? moment(route.extraData.completedDate).format("lll")
                        : "No"}
                    </Typography>
                  </>
                )}
              </>
            )}
          </InfoCard>
        </Grid>
      </Grid>

      <Typography variant="h4">Areas</Typography>
      <Box height="500px">
        <MapApiWrapper>
          <DrawingMap
            color={route?.routeColor}
            mapOnly={true}
            initialCenter={
              route?.property.address.latitude &&
              route?.property.address.longitude
                ? {
                    lat: route?.property.address.latitude,
                    lng: route?.property.address.longitude
                  }
                : undefined
            }
            initialZoom={20}
            areas={areas}
            useAreaNames={true}
          />
        </MapApiWrapper>
      </Box>
      <Box marginTop={10}>
        <ServiceReportsTable routeId={route.id} />
      </Box>
    </Container>
  );
};

const getStatusChip = (report?: ServiceReport) => {
  let chipLabel = "Not started today";
  let chipColor: "default" | "primary" | "secondary" | undefined = undefined;
  if (report) {
    if (report.isCompleted) {
      chipLabel = "Completed";
      chipColor = "primary";
    } else {
      chipLabel = "Started";
      chipColor = "secondary";
    }
  }

  return <Chip label={chipLabel} color={chipColor} />;
};
export default ServiceRoutePage;
