import React from "react";
import {
  HomeRounded as HomeIcon,
  PeopleRounded as UsersIcon,
  BusinessRounded as FranchisesIcon,
  RadioButtonUnchecked,
  SupervisedUserCircleRounded,
  MapRounded,
} from "@material-ui/icons";
import Icon from "@mdi/react";
import { mdiAccountHardHat, mdiHomeCity, mdiAccountTie, mdiAlertCircle, mdiCubeOutline, mdiTableClock } from "@mdi/js";

import { DrawerLink } from "./interfaces";
// import UsersIcon from "@material-ui/icons/PeopleOutline";
// import HomeIcon from "@material-ui/icons/HomeOutlined";

/**
 * These are the links used inside the dashboard / panel. They all will be prepend with '/dashboard.
 */

const links: DrawerLink[] = [
  {
    to: "/dashboard",
    label: "Home",
    renderIcon: (props) => <HomeIcon {...props} />,
    exact: true,
  },
  {
    permission: {
      subject: "all",
      action: "manage",
    },
    to: "/dashboard/franchises",
    label: "Franchises",

    renderIcon: (props) => <FranchisesIcon {...props} />,
  },
  {
    matchRoutes: ["/dashboard/subFranchises", "/dashboard/franchise"],
    permission: {
      subject: "SubTenants",
      action: "read",
    },
    to: "/dashboard/franchise",
    label: "Franchise",

    renderIcon: (props) => <FranchisesIcon {...props} />,
  },
  {
    // matchRoutes: [
    //   '/dashboard/subFranchises',
    //   "/dashboard/franchise"
    // ],
    permission: {
      subject: "Customers",
      action: "read",
    },
    to: "/dashboard/properties",
    label: "Properties",

    renderIcon: (props) => <Icon size={1} path={mdiHomeCity} {...props} />,
  },
  {
    to: "/dashboard/serviceRoutes",
    label: "Service Routes",
    renderIcon: (props) => (
      <MapRounded {...props} />
    ),
    permission: {
      subject: "PropertyManagers",
      action: "read",
    },
  },
  {
    permission: {
      subject: "Users",
      action: "read",
    },
    to: "/dashboard/users",
    exact: false,
    label: "Users List",
    renderIcon: (props) => <UsersIcon {...props} />,
  },
  {
    permission: {
      subject: "Roles",
      action: "read",
    },
    to: "/dashboard/roles",
    exact: false,
    label: "Roles List",
    renderIcon: (props) => <SupervisedUserCircleRounded {...props} />,
  },
  // {
  //   to: "/dashboard/users",
  //   label: "Users Management",
  //   matchRoutes: ["/dashboard/users", "/dashboard/roles"],
  //   nodes: [
    
  //   ],
  //   renderIcon: (props) => <RadioButtonUnchecked {...props} />,
  // },
  {
    to: "/dashboard/valets",
    label: "Valets",
    renderIcon: (props) => (
      <Icon {...props} size={1} path={mdiAccountHardHat} />
    ),
    permission: {
      subject: "Valets",
      action: "read",
    },
  },
  {
    to: "/dashboard/propertyManagers",
    label: "Property Managers",
    renderIcon: (props) => (
      <Icon {...props} size={1} path={mdiAccountTie} />
    ),
    permission: {
      subject: "PropertyManagers",
      action: "read",
    },
  },
  {
    to: "/dashboard/incidents",
    label: "Incidents",
    renderIcon: (props) => (
      <Icon {...props} size={1} path={mdiAlertCircle} />
    ),
    permission: {
      subject: "Incidents",
      action: "read",
    },
  },
  {
    to: "/dashboard/supplyRequests",
    label: "Supply Requests",
    renderIcon: (props) => (
      <Icon {...props} size={1} path={mdiCubeOutline} />
    ),
    permission: {
      subject: "SupplyRequests",
      action: "read",
    },
  },
  {
    to: "/dashboard/timeOffRequests",
    label: "Time Off Requests",
    renderIcon: (props) => (
      <Icon {...props} size={1} path={mdiTableClock} />
    ),
    permission: {
      subject: "TimeOffRequests",
      action: "read",
    },
  },

];
export default links;
