import React, { useState, useEffect } from "react";
import { Container, Button, Paper, makeStyles } from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useFormik } from "formik";
import {
  CustomerFormValues,
  PropertyFormValues
} from "./components/interfaces";
import CustomerForm, {
  getCustomerFormInitialValues,
  getCustomerFormValidationSchema
} from "./components/CustomerForm";
import { useParams, useHistory } from "react-router-dom";
import useAbility from "../../shared/hooks/useAbility";
import PropertyForm, {
  getPropertyFormInitialValues,
  getPropertyFormValidationSchema
} from "./components/PropertyForm";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import CustomStepper, { ChildStep } from "../../shared/components/Stepper";
import { MapApiWrapper } from "../../shared/components/Map";
import { useCreateUpdateCustomer } from "../../apollo/customers/mutations";
import { Customer } from "../../apollo/customers/interfaces";
import { ApolloError } from "@apollo/client";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { useGetFullCustomerLazy } from "../../apollo/customers/queries";
import { rest } from "lodash";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3, 0)
  }
}));
const useCustomerFormPage = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [activeStep, setActiveStep] = useState(0);
  const isEdit = !!customerId;

  const ability = useAbility();
  const history = useHistory();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();

  const onCustomerLoadError = (error: ApolloError) => {
    sendErrorMessage("There was a problem trying to gather the customer.");
    console.warn(error);
  };
  const onCustomerSubmitSuccess = (customer: Customer) => {
    sendSuccessMessage(
      `Customer ${isEdit ? "updated" : "created"} successfully.`
    );
    history.push("/dashboard/properties/" + customer.id);
  };

  const onCustomerSubmitError = (error: ApolloError) => {
    sendErrorMessage(
      "There was a problem processing your request. Please verify the form."
    );
    console.warn(error);
  };

  const { submitCustomer, loading } = useCreateUpdateCustomer(
    onCustomerSubmitSuccess,
    onCustomerSubmitError,
    isEdit
  );
  const onCustomerFormSubmit = (values: CustomerFormValues) => {
    // if (isEdit) {
    //   submitCustomer({
    //     variables: {
    //       customer: customerForm.values,
    //     },
    //   });
    // } else {
    // console.log(values);
    submitCustomer({variables: {customer: values}})
    // setActiveStep(1);
    // }
  };
  const onPropertyFormSubmit = (values: PropertyFormValues) => {
    console.log("submitting...");

    // submitCustomer({
    //   variables: {
    //     property: values,
    //     customer: customerForm.values
    //   }
    // });
  };

  const setCustomerValues = (customer: Customer) => {
    customerForm.setValues(getCustomerFormInitialValues(customer));
    propertyForm.setValues(getPropertyFormInitialValues(customer.property));
  };
  const [getCustomer, fecthingCustomerResults] = useGetFullCustomerLazy(
    customerId,
    onCustomerLoadError
  );

  const customerForm = useFormik<CustomerFormValues>({
    initialValues: getCustomerFormInitialValues(),
    onSubmit: onCustomerFormSubmit,

    validationSchema: getCustomerFormValidationSchema(
      ability.can("manage", "SubTenants")
    )
  });

  const propertyForm = useFormik<PropertyFormValues>({
    initialValues: getPropertyFormInitialValues(),
    onSubmit: onPropertyFormSubmit,
    validationSchema: getPropertyFormValidationSchema(false)
  });


  const onNextPress = () => {
    if (activeStep === 0) {
      customerForm.submitForm();
    }
    if (activeStep === 1) {
      propertyForm.submitForm();
    }
  };

  const onBackPress = () => {
    setActiveStep(activeStep => activeStep - 1);
  };

  useEffect(() => {
    if (isEdit) {
      getCustomer();
    }
  }, []);

  useEffect(() => {
    if (fecthingCustomerResults.customer) {
      setCustomerValues(fecthingCustomerResults.customer);
    }
  }, [fecthingCustomerResults.customer]);

  return {
    model: {
      activeStep,
      customerForm,
      propertyForm,
      isEdit,
      ability,
      processLoading: loading || fecthingCustomerResults.loading
    },
    commands: {
      onNextPress,
      onBackPress
    }
  };
};
const CustomerFormPage: React.FC = ({}) => {
  const {
    model: {
      isEdit,
      customerForm,
      ability,
      propertyForm,
      processLoading,
      activeStep
    },
    commands: { onNextPress, onBackPress }
  } = useCustomerFormPage();
  const classes = useStyles();

  const steps: ChildStep[] = [
    {
      label: "Customer",
      node: (
        <form onSubmit={customerForm.handleSubmit}>
          <CustomerForm
            handleChange={customerForm.handleChange}
            setFieldValue={customerForm.setFieldValue}
            values={customerForm.values}
            errors={customerForm.errors}
            renderSubTenantSelect={ability.can("manage", "SubTenants")}
            renderSubmitButton={isEdit? 'Update' : 'Create'}
          />
        </form>
      ),
      onBackPress,
      onNextPress
    }
  ];

  // steps.push({
  //   label: "Property",
  //   node: (
  //     <form onSubmit={propertyForm.handleSubmit}>
  //       <PropertyForm
  //         handleChange={propertyForm.handleChange}
  //         setFieldValue={propertyForm.setFieldValue}
  //         values={propertyForm.values}
  //         errors={propertyForm.errors}
  //         isEdit={isEdit}
  //         selectedSubTenantId={customerForm.values.subTenantId}
  //         isStandalone={false}
  //       />
  //     </form>
  //   ),
  //   nextLabel: "Submit",
  //   onBackPress,
  //   onNextPress
  // });

  return (
    <MapApiWrapper>
      <Container>
        <LoadingBackdrop open={processLoading} />
        <BreadcrumbsSection
          sections={[
            { label: "Home", link: "/dashboard" },
            { label: "Customers", link: "/dashboard/customers" },
            { label: "Customers Form" }
          ]}
        />
        <PageHeader title={isEdit ? "Edit Customer" : "Create Customer"} />
        <Paper className={classes.paperContainer}>
          <Container>
            <CustomStepper activeStep={activeStep} steps={steps} />
          </Container>
        </Paper>
      </Container>
    </MapApiWrapper>
  );
};

export default CustomerFormPage;
