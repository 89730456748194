import React from "react";
import { Container, Paper, makeStyles } from "@material-ui/core";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";

import { Formik, Form } from "formik";
import { SpecialRouteFormValues } from "./components/interfaces";
import { useCreateUpdateSpecialRoute } from "../../apollo/serviceRoutes/mutations";
import { ServiceRoute } from "../../apollo/serviceRoutes/interfaces";
import { ApolloError } from "@apollo/client";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { useHistory, useParams } from "react-router-dom";
import SpecialRouteForm, {
  getSpecialRouteFormInitialValues,
  getSpecialRouteFormValidationSchema
} from "./components/SpecialRouteForm";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3, 0)
  }
}));

const useSpecialRouteFormPage = () => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const { routeId } = useParams<{ routeId: string }>();
  const isEdit = !!routeId;
  const history = useHistory();
  const onSubmitSuccess = (route: ServiceRoute) => {
    sendSuccessMessage(
      `Service Route ${isEdit ? "updated" : "created"} successfully.`
    );
    history.push("/dashboard/serviceRoutes/" + route.id);
  };

  const onSubmitError = (error: ApolloError) => {
    sendErrorMessage(
      `Something went wrong trying to ${
        isEdit ? "updated" : "created"
      } the service route.`
    );
    console.warn(error);
  };

  const { submitRoute, createRouteLoading } = useCreateUpdateSpecialRoute(
    isEdit,
    onSubmitSuccess,
    onSubmitError
  );

  const onFormSubmit = (values: SpecialRouteFormValues) => {
    const {property, ...rest} = values;
    submitRoute({
      variables: {
        route: rest
      }
    });
  };

  return {
    model: { isSubmitting: createRouteLoading, isEdit },
    commands: {
      onFormSubmit
    }
  };
};
const SpecialRouteFormPage: React.FC = () => {
  const {
    model: { isSubmitting, isEdit },
    commands: { onFormSubmit }
  } = useSpecialRouteFormPage();

  const classes = useStyles();
  return (
    <Container>
      <LoadingBackdrop open={isSubmitting} />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Service Routes", link: "/dashboard/serviceRoutes" },
          { label: "Service Route Form" }
        ]}
      />
      <PageHeader
        title={
          isEdit
            ? "Update Special Service Route"
            : "Create Special Service Route"
        }
      />
      <Paper className={classes.paperContainer}>
        <Container>
          <Formik
            initialValues={getSpecialRouteFormInitialValues()}
            onSubmit={onFormSubmit}
            validateOnMount={false}
            validateOnChange={false}
            validationSchema={getSpecialRouteFormValidationSchema()}
          >
            <Form>
              <SpecialRouteForm />
            </Form>
          </Formik>
        </Container>
      </Paper>
    </Container>
  );
};
export default SpecialRouteFormPage;
