import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextFieldProps,
  ListItemIcon,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import { useGetValetsSelect } from "../../../apollo/valets/queries";
import SearchInput from "../../../shared/components/Datatable/SearchInput";
import { ValetTableView } from "../../../apollo/valets/interfaces";

import { useField } from "formik";
import { CheckRounded } from "@material-ui/icons";

interface ValetSelectModalProps {
  name: string;
}
const limit = 5;
const ValetSelectModal: React.FC<ValetSelectModalProps & TextFieldProps> = ({
  name,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [{value: selectedValet}, meta, helpers] = useField<{
    id: string;
    name: string;
  } | null>(name);

  const [showModal, setShowModal] = useState(false);
 
  const { total, valets } = useGetValetsSelect({
    search,
    limit,
    sortDirection: "ASC",
    sortProperty: "name",
    offset: limit * (currentPage - 1),
  });

  const onValetClick = (valet: ValetTableView) => () => {
    helpers.setValue({ id: valet.id, name: valet.name });

    onModalClose();
  };

  const onInputClick = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Dialog
        open={showModal}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pick a Valet</DialogTitle>
        <DialogContent>
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Search a valet"
          />

          {valets && valets.length ? (
            <>
              <List>
                {valets?.map((valet) => (
                  <ListItem
                    key={valet.id}
                    button
                    onClick={onValetClick(valet)}
                    style={{ borderRadius: 20 }}
                  >
                    <ListItemAvatar>
                      <Avatar>{valet.name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={valet.name}
                      secondary={"@" + valet.username}
                    />
                    {(selectedValet && selectedValet.id === valet.id) && (
                      <ListItemIcon>
                        <CheckRounded color="secondary" />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.round(total / limit)}
                page={currentPage}
                onChange={(e, v) => setCurrentPage(v)}
              />
            </>
          ) : (
            <Typography>No valets have been found</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        error={!!meta.error}
        helperText={meta.error}
        {...props}
        InputProps={{
          readOnly: true,
        }}
        onClick={onInputClick}
        value={selectedValet ? selectedValet.name : ""}
      />
    </>
  );
};

export default ValetSelectModal;
