import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser($user: CreateUserDTO!) {
    createUser(user: $user) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserDTO!, $userId: String!) {
    updateUser(user: $user, id: $userId) {
      id
    }
  }
`;

export const DELETE_USERS = gql`
  mutation deleteUsers($userIds: [String!]!) {
    deleteUsers(ids: $userIds)
  }
`;
