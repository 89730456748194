import React, { useState } from "react";

import {
  Container,
  Paper,
  Button,
  Stepper,
  StepLabel,
  Step,
  TextField,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import SubTenantForm, {
  getSubTenantFormValidator,
} from "./components/SubTenantForm";
import { CREATE_TENANT } from "../../apollo/tenants/mutations";
import { CreateTenantVariables, Tenant } from "../../apollo/tenants/interfaces";
import { SubTenantFormValues } from "./components/interfaces";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import useSnackbar from "../../shared/hooks/useSnackbar";
interface TenantFormValues {
  name: string;
}

const initialTenantForm: TenantFormValues = {
  name: "",
};

const initialSubTenantForm: SubTenantFormValues = {
  name: "",
  phoneNumber: "",
  address: {
    city: "",
    line1: "",
    line2: "",
    state: "",
    zip: "",
  },
};

const validateTenantForm = (values: TenantFormValues) => {
  const errors: { name?: string } = {};
  if (!values.name) {
    errors.name = "This field is required.";
  }
  return errors;
};

const useTenantForm = () => {
  const history = useHistory();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  // const [showBackdrop, setShowBackdrop] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [createTenant, { loading }] = useMutation<
    { tenant: Tenant },
    CreateTenantVariables
  >(CREATE_TENANT, {
    onCompleted: (data) => {
      sendSuccessMessage("Franchise created successfully");
      history.replace("/dashboard/franchises");
    },
    onError: (error) => {
      sendErrorMessage(error.message);
    },
  });
  // const toggleBackdrop = () => {
  //   setShowBackdrop((t) => !t);
  // };

  const submitForm = async (
    tenantData: TenantFormValues,
    subTenantData?: SubTenantFormValues
  ) => {
    createTenant({
      variables: {
        tenant: tenantData,
        subTenant: subTenantData,
      },
    });
  };
  const tenantForm = useFormik<TenantFormValues>({
    onSubmit: (values) => {
      //if we're on the first step means we should move to next step and don't submit to server
      if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
      //if we're not, means the next step was skipped and we need to submit the form without the subTenant;
      submitForm(values);
    },
    validate: validateTenantForm,

    initialValues: initialTenantForm,
  });

  const subTenantForm = useFormik<SubTenantFormValues>({
    onSubmit: (values) => {
      submitForm(tenantForm.values, values);
    },
    initialValues: initialSubTenantForm,
    validate: getSubTenantFormValidator(false),
    validateOnChange: false,
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    //if we're on the first step we will just call the submit logic. If the form is valid, the onSubmit method from
    //formik will move to the next step.
    if (activeStep === 0) {
      tenantForm.submitForm();
    }

    //if we're on the second and this action is called means that the second form is filled and we need to process it.
    if (activeStep === 1) {
      subTenantForm.submitForm();
    }
  };

  return {
    model: {
      activeStep,
      showBackdrop: loading,
      tenantForm,
      subTenantForm,
    },
    commands: {
      // toggleBackdrop,
      nextStep: handleNext,
      prevStep: handleBack,
    },
  };
};

const TenantFormPage: React.FC = () => {
  const {
    model: { activeStep, showBackdrop, subTenantForm, tenantForm },
    commands: { nextStep, prevStep },
  } = useTenantForm();

  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "../" },
          { label: "Franchises", link: "./" },
          { label: "Franchise Form" },
        ]}
      />
      <LoadingBackdrop open={showBackdrop} />
      <PageHeader title="Creating a Franchise" />
      <Paper>
        <Container>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>Fill Franchise Info</StepLabel>
            </Step>
            <Step>
              <StepLabel optional>Create a Sub-Franchise</StepLabel>
            </Step>
          </Stepper>

          {activeStep === 0 ? (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    id="name"
                    label="Name"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    value={tenantForm.values.name}
                    error={!!tenantForm.errors.name}
                    helperText={tenantForm.errors.name}
                    onChange={tenantForm.handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          ) : (
            <SubTenantForm
              onSubmit={subTenantForm.handleSubmit}
              errors={subTenantForm.errors}
              values={subTenantForm.values}
              handleChange={subTenantForm.handleChange}
              setFieldValue={subTenantForm.setFieldValue}
            />
          )}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ margin: "20px 0" }}
              container
              justify="flex-end"
            >
              <ButtonGroup
                color="secondary"
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button disabled={activeStep === 0} onClick={prevStep}>
                  Back
                </Button>
                {activeStep === 1 && (
                  <Button onClick={tenantForm.submitForm}>Skip</Button>
                )}
                <Button onClick={nextStep}>
                  {activeStep === 0 ? "Next" : "Finish"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};

export default TenantFormPage;
