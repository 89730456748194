import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Main from "./Main";
import Home from "../pages/Home/HomePage";
import PrivacyPolicyPage from "../pages/Home/PrivacyPolicyPage";
import MobileAppPolicyPage from "../pages/Home/MobileAppPolicyPage";
import { PDFViewer } from "@react-pdf/renderer";
import IncidentPDF from "../pages/Incidents/components/IncidentPDF";
import RoleRoute from "./RoleRoute";
import IncidentPDFPage from "../pages/Incidents/IncidentPDFPage";
import ValetPreRegistrationPage from "../pages/PreCreations/ValetPreRegistrationPage";
import ExternalCustomerAgreementFormPage from "../pages/Agreements/ExternalCustomerAgreementFormPage";
import PropertyCustomerPage from "../pages/Customers/PropertyCustomerPage";
import ServiceReportPage from "../pages/ServiceRoutes/ServiceReportPage";
import CustomerServiceReportPage from "../pages/Customers/CustomerServiceReportPage";
const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/privacy" exact>
          <PrivacyPolicyPage />
        </Route>
        <Route path="/app/privacy" exact>
          <MobileAppPolicyPage />
        </Route>
        <RoleRoute path="/incidents/pdf/:incidentId" can={["read", "Incidents"]}  exact>
         <IncidentPDFPage/>
        </RoleRoute>
        <PrivateRoute path="/dashboard">
          <Main />
        </PrivateRoute>

        <PrivateRoute isCustomer path="/myProperty/reports/:reportId" exact>
            <CustomerServiceReportPage />
        </PrivateRoute>

        <PrivateRoute isCustomer path="/myProperty" exact>
            <PropertyCustomerPage/>
        </PrivateRoute>

        <Route path="/pre-registration/:hash" exact>
          <ValetPreRegistrationPage />
        </Route>
        <Route path="/agreement/:hash" exact>
          <ExternalCustomerAgreementFormPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
