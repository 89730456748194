import { Container, Fab, Paper } from "@material-ui/core";
import { cleanup } from "@testing-library/react";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCreateSupplyRequest } from "../../apollo/supplyRequests/mutations";
import { useGetSupplyItemsPaginated } from "../../apollo/supplyRequests/queries";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import PageHeader from "../../shared/components/PageHeader";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { SupplyItemsList } from "./components/SupplyItemsList";
import SupplyRequestForm, {
  getSupplyRequestFormValidationSchema,
  getSupplyRequestFormValues,
  SupplyRequestFormValues
} from "./components/SupplyRequestForm";

const SupplyRequestFormPage: React.FC = ({}) => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const {
    createRequest,
    createRequestError,
    createRequestLoading,
    request
  } = useCreateSupplyRequest();
  const history = useHistory();
  const onSubmit = (values: SupplyRequestFormValues) => {
    if (values.route && values.valet) {
      createRequest({
        items: values.selections.map(s => ({
          itemId: s.item.id,
          quantity: s.quantity,
          variety: s.variety
        })),
        routeId: values.route.id,
        valetId: values.valet.id
      });
    }
  };

  useEffect(() => {
    if (request) {
      sendSuccessMessage("Request generated succesfully.");
      history.goBack();
    }
  }, [request]);

  useEffect(() => {
    if (createRequestError) {
      sendErrorMessage(
        "Error generating the request: " + createRequestError.message
      );
    }
  }, [createRequestError]);

  return (
    <Container>
      <LoadingBackdrop open={createRequestLoading} />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Supply Requests", link: "/dashboard/supplyRequests/" },
          { label: "Supply Requests Form" }
        ]}
      />
      <PageHeader title="Generate Supply Request" />
      <Formik
        onSubmit={onSubmit}
        initialValues={getSupplyRequestFormValues()}
        validationSchema={getSupplyRequestFormValidationSchema()}
      >
        {({ submitForm }) => (
          <>
            <SupplyRequestForm />
            <Fab
              variant="extended"
              color="primary"
              onClick={submitForm}
              style={{ position: "fixed", bottom: 30, right: 30 }}
            >
              generate
            </Fab>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default SupplyRequestFormPage;
