import React, { useEffect, useState } from "react";
import {
  Fade,
  Container,
  Paper,
  makeStyles,
  Grid,
  Button,
  colors,
  Chip,
  FormControlLabel,
  Switch,
  Typography
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useGetValetProfile } from "../../apollo/valets/queries";
import { useParams, useHistory } from "react-router-dom";
import LoadingSkeleton from "../../shared/components/LoadingSkeleton";
import UserDetailsSection from "../Users/components/UserDetailsSection";
import FabMenu, { FabMenuAction } from "../../shared/components/FabMenu";
import { AddCircle, DeleteRounded, EditRounded } from "@material-ui/icons";
import useAbility from "../../shared/hooks/useAbility";
import DeleteModal from "../../shared/components/DeleteModal";
import { DELETE_USERS } from "../../apollo/users/mutations";
import AgreementsTable from "./components/AgreementsTable";
import Link from "../../navigation/Link";
import InfoCard from "../../shared/components/InfoCard";
import { useChangeValetDisabledStatus } from "../../apollo/valets/mutations";
import SubscriptionButton from "../../shared/components/SubscriptionButton";
import RegularAgreementsTable from "./components/RegularAgreementsTable";
import CoverAgreementsTable from "./components/CoverAgreementsTable";

const useStyles = makeStyles(theme => ({
  valetSectionContainer: {
    position: "relative",
    height: "100%"
  },
  detailsContainer: {
    position: "relative"
  }
}));

const useValetPage = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { valetId } = useParams<{ valetId: string }>();
  const { valet, valetRequestLoading, valetRequestError } = useGetValetProfile(
    valetId
  );
  const { changeDisabledStatus } = useChangeValetDisabledStatus();
  const [isDisabled, setIsDisabled] = useState(false);

  const history = useHistory();

  const ability = useAbility();
  const onDeleteClick = () => {
    setShowDeleteModal(true);
  };
  const onEditClick = () => {
    history.push(`/dashboard/valets/${valetId}/edit`, {
      valet: valet
    });
  };
  const onCreateAgreementClick = () => {
    history.push(`/dashboard/valets/${valetId}/agreements`, {
      valet: valet,
      valetId: valetId
    });
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    history.replace("/dashboard/valets");
  };

  const onIsDisabledChange = (e: any, checked: boolean) => {
    setIsDisabled(checked);
    changeDisabledStatus(valetId, checked);
  };

  useEffect(() => {
    if (valet && valet.isDisabled) {
      setIsDisabled(true);
    }
  }, [valet]);

  return {
    model: {
      showDeleteModal,
      valet,
      isLoading: valetRequestLoading,
      ability,
      valetId,
      isDisabled
    },
    commands: {
      onDeleteClick,
      onEditClick,
      onDeleteModalClose,
      onDeleteSuccess,
      onCreateAgreementClick,
      onIsDisabledChange
    }
  };
};
const ValetPage: React.FC = ({}) => {
  const {
    model: { isLoading, valet, ability, showDeleteModal, valetId, isDisabled },
    commands: {
      onDeleteClick,
      onEditClick,
      onDeleteModalClose,
      onDeleteSuccess,
      onCreateAgreementClick,
      onIsDisabledChange
    }
  } = useValetPage();
  const classes = useStyles();
  if (!valet) {
    return <LoadingSkeleton />;
  }

  const actions: FabMenuAction[] = [
    {
      name: "Delete",
      icon: <DeleteRounded />,
      onClick: onDeleteClick,
      disabled: ability.cannot("delete", "Users")
    },
    {
      name: "Edit",
      icon: <EditRounded />,
      onClick: onEditClick,
      disabled: ability.cannot("update", "Users")
    }
  ];

  return (
    <Fade in={true}>
      <Container>
        <DeleteModal
          open={showDeleteModal}
          ids={valet ? [valet.user.id] : []}
          mutation={DELETE_USERS}
          customVariableName="userIds"
          onClose={onDeleteModalClose}
          onSuccess={onDeleteSuccess}
          title="Delete Valet"
          description="Are you sure you want to delete this valet? This action cannot be undone."
        />
        <BreadcrumbsSection
          sections={[
            { label: "Home", link: "/dashboard" },
            { label: "Valets", link: "/dashboard/valets" },
            { label: valet?.user ? valet.user.username : "Valet" }
          ]}
        />
        <PageHeader title={"Valet Details"} />
       

        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12}>
            <InfoCard
              className={classes.valetSectionContainer}
              color={colors.brown["300"]}
            >
              <FabMenu actions={actions} />
              <Container>
                {valet.isInTimeOff && (
                  <div>
                    <Chip label="Time-Off" />
                  </div>
                )}
                {ability.can("delete", "Valets") && (
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={isDisabled}
                        name="checkedB"
                        color="primary"
                        onChange={onIsDisabledChange}
                      />
                    }
                    label="Is Disabled?"
                  />
                )}
                 <SubscriptionButton id={valet.id} type="Valets"/>
                <UserDetailsSection user={valet?.user} />
              </Container>
            </InfoCard>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Agreements</Typography>
            <Grid container style={{ marginBottom: 10 }} justify="flex-end">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Link to={`/dashboard/valets/${valetId}/agreements`}>
                  <Button variant="contained" color="primary" fullWidth>
                    Start Agreement Processs
                  </Button>
                </Link>
              </Grid>
            </Grid>

            {/* <AgreementsTable valetId={valetId} /> */}
            <Typography variant="h5" gutterBottom>Regular Agreements</Typography>
            <RegularAgreementsTable valetId={valetId}/>


            <Typography variant="h5" gutterBottom style={{marginTop: 20}}>Cover Agreements</Typography>
            <CoverAgreementsTable valetId={valetId}/>
          </Grid>
        </Grid>
      </Container>
    </Fade>
  );
};

export default ValetPage;
