import React from "react";
import { FormValues } from "./interfaces";
import { getInitialDateSelectionValues } from "./DateSelectionStep";
import { getSigningFormInitialValues } from "./SigningStep";
import { getTypeSelectionFormInitialValues } from "./TypeSelectionStep";
import {
  ValetAgreement,
  ValetContractType
} from "../../../apollo/valetAgreements/interface";
import { getCoverInitialValues, getCoverValidationSchema } from "./CoverFields";
import { Valet } from "../../../apollo/valets/interfaces";
import { getRatesFormInitialValues } from "./RatesStep";

export const getRandomComponentKey = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const getUnitInitialValues = (
  type: ValetContractType,
  agreement?: ValetAgreement
) => {
  switch (type) {
    case "REGULAR":
      return null;
    case "COVER":
      return getCoverInitialValues(agreement);
  }
};
export const getExtraDataInitialValues = (agreement?: ValetAgreement) => {
  return { extraData: agreement?.extraData };
};

export const loadAgreementsValues = (
  valetId: string,
  currentType: ValetContractType,
  agreement?: ValetAgreement,
  valet?: Valet
): FormValues => {
  console.log(agreement)
  return {
    ...getRatesFormInitialValues(agreement),
    ...getUnitInitialValues(currentType, agreement),
    ...getTypeSelectionFormInitialValues(valetId, agreement, valet),
    ...getInitialDateSelectionValues(agreement),
    ...getSigningFormInitialValues(agreement),
    ...getExtraDataInitialValues(agreement),
    ...getExtraDataInitialValues(agreement),
    ...getCoverInitialValues(agreement)
  };
};
