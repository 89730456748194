import { gql, ApolloError } from "@apollo/client";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  ValetProfile,
  ValetForForm,
  Valet,
  ValetTableView
} from "./interfaces";
import { UserForForm } from "../users/interfaces";
import { useEffect, useRef } from "react";
import { PaginationArgs } from "../shared/interfaces";

export const GET_VALETS_PAGINATED = gql`
  query getValetsPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String # $subTenantId: String
    $subTenantId: String
    $tenantId: String
    $isDisabled: Boolean
  ) {
    valetsTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId
      tenantId: $tenantId
      isDisabled: $isDisabled
    ) {
      totalsCount
      nodes {
        id
        username
        # tenantId
        tenant
        subTenant
        name
        email
        createdAt
      }
    }
  }
`;

const GET_VALET_PROFILE = gql`
  query getValetProfile($id: String!) {
    valet(id: $id) {
      id
      name
      isDisabled
      isInTimeOff
      user {
        id
        username
        addressFormatted
        firstName
        lastName
        email
        username
        phone
        emergencyPhone
        roles {
          id
          name
        }
      }
    }
  }
`;

export const GET_VALET_FOR_SELECT = gql`
  query getSubFranchisesValets(
    $subFranchiseId: String
    $valetIdException: String
  ) {
    getSubFranchisesValets(
      subFranchiseId: $subFranchiseId
      valetIdException: $valetIdException
    ) {
      valets {
        id
        name
      }
    }
  }
`;

export const useGetValetsForSelect = (
  onCompleted?: (valets: Valet[]) => void,
  subFranchiseId?: string,
  valetIdException?: string
) => {
  const { data, error } = useQuery<
    { getSubFranchisesValets: { valets: Valet[] } },
    {
      subFranchiseId?: string;
      valetIdException?: string;
    }
  >(GET_VALET_FOR_SELECT, {
    variables: {
      subFranchiseId,
      valetIdException
    },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(data.getSubFranchisesValets.valets);
      }
    }
  });
  return {
    data: data?.getSubFranchisesValets.valets,
    loadingRouteError: error
  };
};
export const useGetValetProfile = (id: string) => {
  const { data, loading, error } = useQuery<
    { valet: ValetProfile },
    { id: string }
  >(GET_VALET_PROFILE, {
    variables: {
      id
    },
    fetchPolicy: "cache-and-network"
  });

  return {
    valet: data?.valet,
    valetRequestLoading: loading,
    valetRequestError: error
  };
};

const GET_VALET_FOR_FORM = gql`
  query getValetForForm($id: String!) {
    valet(id: $id) {
      user {
        id
        firstName
        lastName
        email
        username
        phone
        emergencyPhone
        # password
        subTenantId
        address {
          line1
          line2
          city
          state
          zip
          latitude
          longitude
        }
        roles {
          id
          name
        }
      }
    }
  }
`;

export const useGetValetUserLazy = (
  valetId: string,
  onLoadSuccess?: (user: UserForForm) => void,
  onError?: (err: ApolloError) => void
) => {
  const onCompleted = (data: { valet: ValetForForm }) => {
    if (onLoadSuccess) {
      onLoadSuccess(data.valet.user);
    }
  };
  const [getUser, { data, loading, error }] = useLazyQuery<
    { valet: ValetForForm },
    { id: string }
  >(GET_VALET_FOR_FORM, {
    variables: {
      id: valetId
    },
    fetchPolicy: "cache-and-network",
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    getUser,
    isGettingUser: loading,
    gettingUserError: error,
    user: data?.valet.user
  };
};

export const GET_VALETS_SELECT_PAGINATED = gql`
  query getValetsSelectPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String # $subTenantId: String
    $subTenantId: String
    $tenantId: String
  ) {
    valetsTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      tenantId: $tenantId
      subTenantId: $subTenantId
    ) {
      totalsCount
      nodes {
        id
        username
        name
        email
      }
    }
  }
`;

export const useGetValetsSelect = ({
  limit,
  offset,
  search,
  sortDirection,
  sortProperty,
  subTenantId
}: PaginationArgs) => {
  const [getValets, { data, loading, error, fetchMore }] = useLazyQuery<
    {
      valetsTable: {
        totalsCount: number;
        nodes: ValetTableView[];
      };
    },
    PaginationArgs
  >(GET_VALETS_SELECT_PAGINATED);
  const initial = useRef(false);
  useEffect(() => {
    // console.log({ limit });
    getValets({
      variables: {
        limit,
        offset,
        search,
        sortDirection,
        sortProperty,
        subTenantId
      }
    });
  }, [search, offset]);

  useEffect(() => {
    initial.current = true;
  }, []);

  return {
    valets: data?.valetsTable.nodes,
    total: data?.valetsTable.totalsCount || 0
  };
};

const GET_VALET_SELECT_ITEM = gql`
  query getValetSelectItem($id: String!) {
    valet(id: $id) {
      id
      name
      user {
        username
      }
    }
  }
`;

export const useGetValetSelectItemLazy = () => {
  const [get, { data, error, loading }] = useLazyQuery<
    { valet: Valet },
    { id: string }
  >(GET_VALET_SELECT_ITEM);

  const getValet = (id: string) => {
    get({ variables: { id } });
  };

  return {
    getValet,
    valet: data
      ? {
          id: data.valet.id,
          name: data.valet.name,
          username: data.valet.user.username
        }
      : undefined
  };
};
