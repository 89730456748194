import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
  DrawingManager,
  Polyline
} from "@react-google-maps/api";
import { TextField, makeStyles, Grid } from "@material-ui/core";
import { useManualFetch } from "../../hooks/useFetch";
import { GeocodeResponse, Address, Area } from "./interfaces";
import { getAddressFromGeo } from "./utils";
import AreaPolygon from "./AreaPolygon";
import moment from "moment";
const dotIcon = require("./icons/dot.svg");
const flagIcon = require("./icons/flag.svg");

const libraries = ["places", "drawing"];

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
    // alignItems: 'flex-start'
  },
  mapContainer: {
    // height: "100%",
    borderRadius: 20,
    flex: 1
  },
  searchBoxContainer: {
    "& > div": {
      flex: 1
    }
  },
  searchbox: {
    margin: theme.spacing(2, 0)
  }
}));

const prodKey = "AIzaSyD5w025L-bs7N1HL2XTxH-8Ffi_46fqwSc";

export interface VanillaMapProps {
  initialCenter?: { lat: number; lng: number };
  initialZoom?: number;
  onGeolocationFound?: (address: Address) => void;
  currentLocation?: { lat: number; lng: number } | null;
  disableMarking?: boolean;
  mapOnly?: boolean;
  draggable?: boolean;
  searchOnly?: boolean;
  track?: {
    color?: string;
    paths: { lat: number; lng: number; timestamp: string | Date }[];
  };
  onMapClick?: (point: { latitude: number; longitude: number }) => void;
  markers?: {
    lng: number;
    lat: number;
    label?: string;
    icon?: string;
    title?: string;
  }[];
  areas?: Area[];
}

export const VanillaMap: React.FC<VanillaMapProps> = ({
  onGeolocationFound,
  children,
  mapOnly,
  draggable = true,
  initialCenter,
  initialZoom,
  disableMarking,
  markers = [],
  areas = [],
  track,
  onMapClick: onMapClickProp,
  searchOnly,
  currentLocation: currentLocationProp = null
}) => {
  const classes = useStyles();
  const [center, setCenter] = useState(
    initialCenter
      ? initialCenter
      : currentLocationProp
      ? currentLocationProp
      : {
          lat: 39.6408156,
          lng: -101.7502483
        }
  );
  const [zoom, setZoom] = useState(
    initialZoom ? initialZoom : currentLocationProp ? 7 : 5
  );
  const [currentLocation, setCurrentLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(currentLocationProp);
  const { data, fetchData } = useManualFetch<GeocodeResponse>(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation?.lat},${currentLocation?.lng}&key=${prodKey}`
  );
  const searchbox = useRef<google.maps.places.SearchBox | null>(null);
  const onSearchBoxLoad = (sb: google.maps.places.SearchBox) => {
    searchbox.current = sb;
  };

  const onMapClick = (e: google.maps.MouseEvent) => {
    if (onMapClickProp) {
      onMapClickProp({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
      return;
    }
    if (!disableMarking)
      setCurrentLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };
  const onPlacesChanged = () => {
    const places = searchbox.current?.getPlaces();
    if (places && places[0].geometry) {
      console.log({
        lat: places[0].geometry?.location.lat(),
        lng: places[0].geometry?.location.lng()
      });

      setCenter({
        lat: places[0].geometry?.location.lat(),
        lng: places[0].geometry?.location.lng()
      });
      if (!disableMarking)
        setCurrentLocation({
          lat: places[0].geometry?.location.lat(),
          lng: places[0].geometry?.location.lng()
        });
      setZoom(17);
    }
  };

  useEffect(() => {
    if (currentLocation?.lat && onGeolocationFound) fetchData();
  }, [currentLocation?.lat, currentLocation?.lng]);

  useEffect(() => {
    if (data && onGeolocationFound) {
      onGeolocationFound(getAddressFromGeo(data.results[0]));
    }
  }, [data]);
  return (
    <div className={classes.container}>
      {!mapOnly && (
        <Grid container>
          <Grid item xs={12} container className={classes.searchBoxContainer}>
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <TextField
                className={classes.searchbox}
                fullWidth
                variant="outlined"
                label="Search Place"
              />
            </StandaloneSearchBox>
          </Grid>
        </Grid>
      )}

      {!searchOnly && (
        <GoogleMap
          mapContainerClassName={classes.mapContainer}
          center={center}
          zoom={zoom}
          options={{
            draggable: draggable
          }}
          onClick={onMapClick}
        >
          {track && (
            <>
              <Polyline
                path={track.paths}
                options={{
                  strokeColor: track.color
                }}
              />
              {track.paths.map((p, i) => {
                const time = moment(p.timestamp).format("LT");
                if (i === 0) {
                  return (
                    <Marker
                      key={i + "post"}
                      position={p}
                      icon={{ url: flagIcon }}
                      title={`Start - ${time}`}
                    />
                  );
                }

                if (i === track.paths.length - 1) {
                  return (
                    <Marker
                      key={i + "post"}
                      position={p}
                      icon={{ url: flagIcon }}
                      title={`End - ${time}`}
                    />
                  );
                }

                return (
                  <Marker
                    key={i + "post"}
                    icon={{
                      url: dotIcon
                    }}
                    position={p}
                    title={`${time}`}
                  />
                );
              })}
            </>
          )}
          {markers.map((marker, i) => (
            <Marker
              position={marker}
              label={marker.label}
              key={marker.label ? marker.label + i : "m-" + i}
              icon={marker.icon ? { url: marker.icon } : undefined}
              title={marker.title}
            />
          ))}
          {currentLocation && (
            <Marker
              position={currentLocation}
              animation={google.maps.Animation.BOUNCE}
            />
          )}
          {areas.map((area, i) => (
            <AreaPolygon
              key={area.points[0].latitude}
              area={area}
              useAreaNames={true}
            />
          ))}
          {children}
          {/* Child components, such as markers, info windows, etc. */}
          <></>
        </GoogleMap>
      )}
    </div>
  );
};

interface MapApiWrapperProps {
  onLoad?: () => void;
}

export const MapApiWrapper: React.FC<MapApiWrapperProps> = ({
  children,
  onLoad
}) => {
  return (
    <LoadScript
      id={"g-maps" + Math.floor(Math.random() * 100 + 1)}
      googleMapsApiKey={prodKey}
      libraries={libraries}
      onLoad={onLoad}
    >
      {children}
    </LoadScript>
  );
};
const Map: React.FC<VanillaMapProps & MapApiWrapperProps> = ({
  onLoad,
  ...props
}) => {
  return (
    <MapApiWrapper onLoad={onLoad}>
      <VanillaMap {...props} />
    </MapApiWrapper>
  );
};
export default Map;
