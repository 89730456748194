import * as Firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBaPeaEUpZU0bvu96vK_QWhtJfLVxG0NtA",
  authDomain: "disposal-valet-281215.firebaseapp.com",
  databaseURL: "https://disposal-valet-281215.firebaseio.com",
  projectId: "disposal-valet-281215",
  storageBucket: "disposal-valet-281215.appspot.com",
  messagingSenderId: "41658863677",
  appId: "1:41658863677:web:b9b2696c7010d7ca121a54",
  measurementId: "G-S9QG30CL5M",
};
let app: Firebase.app.App;
let lastSubscription: Firebase.Unsubscribe;

export const initFirebaseApp = () => {
  if (!Firebase.apps.length) app = Firebase.initializeApp(firebaseConfig);
  else app = Firebase.apps[0];
};
export const requestNotificationPermission = async () => {
  try {
    initFirebaseApp();

    const messaging = app.messaging();

    //await messaging.requestPermission();
    const permission = await Notification.requestPermission();
    if (permission === "denied") {
      console.log("Permission wasn't granted. Allow a retry.");
      return null;
    } else if (permission === "default") {
      console.log("The permission request was dismissed.");
      return null;
    }
    const token = await messaging.getToken();
    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type MessageCallback = (payload: any) => void;
export const setOnNotificationReceived = (callBack: MessageCallback) => {
  if (lastSubscription) lastSubscription();
  lastSubscription = app.messaging().onMessage(callBack);
};
