import { Container, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import ValetPreCreationForm from "./components/ValetPreCreationForm";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3, 0)
  }
}));
const ValetPreCreationFormPage: React.FC = ({}) => {
  const { id } = useParams<{ id?: string }>();
  const classes = useStyles();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Valets", link: "/dashboard/valets" },
          { label: "Valet Pre-Registration Form" }
        ]}
      />

      <PageHeader
        title={
          id ? "Edit Valet Pre-Registration" : "Create Valet Pre-Registration"
        }
      />

      <Paper className={classes.paperContainer}>
        <Container>
          <ValetPreCreationForm editingPreCreationId={id} />
        </Container>
      </Paper>
    </Container>
  );
};

export default ValetPreCreationFormPage;
