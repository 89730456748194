import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import useOngoingProcess from "../../../shared/hooks/useOngoingProcess";
import { DocumentNode } from "@apollo/client";

interface Props {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  ids: string[];
  title?: string;
  description?: string;
  successMessage?: string;
  mutation: DocumentNode;
  customVariableName?: string;
}

const DeleteModal: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onError,
  title,
  description,
  ids,
  successMessage,
  mutation,
  customVariableName = "ids",
}) => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const { setOngoingProcess } = useOngoingProcess();
  const [deleteSubs] = useMutation<any, any>(mutation, {
    onCompleted: () => {
      setOngoingProcess(false);
      sendSuccessMessage(
        successMessage ? successMessage : "Item(s) deleted successful."
      );
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      setOngoingProcess(false);
      sendErrorMessage(error.message);
      if (onError) onError();
    },
  });

  const onDelete = () => {
    setOngoingProcess(true);
    deleteSubs({
      variables: {
        [customVariableName]: ids,
      },
    });
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
