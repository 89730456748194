import React from "react";
import { useTheme, Theme } from "@material-ui/core";
import { Icon as SVGIcon } from "@mdi/react";
interface IconProps {
  path: string;
  size?: number;
  color?: ColorSelectionType;
  style?: React.CSSProperties;
}
type ColorSelectionType =
  | "textSecondary"
  | "primary"
  | "secondary"
  | "inherit"
  | "initial"
  | "textPrimary"
  | "error";
const getColor = ({ palette }: Theme, color: ColorSelectionType) => {
  switch (color) {
    case "primary":
      return palette.primary.main;
    case "secondary":
      return palette.secondary.main;
    case "textPrimary":
      return palette.text.primary;
    case "error":
      return palette.error.main;
  }
};
const Icon: React.FC<IconProps> = ({
  path,
  size = 1,
  color = "inherit",
  style,
}) => {
  const theme = useTheme();

  return (
    <SVGIcon
      size={size}
      color={getColor(theme, color)}
      style={style}
      path={path}
    />
  );
};

export default Icon;
