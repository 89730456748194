import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import { Incident } from "../../../apollo/incidents/interfaces";
import moment from "moment";
import { File } from "../../../apollo/shared/interfaces";

const styles = StyleSheet.create({
  page: {},
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    fontSize: 22,
    marginBottom: 10
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "medium",
    marginBottom: 10
  },
  date: {
    fontSize: 14,
    color: "#757575",
    fontWeight: "medium"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5
  },
  chip: {
    fontSize: 14,
    padding: 10,
    borderRadius: 20,
    backgroundColor: "#E0E0E0"
  },
  divider: {
    marginVertical: 10,
    width: "100%",
    borderColor: "#E0E0E0",
    borderWidth: 0.5
  },
  miniTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 2
  },
  text: {
    fontSize: 14,
    fontWeight: "light",
    marginBottom: 20
  },
  italicText: {
    fontSize: 14,
    fontWeight: "light",
    marginBottom: 5,
    fontStyle: "italic"
  }
});

const INCIDENT = {
  isBuilding: false,
  id: "473c2cf7-d0e1-4fa3-b4ec-fca66ee92323",
  createdAt: "2021-02-16T19:21:21.606Z",
  pictures: [
    {
      id: "asassas",
      url: "/files/incidents/58d868e5-523b-4c76-a8c1-0cdf06b7b037.jpeg"
    },
    {
      id: "asasssdsdfsdas",
      url: "/files/incidents/678b32de-da2d-4836-9bf1-f3aa66962027.jpg"
    }
  ],
  category: {
    id: "5d93d5af-715c-4c0e-b266-56c77960cff2",
    name: "Test Incident",
    __typename: "IncidentCategoryDTO"
  },
  subCategory: {
    id: "179b9323-4de6-4029-bc99-276ca05991b7",
    name: "Test Subcategory",
    __typename: "IncidentSubCategoryDTO"
  },
  route: {
    id: "2041893b-f291-4a18-9d1f-00e30856bec6",
    routeCode: "RS23",
    property: {
      id: "8017e523-68c2-40bf-b2c1-23b8f3aa9e7a",
      name: "Residencial Consuelo III",
      __typename: "PropertyDTO"
    },
    __typename: "ServiceRouteDTO"
  },
  notes:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  apartmentNumber: "A1",
  valet: {
    name: "Moises Ynfante",
    id: "f905f314-97aa-402d-b7df-8d3661eac5b3",
    __typename: "ValetDTO"
  },
  serviceReportId: "7deb389d-1aa9-43fa-a014-57d0dfc6cb28",
  __typename: "IncidentDTO"
};
interface IncidentPDFProps {
  incident: Incident;
}
const IncidentPDF: React.FC<IncidentPDFProps> = ({ incident }) => {
  const reporterName = incident.valet?.name || incident?.reporter?.name;
  return (
    <Document title={`Incident-Report-${incident.route.routeCode}-${incident.route.property.name}`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Incident Report</Text>
          <Text style={styles.date}>
            {moment(incident.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </Text>
          <Text style={styles.subtitle}>
            Reported on service route: {incident.route.routeCode} -{" "}
            {incident.route.property.name}
          </Text>
          <View style={styles.row}>
            <Text style={styles.chip}>{incident.category.name}</Text>
            {incident.subCategory && (
              <Text style={{ ...styles.chip, marginHorizontal: 4 }}>
                {incident.subCategory.name}
              </Text>
            )}
          </View>
          <Text style={styles.subtitle}>
            Reported By: {reporterName}
          </Text>
          <View style={styles.divider} />
          {!incident.isBuilding && (
            <View>
              <Text style={styles.miniTitle}>Apartment Number</Text>
              <Text style={styles.text}>{incident.apartmentNumber}</Text>
            </View>
          )}
          <Text style={styles.miniTitle}>Valet's Notes</Text>
          {incident.notes ? (
            <Text style={styles.text}>{incident.notes}</Text>
          ) : (
            <Text style={styles.italicText}>Nothing specified</Text>
          )}
          {!!incident.pictures && !!incident.pictures.length && (
            <View>
              <Text style={styles.miniTitle}>Uploaded Images</Text>
              <View style={{ flexDirection: "column" }}>
                {(incident.pictures as File[]).map(img => (<View>
                  <Image
                    key={img.id}
                    src={img.url}
                    style={{
                      objectFit: "scale-down",
                      marginVertical: 10
                    }}
                  /></View>
                ))}
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default React.memo(IncidentPDF);
