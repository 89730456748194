import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  colors,
  Box,
  GridList,
  GridListTile,
  makeStyles,
  Portal,
  Button,
  Chip,
  Divider,
  IconButton,
  Tooltip
} from "@material-ui/core";
import BreadcrumbsSection from "../../../shared/components/BreadcrumbsSection";
import PageHeader from "../../../shared/components/PageHeader";
import IncidentsTable from "./IncidentsTable";
import {
  useGetIncidentView,
  useGetIncidentViewLazy
} from "../../../apollo/incidents/queries";
import { useHistory, useParams } from "react-router-dom";
import LoadingPage from "../../Home/LoadingPage";
import ErrorPage from "../../../shared/components/ErrorPage";
import Link from "../../../navigation/Link";
import moment from "moment";
import ImageFolderIlustration from "./ImageFolderIlustration";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { FeedbackRounded, Note, Share } from "@material-ui/icons";
import Icon from "../../../shared/components/Icon";
import {
  mdiDownloadCircle,
  mdiDownloadOff,
  mdiFilePdf,
  mdiPdfBox
} from "@mdi/js";
import { PDFDownloadLink } from "@react-pdf/renderer";
import IncidentPDF from "./IncidentPDF";
import IncidentPDFButton from "./IncidentPDFButton";

const useStyles = makeStyles(theme => ({
  image: {
    height: 200,
    width: 200,
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
    cursor: "pointer"
  }
}));
const useIncidentDetails = (incidentId?: string | null) => {
  //   const { incidentId } = useParams<{ incidentId: string }>();
  const {
    incident,
    incidentError,
    incidentLoading,
    getIncident
  } = useGetIncidentViewLazy();

  useEffect(() => {
    console.log("getting incident", incidentId);
    if (incidentId) {
      getIncident({ variables: { id: incidentId } });
    }
  }, [incidentId]);

  return {
    model: {
      incident,
      incidentError,
      incidentLoading
    }
  };
};
interface IncidentDetailsProps {
  incidentId?: string | null;
  renderReportButton?: boolean;
  forCustomer?: boolean;
}
const IncidentDetails: React.FC<IncidentDetailsProps> = ({
  incidentId,
  renderReportButton = true,
  forCustomer
}) => {
  const {
    model: { incident, incidentError, incidentLoading }
  } = useIncidentDetails(incidentId);

  const [currentImage, setCurrentImage] = useState<number | null>(null);
  const onImageClick = (position: number) => () => setCurrentImage(position);
  const onBoxClose = () => setCurrentImage(null);
  const history = useHistory();

  const classes = useStyles();
  if (!incidentId) {
    return (
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5">Select an item to view</Typography>
        <Typography color="textSecondary">Nothing is selected</Typography>
      </Box>
    );
  }
  if (incidentError) {
    console.log("rendering details error component");
    return <ErrorPage />;
  }
  if (!incident) {
    console.log("rendering details loading component");
    return <LoadingPage />;
  }

  const images = incident?.pictures
    ? incident.pictures.map(pic => pic.url)
    : [];

  console.log("rendering details component");

  const reporterName = incident.valet?.name || incident.reporter?.name;
  const reporterLink = incident.valet
    ? "/dashboard/valets/" + incident.valet.id
    : "/dashboard/users/" + incident.reporter?.id;
  console.log({reporterName, reporterLink})
  return (
    <Container style={{ paddingTop: 10 }}>
      <Box display="flex">
        <div>
          <Typography gutterBottom variant="h5">
            Reported on service route:{" "}
            {forCustomer ? (
              `${incident.route.routeCode} - ${incident.route.property.name}`
            ) : (
              <Link to={"/dashboard/serviceRoutes/" + incident.route.id}>
                {incident.route.routeCode} - {incident.route.property.name}
              </Link>
            )}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            style={{}}
          >
            {moment(incident.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        </div>
        <Box
          flex={1}
          justifyContent="flex-end"
          display="flex"
          alignItems="flex-start"
        >
          {renderReportButton && (
            <Tooltip title="View Service Report">
              <IconButton
                onClick={() =>
                  history.push(
                    `/dashboard/serviceRoutes/${incident.route.id}/reports/${incident.serviceReportId}`
                  )
                }
              >
                <Note />
              </IconButton>
            </Tooltip>
          )}

          <IncidentPDFButton incident={incident} />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        <Chip label={incident.category.name} />
        {incident.subCategory && (
          <>
            {"  "} <Chip label={incident.subCategory.name} />
          </>
        )}
      </Typography>

      <Typography>
        By:{' '}
        {forCustomer ? (
          reporterName
        ) : (
          <Link to={reporterLink}>{reporterName}</Link>
        )}
      </Typography>

      <Divider style={{ marginBottom: 10 }} />
      {!incident.isBuilding && (
        <>
          <Typography variant="subtitle1">Apartment Number</Typography>
          <Typography gutterBottom>{incident.apartmentNumber}</Typography>
        </>
      )}
      <Typography variant="subtitle1">Valet's Notes</Typography>
      <Typography>
        {incident.notes ? incident.notes : <i>Nothing specified</i>}
      </Typography>

      <Box marginTop={2}>
        <Typography variant="h6" gutterBottom>
          Uploaded Images
        </Typography>
        {incident.pictures && incident.pictures.length ? (
          <Grid container spacing={3}>
            {incident.pictures.map((pic, i) => (
              <Grid item key={i}>
                <img
                  src={pic.url}
                  alt={"incident-file" + i}
                  className={classes.image}
                  onClick={onImageClick(i)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            //   paddingY={10}
          >
            <ImageFolderIlustration
              style={{ height: "150px", marginBottom: 10 }}
            />
            <Typography color="textSecondary">
              Nothing was uploaded by the valet.
            </Typography>
          </Box>
        )}
      </Box>

      {/* <Button
        fullWidth
        color="primary"
        variant="outlined"
        onClick={() =>
          history.push(
            `/dashboard/serviceRoutes/${incident.route.id}/reports/${incident.serviceReportId}`
          )
        }
      >
        Go to Service Report
      </Button> */}

      {currentImage !== null && (
        <Portal>
          <Lightbox
            mainSrc={images[currentImage]}
            nextSrc={images[(currentImage + 1) % images.length]}
            prevSrc={images[(currentImage + images.length - 1) % images.length]}
            onCloseRequest={onBoxClose}
            onMovePrevRequest={() =>
              setCurrentImage(
                (currentImage + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setCurrentImage((currentImage + 1) % images.length)
            }
            reactModalStyle={{ zIndex: 99999 }}
          />
        </Portal>
      )}

      {/* <IncidentsTable /> */}
    </Container>
  );
};

export default React.memo(IncidentDetails, (prevProps, newProps) => {
  return prevProps.incidentId === newProps.incidentId;
});
