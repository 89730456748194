import { Box, Button, Container } from "@material-ui/core";
import React from "react";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import useAbility from "../../shared/hooks/useAbility";
import PropertiesTable from "./components/PropertiesTable";
import SharedCustomersTable from "./components/SharedCustomersTable";

const PropertiesListPage: React.FC = ({}) => {
  const ability = useAbility();

  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Customers" }
        ]}
      />
      <PageHeader
        title={"Customers"}
        actions={
          <>
            {ability.can("create", "Customers") && (
              <Link to="/dashboard/properties/create">
                <Button variant="contained" color="primary">
                  Create Customer
                </Button>
              </Link>
            )}
          </>
        }
      />
      <PropertiesTable />

      <Box marginTop={8}>
        <PageHeader title={"Shared Customers"} />
      </Box>
      <SharedCustomersTable />
    </Container>
  );
};

export default PropertiesListPage;
