import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextFieldProps,
  ListItemIcon
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  useGetRouteSelectItemLazy,
  useGetRoutesSelect
} from "../../../apollo/serviceRoutes/queries";
import SearchInput from "../../../shared/components/Datatable/SearchInput";
import { ServiceRouteTableView } from "../../../apollo/serviceRoutes/interfaces";

import { useField } from "formik";
import { CheckRounded } from "@material-ui/icons";

interface NewRouteSelectModalProps {
  name: string;
  showAvailableRoutesOnly?: boolean;
  subTenantId?: string;
  requiresSubTenantId?: boolean;
}

type Item = {
  id: string;
  routeCode: string;
  propertyName: string;
};
const limit = 5;
const NewRouteSelectModal: React.FC<NewRouteSelectModalProps &
  TextFieldProps> = ({
  name,
  showAvailableRoutesOnly,
  subTenantId,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRoute, setSelectedRoute] = useState<Item | null>(null);
  const [{ value: selectedRouteId }, meta, helpers] = useField<string | null>(
    name
  );

  const [showModal, setShowModal] = useState(false);
  const { getRoute, route: foundRoute } = useGetRouteSelectItemLazy();
  const { total, routes } = useGetRoutesSelect(
    {
      search,
      limit,
      sortDirection: "ASC",
      sortProperty: "routeCode",
      offset: limit * (currentPage - 1)
    },
    subTenantId,
    showAvailableRoutesOnly
  );

  const onRouteClick = (route: ServiceRouteTableView) => () => {
    helpers.setValue(route.id);

    setSelectedRoute({
      id: route.id,
      propertyName: route.propertyName,
      routeCode: route.routeCode
    });

    onModalClose();
  };

  const onInputClick = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (meta.initialValue) {
      getRoute(meta.initialValue);
    }
  }, [meta.initialValue]);

  useEffect(() => {
    if (foundRoute && !selectedRoute) {
      setSelectedRoute(foundRoute);
    }
  }, [foundRoute, selectedRoute]);

  useEffect(() => {
    if (selectedRouteId) {
      getRoute(selectedRouteId);
    }
  }, []);
  return (
    <>
      <Dialog
        open={showModal}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pick a Route</DialogTitle>
        <DialogContent>
          <SearchInput
            onChange={e => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Search a Route"
          />

          {routes && routes.length ? (
            <>
              <List>
                {routes?.map(route => (
                  <ListItem
                    key={route.id}
                    button
                    onClick={onRouteClick(route)}
                    style={{ borderRadius: 20 }}
                  >
                    <ListItemAvatar>
                      <Avatar>{route.routeCode.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={route.routeCode}
                      secondary={route.propertyName}
                    />
                    {selectedRouteId === route.id && (
                      <ListItemIcon>
                        <CheckRounded color="secondary" />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.round(total / limit)}
                page={currentPage}
                onChange={(e, v) => setCurrentPage(v)}
              />
            </>
          ) : (
            <Typography>No Routes have been found</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        error={!!meta.error}
        helperText={meta.error}
        {...props}
        InputProps={{
          readOnly: true
        }}
        onClick={props.disabled ? undefined : onInputClick}
        value={
          selectedRoute
            ? selectedRoute.routeCode + " - " + selectedRoute.propertyName
            : ""
        }
      />
    </>
  );
};

export default NewRouteSelectModal;
