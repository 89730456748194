import { requestNotificationPermission, setOnNotificationReceived } from "../notifications/NotificationService";
import { useRegisterNotificationToken } from "../../apollo/auth/mutations";
import { useEffect } from "react";
import useSnackbar from "./useSnackbar";
import { Message } from "../notifications/interface";
import {store} from 'react-notifications-component'

export default () => {
  const { sendErrorMessage } = useSnackbar();
  const { registerToken, registerTokenError } = useRegisterNotificationToken();
  const requestPermission = async () => {
    const token = await requestNotificationPermission();
    if (token) registerToken({ variables: { token } });
    setOnNotificationReceived((payload: Message) => {
      console.log(payload)
        store.addNotification({
          container: 'top-right',
          title: payload.notification.title,
          message: payload.notification.body,
          type: payload.data.type,
          dismiss: {
            onScreen: true,
            showIcon: true,
            duration: 5000,
            
          },
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"]
        })
    })
  };

  useEffect(() => {
    if (registerTokenError)
      sendErrorMessage(
        "There was an issue trying to register you for receiving notifications: " +
          registerTokenError.message
      );
  }, [registerTokenError]);

  return {
    requestPermission,
  };
};
