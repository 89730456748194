import React from "react";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import {} from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { SnackbarProvider as NotiProvider } from "notistack";
import AppRoutes from "./navigation/AppRoutes";
import "./App.scss";
import theme from "./theme";
import apolloClient from "./apollo/client";
import { AppContextProvider } from "./shared/contexts/AppContext";

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <NotiProvider>
            <CssBaseline />
            <AppRoutes />
          </NotiProvider>
        </ThemeProvider>
      </AppContextProvider>
    </ApolloProvider>
  );
};

export default App;
