import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { title } from "process";
import React, { useEffect, useState } from "react";
import { useUploadPaperContract } from "../../../apollo/agreements/mutation";
import LoadingButton from "../../../shared/components/LoadingButton";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import { DropzoneArea } from "material-ui-dropzone";

interface UploadPaperContractModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId?: string;
  propertyId?: string;
}
const UploadPaperContractModal: React.FC<UploadPaperContractModalProps> = ({
  onClose,
  open,
  onSuccess,
  customerId,
  propertyId,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState(false);
  const {
    contract,
    uploadLoading,
    uploadContract,
    uploadError,
  } = useUploadPaperContract();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const onUploadClick = () => {
    if (!file) {
      setError(true);
      return;
    }
    setError(false);
    uploadContract({ customerId, contract: file, propertyId });
  };

  useEffect(() => {
    if (uploadError) {
      sendErrorMessage(
        "Error trying to upload the file: " + uploadError.message
      );
    }
  }, [uploadError]);

  useEffect(() => {
    if (contract) {
      sendSuccessMessage("Paper contract uploaded successfully.");
      onSuccess();
      onClose();
    }
  }, [contract]);

  return (
    <Dialog
      open={open}
      onClose={uploadLoading ? undefined : onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Upload Paper Contract</DialogTitle>
      <DialogContent>
        <DropzoneArea
        filesLimit={1}
        showFileNames={true}
          acceptedFiles={["image/*", '.pdf']}
          onChange={(files) => setFile(files.length ? files[0] : null)}
        />
        <Typography variant="caption" color="error">
          You must select a file to upload.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={uploadLoading}>
          Cancel
        </Button>
        <LoadingButton isLoading={uploadLoading} onClick={onUploadClick}>
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPaperContractModal;
