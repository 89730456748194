import React from "react";
import { DebounceInput } from "react-debounce-input";
import {
  TextField,
  IconButton,
  TextFieldProps,
} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(2, 0),
    display: "flex",
    flex: "1 0 auto",
    alignItems: 'center'
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginTop: "10px",
    marginRight: "8px",
  },
  searchText: {
    flex: "1",
  },
  clearIcon: {
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));

interface Props {
  onCloseClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SearchInput: React.FC<TextFieldProps & Props> = ({
  variant,
  onChange,
  onCloseClick,
}) => {
  const classes = useStyles();
  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.main}>
        <SearchIcon className={classes.searchIcon} />
        <DebounceInput
          onChange={(e) => onChange? onChange(e) : null }
          minLength={2}
          autoFocus={true}
          debounceTimeout={300}
          variant={variant}
          element={TextField}
          fullWidth={true}
          className={classes.searchText}
        />
        <IconButton className={classes.clearIcon} onClick={onCloseClick}>
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  );
};

export default SearchInput;