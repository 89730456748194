import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SubscriptionTypes } from "../../apollo/notifications/interfaces";
import { useAddOrRemoveSubscription } from "../../apollo/notifications/mutations";
import { useCheckSubscription } from "../../apollo/notifications/queries";
import LoadingButton from "./LoadingButton";

interface SubscriptionButtonProps {
  id: string;
  type: SubscriptionTypes;
}

const SubscriptionButton: React.FC<SubscriptionButtonProps> = ({
  id,
  type
}) => {
  const { isSubscribed, loading } = useCheckSubscription(id, type);
  const [subbed, setSubbed] = useState(false);
  const { handleSub } = useAddOrRemoveSubscription(!subbed, id, type);

  useEffect(() => {
    if (isSubscribed) setSubbed(true);
  }, [isSubscribed]);

  const onClick = () => {
    handleSub();
    setSubbed(s => !s);
  };

  return (

      <LoadingButton
        noMargins={true}
        isLoading={loading}
        variant={subbed ? "outlined" : "contained"}
        color="primary"
        onClick={onClick}
      >
        {subbed ? "Subscribed" : "Subscribe"}
      </LoadingButton>

  );
};

export default SubscriptionButton;
