import React from "react";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import WipIllustration from "./WipIlustration";
import ServiceReportsTable from "../ServiceRoutes/components/ServiceReportsTable";
import useAbility from "../../shared/hooks/useAbility";

const DashboardHomePage: React.FC = () => {
  const ability = useAbility()
  return (
    <Grid container>
      <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>
          Welcome to the Disposal Valet WebApp
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom color="textSecondary">Service Reports</Typography>
        <ServiceReportsTable showTitle={false} showTenantFilter={true} showDateFilter={true} showSharedRoutesFilter={ability.cannot('manage', 'SubTenants')}/>
      </Grid>
    </Grid>
  );
};

const WIPArea: React.FC = () => {
  return (
    <Container style={{ height: "100%" }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h2">
          Welcome to the Disposal Valet WebApp
        </Typography>
        <Typography variant="h6" color="textSecondary">
          This is a work-in-progress so keep in mind that things might break.
        </Typography>
        <div style={{ flex: 1, paddingTop: 30 }}>
          <WipIllustration style={{ maxHeight: 500, width: "100%" }} />
        </div>
      </Box>
    </Container>
  );
};
export default DashboardHomePage;
