import { gql, ApolloError, DocumentNode } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  ManagementCompany,
  CreatePropertyManager,
  RegionalManager,
} from "./interfaces";

export const CREATE_MANAGEMENT_COMPANY = gql`
  mutation createManagementCompany($company: CreateManagementCompanyDTO!) {
    createManagementCompany(company: $company) {
      id
    }
  }
`;

export const UPDATE_MANAGEMENT_COMPANY = gql`
  mutation updateManagementCompany(
    $id: String!
    $company: UpdateManagementCompanyDTO!
  ) {
    updateManagementCompany(id: $id, company: $company) {
      id
    }
  }
`;

export const DELETE_MANAGEMENT_COMPANIES = gql`
  mutation deleteManagementCompanies($ids: [String!]!) {
    deleteManagementCompanies(ids: $ids)
  }
`;

export const CREATE_REGIONAL_MANAGER = gql`
  mutation createRegionalManager($manager: CreateRegionalManagerDTO!) {
    createRegionalManager(manager: $manager) {
      id
    }
  }
`;

export const UPDATE_REGIONAL_MANAGER = gql`
  mutation updateRegionalManager(
    $id: String!
    $manager: UpdateRegionalManagerDTO!
  ) {
    updateRegionalManager(id: $id, manager: $manager) {
      id
    }
  }
`;

export const DELETE_REGIONAL_MANAGER = gql`
  mutation deleteRegionalManagers($ids: [String!]!) {
    deleteRegionalManagers(ids: $ids)
  }
`;
export const useCreateOrUpdateMgtCompany = (
  isEdit = false,
  onSuccess?: (company: ManagementCompany) => void,
  onError?: (error: ApolloError) => void
) => {
  const [submit, { loading }] = useMutation<
    {
      updateManagementCompany: ManagementCompany;
      createManagementCompany: ManagementCompany;
    },
    { id?: string; company: CreatePropertyManager }
  >(isEdit ? UPDATE_MANAGEMENT_COMPANY : CREATE_MANAGEMENT_COMPANY, {
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(
          isEdit ? data.updateManagementCompany : data.createManagementCompany
        );
      }
    },
    onError: onError,
  });

  return [submit, { loading }] as const;
};

type ResultKeyType =
  | "updateManagementCompany"
  | "createManagementCompany"
  | "updateRegionalManager"
  | "createRegionalManager";
const getQuery = (
  type: "company" | "manager",
  isEdit: boolean
): { query: DocumentNode; resultKey: ResultKeyType } => {
  if (type === "company") {
    return isEdit
      ? {
          query: UPDATE_MANAGEMENT_COMPANY,
          resultKey: "updateManagementCompany",
        }
      : {
          query: CREATE_MANAGEMENT_COMPANY,
          resultKey: "createManagementCompany",
        };
  }
  return isEdit
    ? { query: UPDATE_REGIONAL_MANAGER, resultKey: "updateRegionalManager" }
    : { query: CREATE_REGIONAL_MANAGER, resultKey: "createRegionalManager" };
};
export const useCreateOrUpdatePropertyManager = (
  type: "company" | "manager",
  isEdit = false,
  onSuccess?: (company: ManagementCompany) => void,
  onError?: (error: ApolloError) => void
) => {
  const { query, resultKey } = getQuery(type, isEdit);
  const [submit, { loading }] = useMutation<
    {
      updateManagementCompany: ManagementCompany;
      createManagementCompany: ManagementCompany;
      createRegionalManager: RegionalManager;
      updateRegionalManager: RegionalManager;
    },
    { id?: string; company?: CreatePropertyManager, manager?: CreatePropertyManager }
  >(query, {
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(data[resultKey]);
      }
    },
    onError: onError,
  });

  return [submit, { loading }] as const;
};
