import { OperationVariables, useQuery } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
  Typography,
  DialogActions,
  Button
} from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { DocumentNode } from "graphql";
import React, { useEffect, useState } from "react";
import { ValetTableView } from "../../apollo/valets/interfaces";
import {
  useGetValetSelectItemLazy,
  useGetValetsSelect
} from "../../apollo/valets/queries";
import { ValetSelectItem } from "../../pages/Valets/components/NewValetSelectModal";
import SearchInput from "./Datatable/SearchInput";

interface Item {
  id: string;
  [key: string]: any;
}
export interface SelectModalProps<ItemType extends Item> {
  items?: ItemType[];
  title?: string;
  titleKey: keyof ItemType;
  subtitleKey?: keyof ItemType;
  limit?: number;
  open: boolean;
  total?: number;
  onClose: () => void;
  initialValue?: ItemType;
  onItemChange: (item?: ItemType) => void;
  onSearchChange?: (search: string) => void;
  onPageChange?: (page: number) => void;
  renderClear?: boolean;
}

const SelectModal = <ItemType extends Item>({
  limit = 5,
  total = 0,
  open,
  items,
  initialValue,
  onItemChange,
  onSearchChange,
  onClose,
  title = 'Select an Item',
  onPageChange: onPageChangeProp,
  renderClear,
  ...props
}: SelectModalProps<ItemType>) => {
  // const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(
    initialValue
  );

  //   const { getValet, valet: foundValet } = useGetValetSelectItemLazy();

  const onItemClick = (item?: ItemType) => () => {
    console.log({item})
    setSelectedItem(item);
    if (onItemChange) onItemChange(item);
    onClose();
  };

  useEffect(() => {
    if (initialValue) {
      //   getValet(initialValue);
    }
  }, [initialValue]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    if (onPageChangeProp) {
      onPageChangeProp(page);
    }
  };

  const onClearClick = () => {
    onItemClick(undefined)();
  }

  //   useEffect(() => {
  //     if (foundValet && !selectedValet) {
  //       // helpers.setValue(foundValet.id);
  //       setSelectedValet(foundValet);
  //     }
  //   }, [foundValet, selectedValet]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <SearchInput
          onChange={e =>
            onSearchChange ? onSearchChange(e.target.value) : undefined
          }
          variant="outlined"
          placeholder="Search a valet"
        />

        {items && items.length ? (
          <>
            <List>
              {items.map(item => (
                <ListItem
                  key={item.id}
                  button
                  onClick={onItemClick(item)}
                  style={{ borderRadius: 20 }}
                >
                  <ListItemAvatar>
                    <Avatar>{item.name.charAt(0)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={"@" + item.username}
                  />
                  {item.id === selectedItem?.id && (
                    <ListItemIcon>
                      <CheckRounded color="secondary" />
                    </ListItemIcon>
                  )}
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.round(total / limit)}
              page={currentPage}
              onChange={(e, v) => onPageChange(v)}
            />
          </>
        ) : (
          <Typography>No items have been found</Typography>
        )}
      </DialogContent>
      <DialogActions>
        {renderClear &&   <Button onClick={onClearClick} color="primary">
          Clear
        </Button>}
   
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectModal;
