import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Fade,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useParams, useHistory } from "react-router-dom";
import { SubTenantsTableOnly as SubTenantsTable } from "./components/SubTenantsTable";
import BreadcrumbsSection, {
  BreadcrumbSection,
} from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { GET_TENANT } from "../../apollo/tenants/queries";
import { Tenant } from "../../apollo/tenants/interfaces";
import useAbility from "../../shared/hooks/useAbility";
import useAuthStore from "../../shared/hooks/useAuthStore";
// import { useAuthUserQuery } from "../../apollo/auth/queries";

const LoadingSkeleton: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={50} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Skeleton
            animation="wave"
            width="100%"
            height="50vh"
            style={{ marginTop: -80 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
interface TenantPageProps {
  isAdmin?: Boolean;
}

const useTenantPage = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const history = useHistory();
  const authUser = useAuthStore(state => state.user);
  const finalTenantId = tenantId ? tenantId : authUser?.tenantId;
  const [getTenant, { data, loading, error }] = useLazyQuery<
    { tenant: Tenant },
    { tenantId?: string }
  >(GET_TENANT, {
    variables: {
      tenantId: finalTenantId,
    },
  });
  useEffect(() => {
  
    if (!data && finalTenantId) {
      getTenant();
    }
  }, [finalTenantId]);

  const onCreateClick = () => {
    history.push('/dashboard/subFranchises/create')
  }
  const ability = useAbility();
  return {
    model: {
      ability,
      loading:  loading || (!data && !error),
      tenant: data?.tenant,
    },
    commands: {
      onCreateClick
    }
  };
};
const TenantPage: React.FC<TenantPageProps> = ({ isAdmin }) => {
  const {
    model: { ability, loading, tenant },
    commands: {onCreateClick}
  } = useTenantPage();

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <Fade in={true}>
      <Container>
        <BreadcrumbsSection
          sections={getBreadcrumbsSections(
            ability.can("manage", "all"),
            tenant?.name
          )}
        />
        <PageHeader
          title={tenant ? tenant.name : "Franchise"}
          actions={
            <Button variant="contained" color="primary" onClick={onCreateClick}>
              Create Sub-Franchise
            </Button>
          }
        />
        <Paper>
          <SubTenantsTable
            tenantId={tenant ? tenant.id : null}
            title={
              <Typography variant="h6" color="primary">
                Sub-Franchises
              </Typography>
            }
          />
        </Paper>
      </Container>
    </Fade>
  );
};
const getBreadcrumbsSections = (isAdmin: boolean, tenantName?: string) => {
  const breadcrumbsSections: BreadcrumbSection[] = [
    { label: "Home", link: "/dashboard" },
  ];
  if (!isAdmin) {
    breadcrumbsSections.push({ label: "Franchise" });
    return breadcrumbsSections;
  }
  return [
    ...breadcrumbsSections,
    { label: "Franchises", link: "/dashboard/franchises" },
    { label: tenantName ? tenantName : "Franchise" },
  ];
};
export default TenantPage;
