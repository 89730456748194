import React, { useState } from "react";
import { CustomerAgreement } from "../../../apollo/agreements/interface";
import {
  Grid,
  makeStyles,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import Link from "../../../navigation/Link";
import {
  DescriptionRounded,
  InfoRounded,
  DateRange,
  TodayRounded
} from "@material-ui/icons";
import moment from "moment";
import { File } from "../../../apollo/shared/interfaces";
import UploadPaperContractModal from "./UploadPaperContractModal";
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 10
  }
}));
interface AgreementDetailsSectionProps {
  customerId?: string;
  propertyId?: string;
  agreement?: CustomerAgreement;
  paperContract?: File;
  onPaperContractSubmitSuccess: () => void;
}
const AgreementDetailsSection: React.FC<AgreementDetailsSectionProps> = ({
  agreement,
  customerId,
  propertyId,
  paperContract,
  onPaperContractSubmitSuccess
}) => {
  const classes = useStyles();
  const [showContractModal, setShowContractModal] = useState(false);

  const onContractModalShow = () => {
    setShowContractModal(true);
  };
  const onContractModalClose = () => {
    setShowContractModal(false);
  };

  if (!agreement && !paperContract) {
    return (
      <div className={classes.root}>
        <UploadPaperContractModal
          open={showContractModal}
          onClose={onContractModalClose}
          customerId={customerId}
          propertyId={propertyId}
          onSuccess={onPaperContractSubmitSuccess}
        />
        <Alert severity="info">
          There's no agreement made for this customer yet.
        </Alert>
        <Box display="flex" justifyContent="center" marginTop={5}>
          <Link
            to={`/dashboard/${
              customerId ? "customers" : "properties"
            }/${customerId || propertyId}/agreement`}
          >
            <Button color="primary">Start Agreement Process</Button>
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" marginTop={5}>
          <Button color="primary" onClick={onContractModalShow}>
            Upload a Contract File
          </Button>
        </Box>
      </div>
    );
  }
  if (agreement) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <DescriptionRounded color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Type"
                primaryTypographyProps={{
                  color: "textSecondary"
                }}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {agreement.type}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <InfoRounded color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Status"
                primaryTypographyProps={{
                  color: "textSecondary"
                }}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {agreement.status}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <DateRange color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Term"
                primaryTypographyProps={{
                  color: "textSecondary"
                }}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {agreement.currentTerm}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <TodayRounded color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Service Start Date"
                primaryTypographyProps={{
                  color: "textSecondary"
                }}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {moment(agreement.serviceStartDate).format("L")}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <TodayRounded color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Service Expiration Date"
                primaryTypographyProps={{
                  color: "textSecondary"
                }}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {moment(agreement.currentTermExpirationDate).format("L")}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>

        {(!!!agreement.supervisorSignature ||
          !!!agreement.customerSignature) && (
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Alert severity="warning">
              This agreement is missing signatures.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} container justify="center">
          <Link
            to={`/dashboard/${
              customerId ? "customers" : "properties"
            }/${customerId || propertyId}/agreement`}
          >
            <Button variant="contained" color="primary">
              Go to agreement view
            </Button>
          </Link>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box padding={3} display="flex" flexDirection="column"   alignItems="center" height="100%">
      <Link to={paperContract ? paperContract.url : "./"} target="_blank">
        <Button variant="contained" color="primary">
          View Uploaded Contract
        </Button>
      </Link>
      <div style={{ width: "100%", marginTop: 10, flex: 1 }}>
        <object
          style={{height: '100%', width: '100%', borderRadius: 25, objectFit: 'scale-down'}}
          data={
            paperContract
              ? paperContract.url
              : ""
          }
          type={paperContract ? paperContract.filetype : ""}
        />
      </div>
    </Box>
  );
};

export default AgreementDetailsSection;
