import React, { useState, useEffect } from "react";

import { VanillaMap, VanillaMapProps } from ".";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { DeleteRounded } from "@material-ui/icons";
import _ from "lodash";
import { Marker } from "@react-google-maps/api";
import { Typography, Tooltip } from "@material-ui/core";
const DeleteIconUrl: string = require("./icons/delete.svg");

export interface MarkersMapProps
  extends Pick<
    VanillaMapProps,
    "areas" | "initialCenter" | "currentLocation" | "initialZoom" | "markers"
  > {
  onLocationsChange?: (locations: LocationMarker[]) => void;
  initialMarkers?: LocationMarker[];
}

export interface LocationMarker {
  title: string;
  key: string;
  icon: string;
  latitude: number;
  longitude: number;
}

interface MarkersArrayMap {
  [section: string]: LocationMarker;
}

type SectionIcon = "trash-center";
export const getIconAndTitleFromKey = (key: SectionIcon) => {
  switch (key) {
    case "trash-center":
      return ["Trash Center", DeleteIconUrl] as const;
    default:
      return "";
  }
};

const formatInitialMarkers = (initialMarkers?: LocationMarker[]) => {
  if (!initialMarkers) return null;
  if (!initialMarkers.length) return null;

  return _.reduce<LocationMarker, MarkersArrayMap>(
    initialMarkers,
    (map, location, i) => {
      const titleIcon = getIconAndTitleFromKey(location.key as SectionIcon);
      map[location.key] = {
        ...location,
        title: titleIcon[0],
        icon: titleIcon[1],
      };
      return map;
    },
    {}
  );
};
const MarkersMap: React.FC<MarkersMapProps> = ({
  onLocationsChange,
  initialMarkers,
  ...props
}) => {
  const [section, setSection] = useState<SectionIcon>("trash-center");
  const [markersMap, setMarkersMap] = useState<MarkersArrayMap | null>(
    formatInitialMarkers(initialMarkers)
  );
  const onMapClick = ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) => {
    const iconAndTitle = getIconAndTitleFromKey(section);
    setMarkersMap((m) => ({
      ...m,
      [section]: {
        title: iconAndTitle[0],
        key: section,
        icon: iconAndTitle[1],
        latitude,
        longitude,
      },
    }));
  };
  const onMarkerRightClick = (marker: LocationMarker) => () => {
    setMarkersMap((m) => {
      const temp = { ...m } as MarkersArrayMap;
      const key = marker.key;
      if (temp[key]) delete temp[key];
      return temp;
    });
  };
  const handleSectionPick = (e: any, value: SectionIcon) => {
    setSection(value);
  };
  useEffect(() => {
    if (onLocationsChange) {
      onLocationsChange(_.toArray(markersMap));
    }
  }, [markersMap]);
  return (
    <>
      <ToggleButtonGroup
        value={section}
        exclusive
        onChange={handleSectionPick}
        aria-label="text alignment"
      >
        <ToggleButton value="trash-center" aria-label="left aligned">
          <Tooltip title="Trash Center">
            <DeleteRounded />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <VanillaMap onMapClick={onMapClick} {...props}>
        {_.map(markersMap, (marker) => (
          <Marker
            title={marker.title}
            key={marker.key}
            icon={{ url: marker.icon, scale: 0.5 }}
            position={{ lat: marker.latitude, lng: marker.longitude }}
            onRightClick={onMarkerRightClick(marker)}
          />
        ))}
      </VanillaMap>
    </>
  );
};

export default MarkersMap;
