import React from "react";
import {
  Grid,
  Divider,
  FormControlLabel,
  Switch,
  Collapse,
  Button
} from "@material-ui/core";
import AddressForm, {
  getAddressFormInitialValues,
  getAddressFormValidationSchema
} from "../../../shared/components/AddressForm";
import { PropertyFormProps, PropertyFormValues } from "./interfaces";
import ManagementCompaniesSelect from "../../PropertyManagers/components/MangementCompaniesSelect";
import RegionalManagersSelect from "../../PropertyManagers/components/RegionalManagersSelect";
import * as Yup from "yup";
import { AddressFormValues } from "../../../shared/components/AddressForm/interfaces";
import { Property } from "../../../apollo/customers/interfaces";
import { Field, useFormikContext } from "formik";
import { RoleAwareSubTenantSearchbox } from "../../Tenants/components/SubTenantsSearchbox";
import { TextField } from "formik-material-ui";

export const getPropertyFormValidationSchema = (isAdmin: boolean) => {
  const requiredText = "This field is required.";
  return Yup.object<PropertyFormValues>({
    name: Yup.string().required(requiredText),
    mainPhone: Yup.string()
      .required(requiredText),
    contactName: Yup.string()
      .optional()
      .nullable(),
    email: Yup.string()
      .optional()
      .nullable(),
    maintenancePhone: Yup.string()
      .optional()
      .nullable(),
    subTenantId: isAdmin
      ? Yup.string()
          .required(requiredText)
          .nullable()
      : Yup.string()
          .nullable()
          .notRequired(),
    managementCompanyId: Yup.string()
      .nullable()
      .notRequired(),
    regionalManagerId: Yup.string()
      .nullable()
      .notRequired(),
    address: getAddressFormValidationSchema(true).required()
  });
};

export const getPropertyFormInitialValues = (
  property?: Property
): PropertyFormValues => {
  return {
    id: property?.id,
    name: property ? property.name : "",
    managementCompanyId: property
      ? property.managementCompany
        ? property.managementCompany.id
        : ""
      : "",
    regionalManagerId: property
      ? property.regionalManager
        ? property.regionalManager.id
        : ""
      : "",
    mainPhone: property?.mainPhone || "",
    contactName: property?.contactName || "",
    email: property?.email || "",
    maintenancePhone: property?.maintenancePhone || "",
    subTenantId: property?.subTenantId || "",
    address: getAddressFormInitialValues(property?.address)
  };
};
const PropertyForm: React.FC<PropertyFormProps> = ({ isStandalone, isEdit }) => {
  const { values, errors, setFieldValue, handleChange } = useFormikContext<
    PropertyFormValues
  >();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="subTenantId"
            component={RoleAwareSubTenantSearchbox}
            variant="outlined"
            fullWidth
            label="Sub-Franchise*"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Property Name*"
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Contact Name"
            name="contactName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Main Phone Number*"
            name="mainPhone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Maintenance Phone"
            name="maintenancePhone"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Email Address"
            name="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RegionalManagersSelect
            label="Regional Manager"
            name="regionalManagerId"
            onChange={handleChange}
            subTenantId={values.subTenantId}
            value={values.regionalManagerId}
            error={errors?.regionalManagerId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ManagementCompaniesSelect
            label="Management Company"
            name="managementCompanyId"
            onChange={handleChange}
            subTenantId={values.subTenantId}
            value={values.managementCompanyId}
            error={errors?.managementCompanyId}
          />
        </Grid>
      </Grid>

      <AddressForm
        title="Address Information"
        handleChange={handleChange}
        values={values.address}
        errors={errors?.address}
        setFieldValue={setFieldValue}
        parentForm="address"
        loadMapApi={true}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            {isEdit ? "Update" : "Create"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyForm;
