import { Box, Button, Typography, IconButton, Icon } from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { useSetPropertyManager } from "../../../apollo/customers/mutations";
import { User } from "../../../apollo/users/interfaces";
import UsersSelectModal from "../../Users/components/UsersSelectModal";

interface PropertyManagerSetterProps {
  propertyId: string;
  propertyManager?: User;
  subTenantId?: string | null;
}
const PropertyManagerSetter: React.FC<PropertyManagerSetterProps> = ({
  propertyId,
  propertyManager,
  subTenantId
}) => {
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState<User | undefined>(
    propertyManager
  );
  const { setPropertyManager, loading, error } = useSetPropertyManager();

  const onEditClick = () => {
    setSelectModalOpen(true);
  };

  const onModalClose = () => {
    setSelectModalOpen(false);
  };

  const onManagerChange = (user?: User) => {
    setPropertyManager({
      variables: {
        userId: user?.id,
        propertyId
      }
    });
    setSelectedManager(user);
  };

  return (
    <>
      <UsersSelectModal
        title="Pick a manager"
        open={selectModalOpen}
        onClose={onModalClose}
        initialValue={propertyManager}
        onUserChange={onManagerChange}
        role="Customer"
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent=""
      >
        <Typography
          component="span"
          variant="body2"
          color={selectedManager ? "textPrimary" : "textSecondary"}
        >
          {selectedManager ? selectedManager.name : "Not set"}
        </Typography>
        <Box marginLeft="5px" onClick={onEditClick}>
          <IconButton size="small">
            <EditRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default PropertyManagerSetter;
