import { PropertyManager } from "../../../apollo/propertyManagers/interfaces";
import {
  useCreateOrUpdateMgtCompany,
  useCreateOrUpdatePropertyManager
} from "../../../apollo/propertyManagers/mutations";
import { FormikErrors } from "formik";
import * as Yup from "yup";
import useSnackbar from "../../../shared/hooks/useSnackbar";

interface PropertyManagerFormValues {
  subTenantId?: string | null;
  name: string;
  contacts: { type: "phone" | "email"; value: string }[];
}
const initialFormValues: PropertyManagerFormValues = {
  subTenantId: "",
  name: "",
  contacts: [{ type: "phone", value: "" }]
};

const getInitialValues = (
  item?: PropertyManager | null,
  subTenantId?: string | null
): PropertyManagerFormValues => {
  if (!item) return { ...initialFormValues, subTenantId };
  const { id, ...rest } = item;
  return { ...rest, subTenantId };
};
export default (
  type: "company" | "manager",
  item?: PropertyManager | null,
  onSuccess?: () => void,
  onError?: () => void,
  subTenantId?: string | null
) => {
  const isEdit = !!item;

  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const onSubmitSuccess = () => {
    sendSuccessMessage(
      isEdit ? "Updated item successfully." : "Created item successfully."
    );
    if (onSuccess) onSuccess();
  };
  const onSubmitError = () => {
    sendErrorMessage(
      isEdit ? "Error updating item." : "Error trying to create item"
    );
    if (onError) onError();
  };
  const [submitItem, companyResults] = useCreateOrUpdatePropertyManager(
    type,
    !!item,
    onSubmitSuccess,
    onSubmitError
  );

  const onSubmit = (values: PropertyManagerFormValues) => {
    console.log({ type, values });
    if (isEdit) {
      submitItem({
        variables: {
          [type]: values,
          id: item?.id
        }
      });
    } else {
      submitItem({
        variables: {
          [type]: values
        }
      });
    }
  };

  return {
    model: {
      isSubmitting: companyResults.loading,
      isEdit,
      initialFormValues: getInitialValues(item, subTenantId)
    },
    commands: { onSubmit }
  };
};

export const getFormValidation = (tenantRequired?: boolean) => {
  return Yup.object<PropertyManagerFormValues>({
    subTenantId: tenantRequired
      ? Yup.string().required("This field is required")
      : Yup.string().optional(),
    name: Yup.string().required("This field is required."),
    contacts: Yup.array(
      Yup.object<{ type: "phone" | "email"; value: string }>({
        type: Yup.string()
          .oneOf(["phone", "email"])
          .required(),
        value: Yup.string()
          .when("type", {
            is: value => value === "email",
            then: Yup.string().email("This is not a valid email"),
            otherwise: Yup.string().min(5, "This is not a valid phone number.")
          })
          .required("You must specify a value")
      }).required()
    )
      .required()
      .min(1, "You must specify at least 1 contact.")
  });
};

export const getFieldError = (
  index: number,
  key: "value" | "type",
  contacts?:
    | string
    | string[]
    | FormikErrors<{
        type: "phone" | "email";
        value: string;
      }>[]
    | undefined
) => {
  if (!contacts) return;

  if (typeof contacts === "string") {
    return contacts;
  }

  if (Array.isArray(contacts)) {
    const value = contacts[index];
    if (!value) return;
    if (typeof value === "string") return value;

    if (value[key]) return value[key];
  }
};
