import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextFieldProps,
  ListItemIcon,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import { useGetRoutesSelect } from "../../../apollo/serviceRoutes/queries";
import SearchInput from "../../../shared/components/Datatable/SearchInput";
import { ServiceRouteTableView } from "../../../apollo/serviceRoutes/interfaces";

import { useField } from "formik";
import { CheckRounded, Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  input: {
    position: "relative",
    width: "100%"
  },
  clearIcon: {
    position: "absolute",
    right: 12,
    top: 12
  }
}));
interface RouteSelectModalProps {
  name: string;
  propertyId?: string;
}

export interface ServiceRouteSelectItem {
  id: string;
  routeCode: string;
  propertyName: string;
}

const limit = 5;
const RouteSelectModal: React.FC<RouteSelectModalProps & TextFieldProps> = ({
  name,
  propertyId,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [
    { value: selectedRoute },
    meta,
    helpers
  ] = useField<ServiceRouteSelectItem | null>(name);

  const [showModal, setShowModal] = useState(false);
  // const [selectedRoute, setSelectedRoute] = useState<{
  //   id: string;
  //   routeCode: string;
  //   propertyName: string;
  // } | null>(meta.initialValue ? meta.initialValue : null);
  const { total, routes } = useGetRoutesSelect({
    search,
    limit,
    sortDirection: "ASC",
    sortProperty: "routeCode",
    offset: limit * (currentPage - 1),
    propertyId
  });

  const onRouteClick = (route: ServiceRouteTableView) => () => {
    helpers.setValue({
      id: route.id,
      routeCode: route.routeCode,
      propertyName: route.propertyName
    });

    onModalClose();
  };

  const clearSelection = () => {
    helpers.setValue(null);
  };

  const onInputClick = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const classes = useStyles();
  return (
    <>
      <Dialog
        open={showModal}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pick a Route</DialogTitle>
        <DialogContent>
          <SearchInput
            onChange={e => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Search a Route"
          />

          {routes && routes.length ? (
            <>
              <List>
                {routes?.map(route => (
                  <ListItem
                    key={route.id}
                    button
                    onClick={onRouteClick(route)}
                    style={{ borderRadius: 20 }}
                  >
                    <ListItemAvatar>
                      <Avatar>{route.routeCode.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={route.routeCode}
                      secondary={route.propertyName}
                    />
                    {selectedRoute && selectedRoute.id === route.id && (
                      <ListItemIcon>
                        <CheckRounded color="secondary" />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.round(total / limit)}
                page={currentPage}
                onChange={(e, v) => setCurrentPage(v)}
              />
            </>
          ) : (
            <Typography>No Routes have been found</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.input}>
        <TextField
          error={!!meta.error}
          helperText={meta.error}
          {...props}
          InputProps={{
            ...props.InputProps,
            readOnly: true
          }}
          onClick={onInputClick}
          value={
            selectedRoute
              ? selectedRoute.routeCode + " - " + selectedRoute.propertyName
              : ""
          }
        />
        {selectedRoute && (
          <IconButton
            className={classes.clearIcon}
            size="small"
            onClick={clearSelection}
          >
            <Close />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default RouteSelectModal;
