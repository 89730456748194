import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  makeStyles,
  createStyles,
  Grid,
  Box,
  Button
} from "@material-ui/core";
import React from "react";
import { SupplyItem } from "../../../apollo/supplyRequests/interfaces";
import {
  EditRounded,
  DeleteRounded,
  Image as ImageIcon
} from "@material-ui/icons";
import Image from "material-ui-image";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 300,
      display: "flex"
    },
    details: {
      minWidth: 150,
      display: "flex",
      flexDirection: "column"
    },
    content: {
      flex: "1 0 auto"
    },
    cover: {
      width: "100%"
    },
    varieties: {
      margin: 0,
      paddingLeft: 20
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    playIcon: {
      height: 38,
      width: 38
    }
  })
);
interface SupplyItemCardProps {
  item: SupplyItem;
  isForRequest?: boolean;
  onAddClick?: (item: SupplyItem) => void;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
}
const SupplyItemCard: React.FC<SupplyItemCardProps> = ({
  item,
  onDeleteClick,
  onEditClick,
  onAddClick: onAddClickProp,
  isForRequest = false
}) => {
  const classes = useStyles();
  const onAddClick = () => {
    if (onAddClickProp) {
      onAddClickProp(item);
    }
  };

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {item.name}
          </Typography>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            {item.description}
          </Typography>
          {!!item.varieties && !!item.varieties.length && (
            <Typography variant="subtitle1" color="textSecondary">
              Variations:
              <ul className={classes.varieties}>
                {item.varieties.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </Typography>
          )}
        </CardContent>
        <div className={classes.controls}>
          {isForRequest ? (
            <>
              <Button variant="outlined" color="primary" onClick={onAddClick}>
                Add
              </Button>
            </>
          ) : (
            <>
              {" "}
              <IconButton onClick={onEditClick}>
                <EditRounded />
              </IconButton>
              <IconButton onClick={onDeleteClick}>
                <DeleteRounded />
              </IconButton>{" "}
            </>
          )}
        </div>
      </div>
      <CardMedia
        className={classes.cover}
        image={item.image ? item.image.url : "not"}
        title={item.name}
      />
    </Card>
  );

  // return (
  //   <div>
  //     <Image
  //       src={item.image ? item.image.url : "not"}
  //       errorIcon={<ImageIcon className={classes.imageIcon} />}
  //       imageStyle={{
  //         borderRadius: 20,
  //         objectFit: "contain",
  //         objectPosition: "center",
  //       }}
  //       style={{ borderRadius: 20, backgroundColor: "transparent" }}
  //     />
  //     <Box display="flex" alignItems="center">
  //       <Typography variant="h6" style={{ flex: 1 }}>
  //         {item.name}
  //       </Typography>

  //     </Box>

  //     <Typography variant="body2" color="textSecondary">
  //       {item.description}
  //     </Typography>
  //   </div>
  // );
};

export default SupplyItemCard;
