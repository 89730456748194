import { ApolloError } from "@apollo/client";
import { colors, Box, Typography, Grid } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { mdiCubeOutline } from "@mdi/js";
import React from "react";
import { SupplyItem } from "../../../apollo/supplyRequests/interfaces";
import Icon from "../../../shared/components/Icon";
import LoadingPage from "../../Home/LoadingPage";
import SupplyItemCard from "./SupplyItemCard";
import SupplyItemRequestCard from "./SupplyItemRequestCard";

interface SupplyItemsListProps {
  isForRequest?: boolean;
  onItemEdit?: (item: SupplyItem) => void;
  onItemDelete?: (item: SupplyItem) => void;
  onItemAdd?: (item: SupplyItem) => void;
  onItemRemove?: (item: SupplyItem) => void;
  error?: ApolloError;
  items?: SupplyItem[];
  selectedItems?: string[];
}
export const SupplyItemsList: React.FC<SupplyItemsListProps> = ({
  error,
  items,
  isForRequest = false,
  onItemEdit: onItemEditProp,
  onItemDelete: onItemDeleteProp,
  onItemAdd: onItemAddProp,
  onItemRemove: onItemRemoveProp,
  selectedItems = []
}) => {
  const onItemEdit = (item: SupplyItem) => () => {
    if (onItemEditProp) onItemEditProp(item);
  };
  const onItemDelete = (item: SupplyItem) => () => {
    if (onItemDeleteProp) onItemDeleteProp(item);
  };
  const onItemAdd = (item: SupplyItem) => () => {
    if (onItemAddProp) onItemAddProp(item);
  };
  const onItemRemove = (item: SupplyItem) => () => {
    if (onItemRemoveProp) onItemRemoveProp(item);
  };
  if (error) {
    return (
      <Box
        marginTop={2}
        display="flex"
        flexDirection="column"
        justifyItems="center"
        alignItems="center"
      >
        <Warning style={{ fontSize: 100, color: colors.red[400] }} />
        <Typography>
          There was a problem trying to get the items: {error.message}
        </Typography>
      </Box>
    );
  }

  if (!items) {
    return <LoadingPage />;
  }

  if (items.length) {
    return (
      <Grid container spacing={10} style={{ marginTop: 10 }}>
        {items?.map(item => (
          <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
            <SupplyItemCard
              isForRequest={isForRequest}
              onAddClick={onItemAdd(item)}
              item={item}
              key={item.id}
              onEditClick={onItemEdit(item)}
              onDeleteClick={onItemDelete(item)}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box
      marginTop={2}
      display="flex"
      flexDirection="column"
      justifyItems="center"
      alignItems="center"
    >
      <Icon path={mdiCubeOutline} size={7} color="secondary" />
      <Typography>There are no items to display.</Typography>
    </Box>
  );
};
