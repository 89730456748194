import { gql, useMutation } from "@apollo/client";
import {
  SupplyItem,
  UpdateSupplyItem,
  CreateSupplyItem,
  SupplyRequest,
  CreateSupplyRequest,
  RequestStatus
} from "./interfaces";

const CREATE_SUPPLY_ITEM = gql`
  mutation createSupplyItem($item: CreateSupplyItemDTO!) {
    createSupplyItem(item: $item) {
      id
    }
  }
`;

const UPDATE_SUPPLY_ITEM = gql`
  mutation updateSupplyItem($item: UpdateSupplyItemDTO!) {
    updateSupplyItem(item: $item) {
      id
    }
  }
`;
export const useCreateUpdateSupplyItem = (isEdit = false) => {
  const [submitItem, { data, error, loading }] = useMutation<
    { createSupplyItem: SupplyItem; updateSupplyItem: SupplyItem },
    { item: CreateSupplyItem | UpdateSupplyItem }
  >(isEdit ? UPDATE_SUPPLY_ITEM : CREATE_SUPPLY_ITEM);

  return {
    submitItem,
    item: isEdit ? data?.updateSupplyItem : data?.createSupplyItem,
    submitError: error,
    submitLoading: loading
  };
};

export const DELETE_SUPPLY_ITEMS = gql`
  mutation deleteSupplyItems($ids: [String!]!) {
    deleteSupplyItems(ids: $ids)
  }
`;

const CREATE_SUPPLY_REQUEST = gql`
  mutation createSupplyRequest($request: CreateSupplyRequestDTO!) {
    createSupplyRequest(request: $request) {
      id
    }
  }
`;

export const useCreateSupplyRequest = () => {
  const [create, { data, error, loading }] = useMutation<
    { createSupplyRequest: SupplyRequest },
    { request: CreateSupplyRequest }
  >(CREATE_SUPPLY_REQUEST);

  const createRequest = (data: CreateSupplyRequest) => {
    create({ variables: { request: data } });
  };

  return {
    createRequest,
    request: data?.createSupplyRequest,
    createRequestError: error,
    createRequestLoading: loading
  };
};

const CHANGE_REQUEST_STATUS = gql`
  mutation changeRequestStatus($requestId: String!, $status: String!) {
    changeSupplyRequestStatus(requestId: $requestId, status: $status) {
      id
      status
    }
  }
`;

export const useChangeRequestStatus = () => {
  const [change, { data, error, loading }] = useMutation<
    { changeSupplyRequestStatus: SupplyRequest },
    { requestId: string; status: RequestStatus }
  >(CHANGE_REQUEST_STATUS);

  const changeStatus = (requestId: string, status: RequestStatus) => {
    change({
      variables: {
        requestId,
        status
      }
    });
  };

  return {
    changeStatus,
    changedStatus: data?.changeSupplyRequestStatus.status,
    changeStatusError: error,
    changeStatusLoading: loading,
  }
};
