import React from "react";
import { Grid } from "@material-ui/core";

import CalculatedField from "../../../shared/components/CalculatedField";
import UnitBaseFields, {
  UnitsBaseFormValues,
  getUnitBaseValidationSchema,
  getUnitBaseInitialValues,
} from "./UnitBaseFields";

import * as Yup from "yup";
import { CustomerAgreement } from "../../../apollo/agreements/interface";
export interface UnitsFullStepFormValues extends UnitsBaseFormValues {
  metadata: {
    maximumMonthlyCharge: number;
  };
}

interface BaseUnitsFullStepFormValues {
  metadata: {
    maximumMonthlyCharge: number;
  };
}

export const getFullStepValidationSchema = () => {
  return getUnitBaseValidationSchema().shape<BaseUnitsFullStepFormValues>({
    metadata: Yup.object({
      maximumMonthlyCharge: Yup.number()
        .moreThan(0, "You must specify a higher value")
        .required("This field is required."),
    }).required(),
  });
};

export const getFullStepInitialValues = (
  agreement?: CustomerAgreement
): UnitsFullStepFormValues => {
  return {
    ...getUnitBaseInitialValues(agreement),
    metadata: {
      maximumMonthlyCharge: agreement?.metadata?.maximumMonthlyCharge
        ? agreement.metadata.maximumMonthlyCharge
        : 0,
    },
  };
};

const UnitFullStep: React.FC = () => {
  return (
    <UnitBaseFields>
      <Grid item xs={12} md={6}>
        <CalculatedField<UnitsFullStepFormValues>
          firstValue="aptUnitsCharged"
          secondValue="aptUnitPrice"
          operation={(aptUnitsCharged: number, aptUnitPrice: number) => {
            return aptUnitsCharged * aptUnitPrice;
          }}
          inputProps={{
            readOnly: true,
          }}
          type="number"
          variant="outlined"
          name="metadata.maximumMonthlyCharge"
          fullWidth
          label="Maximum Monthly Charge"
        />
      </Grid>
    </UnitBaseFields>
  );
};

export default UnitFullStep;
