

export interface CreateLogDTO {
  
    userId?: string;

    userName?: string;

    platform: 'web'

    message: string;
    
    name: string;

    stack?: string

    path?: string
}


export const remoteLog = async (name: string, message: string, stack?: string ) => {
    const token = localStorage.getItem("token");
    const response = await fetch('/logs', { 
        method: 'POST',
        body: JSON.stringify({
            path:  window.location.href,
            message,
            name,
            stack,
            platform: 'web'
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = response.json();


}