import { gql, ApolloError } from "@apollo/client";
import {
  CreateRegularServiceRoute,
  CreateSpecialServiceRoute,
  RegularServiceRoute,
  ServiceRoute,
  UpdateRegularServiceRoute,
  UpdateSpecialServiceRoute
} from "./interfaces";
import { useMutation } from "@apollo/react-hooks";

const CREATE_REGULAR_ROUTE = gql`
  mutation createRegularRoute($route: CreateRegularServiceRouteDTO!) {
    createRegularServiceRoute(route: $route) {
      id
    }
  }
`;

const UPDATE_REGULAR_ROUTE = gql`
  mutation updateRegularRoute($route: UpdateRegularServiceRouteDTO!) {
    updateRegularServiceRoute(route: $route) {
      id
    }
  }
`;

export const useCreateUpdateRegularRoute = (
  isEdit = false,
  onSuccess?: (route: ServiceRoute) => void,
  onError?: (error: ApolloError) => void
) => {
  const [submitRoute, { data, loading, error }] = useMutation<
    {
      createRegularServiceRoute: ServiceRoute;
      updateRegularServiceRoute: ServiceRoute;
    },
    { route: CreateRegularServiceRoute | UpdateRegularServiceRoute }
  >(isEdit ? UPDATE_REGULAR_ROUTE : CREATE_REGULAR_ROUTE, {
    onCompleted: ({ createRegularServiceRoute, updateRegularServiceRoute }) => {
      if (onSuccess) {
        if (isEdit) {
          onSuccess(updateRegularServiceRoute);
        } else {
          onSuccess(createRegularServiceRoute);
        }
      }
    },
    onError
  });

  return {
    submitRoute,
    route: isEdit
      ? data?.updateRegularServiceRoute
      : data?.createRegularServiceRoute,
    createRouteLoading: loading,
    creatingRouteError: error
  };
};

const CREATE_SPECIAL_ROUTE = gql`
  mutation createSpecialRoute($route: CreateSpecialServiceRouteDTO!) {
    createSpecialServiceRoute(route: $route) {
      id
    }
  }
`;

const UPDATE_SPECIAL_ROUTE = gql`
  mutation updateSpecialRoute($route: UpdateSpecialServiceRouteDTO!) {
    updateSpecialServiceRoute(route: $route) {
      id
    }
  }
`;
export const useCreateUpdateSpecialRoute = (
  isEdit = false,
  onSuccess?: (route: ServiceRoute) => void,
  onError?: (error: ApolloError) => void
) => {
  const [submitRoute, { data, loading, error }] = useMutation<
    {
      createSpecialServiceRoute: ServiceRoute;
      updateSpecialServiceRoute: ServiceRoute;
    },
    { route: CreateSpecialServiceRoute | UpdateSpecialServiceRoute }
  >(isEdit ? UPDATE_SPECIAL_ROUTE : CREATE_SPECIAL_ROUTE, {
    onCompleted: ({ createSpecialServiceRoute, updateSpecialServiceRoute }) => {
      if (onSuccess) {
        if (isEdit) {
          onSuccess(updateSpecialServiceRoute);
        } else {
          onSuccess(createSpecialServiceRoute);
        }
      }
    },
    onError
  });

  return {
    submitRoute,
    route: isEdit
      ? data?.updateSpecialServiceRoute
      : data?.createSpecialServiceRoute,
    createRouteLoading: loading,
    creatingRouteError: error
  };
};

export const DELETE_SERVICE_ROUTES = gql`
  mutation deleteServiceRoutes($ids: [String!]!) {
    deleteServiceRoutes(ids: $ids)
  }
`;

const ASSIGN_DEFAULT_VALET = gql`
  mutation assignDefaultValet($routeId: String!, $valetId: String!) {
    assignRouteToValet(valetId: $valetId, routeId: $routeId)
  }
`;

export const useAssignDefaultValet = () => {
  const [assign, { data, loading, error }] = useMutation<
    { assignRouteToValet: boolean },
    { routeId: string; valetId: string }
  >(ASSIGN_DEFAULT_VALET);

  const assignDefaultValet = (routeId: string, valetId: string) => {
    assign({
      variables: {
        routeId,
        valetId
      }
    });
  };

  return {
    assignDefaultValet,
    assignDefaultValetLoading: loading,
    assignDefaultValetError: error
  };
};
