import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import {
  AlternateEmailRounded,
  SupervisedUserCircleRounded,
  EmailRounded,
  PinDropRounded,
  PersonRounded,
  PhoneRounded
} from "@material-ui/icons";
import { formatAddress } from "../../../shared/utils";
import { User } from "../../../apollo/users/interfaces";
import PasswordChangeModal from "./PasswordChangeModal";
import useAbility from "../../../shared/hooks/useAbility";

interface UserDetailsSectionProps {
  user?: User;
}
const UserDetailsSection: React.FC<UserDetailsSectionProps> = ({ user }) => {
  const ability = useAbility();
  return (
    <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Grid item xs={12} container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h6">Basic Information</Typography>
        </Grid>
        {ability.can("update", "Users") && (
          <Grid item>
            <PasswordChangeModal userId={user ? user.id : ""} />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <List>
          <Item
            title="Name"
            icon={<PersonRounded color="primary" />}
            description={
              user?.name ? user.name : user?.firstName + " " + user?.lastName
            }
          />
          <Item
            title="Username"
            icon={<AlternateEmailRounded color="primary" />}
            description={user?.username}
          />

          <Item
            title="Role"
            icon={<SupervisedUserCircleRounded color="primary" />}
            description={user?.roles[0].name}
          />
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <List>
          <Item
            title="Email"
            icon={<EmailRounded color="primary" />}
            description={user?.email}
          />
          <Item
            title="Address"
            icon={<PinDropRounded color="primary" />}
            description={
              user?.addressFormatted
                ? user?.addressFormatted
                : formatAddress(user?.address)
            }
          />

          <Item
            title="Phone"
            icon={<PhoneRounded color="primary" />}
            description={user?.phone}
          />

          <Item
            title="Emergency Phone"
            icon={<PhoneRounded color="primary" />}
            description={user?.emergencyPhone}
          />
        </List>
      </Grid>
    </Grid>
  );
};

interface ItemProps {
  icon: React.ReactNode;
  title: string;
  description?: string | null;
}

const Item: React.FC<ItemProps> = ({ icon, title, description }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          color: "textSecondary"
        }}
        secondary={
          <Typography component="span" variant="body2" color="textPrimary">
            {description ? description : "Not Specified"}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default UserDetailsSection;
