import { Button, CircularProgress, Fade, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import React, { useEffect } from "react";
import { ValetPreCreation } from "../../../apollo/preCreations/interfaces";
import { useSelfSignValetAgreement } from "../../../apollo/preCreations/mutations";
import { useGetValetAgreementPreviewLazy } from "../../../apollo/preCreations/queries";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";
import SignatureField from "../../../shared/components/SignatureField";
import useDebounce from "../../../shared/hooks/useDebounce";
import useSnackBar from "../../../shared/hooks/useSnackbar";

interface ValetSelfAgreementFormProps {
  preCreation: ValetPreCreation;
  onSuccess?: () => void;
}

const ValetSelfAgreementForm: React.FC<ValetSelfAgreementFormProps> = ({
  preCreation,
  onSuccess
}) => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackBar();
  const { sign, error, loading, success } = useSelfSignValetAgreement();

  const onSubmit = (values: ValetSelfAgreementFormValues) => {
    sign({ ...values, preCreationId: preCreation.id });
  };

  useEffect(() => {
    if (error) {
      sendErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      sendSuccessMessage("Agreement signed successfully");
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [success]);

  return (
    <Formik onSubmit={onSubmit} initialValues={{ signature: null, ssn: null }}>
      <>
        <LoadingBackdrop open={loading} />
        <ValetAgreementFormInputs preCreationId={preCreation.id} />
      </>
    </Formik>
  );
};

interface ValetSelfAgreementFormValues {
  signature: string | null;
  ssn: string | null;
}

interface ValetAgreementFormInputsProps {
  preCreationId: string;
}
const ValetAgreementFormInputs: React.FC<ValetAgreementFormInputsProps> = ({
  preCreationId
}) => {
  const {
    getPreview,
    error,
    loading,
    preview
  } = useGetValetAgreementPreviewLazy();

  const { values } = useFormikContext<ValetSelfAgreementFormValues>();
  const debouncedSsn = useDebounce<string | null>(values.ssn, 1000);
  useEffect(() => {
    getPreview({
      preCreationId,
      signature: values.signature,
      ssn: debouncedSsn
    });
  }, [values.signature, debouncedSsn]);

  return (
    <Form style={{ width: "100%" }}>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <Fade in={!!preview && !loading} unmountOnExit={true}>
            <img
              src={preview}
              style={{ width: "100%", borderRadius: 20 }}
              alt="agreement-preview"
            />
          </Fade>
        </Grid>

        <Grid item xs={12} container justify="center">
          {loading && <CircularProgress size={50} />}
          {error && (
            <Alert severity="error">
              Something went wrong trying to generate the agreement's preview!
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Field
            component={TextField}
            name="ssn"
            variant="outlined"
            fullWidth
            label="SSN / Tax ID"
          />
        </Grid>

        <Grid item xs={12} container justify="center">
          <Grid item xs={12} sm={10} md={4}>
            <Field
              component={SignatureField}
              name={"signature"}
              createLabel="Sign"
              title="Your Signature"
              buttonProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container justify="center">
          <Grid item xs={12} sm={10} md={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={!values.signature || !values.ssn}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ValetSelfAgreementForm;
