import React, { useState } from "react";
import { Container, Button, Box } from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import Link from "../../navigation/Link";
import DataTable from "../../shared/components/Datatable";
import { MUIDataTableColumn } from "mui-datatables";
import { GET_CUSTOMERS_PAGINATED } from "../../apollo/customers/queries";
import SharedCustomersTable from "./components/SharedCustomersTable";
import useAbility from "../../shared/hooks/useAbility";
import SubTenantSearchBox from "../Tenants/components/SubTenantsSearchbox";

const columns: MUIDataTableColumn[] = [
  {
    name: "id",
    options: {
      display: "excluded"
    }
  },

  {
    name: "propertyName",
    label: "Property",
    options: {
      customBodyRender: (value, tableMeta) => {
        // updateValue(tableMeta.rowData[1] + " " + tableMeta.rowData[2]);
        return (
          <Link to={"/dashboard/customers/" + tableMeta.rowData[0]}>
            {value}
          </Link>
        );
      }
    }
  },
  {
    name: "customerName",
    label: "Property Manager"
  },
  {
    name: "mainPhone",
    label: "Property Contact Phone",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => value
    }
  },
  {
    name: "email",
    label: "Property Contact Email",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not specified";
        return value;
      }
    }
  },
  {
    name: "regionalManagerName",
    label: "Regional Manager",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not set";
        return value;
      }
    }
  },
  {
    name: "managementCompanyName",
    label: "Management Company",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not set";
        return value;
      }
    }
  }
];
const CustomersListPage: React.FC = ({}) => {
  const ability = useAbility();

  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Customers" }
        ]}
      />
      <PageHeader
        title={"Customers"}
        actions={
          <>
            {ability.can("create", "Customers") && (
              <Link to="/dashboard/customers/create">
                <Button variant="contained" color="primary">
                  Create Customer
                </Button>
              </Link>
            )}
          </>
        }
      />
      <DataTable
        query={GET_CUSTOMERS_PAGINATED}
        columns={columns}
        subTenantFilter={{show: true}}
        includeTenants
        dateColumns={[{ name: "createdAt", label: "Created At" }]}
        options={{
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 30],
          download: false,
          print: false,
          filter: false,
          selectToolbarPlacement: "none"
        }}
      />

      <Box marginTop={8}>
        <PageHeader title={"Shared Customers"} />
      </Box>
      <SharedCustomersTable />
    </Container>
  );
};

export default CustomersListPage;
