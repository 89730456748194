import { Container, Grid, colors, Typography, Button } from "@material-ui/core";
import { request } from "http";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TimeOffRequestStatus } from "../../apollo/timeOffRequests/interfaces";
import { useChangeRequestStatus } from "../../apollo/timeOffRequests/mutations";
import { useGetTimeOffRequestView } from "../../apollo/timeOffRequests/queries";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import ErrorPage from "../../shared/components/ErrorPage";
import InfoCard from "../../shared/components/InfoCard";
import PageHeader from "../../shared/components/PageHeader";
import useOngoingProcess from "../../shared/hooks/useOngoingProcess";
import useSnackbar from "../../shared/hooks/useSnackbar";
import LoadingPage from "../Home/LoadingPage";

const TimeOffRequestPage: React.FC = ({}) => {
  const { requestId } = useParams<{ requestId: string }>();
  const { request, requestError } = useGetTimeOffRequestView(requestId);
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const { setOngoingProcess, ongoingProcess } = useOngoingProcess();
  const {
    changeStatus,
    changedStatus,
    changeStatusError,
    changeStatusLoading
  } = useChangeRequestStatus();

  const onChangeStatus = (type: TimeOffRequestStatus) => () => {
    changeStatus(requestId, type);
  };

  useEffect(() => {
    if (changeStatusError) {
      setOngoingProcess(false);
      sendErrorMessage(
        "Error trying to change the status: " + changeStatusError.message
      );
    }
  }, [changeStatusError]);

  useEffect(() => {
    if (changedStatus) {
      setOngoingProcess(false);
      sendSuccessMessage("Status changed successfully,");
    }
  }, [changedStatus]);

  useEffect(() => {
    if (changeStatusLoading && !ongoingProcess) {
      setOngoingProcess(true);
    }
  }, [changeStatusLoading, ongoingProcess]);

  if (requestError) {
    return <ErrorPage />;
  }

  if (!request) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Time-Off Requests", link: "/dashboard/timeOffRequests" },
          { label: "Time-Off Request" }
        ]}
      />
      <PageHeader title={"Time-Off Request"} />

      <Grid
        container
        spacing={3}
        alignItems="stretch"
        style={{ marginBottom: 30 }}
      >
        <Grid item xs={12}>
          <InfoCard color={colors.purple[300]}>
            <Typography>Status</Typography>
            <Typography variant="h6" gutterBottom>
              {changedStatus ? changedStatus : request.status}
            </Typography>
            {request.status === "Waiting" && (
              <Grid container spacing={2} style={{ marginBottom: 2 }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onChangeStatus("Approved")}
                  >
                    Approve
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onChangeStatus("Denied")}
                  >
                    Deny
                  </Button>
                </Grid>
              </Grid>
            )}
            <Typography>Supervisor</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/valets/" + request.valet.id}>
                {request.supervisor
                  ? `${request.supervisor.firstName} ${request.supervisor.lastName}`
                  : "Not specified"}
              </Link>
            </Typography>
            <Typography>Requested By</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/valets/" + request.valet.id}>
                {request.valet.name}
              </Link>
            </Typography>
            {/* <Typography>Requested on service route</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/serviceRoutes/" + request.route.id}>
                {`${request.route.routeCode} - ${request.route.property.name}`}
              </Link>
            </Typography> */}

            <Typography>Requested on</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(request.createdAt).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            </Typography>
            <Typography>Start date</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(request.startDate).format(
                "LL"
              )}
            </Typography>
            <Typography>End Date</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(request.endDate).format(
               'LL'
              )}
            </Typography>
          </InfoCard>
        </Grid>
        {/* 
        <Grid item xs={12}>
          <Typography variant="h6">Requested Items</Typography>
          <ItemsTable items={request.items} />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default TimeOffRequestPage;
