import {
  Button,
  colors,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { mdiCubeOutline } from "@mdi/js";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  RequestStatus,
  SupplyLineItem
} from "../../apollo/supplyRequests/interfaces";
import { useGetSupplyRequestView } from "../../apollo/supplyRequests/queries";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import ErrorPage from "../../shared/components/ErrorPage";
import Icon from "../../shared/components/Icon";
import InfoCard from "../../shared/components/InfoCard";
import PageHeader from "../../shared/components/PageHeader";
import LoadingPage from "../Home/LoadingPage";
import Image from "material-ui-image";
import { useChangeRequestStatus } from "../../apollo/supplyRequests/mutations";
import useSnackbar from "../../shared/hooks/useSnackbar";
import useOngoingProcess from "../../shared/hooks/useOngoingProcess";

const SupplyRequestPage: React.FC = ({}) => {
  const { requestId } = useParams<{ requestId: string }>();
  const { loadingRequestError, request } = useGetSupplyRequestView(requestId);
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const { setOngoingProcess, ongoingProcess } = useOngoingProcess();
  const {
    changeStatus,
    changedStatus,
    changeStatusError,
    changeStatusLoading
  } = useChangeRequestStatus();

  const onChangeStatus = (type: RequestStatus) => () => {
    changeStatus(requestId, type);
  };
  useEffect(() => {
    if (changeStatusError) {
      setOngoingProcess(false);
      sendErrorMessage(
        "Error trying to change the status: " + changeStatusError.message
      );
    }
  }, [changeStatusError]);

  useEffect(() => {
    if (changedStatus) {
      setOngoingProcess(false);
      sendSuccessMessage("Status changed successfully,");
    }
  }, [changedStatus]);

  useEffect(() => {
    if (changeStatusLoading && !ongoingProcess) {
      setOngoingProcess(true);
    }
  }, [changeStatusLoading, ongoingProcess]);

  if (loadingRequestError) {
    return <ErrorPage />;
  }

  if (!request) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Supply Requests", link: "/dashboard/supplyRequests" },
          { label: "Supply Request" }
        ]}
      />
      <PageHeader title={"Supply Request"} />

      <Grid
        container
        spacing={3}
        alignItems="stretch"
        style={{ marginBottom: 30 }}
      >
        <Grid item xs={12}>
          <InfoCard color={colors.green[300]}>
            <Typography>Status</Typography>
            <Typography variant="h6" gutterBottom>
              {changedStatus ? changedStatus : request.status}
            </Typography>
            {request.status === "Waiting" && (
              <Grid container spacing={2} style={{ marginBottom: 2 }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onChangeStatus("Approved")}
                  >
                    Approve
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onChangeStatus("Denied")}
                  >
                    Deny
                  </Button>
                </Grid>
              </Grid>
            )}
            <Typography>Requested By</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/valets/" + request.valet.id}>
                {request.valet.name}
              </Link>
            </Typography>
            <Typography>Requested on service route</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/serviceRoutes/" + request.route.id}>
                {`${request.route.routeCode} - ${request.route.property.name}`}
              </Link>
            </Typography>

            <Typography>Requested at</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(request.createdAt).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            </Typography>
          </InfoCard>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Requested Items</Typography>
          <ItemsTable items={request.items} />
        </Grid>
      </Grid>
    </Container>
  );
};

interface ItemsTableProps {
  items: SupplyLineItem[];
}
const ItemsTable: React.FC<ItemsTableProps> = ({ items }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Picture</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Variety</TableCell> 
            <TableCell>Quantity</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <TableRow key={item.id}>
              <TableCell>
                {item.image ? (
                  <Image
                    src={item.image.url}
                    imageStyle={{
                      width: "auto",
                      height: "100%",
                      objectFit: "scale"
                    }}
                    style={{
                      height: 50,
                      paddingTop: 0,
                      backgroundColor: "transparent"
                    }}
                  />
                ) : (
                  <Icon path={mdiCubeOutline} />
                )}
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.variety || '-'}</TableCell>
              <TableCell>{item.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SupplyRequestPage;
