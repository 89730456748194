import React from "react";

const Logo: React.FC = () => {
  return (
    <svg
      width="50"
      height="42"
      viewBox="0 0 50 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.5 21C43.5 31.2744 35.3847 39.5 25.5 39.5C15.6153 39.5 7.5 31.2744 7.5 21C7.5 10.7256 15.6153 2.5 25.5 2.5C35.3847 2.5 43.5 10.7256 43.5 21Z"
        stroke="#E3C4A8"
        strokeOpacity="0.69"
        strokeWidth="5"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M12.4 13.304L2 13.278V33.948H6.992V18.296H7.018C7.018 18.296 9.358 18.296 12.4 18.296C15.364 18.296 17.756 20.714 17.756 23.678C17.756 26.642 15.338 29.06 12.374 29.086H10.84C9.514 29.086 8.5 30.256 8.5 31.582C8.5 32.96 9.618 34.052 10.996 34.052C10.996 34.052 11.074 34.052 12.374 34.052C18.094 34.052 22.774 29.398 22.774 23.678C22.774 17.958 18.12 13.304 12.4 13.304Z"
          fill="#006480"
        />
      </g>
      <g filter="url(#filter1_d)">
        <path
          d="M36.2014 34C37.0594 34 37.8914 33.558 38.3334 32.804L47.6154 16.944C48.2914 15.748 47.9014 14.266 46.7314 13.564C45.5614 12.888 44.0534 13.278 43.3514 14.448L36.3314 26.486L30.1694 14.552C29.5454 13.356 28.0634 12.888 26.8674 13.512C25.6454 14.136 25.2034 15.618 25.8014 16.814L34.0174 32.7C34.4334 33.506 35.2394 34 36.1234 34C36.1494 34 36.1754 34 36.2014 34Z"
          fill="#006480"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-1"
          y="11.278"
          width="26.774"
          height="26.774"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.47451 0 0 0 0 0.760784 0 0 0 0 0.882353 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="22.537"
          y="11.2327"
          width="28.4097"
          height="26.7673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.47451 0 0 0 0 0.760784 0 0 0 0 0.882353 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Logo;
