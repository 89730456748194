import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  capitalize
} from "@material-ui/core";
import { Field, useFormikContext, FieldArray, validateYupSchema } from "formik";
import {
  RegularRouteFormValues,
  RouteArea,
  RouteAreaPoint
} from "./interfaces";
import { TextField, Checkbox, CheckboxWithLabel } from "formik-material-ui";
import { TimePicker } from "formik-material-ui-pickers";
import { WorkingDay } from "../../Agreements/CustomerSteps/interfaces";
import { ColorPickerField } from "../../../shared/components/ColorPicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import WorkingDayFields from "../../../shared/components/WorkingDaysField";
import DrawingMap from "../../../shared/components/Map/DrawingMap";
import * as Yup from "yup";
import { Moment } from "moment";
import { ErrorOutlineSharp, RefreshRounded } from "@material-ui/icons";
import {
  useGetServiceRouteCustomerDataLazy,
  useGetServiceRouteAgreementDataLazy,
  useIsRouteCodeAvailable,
  useGetServiceRouteForEdit,
  useGetServiceRoutePropertyDataLazy
} from "../../../apollo/serviceRoutes/queries";
import { property } from "lodash";
import useUrlQuery from "../../../shared/hooks/useUrlQuery";
import LoadingPage from "../../Home/LoadingPage";
import { MapApiWrapper } from "../../../shared/components/Map";
import moment from "moment";
import _ from "lodash";
import { getAreasFromRoutes } from "../utils";
import {
  isRegularRoute,
  ServiceRoute
} from "../../../apollo/serviceRoutes/interfaces";
import { useParams } from "react-router-dom";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import PropertySelectModal from "../../Customers/components/PropertySelectModal";
import { PropertySelectItem } from "../../../apollo/customers/interfaces";

export const getRegularRouteFormInitialValues = (
  route?: ServiceRoute
): RegularRouteFormValues => {
  return {
    id: route?.id,
    routeCode: route ? route.routeCode : "",
    areas: route
      ? route.areas.map(a => ({ name: a.name, points: a.points }))
      : [],
    canBeWorkedOn: route ? !!route.canBeWorkedOn : false,
    monthlyRoutePay: route ? route.monthlyRoutePay : 0.0,
    startTime: route ? moment(route.startTime) : moment('8:00 PM', 'h:mm A'),
    offPropertyTime: route ? moment(route.offPropertyTime) : null,
    routeColor: route ? route.routeColor : "red",
    workingDays:
      route && isRegularRoute(route.extraData)
        ? route.extraData.workingDays.map(day => capitalize(day) as WorkingDay)
        : [],
    propertyId: route ? route.propertyId : "",
    dailyRoutePay: route ? route.dailyRoutePay : 0,
    property: null
  };
};

export const getRegularRouteFormValidationSchema = () => {
  return Yup.object<RegularRouteFormValues>({
    areas: Yup.array(
      Yup.object<RouteArea>({
        name: Yup.string().required("This field is required"),
        points: Yup.array(
          Yup.object<RouteAreaPoint>({
            latitude: Yup.number().required(),
            longitude: Yup.number().required()
          }).required()
        ).required()
      }).required()
    )
      .min(1, "You must specify at least one area.")
      .required(),
    routeCode: Yup.string().required(),
    canBeWorkedOn: Yup.boolean().required(),
    monthlyRoutePay: Yup.number().required("This field is required."),
    dailyRoutePay: Yup.number().required(),
    startTime: Yup.object<Moment>()
      .nullable()
      .required(),
    offPropertyTime: Yup.object<Moment>()
      .nullable()
      .required(),
    routeColor: Yup.string().required(),
    workingDays: Yup.array(
      Yup.string()
        .oneOf([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ])
        .required()
    )
      .min(1, "You must specify at least one working day")
      .required(),
    propertyId: Yup.string().required(),
    property: Yup.object<PropertySelectItem>().test(
      "check if property is selected",
      "You must specify a propery.",
      function() {
        const { parent } = this;
        const { propertyId } = parent as RegularRouteFormValues;

        return !!propertyId;
      }
    ).nullable()
  });
};

const workingDays: WorkingDay[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const useRegularRouteForm = () => {
  const { routeId } = useParams<{ routeId: string }>();
  const [propertyId, setPropertyId] = useState(useUrlQuery().get("propertyId"));
  const { sendErrorMessage } = useSnackbar();
  const isRouteCodeAvailable = useIsRouteCodeAvailable();
  const onFormSubmit = (values: RegularRouteFormValues) => {};
  const isEdit = !!routeId;
  const { values, errors, setFieldValue, setValues } = useFormikContext<
    RegularRouteFormValues
  >();

  const {
    getRoute,
    route,
    routeLoading,
    routeLoadingError
  } = useGetServiceRouteForEdit();

  const {
    getProperty,
    property,
    loadingProperty
  } = useGetServiceRoutePropertyDataLazy();

  const {
    getAgreement: _getAgreement,
    agreement,
    loadingAgreement,
    loadingAgreementError
  } = useGetServiceRouteAgreementDataLazy();

  const getAgreement = () => {
    if (values.propertyId) _getAgreement(values.propertyId);
  };

  const validateCode = async (value: string) => {
    if (!property || !value) return;
    const isAvailable = await isRouteCodeAvailable(value, property.id, routeId);
    if (isAvailable) return;

    return "This route code is being used by another route inside this property.";
  };

  useEffect(() => {
    if (routeId) {
      getRoute(routeId);
    }
  }, [routeId]);

  useEffect(() => {
    if (propertyId) {
      getProperty(propertyId);
    } else if (values.propertyId) {
      getProperty(values.propertyId);
    }
  }, [propertyId, values.propertyId]);

  useEffect(() => {
    if (property && !isEdit) {
      setFieldValue("propertyId", property.id);
    }
  }, [property]);

  useEffect(() => {
    if (route) {
      setValues(getRegularRouteFormInitialValues(route));
      if (route.property.id) setPropertyId(route.property.id);
    }
  }, [route]);

  useEffect(() => {
    if (routeLoadingError) {
      sendErrorMessage(
        "Error loading the route's info: " + routeLoadingError.message
      );
    }
  }, [routeLoadingError]);

  useEffect(() => {
    const numberOfDays = values.workingDays.length;
    if (numberOfDays === 0) {
      setFieldValue("dailyRoutePay", 0);
    } else {
      setFieldValue("dailyRoutePay", values.monthlyRoutePay / 4 / numberOfDays);
    }
  }, [values.workingDays, values.monthlyRoutePay]);

  useEffect(() => {
    if (agreement) {
      setValues({
        ...values,
        workingDays: agreement.workingDays,
        startTime: moment(agreement.serviceStartTime),
        offPropertyTime:agreement.offPropertyTime? moment(agreement.offPropertyTime) : null
      });
    }
  }, [agreement]);

  useEffect(() => {
    if (values.property) setFieldValue("propertyId", values.property.id);
  }, [values.property]);

  return {
    model: {
      property,
      values,
      errors,
      loading: loadingProperty || routeLoading,
      loadingAgreement,
      isEdit,
      disableAgreementButton: !values.propertyId,
      fromProperty: !!propertyId,
      routeId
    },
    commands: {
      getAgreement,
      onFormSubmit,
      validateCode
    }
  };
};

const RegularRouteForm: React.FC = () => {
  const {
    model: {
      values,
      errors,
      property,
      loading,
      loadingAgreement,
      disableAgreementButton,
      isEdit,
      routeId,
      fromProperty
    },
    commands: { onFormSubmit, getAgreement, validateCode }
  } = useRegularRouteForm();
  const existingAreas = useMemo(() => {
    if (property) {
      return getAreasFromRoutes(property.serviceRoutes, routeId);
    }
    return [];
  }, [property, routeId]);

  const propertyCenter =
    property?.address.latitude && property?.address.longitude
      ? {
          lat: property.address.latitude,
          lng: property.address.longitude
        }
      : undefined;
  return (
    <MapApiWrapper>
      {loading ? (
        <LoadingPage />
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={4}>
              <Box>
                <Typography color="textSecondary">Customer</Typography>
                <Typography color="textPrimary" variant="h6" gutterBottom>
                  {customer ? customer.firstName + " " + customer.lastName : ""}
                </Typography>
              </Box>
            </Grid> */}

            {fromProperty && (
              <>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography color="textSecondary">Property Name</Typography>
                    <Typography color="textPrimary" variant="h6" gutterBottom>
                      {property ? property.name : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography color="textSecondary">
                      Property Address
                    </Typography>
                    <Typography color="textPrimary" variant="h6" gutterBottom>
                      {property ? property.address.formatted : ""}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
            {!fromProperty && !isEdit && (
              <Grid item xs={12}>
                <PropertySelectModal
                  name="property"
                  fullWidth
                  variant="outlined"
                  label="Property"
                />
              </Grid>
            )}
            {!isEdit && (
              <Grid item xs={12}>
                <Button
                  endIcon={
                    loadingAgreement ? (
                      <CircularProgress size={24} />
                    ) : (
                      <RefreshRounded />
                    )
                  }
                  color="primary"
                  disabled={loadingAgreement || disableAgreementButton}
                  onClick={() => getAgreement()}
                >
                  Load information from agreement
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <Field
                validate={validateCode}
                fullWidth
                component={TextField}
                variant="outlined"
                name="routeCode"
                label="Route Code"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                component={TimePicker}
                inputVariant="outlined"
                variant="inline"
                name="startTime"
                label="Start Time"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                component={TimePicker}
                inputVariant="outlined"
                variant="inline"
                name="offPropertyTime"
                label="Off Property Time"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                component={TextField}
                variant="outlined"
                name="monthlyRoutePay"
                label="Monthly Pay Rate"
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                component={TextField}
                variant="outlined"
                name="dailyRoutePay"
                label="Daily Pay Rate"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                label="Working Days"
                component={WorkingDayFields}
                name="workingDays"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Field
                Label={{ label: "Can be worked on?", labelPlacement: "end" }}
                component={CheckboxWithLabel}
                name="canBeWorkedOn"
                type="checkbox"
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Route areas</Typography>
            <Typography gutterBottom>
              Here you must outline the areas on the map by creating a polygon.
              You can delete an area by right clicking.
            </Typography>
            <Grid item xs={12}>
              <Field
                component={ColorPickerField}
                name="routeColor"
                label="Route Color"
              />
              <FieldArray
                name="areas"
                render={arrayHelpers => (
                  <>
                    <div style={{ height: "500px" }}>
                      <DrawingMap
                        displayOnlyAreas={existingAreas}
                        markers={
                          propertyCenter
                            ? [{ ...propertyCenter, label: "Property" }]
                            : undefined
                        }
                        initialCenter={propertyCenter}
                        initialZoom={
                          property?.address.latitude ? 20 : undefined
                        }
                        areas={values.areas}
                        onAreaAdded={area => arrayHelpers.push(area)}
                        color={values.routeColor}
                        onAreaRemoved={i => arrayHelpers.remove(i)}
                      />
                    </div>
                    {errors.areas && typeof errors.areas === "string" && (
                      <Typography variant="caption" color="error">
                        {errors.areas}
                      </Typography>
                    )}
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                      {values.areas.map((area, i) => (
                        <Grid item xs={12} key={i}>
                          <Typography gutterBottom>Area {i + 1} </Typography>
                          <Field
                            variant={"outlined"}
                            fullWidth
                            component={TextField}
                            label="Area Name"
                            name={`areas[${i}].name`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      )}
    </MapApiWrapper>
  );
};

export default RegularRouteForm;
