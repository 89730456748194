import { useEffect, useState } from "react";
interface FetchResults<T> {
  data: T | null;
  error: any;
  loading: boolean;
}
export const useFetch = <T,>(url: string, options?: RequestInit): FetchResults<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const data = await res.json();
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, error, loading };
};

interface ManualFetchResults<T> extends FetchResults<T> {
  fetchData: () => Promise<void>;
}
export const useManualFetch = <T,>(
  url: string,
  options?: RequestInit
): ManualFetchResults<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await fetch(url, options);
      const data = await res.json();
      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, fetchData, loading };
};
