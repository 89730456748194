import React, { useState } from "react";
import { Container, Button, Box } from "@material-ui/core";
import BreadcrumbsSection from "../../../shared/components/BreadcrumbsSection";
import PageHeader from "../../../shared/components/PageHeader";
import Link from "../../../navigation/Link";
import DataTable from "../../../shared/components/Datatable";
import { MUIDataTableColumn } from "mui-datatables";
import { GET_CUSTOMERS_PAGINATED, GET_PROPERTIES_PAGINATED } from "../../../apollo/customers/queries";
import SharedCustomersTable from "./SharedCustomersTable";
import useAbility from "../../../shared/hooks/useAbility";

const columns: MUIDataTableColumn[] = [
  {
    name: "id",
    label: "View",
    options: {
      sort: false,
      filter: false,

      customBodyRender: (value, tableMeta) => {
        return (
          <Link to={"/dashboard/properties/" + value}>
            <Button variant="outlined" color="primary">View</Button>
          </Link>
        );
      }
    }
  },

  {
    name: "name",
    label: "Property"
  },
  {
    name: "mainPhone",
    label: "Main Phone",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => value
    }
  },
  {
    name: "contactName",
    label: "Contact Name",
    options: {
      customBodyRender: (value, tableMeta, updateValue) =>
        value || "Not Specified"
    }
  },

  {
    name: "email",
    label: "Email",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not specified";
        return value;
      }
    }
  },
  {
    name: "regionalManager",
    label: "Regional Manager",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not specified";
        return value;
      }
    }
  },
  {
    name: "managementCompany",
    label: "Management Company",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not specified";
        return value;
      }
    }
  }
];
const PropertiesTable: React.FC = ({}) => {
  const ability = useAbility();

  return (
    <DataTable
      query={GET_PROPERTIES_PAGINATED}
      columns={columns}
      subTenantFilter={{ show: true }}
      includeTenants
      dateColumns={[{ name: "createdAt", label: "Created At" }]}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
        selectToolbarPlacement: "none"
      }}
    />
  );
};

export default PropertiesTable;
