import React, { useState, useEffect } from "react";
import { DrawerItemProps, ItemProps, SectionProps } from "./interfaces";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Popover,
  makeStyles,
  createStyles
} from "@material-ui/core";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import useStyles from "./styles";
import clsx from "clsx";
import useAbility from "../../shared/hooks/useAbility";
const usePopperStyles = makeStyles((theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(2),
    },
  }),
);
const DrawerItem: React.FC<DrawerItemProps> = ({ link, nested, renderPopover }) => {
  const classes = useStyles();
  if (link.nodes) {
    return (
      <Section
        {...link}
        iconClass={classes.drawerItemIcon}
        itemClass={classes.listItem}
        renderPopover={renderPopover}
      />
    );
  }

  return (
    <Item
      {...link}
      iconClass={clsx(classes.drawerItemIcon, { nested: nested })}
      activeClassName={classes.activeLink}
      itemClass={classes.listItem}
      nestedClass={nested ? classes.nested : undefined}
      renderPopover={renderPopover}
    />
  );
};

const Item: React.FC<ItemProps> = ({
  to,
  exact,
  renderIcon,
  label,
  activeClassName,
  nestedClass,
  itemClass,
  iconClass,
  permission,
  matchRoutes,
  renderPopover
}) => {
  const classes = usePopperStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    console.log('mouse enter')
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const ability = useAbility();
  const canAccess = permission
    ? ability.can(permission.action, permission.subject)
    : true;
  const matches = useRouteMatch({
    path: matchRoutes ? matchRoutes : to,
    exact
  });
  if (!canAccess) return null;
  const popOverOpen = Boolean(anchorEl);

  return (
    <>
      <NavLink
        aria-owns={popOverOpen ? label : undefined}
        aria-haspopup="true"
        to={to}
        exact={exact}
        isActive={() => !!matches}
        activeClassName={activeClassName}
        onMouseEnter={renderPopover? handlePopoverOpen : undefined}
        onMouseLeave={renderPopover? handlePopoverClose : undefined}
      >
        <ListItem button className={clsx(itemClass)}>
          <ListItemIcon>{renderIcon({ className: iconClass })}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      </NavLink>
      <Popover
        id={label}
        open={popOverOpen}
        anchorEl={anchorEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{label}</Typography>
      </Popover>
    </>
  );
};

const Section: React.FC<SectionProps> = ({
  renderIcon,
  label,
  exact,
  to,
  nodes,
  activeRoute,
  iconClass,
  itemClass,
  matchRoutes
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popOverOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(o => !o);
  };
  const matches = useRouteMatch({
    path: matchRoutes ? matchRoutes : to,
    exact
  });
  useEffect(() => {
    if (matches) {
      setOpen(o => true);
    }
    return () => {};
  }, []);

  return (
    <>
      <ListItem
        button
        onClick={toggle}
        className={itemClass}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ListItemIcon>{renderIcon({ className: iconClass })}</ListItemIcon>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Popover
        id={label}
        open={popOverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{label}</Typography>
      </Popover>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ borderRadius: 10 }}>
          {nodes &&
            nodes.map((link, i) => (
              <DrawerItem key={link.label + i} nested={true} link={link} />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default DrawerItem;
