import { TextFieldProps } from "@material-ui/core";
import { useField } from "formik";
import React, { useState } from "react";
import { PropertySelectItem } from "../../../apollo/customers/interfaces";
import { useGetPropertiesForSelect } from "../../../apollo/customers/queries";
import ItemSelectModal from "../../../shared/components/ItemSelectModal";
import useAbility from "../../../shared/hooks/useAbility";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

const PropertySelectModal: React.FC<TextFieldProps & {
  onItemChange?: (property: PropertySelectItem) => void;
}> = ({ name = "property", onItemChange, ...props }) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const {
    properties,
    total,
    error,
    loading,
    refetch
  } = useGetPropertiesForSelect({ limit: 5, search, offset: offset });

  const [{ value }, meta, helpers] = useField<PropertySelectItem>(name);
  const ability = useAbility();
  return (
    <ItemSelectModal
      modalTitle="Select a property"
      noItemsText="No properties have been found."
      onItemClick={onItemChange ?? helpers.setValue}
      matchingKey="id"
      title="name"
      value={value}
      subtitle={ability.can("manage", "SubTenants") ? "subTenant" : undefined}
      items={properties}
      total={total}
      onSearchChange={setSearch}
      onOffsetChange={setOffset}
      InputProps={{
        ...props,
        error: !!meta.error,
        helperText: meta.error
      }}
    />
  );
};

export default PropertySelectModal;
