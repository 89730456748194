import React, { useState } from "react";
import { User } from "../../../apollo/users/interfaces";
import { useGetUsersForSelect } from "../../../apollo/users/queries";
import SelectModal from "../../../shared/components/SelectModal";
interface UsersSelectModalProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  onUserChange: (user?: User) => void;
  subTenantId?: string;
  role?: string;
  initialValue?: User
}
const UsersSelectModal: React.FC<UsersSelectModalProps> = ({
  onClose,
  onUserChange,
  open,
  subTenantId,
  role,
  initialValue,
  title
}) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);

  const { error, loading, total, users } = useGetUsersForSelect({
    role,
    search,
    offset,
    subTenantId,
    limit: 5
  });

  return (
    <SelectModal
      title={title}
      titleKey="name"
      subtitleKey="username"
      open={open}
      initialValue={initialValue}
      onSearchChange={setSearch}
      onClose={onClose}
      renderClear
      onItemChange={onUserChange}
      items={users}
    />
  );
};

export default UsersSelectModal;
