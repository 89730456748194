import React from "react";
import { Grid } from "@material-ui/core";

import CalculatedField from "../../../shared/components/CalculatedField";
import UnitBaseFields, {
  UnitsBaseFormValues,
  getUnitBaseValidationSchema,
  getUnitBaseInitialValues,
} from "./UnitBaseFields";

import * as Yup from "yup";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { CustomerAgreement } from "../../../apollo/agreements/interface";
export interface UnitsPhaseInStepFormValues extends UnitsBaseFormValues {
  metadata: {
    maximumMonthlyCharge: number;
    firstMonthCharge?: number;
    secondMonthCharge?: number;
    increment?: number;
  };
}

interface BaseUnitsPhaseInStepFormValues {
  metadata: {
    maximumMonthlyCharge: number;
    firstMonthCharge?: number;
    secondMonthCharge?: number;
    increment?: number;
  };
}

export const getPhaseInStepValidationSchema = () => {
  return getUnitBaseValidationSchema().shape<BaseUnitsPhaseInStepFormValues>({
    metadata: Yup.object({
      maximumMonthlyCharge: Yup.number()
        .moreThan(0, "You must specify a higher value")
        .required("This field is required."),
      firstMonthCharge: Yup.number()
        .moreThan(0, "You must specify a higher value")
        .lessThan(
          Yup.ref("maximumMonthlyCharge"),
          "This value must be less than the maximum monthly charge."
        )
        .required("This field is required."),
      secondMonthCharge: Yup.number()
        .lessThan(
          Yup.ref("maximumMonthlyCharge"),
          "This value must be less than the maximum monthly charge."
        )
        .moreThan(0, "You must specify a higher value")
        .required("This field is required."),
      increment: Yup.number()
        .lessThan(
          Yup.ref("maximumMonthlyCharge"),
          "This value must be less than the maximum monthly charge."
        )
        .moreThan(0, "You must specify a higher value")
        .required("This field is required."),
    }).required(),
  });
};

export const getPhaseInStepInitialValues = (
  agreement?: CustomerAgreement
): UnitsPhaseInStepFormValues => {
  return {
    ...getUnitBaseInitialValues(agreement),
    metadata: {
      maximumMonthlyCharge: agreement?.metadata?.maximumMonthlyCharge
        ? agreement.metadata.maximumMonthlyCharge
        : 0,
      firstMonthCharge: agreement?.metadata?.firstMonthCharge
        ? agreement.metadata.firstMonthCharge
        : 0,
      secondMonthCharge: agreement?.metadata?.secondMonthCharge
        ? agreement.metadata.secondMonthCharge
        : 0,
      increment: agreement?.metadata?.increment
        ? agreement.metadata.increment
        : 0,
    },
  };
};

const UnitPhaseInStep: React.FC = () => {
  return (
    <UnitBaseFields>
      <Grid item xs={12} md={6}>
        <CalculatedField<UnitsPhaseInStepFormValues>
          firstValue="aptUnitsCharged"
          secondValue="aptUnitPrice"
          operation={(aptUnitsCharged: number, aptUnitPrice: number) => {
            return aptUnitsCharged * aptUnitPrice;
          }}
          inputProps={{
            readOnly: true,
          }}
          type="number"
          variant="outlined"
          name="metadata.maximumMonthlyCharge"
          fullWidth
          label="Maximum Monthly Charge"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Field
          component={TextField}
          variant="outlined"
          name="metadata.firstMonthCharge"
          fullWidth
          label="First Month Charge"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Field
          component={TextField}
          variant="outlined"
          name="metadata.secondMonthCharge"
          fullWidth
          label="Second Month Charge"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Field
          component={TextField}
          variant="outlined"
          name="metadata.increment"
          fullWidth
          label="Increment"
          type="number"
        />
      </Grid>
    </UnitBaseFields>
  );
};

export default UnitPhaseInStep;
