import React from "react";

import { GET_TENANTS_SIMPLE } from "../../../apollo/tenants/queries";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { Tenant } from "../../../apollo/tenants/interfaces";

interface TenantSearchboxProps {
  onChange?: (event: React.ChangeEvent<any>) => void;
  onOptionChange?: (tenantId: string) => void;
  error?: string | null;
  name?: string;
  label?: string;
  value?: Tenant | null | string;
}
const useTenantSearchbox = () => {
  const { data } = useQuery<{ tenantsSimple: Tenant[] }>(GET_TENANTS_SIMPLE);

  return {
    model: {
      tenants: data?.tenantsSimple,
    },
  };
};
const TenantSearchbox: React.FC<TenantSearchboxProps> = ({
  onOptionChange,
  error,
  onChange,
  name,
  label,
  value
}) => {
  const {
    model: { tenants },
  } = useTenantSearchbox();

  const onSearchChange = (e: React.ChangeEvent<{}>, tenant: Tenant | null) => {
    if (onChange && name) {
      const synteticEvent = {
        ...e,
        target: {
          value: tenant?.id,
          name,
        },
      };
      onChange(synteticEvent);
    }

    if (onOptionChange) {
      onOptionChange(tenant ? tenant.id : "");
    }
  };

  const getValue = () => {
    if (typeof value === "string") {
      const tenant = tenants?.find((t) => t.id === value);
      return tenant ? tenant : null;
    }
    return value;
  };
  return (
    <Autocomplete
      // id="combo-box-demo"
      options={tenants ? tenants : []}
      getOptionLabel={(option) => option.name}
      onChange={onSearchChange}
      value={getValue()}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : "Get By Tenant"}
          variant="outlined"
          error={!!error}
          helperText={error}
          fullWidth
        />
      )}
    />
  );
};

export default TenantSearchbox;
