import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  colors
} from "@material-ui/core";
import {
  PinDropRounded,
  DeleteRounded,
  EditRounded,
  AlternateEmailRounded,
  EmailRounded,
  SupervisedUserCircleRounded
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useParams, useHistory } from "react-router-dom";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER } from "../../apollo/users/queries";
import { formatAddress } from "../../shared/utils";
import DeleteModal from "../../shared/components/DeleteModal";
import { User } from "../../apollo/users/interfaces";
import FabMenu, { FabMenuAction } from "../../shared/components/FabMenu";
import { DELETE_USERS } from "../../apollo/users/mutations";
import useAbility from "../../shared/hooks/useAbility";
import UserDetailsSection from "./components/UserDetailsSection";
import InfoCard from "../../shared/components/InfoCard";

const useStyles = makeStyles(theme => ({
  basicPaper: {
    position: "relative"
  }
}));

const LoadingSkeleton: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={50} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Skeleton
            animation="wave"
            width="100%"
            height="50vh"
            style={{ marginTop: -80 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const useUserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const ability = useAbility();
  const { data, loading } = useQuery<{ user: User }, { userId: string }>(
    GET_USER,
    {
      variables: {
        userId
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const onDeleteSuccess = () => {
    history.replace("/dashboard/users");
  };

  const onDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onEditClick = () => {
    history.push(`/dashboard/users/${userId}/edit`, {
      user: data?.user
    });
  };

  return {
    model: {
      loading,
      showDeleteModal,
      userId,
      user: data?.user,
      ability
    },
    commands: {
      onDeleteSuccess,
      onDeleteClick,
      onEditClick,
      onDeleteModalClose
    }
  };
};
const UserPage: React.FC = () => {
  const classes = useStyles();
  const {
    model: { loading, showDeleteModal, userId, user, ability },
    commands: {
      onDeleteModalClose,
      onDeleteSuccess,
      onDeleteClick,
      onEditClick
    }
  } = useUserPage();

  if (loading) {
    return <LoadingSkeleton />;
  }

  const actions: FabMenuAction[] = [
    {
      name: "Delete",
      icon: <DeleteRounded />,
      onClick: onDeleteClick,
      disabled: ability.cannot("delete", "Users")
    },
    {
      name: "Edit",
      icon: <EditRounded />,
      onClick: onEditClick,
      disabled: ability.cannot("update", "Users")
    }
  ];

  return (
    <Fade in={true}>
      <Container>
        <DeleteModal
          open={showDeleteModal}
          ids={[userId]}
          mutation={DELETE_USERS}
          customVariableName="userIds"
          onClose={onDeleteModalClose}
          onSuccess={onDeleteSuccess}
          title="Delete user"
          description="Are you sure you want to delete this user? This action cannot be undone."
        />

        <BreadcrumbsSection
          sections={[
            { label: "Home", link: "/dashboard" },
            { label: "Users", link: "/dashboard/Users" },
            { label: user ? user.username : "User" }
          ]}
        />
        <PageHeader
          title={user ? `${user.firstName} ${user.lastName}` : "User"}
        />
        <InfoCard className={classes.basicPaper} color={colors.lightBlue["200"]}>
          <FabMenu actions={actions} />

          <Container>
            <UserDetailsSection user={user} />
          </Container>
        </InfoCard>
      </Container>
    </Fade>
  );
};

export default UserPage;
