import React from 'react'

import { IconButton, makeStyles } from "@material-ui/core";
import { DeleteRounded } from '@material-ui/icons';

interface ImageContainerProps {
    onClose?: () => void;
    src?: string;
}
  const useImageContainerStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      height: "100%",
      position: "relative"
    },
    image: {
      width: "100%",
      borderColor: theme.palette.secondary.main,
      border: "solid 3px",
      borderRadius: theme.shape.borderRadius
    },
    icon: {
      top: -theme.spacing(2),
      right: -theme.spacing(2),
      background: theme.palette.secondary.main,
      position: "absolute"
    }
  }));
  const ImageContainer: React.FC<ImageContainerProps> = ({ src, onClose }) => {
    const classes = useImageContainerStyles();
  
    return (
      <div className={classes.root}>
        <IconButton
          aria-label="delete"
          className={classes.icon}
          onClick={onClose}
        >
          <DeleteRounded />
        </IconButton>
        <img src={src} className={classes.image} />
      </div>
    );
  };

  export default ImageContainer;