import React from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import SignalSearchingIllustration from "./SignalSearchingIllustration";

interface ErrorPageProps {
  code?: number | string;
  message?: string;
}
const ErrorPage: React.FC<ErrorPageProps> = ({
  code = "404",
  message = "The resource you were looking for could not be found."
}) => {
  return (
    <Container style={{ height: "100%" }}>
      <Grid
        container
        alignItems="stretch"
        spacing={4}
        style={{ height: "100%" }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box marginBottom={"10%"} marginTop={"20%"}>
            <Typography variant="h1" color="primary">
              {code}
            </Typography>
          </Box>

          <Typography variant="h4">{message}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SignalSearchingIllustration />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
