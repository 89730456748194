import React, { useState } from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Box } from "@material-ui/core";
import useValue from "../../../shared/hooks/useValue";
import { SubTenant, } from "../../../apollo/tenants/interfaces";
import DataTable from "../../../shared/components/Datatable";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import Link from "../../../navigation/Link";
import { SERVICE_ROUTES_DATATABLE } from "../../../apollo/serviceRoutes/queries";

const getColumns = (includeProperty = true) => {
  let columns: MUIDataTableColumn[] = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "propertyId",
      options: {
        display: "excluded",
      },
    },
    {
      name: "valetId",
      options: {
        display: "excluded",
      },
    },
    {
      name: "routeCode",
      label: "Route Code",

      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/dashboard/serviceRoutes/${tableMeta.rowData[0]}`}>
            {value}
          </Link>
        ),
      },
    },
  ];

  if (includeProperty) {
    columns = [
      ...columns,
      {
        name: "propertyName",
        label: "Property",
        options: {
          customBodyRender: (value, tableMeta) => {
            if (!value) return "Not assigned";
            return (
              <Link to={`/dashboard/properties/${tableMeta.rowData[1]}`}>
                {value}
              </Link>
            );
          },
        },
      },
    ];
  }
  columns = [
    ...columns,
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: "valetName",
      label: "Default Valet",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (!value) return "Not assigned";
          return (
            <Link to={`/dashboard/valets/${tableMeta.rowData[2]}`}>
              {value}
            </Link>
          );
        },
      },
    },
  ];

  return columns;
};

const useServiceRoutesTable = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fetchMore, setFetchMore] = useValue<null | Function>(null);
  const [subTenant, setSubTenant] = useState<null | SubTenant>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onDelete = (ids: string[]) => {
    setSelectedIds(ids);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    if (fetchMore) fetchMore();

    setSelectedIds([]);
  };

  const onSubTenantChange = (subTenant: SubTenant | null) => {
    setSubTenant(subTenant);
  };

  return {
    model: {
      showDeleteModal,
      selectedIds,
      subTenant,
    },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange,
    },
  };
};

interface ServiceRoutesTable {
  canDelete?: boolean;
  renderFilter?: boolean;
  includeTenants?: boolean;
  type?: "REGULAR" | "SPECIAL";
  propertyId?: string;
}

const getVariables = (type?: "REGULAR" | "SPECIAL", propertyId?: string) => {
  if (!type && !propertyId) return undefined;

  let variables: any = {};
  if (type) variables = { type };
  if (propertyId) variables = { ...variables, propertyId };

  return variables;
};
const ServiceRoutesTable: React.FC<ServiceRoutesTable> = ({
  canDelete,
  renderFilter,
  includeTenants,
  type,
  propertyId,
}) => {
  const {
    model: { showDeleteModal, selectedIds, subTenant },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange,
    },
  } = useServiceRoutesTable();
  return (
    <>
      {/* <DeleteModal
        open={showDeleteModal}
        ids={selectedIds}
        mutation={DELETE_USERS}
        customVariableName="userIds"
        onClose={onDeleteModalClose}
        onSuccess={onDeleteSuccess}
        title="Delete user(s)"
        description="This action cannot be undone."
      /> */}
      <DataTable
       
        subTenantFilter={{show: !!renderFilter}}
        includeTenants={includeTenants}
        dateColumns={[{ name: "createdAt", label: "Created At" }]}
        fetchMore={setFetchMore}
        columns={getColumns(!!!propertyId)}
        query={SERVICE_ROUTES_DATATABLE}
        variables={getVariables(type, propertyId)}
        onDelete={onDelete}
        options={{
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 30],
          download: false,
          print: false,
          filter: false,
          selectToolbarPlacement: canDelete? 'above' : 'none',
        }}
      />
    </>
  );
};

export default ServiceRoutesTable;
