import { gql, ApolloError, useMutation } from "@apollo/client";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Customer, Property } from "../customers/interfaces";
import { CustomerAgreement } from "../agreements/interface";
import {
  RegularServiceRoute,
  ServiceRoute,
  ServiceReport,
  ServiceRouteTableView
} from "./interfaces";
import { PaginationArgs } from "../shared/interfaces";
import { useRef, useEffect } from "react";

const GET_SERVICE_ROUTE_CUSTOMER_DATA = gql`
  query getServiceRouteCustomerData($id: String!) {
    customer(id: $id) {
      id
      firstName
      lastName

      property {
        id
        name
        address {
          formatted
          longitude
          latitude
        }
        serviceRoutes {
          id
          routeColor
          routeCode
          areas {
            points {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

export const GET_ROUTES_FOR_SELECT = gql`
  query getSubFranchisesRoutes($subFranchiseId: String) {
    getSubFranchisesRoutes(subFranchiseId: $subFranchiseId) {
      routes {
        id
        property {
          name
          address {
            formatted
          }
        }
        startTime
      }
    }
  }
`;

export const useGetRoutesForSelect = (
  onCompleted?: (routes: ServiceRoute[]) => void,
  subFranchiseId?: string
) => {
  const { data, error } = useQuery<
    { getSubFranchisesRoutes: { routes: ServiceRoute[] } },
    { subFranchiseId?: string }
  >(GET_ROUTES_FOR_SELECT, {
    variables: {
      subFranchiseId
    },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(data.getSubFranchisesRoutes.routes);
      }
    }
  });
  return {
    data: data?.getSubFranchisesRoutes.routes,
    loadingRouteError: error
  };
};

const GET_SERVICE_ROUTE_PROPERTY_DATA = gql`
  query getServiceRoutePropertyData($id: String!) {
    property(id: $id) {
      id
      name
      address {
        formatted
        longitude
        latitude
      }
      serviceRoutes {
        id
        routeColor
        routeCode
        areas {
          points {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const useGetServiceRoutePropertyDataLazy = () => {
  const [_getProperty, { data, error, loading }] = useLazyQuery<
    { property: Property },
    { id: string | null }
  >(GET_SERVICE_ROUTE_PROPERTY_DATA);
  const getProperty = (id: string) => {
    _getProperty({ variables: { id } });
  };
  return {
    getProperty,
    property: data?.property,
    loadingPropertyError: error,
    loadingProperty: loading
  };
};

export const useGetServiceRouteCustomerDataLazy = (
  customerId: string | null
) => {
  const [getCustomer, { data, error, loading }] = useLazyQuery<
    { customer: Customer },
    { id: string | null }
  >(GET_SERVICE_ROUTE_CUSTOMER_DATA, { variables: { id: customerId } });

  return {
    getCustomer,
    customer: data?.customer,
    loadingCustomerError: error,
    loadingCustomer: loading
  };
};
const GET_SERVICE_ROUTE_AGREEMENT_DATA = gql`
  query getServiceRouteAgreementData($id: String!) {
    property(id: $id) {
      agreement {
        offPropertyTime
        serviceStartTime
        workingDays
      }
    }
  }
`;

export const useGetServiceRouteAgreementDataLazy = (
  onSuccess?: (agreement: CustomerAgreement) => void,
  onError?: (error: ApolloError) => void
) => {
  const [_getAgreement, { data, error, loading }] = useLazyQuery<
    { property: Property },
    { id: string | null }
  >(GET_SERVICE_ROUTE_AGREEMENT_DATA, {
    onCompleted: ({ property }) => {
      if (property.agreement)
        if (onSuccess) onSuccess(property.agreement);
        else if (onError)
          onError(
            new ApolloError({
              errorMessage: "This customer does not have a contract"
            })
          );
    },
    onError: onError
  });
  const getAgreement = (id: string) => {
    _getAgreement({ variables: { id } });
  };

  return {
    getAgreement,
    agreement: data?.property.agreement,
    loadingAgreementError: error,
    loadingAgreement: loading
  };
};

export const SERVICE_REPORTS_DATATABLE = gql`
  query getServiceReportsDatatable(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $routeId: String
    $startDate: DateTime
    $endDate: DateTime
    $subTenantId: String
    $propertyId: String
    $showSharedRoutes: Boolean
  ) {
    serviceReportsTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      routeId: $routeId
      propertyId: $propertyId
      startDate: $startDate
      endDate: $endDate
      subTenantId: $subTenantId
      showSharedRoutes: $showSharedRoutes
    ) {
      totalsCount
      currentCount
      nodes {
        endTime
        startTime
        routeCode
        routeId
        propertyName
        subTenant
        subTenantId
        tenant
        tenantId
        isCompleted
        userName
        userId
        valetId
        id
      }
    }
  }
`;
export const SERVICE_ROUTES_DATATABLE = gql`
  query getServiceRoutesDatatable(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $type: String
    $propertyId: String
    $subTenantId: String
  ) {
    serviceRoutesTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      type: $type
      propertyId: $propertyId
      subTenantId: $subTenantId
    ) {
      totalsCount
      currentCount
      nodes {
        propertyId
        routeCode
        createdAt
        propertyName
        type
        valetName
        valetId
        subTenant
        tenant
        id
      }
    }
  }
`;

export const SHARED_SERVICE_ROUTES_DATATABLE = gql`
  query getSharedServiceRoutesDatatable(
    $offset: Int!
    $limit: Int!
    $sharedSubTenantId: String
    $sortProperty: String
    $sortDirection: String
    $search: String
    $type: String
    $propertyId: String
  ) {
    sharedServiceRoutesTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      type: $type
      propertyId: $propertyId
      sharedSubTenantId: $sharedSubTenantId
    ) {
      totalsCount
      currentCount
      nodes {
        routeCode
        createdAt
        customerId
        customerName
        propertyName
        type
        valetName
        valetId
        subTenant
        id
      }
    }
  }
`;

const GET_ROUTES_SELECT_PAGINAED = gql`
  query getServiceRoutesSelectPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $type: String
    $propertyId: String
    $showAvailableRoutesOnly: Boolean
    $subTenantId: String
  ) {
    serviceRoutesTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      type: $type
      propertyId: $propertyId
      showAvailableRoutesOnly: $showAvailableRoutesOnly
      subTenantId: $subTenantId
    ) {
      totalsCount
      currentCount
      nodes {
        routeCode
        propertyName
        id
      }
    }
  }
`;

export const useGetRoutesSelect = (
  {
    limit,
    offset,
    search,
    sortDirection,
    sortProperty,
    propertyId
  }: PaginationArgs & { propertyId?: string },
  subTenantId?: string,
  showAvailableRoutesOnly = false
) => {
  const [getRoutes, { data, loading, error, fetchMore }] = useLazyQuery<
    {
      serviceRoutesTable: {
        totalsCount: number;
        nodes: ServiceRouteTableView[];
      };
    },
    {
      offset: number;
      limit: number;
      sortProperty: string;
      sortDirection: string;
      search: string;
      subTenantId?: string;
      propertyId?: string;
      showAvailableRoutesOnly?: boolean;
    }
  >(GET_ROUTES_SELECT_PAGINAED, {
    fetchPolicy: "cache-and-network"
  });
  const initial = useRef(false);
  useEffect(() => {
    getRoutes({
      variables: {
        limit,
        offset,
        search,
        sortDirection,
        sortProperty,
        subTenantId,
        showAvailableRoutesOnly,
        propertyId
      }
    });
  }, [search, offset, subTenantId, showAvailableRoutesOnly, propertyId]);

  useEffect(() => {
    initial.current = true;
  }, []);

  return {
    routes: data?.serviceRoutesTable.nodes,
    total: data?.serviceRoutesTable.totalsCount || 0
  };
};

// const Fragments = {
//   RegularServiceRoute: gql`
//     fragment RegularServiceRoute on RegularServiceRouteDTO {
//       monthlyRoutePay
//       workingDays
//     }
//   `,
//   SpecialServiceRoute: gql`
//     fragment SpecialServiceRoute on SpecialServiceRouteDTO {
//       scheduledDates {
//         date
//       }
//       completedDate

//       isRecurrent
//       recurrentDay
//       makeUpReason
//       isMakeUp
//       isCompleted
//     }
//   `
// };

const GET_SERVICE_ROUTE_FOR_EDIT = gql`
  query getServiceRouteForEdit($id: String!) {
    serviceRoute(id: $id) {
      propertyId
      id
      routeCode
      routeColor
      property {
        ownerId
      }
      areas {
        points {
          latitude
          longitude
        }
        name
      }
      monthlyRoutePay
      dailyRoutePay
      startTime
      offPropertyTime
      extraData {
        ... on RegularServiceRouteDTO {
          workingDays
        }
        ... on SpecialServiceRouteDTO {
          completedDate
          scheduledDates {
            date
          }
          isRecurrent
          recurrentDays
          makeUpReason
          isMakeUp
          isCompleted
        }
      }
    }
  }
`;

export const useGetServiceRouteForEdit = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    {
      serviceRoute: ServiceRoute;
    },
    { id: string }
  >(GET_SERVICE_ROUTE_FOR_EDIT, { fetchPolicy: "cache-and-network" });

  const getRoute = (id: string) => {
    get({ variables: { id } });
  };

  return {
    getRoute,
    route: data?.serviceRoute,
    routeLoading: loading,
    routeLoadingError: error
  };
};

const GET_SERVICE_ROUTE_VIEW = gql`
  query getServiceRouteView($id: String!) {
    serviceRoute(id: $id) {
      id
      type
      routeCode
      routeColor
      startTime
      offPropertyTime
      isShared
      monthlyRoutePay
      dailyRoutePay
      subTenant {
        id
        name
      }
      extraData {
        ... on RegularServiceRouteDTO {
          workingDays
        }
        ... on SpecialServiceRouteDTO {
          completedDate
          scheduledDates {
            date
          }
          isRecurrent
          recurrentDays

          makeUpReason
          isMakeUp
          isCompleted
        }
      }
      updatedAt
      createdAt
      todaysReport {
        id
        isCompleted
      }
      lastReport {
        createdAt
      }
      supervisor {
        id
        firstName
        lastName
        username
      }
      areas {
        id
        points {
          id
          latitude
          longitude
        }
        name
      }
      defaultValet {
        name
        id
      }
      neighborRoutes {
        id
        routeCode
        routeColor
        areas {
          points {
            latitude
            longitude
          }
        }
      }
      property {
        id
        ownerId
        name
        address {
          formatted
          latitude
          longitude
        }
      }
    }
  }
`;

export const useGetServiceRouteView = (id: string) => {
  const { data, error, loading } = useQuery<
    { serviceRoute: ServiceRoute },
    { id: string }
  >(GET_SERVICE_ROUTE_VIEW, {
    variables: {
      id
    },
    fetchPolicy: "cache-and-network"
  });
  return {
    route: data?.serviceRoute,
    loadingRoute: loading,
    loadingRouteError: error
  };
};

const IS_ROUTE_CODE_AVAILABLE = gql`
  mutation isRouteCodeAvailable(
    $routeCode: String!
    $propertyId: String!
    $routeId: String
  ) {
    isRouteCodeAvailable(
      routeCode: $routeCode
      propertyId: $propertyId
      routeId: $routeId
    )
  }
`;
export const useIsRouteCodeAvailable = () => {
  const [isRouteCodeAvailableCall, { data }] = useMutation<
    { isRouteCodeAvailable: boolean },
    {
      routeCode: string;
      propertyId: string;
      routeId?: string;
    }
  >(IS_ROUTE_CODE_AVAILABLE);
  const isRouteCodeAvailable = async (
    routeCode: string,
    propertyId: string,
    routeId?: string
  ) => {
    const result = await isRouteCodeAvailableCall({
      variables: {
        routeCode,
        propertyId,
        routeId
      }
    });

    return !!result.data?.isRouteCodeAvailable;
  };
  return isRouteCodeAvailable;
};

const SERVICE_REPORT_VIEW = gql`
  query getServiceReportView($id: String!) {
    serviceReport(id: $id) {
      id
      isCompleted
      serviceRouteCode
      serviceRouteId
      startTime
      endTime
      serviceRoute {
        id
        latitude
        longitude
        routeColor
        routeCode
        areas {
          id
          name
          points {
            latitude
            longitude
          }
        }
      }
      valetId
      userId
      user {
        id
        name
      }
      userTracks {
        latitude
        longitude
        timestamp
      }
    }
  }
`;

export const useServiceReportView = (id: string) => {
  const { data, error } = useQuery<
    { serviceReport: ServiceReport },
    { id: string }
  >(SERVICE_REPORT_VIEW, { variables: { id } });

  return {
    report: data?.serviceReport,
    reportError: error
  };
};

const GET_ROUTE_SELECT_ITEM = gql`
  query getRouteSelectItem($id: String!) {
    serviceRoute(id: $id) {
      id
      routeCode
      property {
        name
      }
    }
  }
`;

export const useGetRouteSelectItemLazy = () => {
  const [get, { data, error, loading }] = useLazyQuery<
    { serviceRoute: ServiceRoute },
    { id: string }
  >(GET_ROUTE_SELECT_ITEM);

  const getRoute = (id: string) => {
    get({ variables: { id } });
  };

  return {
    getRoute,
    route: data
      ? {
          id: data.serviceRoute.id,
          routeCode: data.serviceRoute.routeCode,
          propertyName: data.serviceRoute.property.name
        }
      : undefined
  };
};

const GET_SERVICE_ROUTE_FOR_VALET_AGREEMENT = gql`
  query getServiceRouteForValetAgreement($routeId: String!) {
    serviceRoute(id: $routeId) {
      id
      routeCode
      type
      monthlyRoutePay
      dailyRoutePay
      extraData {
        ... on SpecialServiceRouteDTO {
          id
          isRecurrent
          recurrentDays
          scheduledDates {
            date
          }
        }
        ... on RegularServiceRouteDTO {
          id
          workingDays
        }
      }
    }
  }
`;

export const useGetServiceRouteForValetAgreement = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    { serviceRoute: ServiceRoute },
    { routeId: string }
  >(GET_SERVICE_ROUTE_FOR_VALET_AGREEMENT);

  const getRoute = (routeId: string) => {
    get({ variables: { routeId } });
  };

  return {
    getRoute,
    route: data?.serviceRoute,
    getRouteLoading: loading,
    getRouteError: error
  };
};
