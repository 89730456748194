import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  makeStyles,
  createStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import IncidentCategoryForm, {
  getIncidentCategoryFormInitialValues,
  getIncidentCategoryFormValidationSchema,
  IncidentCategoryFormValues,
} from "./IncidentCategoryForm";
import classes from "*.module.css";
import { useCreateUpdateIncidentCategory } from "../../../apollo/incidents/mutations";
import {
  IncidentCategory,
  IncidentSubCategory,
} from "../../../apollo/incidents/interfaces";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import { ApolloError } from "@apollo/client";
interface IncidentCategoryFormModalProps {
  open: boolean;
  category?: IncidentCategory;
  onClose?: () => void;
  onSubmitSuccess: (category: IncidentCategory) => void;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const useIncidentCategoryFormModal = (
  category?: IncidentCategory,
  onSubmitSuccessProp?: (category: IncidentCategory) => void
) => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const isEdit = !!category;
  const onSubmitSuccess = (category: IncidentCategory) => {
    sendSuccessMessage(`Category ${isEdit? 'updated' : 'created'} successfully.`);
    if (onSubmitSuccessProp) onSubmitSuccessProp(category);
  };
  const onSubmitError = (error: ApolloError) => {
    sendErrorMessage("There was an error trying to process your submission.");
    console.warn(error);
  };
  const { loading, submit } = useCreateUpdateIncidentCategory(
    !!category,
    onSubmitSuccess,
    onSubmitError
  );
  const onFormSubmit = (values: IncidentCategoryFormValues) => {
    submit({ variables: { category: values } });
  };

  return {
    model: {
      isSubmitting: loading,
    },
    commands: {
      onFormSubmit,
    },
  };
};

const IncidentCategoryFormModal: React.FC<IncidentCategoryFormModalProps> = ({
  open,
  category,
  onClose,
  onSubmitSuccess
}) => {
  const {
    commands: { onFormSubmit },
    model: { isSubmitting },
  } = useIncidentCategoryFormModal(category, onSubmitSuccess);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      keepMounted={false}
      maxWidth="lg"
      onClose={isSubmitting ? undefined : onClose}
    >
      <Formik
        onSubmit={onFormSubmit}
        initialValues={getIncidentCategoryFormInitialValues()}
        validationSchema={getIncidentCategoryFormValidationSchema()}
      >
        {({ submitForm }) => (
          <>
            <DialogContent>
              <Typography variant="h5" gutterBottom>
                {!!category ? "Editing Category" : "Creating Category"}
              </Typography>
              <IncidentCategoryForm category={category} />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {!!category ? "Update" : "Create"}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default IncidentCategoryFormModal;
