import { gql } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";

const REGISTER_NOTIFICATION_TOKEN = gql`
  mutation registerNotificationToken($token: String!) {
    registerNotificationToken(token: $token, type: "web")
  }
`;

export const useRegisterNotificationToken = () => {
  const [registerToken, { data, error, loading }] = useMutation<
    { registerNotificationToken: boolean },
    { token: string }
  >(REGISTER_NOTIFICATION_TOKEN);

  return {
    registerToken,
    registerTokenError: error
  };
};

const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword($userId: String!, $password: String!) {
    changeUserPassword(password: $password, id: $userId)
  }
`;

export const useChangeUserPassword = () => {
  const [change, { data, error, loading }] = useMutation<
    { changeUserPassword: boolean },
    { userId: string; password: string }
  >(CHANGE_USER_PASSWORD);

  const changePassword = (userId: string, password: string) => {
    change({
      variables: {
        password,
        userId
      }
    });
  };
  return {
    changePassword,
    changePasswordSuccess: data?.changeUserPassword,
    changePasswordError: error,
    changePasswordLoading: loading
  };
};
