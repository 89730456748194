import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import UsersListPage from "../pages/Users/UsersListPage";
import UsersFormPage from "../pages/Users/UsersFormPage";
import TenantsList from "../pages/Tenants/TenantsListPage";
import TenantFormPage from "../pages/Tenants/TenantFormPage";
import SubTenantFormPage from "../pages/Tenants/SubTenantFormPage";
import TenantPage from "../pages/Tenants/TenantPage";
import SubTenantPage from "../pages/Tenants/SubTenantPage";
import RolesListPage from "../pages/Roles/RolesListPage";
import UserPage from "../pages/Users/UserPage";
import RoleRoute from "./RoleRoute";
import PropertyManagersPage from "../pages/PropertyManagers/PropertyManagersPage";
import CustomersListPage from "../pages/Customers/CustomersListPage";
import CustomerFormPage from "../pages/Customers/CustomerFormPage";
import CustomerPage from "../pages/Customers/CustomerPage";
import ValetListPage from "../pages/Valets/ValetListPage";
import ValetPage from "../pages/Valets/ValetPage";
import ValetsFormPage from "../pages/Valets/ValetFormPage";
import CustomerAgreementFormPage from "../pages/Agreements/CustomerAgreementFormPage";
import DashboardHomePage from "../pages/Home/DashboardHomePage";
import ServiceRoutesListPage from "../pages/ServiceRoutes/ServiceRoutesListPage";
import RegularRouteFormPage from "../pages/ServiceRoutes/RegularRouteFormPage";
import ServiceRoutePage from "../pages/ServiceRoutes/ServiceRoutePage";
import IncidentCategoriesList from "../pages/Incidents/IncidentCategoriesList";
import IncidentsListPage from "../pages/Incidents/IncidentsListPage";
import IncidentPage from "../pages/Incidents/IncidentPage";
import ServiceReportPage from "../pages/ServiceRoutes/ServiceReportPage";
import SupplyRequestItemsPage from "../pages/SupplyRequests/SupplyRequestItemsPage";
import SupplyRequestFormPage from "../pages/SupplyRequests/SupplyRequestFormPage";
import SupplyRequestsPage from "../pages/SupplyRequests/SupplyRequestsPage";
import SupplyRequestPage from "../pages/SupplyRequests/SupplyRequestPage";
import ValetAgreementFormPage from "../pages/ValetAgreements/ValetAgreementFormPage";
import ServiceRouteFormPage from "../pages/ServiceRoutes/ServiceRouteFormPage";
import TimeOffRequestsPage from "../pages/TimeOffRequests/TimeOffRequestsPage";
import TimeOffRequestFormPage from "../pages/TimeOffRequests/TimeOffRequestFormPage";
import TimeOffRequestPage from "../pages/TimeOffRequests/TimeOffRequestPage";
import useIsAdmin from "../shared/hooks/useIsAdmin";
import ValetPreCreationFormPage from "../pages/PreCreations/ValetPreCreationFormPage";
import PropertyFormPage from "../pages/Customers/PropertyFormPage";
import PropertiesListPage from "../pages/Customers/PropertiesListPage";
import PropertyPage from "../pages/Customers/PropertyPage";

const PanelRoutes: React.FC = () => {
  let { path } = useRouteMatch();
  const isAdmin = useIsAdmin();

  return (
    <Switch>
      <Route path={path} exact>
        <DashboardHomePage />
      </Route>

      <RoleRoute path={path + "/users"} can={["read", "Users"]} exact>
        <UsersListPage />
      </RoleRoute>
      <RoleRoute path={path + "/users/create"} can={["create", "Users"]} exact>
        <UsersFormPage />
      </RoleRoute>
      <Route path={path + "/users/:userId"} exact>
        <UserPage />
      </Route>
      <RoleRoute
        path={path + "/users/:userId/edit"}
        can={["read", "Users"]}
        exact
      >
        <UsersFormPage />
      </RoleRoute>

      <RoleRoute path={path + "/roles/create"} can={["create", "Roles"]} exact>
        <RolesListPage />
      </RoleRoute>
      {/* <RoleRoute path={path + "/roles/:roleId"} can={["read", "Roles"]} exact>
        <RolePage />
      </RoleRoute> */}
      <RoleRoute path={path + "/roles"} can={["read", "Roles"]} exact>
        <RolesListPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/franchises/create"}
        can={["manage", "all"]}
        exact
      >
        <TenantFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/franchises/:tenantId"}
        can={["manage", "all"]}
        exact
      >
        <TenantPage />
      </RoleRoute>

      <RoleRoute path={path + "/franchises"} can={["manage", "all"]} exact>
        <TenantsList />
      </RoleRoute>

      <RoleRoute path={path + "/franchise"} can={["read", "SubTenants"]} exact>
        {isAdmin ? <TenantPage /> : <SubTenantPage />}
      </RoleRoute>
      <RoleRoute
        path={path + "/subFranchises/create"}
        can={["create", "SubTenants"]}
        exact
      >
        <SubTenantFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/subFranchises/:subTenantId"}
        can={["read", "SubTenants"]}
        exact
      >
        <SubTenantPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/subFranchises/:subTenantId/edit"}
        can={["update", "SubTenants"]}
        exact
      >
        <SubTenantFormPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/subFranchises"}
        can={["read", "SubTenants"]}
        exact
      >
        <TenantPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/propertyManagers"}
        can={["read", "PropertyManagers"]}
        exact
      >
        <PropertyManagersPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/customers/create"}
        can={["create", "Customers"]}
        exact
      >
        <CustomerFormPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/customers/:customerId"}
        can={["read", "Customers"]}
        exact
      >
        <CustomerPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/customers/:customerId/edit"}
        can={["read", "Customers"]}
        exact
      >
        <CustomerFormPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/properties/create"}
        can={["read", "Customers"]}
        exact
      >
        <PropertyFormPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/properties/:propertyId/edit"}
        can={["read", "Customers"]}
        exact
      >
        <PropertyFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/properties/:propertyId/agreement"}
        can={["read", "Customers"]}
        exact
      >
        <CustomerAgreementFormPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/properties/:propertyId"}
        can={["read", "Customers"]}
        exact
      >
        <PropertyPage />
      </RoleRoute>
    
      <RoleRoute
        path={path + "/properties"}
        can={["read", "Customers"]}
        exact
      >
        <PropertiesListPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/customers/:customerId/agreement"}
        can={["read", "Customers"]}
        exact
      >
        <CustomerAgreementFormPage />
      </RoleRoute>
      <RoleRoute path={path + "/customers"} can={["read", "Customers"]} exact>
        <CustomersListPage />
      </RoleRoute>
      <RoleRoute path={path + "/valets/:valetId/agreements"} exact>
        <ValetAgreementFormPage />
      </RoleRoute>
      <RoleRoute path={path + "/valets/:valetId/agreements/:agreementId"} exact>
        <ValetAgreementFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/valets/create"}
        can={["create", "Valets"]}
        exact
      >
        <ValetsFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/valets/pre-registration/form"}
        can={["create", "Valets"]}
        exact
      >
        <ValetPreCreationFormPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/valets/pre-registration/form/:id"}
        can={["create", "Valets"]}
        exact
      >
        <ValetPreCreationFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/valets/:valetId"}
        can={["read", "Valets"]}
        exact
      >
        <ValetPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/valets/:valetId/edit"}
        can={["update", "Valets"]}
        exact
      >
        <ValetsFormPage />
      </RoleRoute>
      <RoleRoute path={path + "/valets"} can={["read", "Valets"]} exact>
        <ValetListPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/serviceRoutes/create"}
        can={["create", "ServiceRoutes"]}
        exact
      >
        <ServiceRouteFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/serviceRoutes/:routeId/reports/:reportId"}
        can={["read", "ServiceRoutes"]}
        exact
      >
        <ServiceReportPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/serviceRoutes/:routeId/edit"}
        can={["update", "ServiceRoutes"]}
        exact
      >
        <ServiceRouteFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/serviceRoutes/:routeId"}
        can={["read", "ServiceRoutes"]}
        exact
      >
        <ServiceRoutePage />
      </RoleRoute>
      <RoleRoute
        path={path + "/serviceRoutes/"}
        can={["read", "ServiceRoutes"]}
        exact
      >
        <ServiceRoutesListPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/incidents/categories"}
        can={["read", "Incidents"]}
        exact
      >
        <IncidentCategoriesList />
      </RoleRoute>
      <RoleRoute
        path={path + "/incidents/:incidentId"}
        can={["read", "Incidents"]}
        exact
      >
        <IncidentPage />
      </RoleRoute>
      <RoleRoute path={path + "/incidents/"} can={["read", "Incidents"]} exact>
        <IncidentsListPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/supplyRequests/items"}
        can={["read", "SupplyRequests"]}
        exact
      >
        <SupplyRequestItemsPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/supplyRequests/form"}
        can={["read", "SupplyRequests"]}
        exact
      >
        <SupplyRequestFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/supplyRequests/:requestId"}
        can={["read", "SupplyRequests"]}
        exact
      >
        <SupplyRequestPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/supplyRequests/"}
        can={["read", "SupplyRequests"]}
        exact
      >
        <SupplyRequestsPage />
      </RoleRoute>

      <RoleRoute
        path={path + "/timeOffRequests/form"}
        can={["create", "TimeOffRequests"]}
        exact
      >
        <TimeOffRequestFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/timeOffRequests/form/:requestId"}
        can={["update", "TimeOffRequests"]}
        exact
      >
        <TimeOffRequestFormPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/timeOffRequests/:requestId"}
        can={["update", "TimeOffRequests"]}
        exact
      >
        <TimeOffRequestPage />
      </RoleRoute>
      <RoleRoute
        path={path + "/timeOffRequests/"}
        can={["read", "TimeOffRequests"]}
        exact
      >
        <TimeOffRequestsPage />
      </RoleRoute>
    </Switch>
  );
};

export default PanelRoutes;
