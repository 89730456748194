import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as LoginSVG } from "./login.svg";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useHistory, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { SIGN_IN } from "../../apollo/auth/queries";
import { SignInFormData } from "./interfaces";
import { SignInInput, SignInResult } from "../../apollo/auth/interfaces";
import LoginIllustration from "./LoginIlustration";
import useAuthToken from "../../shared/hooks/useAuthToken";
import useAuthStore from "../../shared/hooks/useAuthStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      backgroundColor: theme.palette.secondary.main
    },
    buttonContainer: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center"
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-end"
      }
    },
    textfield: {
      marginBottom: "2em"
    }
  })
);

const validateForm = (values: SignInFormData) => {
  const errors: { [key: string]: string } = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};

const Home: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    updateAuthToken,
    authToken,
    setAuthError
  } = useAuthStore(({ updateAuthToken, authToken, setAuthError }) => ({
    updateAuthToken,
    authToken,
    setAuthError
  }));
  const theme = useTheme();

  const [signIn] = useLazyQuery<{ signIn: SignInResult }, SignInInput>(
    SIGN_IN,
    {
      onCompleted: data => {
        updateAuthToken(data.signIn.token);

        enqueueSnackbar("Signed In successfully", {
          anchorOrigin: { horizontal: "center", vertical: "top" },
          variant: "success"
        });
        if (data.signIn.user.isCustomer) {
          history.push('/myProperty');
          return;
        }
        history.push("/dashboard");
      },
      onError: error => {
        console.warn(error.message);
        enqueueSnackbar(
          "Something went wrong. Please verify your credentials are correct.",
          {
            anchorOrigin: { horizontal: "center", vertical: "top" },
            variant: "error"
          }
        );
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validate: validateForm,
    onSubmit: values => {
      setAuthError(null);
      signIn({ variables: values });
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  if (authToken) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Grid container alignItems="stretch">
      <Hidden xsDown>
        <Grid item className={classes.backdrop} sm={4}>
          <Box alignItems="center" height="100%" display="flex">
            <Container style={{ width: "70%" }}>
              <LoginIllustration
                acentColor={theme.palette.primary.dark}
                style={{ height: "100%", width: "100%" }}
              />
            </Container>
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={8}>
        <Box display="flex" height="100%" alignItems="center" paddingY="10%">
          <Container>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Sign In</Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "5rem" }}
                color="textSecondary"
              >
                Enter your username and password
              </Typography>
              <Box width="60%">
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    label="Username"
                    id="username"
                    variant="filled"
                    defaultValue={formik.values.username}
                    size="medium"
                    fullWidth
                    className={classes.textfield}
                    onChange={formik.handleChange}
                    error={!!formik.errors.username}
                    helperText={formik.errors.username}
                  />

                  <TextField
                    type="password"
                    label="Password"
                    id="password"
                    variant="filled"
                    defaultValue={formik.values.password}
                    size="medium"
                    fullWidth
                    className={classes.textfield}
                    onChange={formik.handleChange}
                    error={!!formik.errors.password}
                    helperText={formik.errors.password}
                  />
                  <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" type="submit">
                      Sign In
                    </Button>
                  </div>
                </form>
              </Box>
            </Box>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
