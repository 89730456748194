import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { ApolloQueryResult, DocumentNode } from "@apollo/client";
import { GET_SUBTENANTS_FOR_SELECT } from "../../../apollo/tenants/queries";
import { SubTenant } from "../../../apollo/tenants/interfaces";
import useAbility from "../../../shared/hooks/useAbility";
import { FieldAttributes, FormikState } from "formik";

interface SubTenantSearchBoxProps {
  onChange?: (event: React.ChangeEvent<any>) => void;
  onSubTenantsLoad?: (subTenants: SubTenant[]) => void;
  onOptionChange?: (subTenant: SubTenant | null) => void;
  error?: string | null;
  name?: string;
  label?: string;
  value?: SubTenant | null | string;
  fetchMoreRef?: (ref: LoadSubTenantFunction) => void;
  customQuery?: DocumentNode;
  variables?: { [k: string]: any };
  customQueryResultName?: string;
  refresher?: boolean;
  field?: FieldAttributes<SubTenant | null | string>;
  form?: FormikState<any>;
}

export type LoadSubTenantFunction = (
  reset?: boolean
) => Promise<ApolloQueryResult<{ subTenants: SubTenant[] }>>;

const useSubTenantSearchBox = (
  onChange?: (event: React.ChangeEvent<any>) => void,
  name?: string,
  onOptionChange?: (subTenant: SubTenant | null) => void,
  onSubTenantsLoad?: (subTenants: SubTenant[]) => void,
  fetchMoreRef?: (ref: LoadSubTenantFunction) => void,
  refresher?: boolean,
  variables?: { [k: string]: any },
  customQuery?: DocumentNode,
  queryResultName: string = "subTenants"
) => {
  const isFirstRun = useRef(true);
  const { data, fetchMore } = useQuery(
    customQuery ? customQuery : GET_SUBTENANTS_FOR_SELECT,
    {
      variables: variables,
      onCompleted: data => {
        if (onSubTenantsLoad) {
          onSubTenantsLoad(data[queryResultName]);
        }
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const onSearchChange = (
    e: React.ChangeEvent<{}>,
    subTenant: SubTenant | null
  ) => {
    if (onChange && name) {
      const synteticEvent = {
        ...e,
        target: {
          value: subTenant?.id,
          name
        }
      };
      onChange(synteticEvent);
    }

    if (onOptionChange) {
      onOptionChange(subTenant);
    }
  };

  const reloadRoles = useCallback(
    async (reset?: boolean) => {
      const result = await fetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return fetchMoreResult;
        }
      });
      if (result && result.data) {
        const subTenants = result.data[queryResultName];
        if (reset && subTenants.length && onOptionChange) {
          onOptionChange(subTenants[0]);
        }
      }
      return result;
    },
    [fetchMore, onOptionChange]
  );
  useEffect(() => {
    if (fetchMoreRef) fetchMoreRef(reloadRoles);
  }, [fetchMoreRef, reloadRoles]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    reloadRoles(true);
  }, [refresher]);
  return {
    model: {
      subTenants: data ? (data[queryResultName] as SubTenant[]) : undefined
    },
    commands: {
      onSearchChange
    }
  };
};
const SubTenantSearchBox: React.FC<SubTenantSearchBoxProps> = ({
  onOptionChange,
  error,
  onChange,
  name,
  label,
  onSubTenantsLoad,
  value,
  fetchMoreRef,
  customQuery,
  variables,
  customQueryResultName,
  refresher,
  field,
  form
}) => {
  const {
    model: { subTenants },
    commands: { onSearchChange }
  } = useSubTenantSearchBox(
    field ? field.onChange : onChange,
    field ? field.name : name,
    onOptionChange,
    onSubTenantsLoad,
    fetchMoreRef,
    refresher,
    variables,
    customQuery,
    customQueryResultName
  );

  error = form && field ? (form.errors[field.name] as string) : error;

  const getValue = () => {
    
    const finalValue = field? field.value : value;
    if (typeof finalValue === "string") {
      const subTenant = subTenants?.find(st => st.id === finalValue);
      return subTenant ? subTenant : null;
    }
    return finalValue;
  };
  return (
    <Autocomplete
      // id="combo-box-demo"
      // disableClearable={true}
      options={subTenants ? subTenants : []}
      getOptionLabel={option => option.name}
      onChange={onSearchChange}
      groupBy={st => (st.tenant ? st.tenant.name : "")}
      value={getValue()}
      fullWidth
      onBlur={field?.onBlur as React.FocusEventHandler<HTMLDivElement>}
      getOptionSelected={(option, value) => {
        if (!value) return false;
        return option.id === value.id;
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label ? label : "SubTenants"}
          variant="outlined"
          error={!!error}
          helperText={error}
          fullWidth
        />
      )}
    />
  );
};

export const RoleAwareSubTenantSearchbox: React.FC<SubTenantSearchBoxProps> = props => {
  const ability = useAbility();
  const isAdmin = useMemo(() => ability.can("manage", "SubTenants"), []);

  if (isAdmin) {
    return <SubTenantSearchBox {...props} />;
  }

  return null;
};

export default SubTenantSearchBox;
