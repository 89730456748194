import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {
  AccordionDetails,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from "@material-ui/core";
import ServiceRouteSearchBox from "../../Tenants/components/ServiceRouteSearchbox";
import RouteSelectModal from "../../ServiceRoutes/components/RoutesSelectModal";
import { Formik, useFormikContext } from "formik";
import { ServiceRouteTableView } from "../../../apollo/serviceRoutes/interfaces";
import ValetSelectModal from "../../Valets/components/NewValetSelectModal";
import { ValetTableView } from "../../../apollo/valets/interfaces";
import { Moment } from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field } from "formik";
import { DateTimePicker } from "formik-material-ui-pickers";
import IncidentCategorySearchbox from "./IncidentCategorySearchbox";
import {
  Filter,
  FilterListRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
  VisibilityOff
} from "@material-ui/icons";
import InputClearButton from "../../../shared/components/InputClearButton";
import { Select, TextField } from "formik-material-ui";
import {
  Accordion,
  AccordionSummary
} from "../../../shared/components/Accordion";
import { truncateSync } from "fs";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import PropertySelectModal from "../../Customers/components/PropertySelectModal";
import { PropertySelectItem } from "../../../apollo/customers/interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterButton: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius
    },
    filterLabel: {
      display: "flex",
      alignItems: "center"
    },
    root: {
      backgroundColor: theme.palette.background.paper
    }
  })
);

const sortproperties = [
  {
    label: "Date",
    value: "createdAt"
  },
  {
    label: "Service Route",
    value: "route"
  },
  {
    label: "Valet",
    value: "valet"
  },
  {
    label: "Category",
    value: "category"
  }
];

const orderProperties = [
  {
    label: "Ascending",
    value: "ASC"
  },

  {
    label: "Descending",
    value: "DESC"
  }
];
export interface IncidentFilterValues {
  route: null | ServiceRouteTableView;
  valet: null | string;
  report: null | string;
  category: null | string;
  property: null | string;
  fromDate: null | Moment;
  toDate: null | Moment;
  sortProperty: string;
  sortDirection: string;
}
interface IncidentListFilterProps {
  renderRouteFilter?: boolean;
  initialValues: IncidentFilterValues;
  onValuesChange: (values: IncidentFilterValues) => void;
}
const IncidentListFilter: React.FC<IncidentListFilterProps> = ({
  initialValues,
  onValuesChange,
  renderRouteFilter = true
}) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);

  const onSubmit = (values: IncidentFilterValues) => {};
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, setFieldValue, handleChange }) => (
          <Grid container style={{ marginBottom: 10 }}>
            <ContextUser onValuesChange={onValuesChange} />
            <Grid item xs={12}>
              <ButtonBase
                className={classes.filterButton}
                onClick={() => setShowFilters(s => !s)}
              >
                <Typography
                  variant="h6"
                  color={"primary"}
                  className={classes.filterLabel}
                >
                  FILTERS <FilterListRounded />
                </Typography>
                {showFilters ? (
                  <KeyboardArrowDown fontSize="large" color="primary" />
                ) : (
                  <KeyboardArrowUp fontSize="large" color="primary" />
                )}
              </ButtonBase>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={showFilters}>
                <Grid container spacing={2}>
                  {renderRouteFilter && (
                    <>
                      <Grid item md={6} xs={12}>
                        <PropertySelectModal
                          label="Property"
                          variant="outlined"
                          name="property"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputClearButton
                                visible={!!values.property}
                                onClick={() => {
                                  setFieldValue("property", null);
                                }}
                              />
                            )
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <RouteSelectModal
                          label="Service Route"
                          variant="outlined"
                          name="route"
                          propertyId={
                            ((values.property as unknown) as PropertySelectItem)
                              ?.id
                          }
                          fullWidth
                          // InputProps={{
                          //   endAdornment: (
                          //     <InputClearButton
                          //       visible={!!values.route}
                          //       onClick={() => {
                          //         setFieldValue("route", null);
                          //       }}
                          //     />
                          //   )
                          // }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item md={renderRouteFilter ? 6 : 5} xs={12}>
                    <ValetSelectModal
                      label="Valet"
                      variant="outlined"
                      name="valet"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputClearButton
                            visible={!!values.valet}
                            onClick={() => {
                              setFieldValue("valet", null);
                            }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item md={renderRouteFilter ? 6 : 5} xs={12}>
                    <IncidentCategorySearchbox
                      name="category"
                      onChange={handleChange}
                      value={values.category}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Field
                      component={DateTimePicker}
                      name="fromDate"
                      fullWidth
                      label="From Date"
                      inputVariant="outlined"
                      variant="inline"
                      maxDate={values.toDate || undefined}
                      InputProps={{
                        endAdornment: (
                          <InputClearButton
                            visible={!!values.fromDate}
                            onClick={() => {
                              setFieldValue("fromDate", null);
                            }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Field
                      component={DateTimePicker}
                      name="toDate"
                      fullWidth
                      label="To Date"
                      inputVariant="outlined"
                      variant="inline"
                      minDate={values.fromDate || undefined}
                      InputProps={{
                        endAdornment: (
                          <InputClearButton
                            visible={!!values.toDate}
                            onClick={() => {
                              setFieldValue("toDate", null);
                            }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {/* This requires a more complex implementation */}
                  {/* <Grid item md={3}>
                    <Field
                      component={TextField}
                      name="sortProperty"
                      variant="outlined"
                      label="Sort By"
                      select
                      fullWidth
                    >
                     
                      {sortproperties.map(property => (
                        <MenuItem key={property.value} value={property.value}>
                          {property.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid> */}
                  <Grid item md={3} xs={10}>
                    <Field
                      component={TextField}
                      name="sortDirection"
                      variant="outlined"
                      label="Sort Direction"
                      select
                      fullWidth
                    >
                      {/* <MenuItem value=''></MenuItem> */}
                      {orderProperties.map(property => (
                        <MenuItem key={property.value} value={property.value}>
                          {property.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

interface ContextUserProps {
  onValuesChange: (values: IncidentFilterValues) => void;
}
const ContextUser: React.FC<ContextUserProps> = ({ onValuesChange }) => {
  const { values, setFieldValue } = useFormikContext<IncidentFilterValues>();

  useDidMountEffect(() => {
    setFieldValue("route", null);
  }, [values.property]);

  useDidMountEffect(() => {
    const properyFromForm = (values.property as unknown) as PropertySelectItem;
    onValuesChange({ ...values, property: properyFromForm?.id });
  }, [values]);
  return null;
};
export default IncidentListFilter;
