import React, { useState } from "react";
import {
  Container,
  makeStyles,
  createStyles,
  Grid,
  Box,
  colors,
  Typography,
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import { Fab } from "@material-ui/core";
import { Add, Warning } from "@material-ui/icons";
import { mdiCubeOutline } from "@mdi/js";
import Icon from "../../shared/components/Icon";
import SupplyItemFormModal from "./components/SupplyItemFormModal";
import { useGetSupplyItemsPaginated } from "../../apollo/supplyRequests/queries";
import SupplyItemCard from "./components/SupplyItemCard";
import PageHeader from "../../shared/components/PageHeader";
import { SupplyItem } from "../../apollo/supplyRequests/interfaces";
import LoadingPage from "../Home/LoadingPage";
import { ApolloError } from "@apollo/client";
import DeleteModal from "../../shared/components/DeleteModal";
import { DELETE_SUPPLY_ITEMS } from "../../apollo/supplyRequests/mutations";
import SupplyItemRequestCard from "./components/SupplyItemRequestCard";
import { SupplyItemsList } from "./components/SupplyItemsList";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    createBtn: {
      position: "fixed",
      bottom: 0,
      right: 0,
      margin: theme.spacing(0, 4, 5, 0),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

const useSupplyRequestItemsPage = () => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editingItem, setEditingItem] = useState<SupplyItem | undefined>();
  const {
    items,
    itemsError,
    refetch,
    itemsLoading,
  } = useGetSupplyItemsPaginated();
  const onCreateClick = () => {
    setEditingItem(undefined);
    setShowFormModal(true);
  };

  const onDeleteSuccess = () => {
    if (refetch) refetch();
  };

  const onDeleteFormModalClose = () => {
    setShowDeleteModal(false);
  };

  const onItemSubmitSuccess = () => {
    setShowFormModal(false);
    if (refetch) refetch();
  };
  const onFormModalClose = () => setShowFormModal(false);
  const onItemEdit = (item: SupplyItem) => {
    setEditingItem(item);
    setShowFormModal(true);
  };
  const onItemDelete = (item: SupplyItem) => {
    setEditingItem(item);
    setShowDeleteModal(true);
  };

  return {
    model: {
      showFormModal,
      items,
      itemsError,
      editingItem,
      itemsLoading,
      showDeleteModal,
    },
    commands: {
      onItemSubmitSuccess,
      onFormModalClose,
      onCreateClick,
      onItemEdit,
      onItemDelete,
      onDeleteSuccess,
      onDeleteFormModalClose,
    },
  };
};

const SupplyRequestItemsPage: React.FC = ({}) => {
  const {
    model: {
      showDeleteModal,
      showFormModal,
      items,
      editingItem,
      itemsLoading,
      itemsError,
    },
    commands: {
      onCreateClick,
      onFormModalClose,
      onItemEdit,
      onItemDelete,
      onItemSubmitSuccess,
      onDeleteSuccess,
      onDeleteFormModalClose,
    },
  } = useSupplyRequestItemsPage();
  const classes = useStyles();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Supply Requests", link: "/dashboard/supplyRequests/" },
          { label: "Supply Requests Items" },
        ]}
      />
      <PageHeader title="Supply Items" />
      <Fab
        variant="extended"
        color="primary"
        className={classes.createBtn}
        onClick={onCreateClick}
      >
        <Add className={classes.extendedIcon} /> Create
      </Fab>

      <SupplyItemFormModal
        open={showFormModal}
        onSuccess={onItemSubmitSuccess}
        onClose={onFormModalClose}
        editingItem={editingItem}
      />

      <DeleteModal
        open={showDeleteModal}
        mutation={DELETE_SUPPLY_ITEMS}
        ids={editingItem ? [editingItem.id] : []}
        description="Are you sure you want to delete this item? This cannot be undone."
        title={"Delete Supply Item"}
        onSuccess={onDeleteSuccess}
        onClose={onDeleteFormModalClose}
      />
      <SupplyItemsList
        items={items}
        error={itemsError}
        onItemDelete={onItemDelete}
        onItemEdit={onItemEdit}
      />
    </Container>
  );
};


export default SupplyRequestItemsPage;
