import React from "react";
import {
  ButtonBase,
  ListItemIcon,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  anchor: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.action.active,
  },
}));
interface MenuItem {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}
interface MenuProps {
  anchor: React.ReactNode;
  items: MenuItem[];
}

const Menu: React.FC<MenuProps> = ({ anchor, items }) => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  };
  const hideMenu = () => {
    setMenuAnchor(null);
  };
  const classes = useStyles();

  return (
    <>
      <ButtonBase className={classes.anchor} onClick={showMenu}>
        {anchor}
      </ButtonBase>
      <MuiMenu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={hideMenu}
      >
        {items.map((item) => (
          <MuiMenuItem
            key={item.label}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              hideMenu();
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            {item.label}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </>
  );
};

export default Menu;
