import { gql, ApolloError } from "@apollo/client";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { PaginatedResult, PaginatedVariables } from "../shared/interfaces";
import { Customer, Property, PropertySelectItem } from "./interfaces";

export const Fragments = {
  customerDisplayInfo: gql`
    fragment CustomerDisplayInfo on CustomerDTO {
      id
      firstName
      lastName
      email
      mainPhone
      emergencyPhone
      gender
    }
  `,
  propertyView: gql`
    fragment PropertyView on PropertyDTO {
      id
      name
      contactName
      createdAt
      mainPhone
      maintenancePhone
      email
      specialNotice
      address {
        id
        formatted
        latitude
        longitude
      }
      propertyManager {
        id
        name
      }
      regionalManager {
        id
        name
      }
      managementCompany {
        id
        name
      }
      pointsOfInterest {
        id
        key
        longitude
        latitude
      }

      agreement {
        id
        status
        type
        currentTerm
        currentTermExpirationDate
        serviceStartDate
        customerSignature {
          id
        }
        supervisorSignature {
          id
        }
        generatedFile {
          url
        }
      }
      paperContract {
        createdAt
        id
        filetype
        url
      }

      isShared
    }
  `
};

export const GET_CUSTOMERS_PAGINATED = gql`
  query getCustomersPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $subTenantId: String
  ) {
    customersTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId
    ) {
      nodes {
        id
        customerName
        email
        mainPhone
        propertyName
        managementCompanyName
        regionalManagerName
        subTenant
        subTenantId
        createdAt
      }
      currentCount
      totalsCount
    }
  }
`;
export const GET_SHARED_CUSTOMERS_PAGINATED = gql`
  query getSharedCustomersPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $subTenantId: String
    $sharedSubTenantId: String
  ) {
    sharedCustomersTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId
      sharedSubTenantId: $sharedSubTenantId
    ) {
      nodes {
        id
        email
        mainPhone
        name
        managementCompany
        regionalManager
        createdAt
        subTenant
        subTenantId
      }
      currentCount
      totalsCount
    }
  }
`;

const GET_FULL_CUSTOMER = gql`
  query getCustomer($id: String!) {
    customer(id: $id) {
      ...CustomerDisplayInfo
      address {
        city
        line1
        line2
        state
        zip
        longitude
        latitude
      }
      property {
        id
        name
        address {
          city
          line1
          line2
          state
          zip
          longitude
          latitude
        }
        regionalManager {
          name
          id
        }
        managementCompany {
          name
          id
        }
      }
    }
  }
  ${Fragments.customerDisplayInfo}
`;

export const useGetFullCustomer = (
  customerId: string,
  onError?: (error: ApolloError) => void
) => {
  const { data, loading, error } = useQuery<
    { customer: Customer },
    { id: string }
  >(GET_FULL_CUSTOMER, {
    onError,
    variables: { id: customerId },
    fetchPolicy: "cache-and-network"
  });

  return {
    customer: data?.customer,
    loading: loading,
    error
  };
};

export const useGetFullCustomerLazy = (
  customerId: string,
  onError?: (error: ApolloError) => void
) => {
  const [getCustomer, { data, loading, error }] = useLazyQuery<
    { customer: Customer },
    { id: string }
  >(GET_FULL_CUSTOMER, {
    onError,
    variables: { id: customerId }
  });

  return [
    getCustomer,
    {
      customer: data?.customer,
      loading: loading,
      error
    }
  ] as const;
};

const GET_CUSTOMER_VIEW = gql`
  query getCustomerView($id: String!) {
    customer(id: $id) {
      ...CustomerDisplayInfo
      subTenant {
        id
        name
      }
      isShared
      address {
        formatted
      }
      property {
        id
        name
        specialNotice
        address {
          formatted
          latitude
          longitude
        }
        regionalManager {
          name
        }
        managementCompany {
          name
        }
        pointsOfInterest {
          key
          longitude
          latitude
        }
      }
      paperContract {
        createdAt
        filetype
        id
        url
      }
      agreement {
        id
        status
        type
        currentTerm
        currentTermExpirationDate
        serviceStartDate
        customerSignature {
          id
        }
        supervisorSignature {
          id
        }
        generatedFile {
          url
        }
      }
    }
  }
  ${Fragments.customerDisplayInfo}
`;

export const useGetCustomerView = (
  customerId: string,
  onError?: (error: ApolloError) => void
) => {
  const { data, loading, error, refetch } = useQuery<
    { customer: Customer },
    { id: string }
  >(GET_CUSTOMER_VIEW, {
    onError,
    variables: { id: customerId },
    fetchPolicy: "cache-and-network"
  });

  return {
    refetch,
    customer: data?.customer,
    loading: loading,
    error
  };
};

const GET_PROPERTY_VIEW = gql`
  query propertyView($id: String!) {
    property(id: $id) {
      ...PropertyView
    }
  }
  ${Fragments.propertyView}
`;

export const useGetPropertyView = (propertyId: string) => {
  const { data, loading, error, refetch } = useQuery<
    { property: Property },
    { id: string }
  >(GET_PROPERTY_VIEW, {
    variables: { id: propertyId },
    fetchPolicy: "cache-and-network"
  });

  return {
    refetch,
    property: data?.property,
    loading: loading,
    error
  };
};

const GET_MY_PROPERTY = gql`
  query getMyProperty {
    myProperty {
      ...PropertyView
    }
  }
  ${Fragments.propertyView}
`;

export const useGetMyProperty = () => {
  const { data, loading, error, refetch } = useQuery<{ myProperty: Property }>(
    GET_MY_PROPERTY,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  return {
    refetch,
    property: data?.myProperty,
    loading: loading,
    error
  };
};

export const GET_SHARED_SUB_TENANTS_TABLE = gql`
  query getSharedSubTenantsTable($propertyId: String!) {
    sharedSubTenantsTable(propertyId: $propertyId) {
      totalsCount
      nodes {
        subTenantName
        subTenantId
        createdAt
      }
    }
  }
`;

export const GET_AVAILABLE_SUB_TENANTS = gql`
  query getAvailableSubTenants($propertyId: String!) {
    availableSubTenants(propertyId: $propertyId) {
      id
      name
      tenant {
        name
      }
    }
  }
`;
export const GET_PROPERTIES_PAGINATED = gql`
  query getPropertiesPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $subTenantId: String
    $tenantId: String
  ) {
    propertiesTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId
      tenantId: $tenantId
    ) {
      nodes {
        id
        name
        email
        mainPhone
        managementCompany
        regionalManager
        subTenant
        tenant
        createdAt
      }
      currentCount
      totalsCount
    }
  }
`;

const GET_PROPERTIES_FOR_SELECT = gql`
  query getPropertiesForSelect(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $subTenantId: String
    $tenantId: String
  ) {
    propertiesTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId
      tenantId: $tenantId
    ) {
      nodes {
        id
        name
        email
        mainPhone
        managementCompany
        regionalManager
        subTenant
        tenant
        createdAt
      }
      currentCount
      totalsCount
    }
  }
`;

export const useGetPropertiesForSelect = (variables?: PaginatedVariables) => {
  const { data, loading, refetch, error } = useQuery<
    { propertiesTable: PaginatedResult<PropertySelectItem> },
    PaginatedVariables
  >(GET_PROPERTIES_FOR_SELECT, { variables });
  return {
    properties: data?.propertiesTable.nodes,
    total: data?.propertiesTable.totalsCount,
    loading,
    refetch,
    error
  };
};

const GET_PROPERTY_FOR_EDIT = gql`
  query getPropertyForEdit($id: String!) {
    property(id: $id) {
      id
      name
      contactName
      email
      mainPhone
      maintenancePhone
      subTenantId
      address {
        id
        city
        line1
        line2
        state
        zip
        longitude
        latitude
      }
      regionalManager {
        name
        id
      }
      managementCompany {
        name
        id
      }
    }
  }
`;
export const useGetPropertyForEdit = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    { property: Property },
    { id: string }
  >(GET_PROPERTY_FOR_EDIT, { fetchPolicy: "network-only" });

  const getProperty = (id: string) => {
    get({ variables: { id } });
  };

  return {
    getProperty,
    property: data?.property,
    loading,
    error
  };
};
