import { Box, Button, Fade } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useCallback } from "react";

const PropertyInicidentAlertBanner: React.FC<{ visible: boolean }> = ({
  visible
}) => {
  const onGoToReportClick = useCallback(() => {
    const report = document.getElementById("property-incident-alert-container");

    if (report) {
      report.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, []);
  return (
    <Fade in={visible} mountOnEnter>
      <Box paddingTop="30px">
        <Alert
          variant="standard"
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={onGoToReportClick}>
              Go to report
            </Button>
          }
        >
          This property has had multiple B.O.B and APT Audit Incidents in the
          last 45 days.
        </Alert>
      </Box>
    </Fade>
  );
};

export default PropertyInicidentAlertBanner;
