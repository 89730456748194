import { gql, NetworkStatus } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { ManagementCompany, RegionalManager } from "./interfaces";

const GET_MANAGEMENT_COMPANIES = gql`
  query managementCompanies($subTenantId: String) {
    managementCompanies(subTenantId: $subTenantId) {
      id
      name
      tenantId
      contacts {
        id
        type
        value
      }
    }
  }
`;
export const GET_MANAGEMENT_COMPANIES_SELECT = gql`
  query managementCompaniesSelect($subTenantId: String) {
    managementCompanies(subTenantId: $subTenantId) {
      id
      name
    }
  }
`;
export const useGetManagementCompanies = (subTenantId?: string | null) => {
  const { loading, data, error, refetch, networkStatus } = useQuery<{
    managementCompanies: ManagementCompany[];
  }>(GET_MANAGEMENT_COMPANIES, { variables: { subTenantId } });

  return {
    loading,
    refetch,
    refetching: networkStatus === NetworkStatus.refetch,
    companies: data?.managementCompanies,
    error
  };
};
const GET_REGIONAL_MANAGERS = gql`
  query regionalManagers($subTenantId: String) {
    regionalManagers(subTenantId: $subTenantId) {
      id
      name
      tenantId
      contacts {
        id
        type
        value
      }
    }
  }
`;

export const GET_REGIONAL_MANAGERS_SELECT = gql`
  query regionalManagersSelect($subTenantId: String) {
    regionalManagers(subTenantId: $subTenantId) {
      id
      name
    }
  }
`;

export const useRegionalManagers = (subTenantId?: string | null) => {
  const { loading, data, error, refetch, networkStatus } = useQuery<{
    regionalManagers: RegionalManager[];
  }>(GET_REGIONAL_MANAGERS, {
    variables: {
      subTenantId
    }
  });

  return {
    loading,
    refetch,
    refetching: networkStatus === NetworkStatus.refetch,
    managers: data?.regionalManagers,
    error
  };
};
