import React from "react";
import { Grid, Typography, makeStyles, Box, TypographyTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '10px'
    }
  }
}));

interface Props {

  title: string | React.ReactNode;
  actions?: React.ReactNode;
  containerStyle?: React.CSSProperties
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" 
}
const PageHeader: React.FC<Props> = ({ title, actions, containerStyle, variant = 'h4' }) => {
  const classes = useStyles();
  const titleIsString = typeof title === 'string';
  return (
    <div className={classes.root} style={containerStyle}>
      <Box flex="1">
        { titleIsString? (<Typography variant={variant}>{title}</Typography>) : title}
        
      </Box>

      <Box display="flex">{actions}</Box>
    </div>
  );
};

export default PageHeader;
