import { Button } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { MUIDataTableColumn } from "mui-datatables";
import React, { useMemo } from "react";
import { VALET_PRE_CREATIONS_TABLE } from "../../../apollo/preCreations/queries";
import Link from "../../../navigation/Link";
import DataTable from "../../../shared/components/Datatable";

const getColumns = (
  onEditClick?: (id: string) => void
): MUIDataTableColumn[] => {
  let columns: MUIDataTableColumn[] = [
    {
      name: "id",

      options: {
        display: 'excluded'
      }
    },
    {
      name: "name",
      label: "Name"
    },
    {
      name: "email",
      label: "Email"
    },
    {
      name: "userCreated",
      label: "User Created?",

      options: {
        sort: false,
        customBodyRender: (value: boolean, tableMeta) => {
          return value ? <Check /> : <Close />;
        }
      }
    },
    {
      name: "agreementSigned",
      label: "Agreement signed?",

      options: {
        sort: false,
        customBodyRender: (value: boolean, tableMeta) => {
          return value ? <Check /> : <Close />;
        }
      }
    }
  ];

  // if (onEditClick) {
  columns.splice(1, 0, {
    name: "edit",
    label: "Edit",

    options: {
      searchable: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        const isDisabled = !!tableMeta.rowData[4] || !!tableMeta.rowData[5];
        if (isDisabled) {
          return (
            <Button
              disabled={true}
              color="primary"
              // onClick={() => onEditClick(tableMeta.rowData[0] as string)}
            >
              Edit
            </Button>
          );
        }
        return (
          <Link
            to={
              "/dashboard/valets/pre-registration/form/" + tableMeta.rowData[0]
            }
          >
            <Button
              color="primary"
              // onClick={() => onEditClick(tableMeta.rowData[0] as string)}
            >
              Edit
            </Button>
          </Link>
        );
      }
    }
  });
  // }
  return columns;
};

interface ValetPreCreationsTableProps {
  refetchDependencies?: any[];
  onEditClick?: (id: string) => void;
}
const ValetPreCreationsTable: React.FC<ValetPreCreationsTableProps> = ({
  refetchDependencies,
  onEditClick
}) => {
  const columns = useMemo(() => getColumns(onEditClick), [onEditClick]);
  return (
    <DataTable
      subTenantFilter={{ show: true }}
      includeTenants={true}
      dateColumns={[{ name: "createdAt", label: "Created at" }]}
      columns={columns}
      query={VALET_PRE_CREATIONS_TABLE}
      refetchDependencies={refetchDependencies}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
        selectToolbarPlacement: "none"
      }}
    />
  );
};

export default ValetPreCreationsTable;
