import React, { useState } from "react";
import { FormikErrors, useFormikContext } from "formik";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import * as Yup from "yup";
import {
  ValetAgreement,
  ValetContractType
} from "../../../apollo/valetAgreements/interface";
import { FormValues } from "./interfaces";
import { UserFormValues } from "../../Users/components/interfaces";
import {
  AddressFormErrors,
  AddressFormValues
} from "../../../shared/components/AddressForm/interfaces";
import { Valet } from "../../../apollo/valets/interfaces";
import { TypeSelectionFormValues } from "./TypeSelectionStep";
import { FormikStep } from "../../../shared/components/FormikStepForm";
import ValetForCoverSearchBox from "../../Tenants/components/ValetForCoverSearchbox";
import ValetsFormPage from "../../Valets/ValetFormPage";
import { Moment } from "moment";

export interface CoverFormValues {
  coverValetId?: string;
  coverValetUser?: UserFormValues;
  coverValetAddress?: AddressFormValues;
}
interface CoverFormErrors extends FormikErrors<CoverFormValues> {}

export const coverFormValidator = (values: CoverFormValues) => {
  const errors: CoverFormErrors = {};
  if (!values.coverValetId && !values.coverValetAddress) {
    errors.coverValetId = "This field is required.";
    errors.coverValetUser = "This form is required.";
  }
  return errors;
};
export const getCoverValidationSchema = () => {
  return Yup.object<CoverFormValues>({
    coverValetId: Yup.string()
      .optional()
      .test("required", "This field is required.", function(value) {
        const { coverValetUser } = this.parent as FormValues;
        return !(!value && !coverValetUser);
      }),
    coverValetAddress: Yup.mixed<AddressFormValues>().optional(),
    coverValetUser: Yup.mixed<UserFormValues>()
      .optional()
      .test("required", "This field is required.", function(value) {
        const { coverValetId } = this.parent as FormValues;
        return !(!value && !coverValetId);
      })
  });
};

export const getCoverInitialValues = (agreement?: ValetAgreement) => {
  return {
    coverValetId: agreement?.metadata?.coverValetId
      ? agreement.metadata.coverValetId
      : ""
  };
};

interface CoverSelectionStepProps {
  currentType: ValetContractType;
  valetId?: string;
  errors?: FormikErrors<TypeSelectionFormValues>;
  onUserCreated?: () => void;
}
const useCoverSelectionStep = (onUserCreated?: () => void) => {
  const { values, setValues, errors } = useFormikContext<FormValues>();

  const handleCoverChange = (valet: Valet | null) => {
    setValues({ ...values, coverValetId: valet?.id });
  };
  const onUserFormReady = (valet: {
    user: UserFormValues;
    address: AddressFormValues;
  }) => {
    if (onUserCreated) {
      onUserCreated();
    }
    setValues({
      ...values,
      coverValetUser: valet.user,
      coverValetAddress: valet.address
    });
  };

  return { values, errors, handleCoverChange, onUserFormReady };
};

const CoverSelectionStep: React.FC<CoverSelectionStepProps> = ({
  valetId,
  currentType,
  onUserCreated
}) => {
  const [createNewValet, setCreateNewValet] = useState<boolean>(false);
  const {
    values,
    errors,
    onUserFormReady,
    handleCoverChange
  } = useCoverSelectionStep(onUserCreated);
  return (
    <Grid container spacing={2}>
      {currentType === "COVER" && (
        <FormikStep
          label="Cover Valet"
          validationSchema={getCoverValidationSchema()}
        >
          <FormControlLabel
            label={
              createNewValet
                ? "Create a temporal valet"
                : "Use an existing valet"
            }
            control={
              <Switch
                checked={createNewValet}
                onChange={event => setCreateNewValet(event.target.checked)}
                name="createNewValet"
                color="primary"
              />
            }
          />
          {!createNewValet && (
            <ValetForCoverSearchBox
              subFranchiseId={values.subTenantId}
              value={values.coverValetId}
              onOptionChange={handleCoverChange}
              valetIdException={valetId}
              error={errors.coverValetId}
              label="Select valet for cover"
            />
          )}

          {currentType === "COVER" && !values?.coverValetId && createNewValet && (
            // <FormikStep
            //     label="Cover Information"
            //     validationSchema={unitStep.validation}>
            //     {unitStep.component}
            // </FormikStep>
            <ValetsFormPage isSubForm onFormReady={onUserFormReady} />
          )}
        </FormikStep>
      )}
    </Grid>
  );
};

export default CoverSelectionStep;
