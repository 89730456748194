import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useEffect } from "react";
import { ValetPreCreation } from "../../../apollo/preCreations/interfaces";
import { Address } from "../../../apollo/shared/interfaces";
import AddressForm, {
  getAddressFormInitialValues,
  getAddressFormValidationSchema
} from "../../../shared/components/AddressForm";
import { AddressFormValues } from "../../../shared/components/AddressForm/interfaces";
import * as Yup from "yup";
import { useSelfCreateValet } from "../../../apollo/preCreations/mutations";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";
import useSnackbar from "../../../shared/hooks/useSnackbar";

const useClasses = makeStyles(theme => ({
  sencondaryHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem"
    }
  }
}));

interface ValetSelfCreationFormValues {
  username: string;
  password: string;
  address: AddressFormValues;
}

const getValetSelfCreationFormInitialValues = (
  preCreation: ValetPreCreation
): ValetSelfCreationFormValues => {
  const address = preCreation.addressLine1
    ? ({
        city: preCreation.addressCity,
        line1: preCreation.addressLine1,
        line2: preCreation.addressLine2,
        state: preCreation.addressState,
        zip: preCreation.addressZip
      } as Address)
    : undefined;
  return {
    username: "",
    password: "",
    address: getAddressFormInitialValues(address)
  };
};

const getValetSelfCreationFormValidation = () => {
  const requiredField = "This field is required.";
  return Yup.object<ValetSelfCreationFormValues>({
    username: Yup.string().required(requiredField),
    password: Yup.string().required(requiredField),
    address: getAddressFormValidationSchema(false).required()
  });
};

interface ValetSelfCreationFormProps {
  preCreation: ValetPreCreation;
  onSuccess: () => void;
}
const ValetSelfCreationForm: React.FC<ValetSelfCreationFormProps> = ({
  preCreation,
  onSuccess
}) => {
  const classes = useClasses();

  const { create, createdValet, loading, error } = useSelfCreateValet();

  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();

  const onSubmit = (values: ValetSelfCreationFormValues) => {
    create({ ...values, preCreationId: preCreation.id });
  };

  useEffect(() => {
    if (createdValet) {
      sendSuccessMessage("Your access has been created successfully");
      onSuccess();
    }
  }, [createdValet]);

  useEffect(() => {
    if (error) {
      sendErrorMessage(error.message);
    }
  }, [error]);

  return (
    <>
      <LoadingBackdrop open={loading} />
      <Formik
        initialValues={getValetSelfCreationFormInitialValues(preCreation)}
        validationSchema={getValetSelfCreationFormValidation()}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, handleChange }) => (
          <Form>
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  name="username"
                  fullWidth
                  label="Username"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  variant="outlined"
                  name="password"
                  type="password"
                  fullWidth
                  label="Password"
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  className={classes.sencondaryHeading}
                >
                  Address Information
                </Typography>
                <AddressForm
                  handleChange={handleChange}
                  values={values.address}
                  setFieldValue={setFieldValue}
                  errors={errors.address}
                  parentForm={"address"}
                  searchOnly
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{ marginLeft: "auto" }}>
                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ValetSelfCreationForm;
