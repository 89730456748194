import React, { useEffect } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { Actions, Subjects } from "../shared/contexts/AbilityContext";
import useAbility from "../shared/hooks/useAbility";
import ForbiddenPage from "../pages/Home/ForbiddenPage";

interface RoleRouteProps extends RouteProps {
  can?: [Actions, Subjects];
}

const RoleRoute: React.FC<RoleRouteProps> = ({ children, can, ...rest }) => {
  const ability = useAbility();
  const canAccess = can ? ability.can(can[0], can[1]) : true;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!canAccess) return <ForbiddenPage/>;
        return children;
      }}
    />
  );
};

export default RoleRoute;
