import React from "react";
import {
  Container,
  Box,
  Breadcrumbs,
  Typography,
  Grid,
  Button
} from "@material-ui/core";

import Link from "../../navigation/Link";
import ValetsTable from "./components/ValetsTable";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import ValetPreCreationsTable from "../PreCreations/components/ValetPreCreationsTable";
import ValetPreCreationModal from "../PreCreations/components/ValetPreCreationModal";
import ValetPreCreationsSection from "../PreCreations/components/ValetPreCreationsSection";

const ValetListPage: React.FC = ({}) => {
  return (
    <Container>
      <BreadcrumbsSection
        sections={[{ label: "Home", link: "/dashboard" }, { label: "Valets" }]}
      />

      <PageHeader
        title={"Valets"}
        actions={
          <Link to="/dashboard/valets/create">
            <Button variant="contained" color="primary">
              Create Valet
            </Button>
          </Link>
        }
      />

      <ValetsTable />
      <ValetPreCreationsSection />
    </Container>
  );
};

export default ValetListPage;
