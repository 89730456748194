import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, ListSubheader } from "@material-ui/core";
import List from "@material-ui/core/List";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FiberManualRecordRounded as Circle } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      //   height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    card: {
      // minHeight: 230,
      // minWidth: 500,
      border: ` 2px solid ${theme.palette.primary.light}`,
      borderRadius: theme.shape.borderRadius,
      overflow: "auto",
      maxHeight: "30em",
      width: "100%",
    },
  })
);

function not<T>(a: T[], b: T[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection<T>(a: T[], b: T[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union<T>(a: T[], b: T[]) {
  return [...a, ...not(b, a)];
}

interface TransferListProps<T> {
  leftItems: T[];
  rightItems: T[];
  onLeftItemsChange: (newItems: T[]) => void;
  onRightItemsChange: (newItems: T[]) => void;
  sectionKey: keyof T;
  displayKey: keyof T;
  rightTitle?: string;
  leftTitle?: string;
  viewOnly?: boolean;
}
const TransferList = <T,>({
  leftItems,
  rightItems,
  onLeftItemsChange,
  onRightItemsChange,
  sectionKey,
  displayKey,
  leftTitle,
  rightTitle,
  viewOnly,
}: React.PropsWithChildren<TransferListProps<T>>) => {
  const classes = useStyles();
  const [leftChecked, setLeftChecked] = React.useState<T[]>([]);
  const [rightChecked, setRightChecked] = React.useState<T[]>([]);

  //   const leftChecked = intersection(checked, left);
  //   const rightChecked = intersection(checked, right);

  const handleCheckedRight = () => {
    onRightItemsChange(rightItems.concat(leftChecked));
    onLeftItemsChange(not(leftItems, leftChecked));
    setLeftChecked([]);
  };

  const handleCheckedLeft = () => {
    onLeftItemsChange(leftItems.concat(rightChecked));
    onRightItemsChange(not(rightItems, rightChecked));
    setRightChecked([]);
  };

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
      alignItems="stretch"
      className={classes.root}
    >
      <Grid item container xs={12} md={5}>
        <SideList
          title={leftTitle}
          checked={leftChecked}
          onChecked={setLeftChecked}
          items={leftItems}
          sectionKey={sectionKey}
          displayKey={displayKey}
          viewOnly={viewOnly}
        />
      </Grid>
      {!viewOnly && (
        <Grid item container xs={12} md={2} alignItems="center">
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12} md={5}>
        <SideList
          title={rightTitle}
          checked={rightChecked}
          onChecked={setRightChecked}
          items={rightItems}
          sectionKey={sectionKey}
          displayKey={displayKey}
          viewOnly={viewOnly}
        />
      </Grid>
    </Grid>
  );
};

interface SideListProps<T> {
  items: T[];
  checked: T[];
  title?: string;
  sectionKey: keyof T;
  displayKey: keyof T;
  onChecked: (items: T[]) => void;
  viewOnly?: boolean;
}
const SideList = <T,>({
  items,
  title,
  onChecked,
  checked,
  sectionKey,
  displayKey,
  viewOnly,
}: React.PropsWithChildren<SideListProps<T>>) => {
  const classes = useStyles();

  const numberOfChecked = (items: T[]) => intersection(checked, items).length;
  const onToggleAll = (items: T[]) => {
    if (numberOfChecked(items) === items.length) {
      onChecked(not(checked, items));
    } else {
      onChecked(union(checked, items));
    }
  };

  const handleToggle = (value: T) => {
    const index = checked.indexOf(value);

    const newChecked = [...checked];
    if (index >= 0) {
      newChecked.splice(index, 1);
    } else {
      newChecked.push(value);
    }

    onChecked(newChecked);
  };
  const sections = formatSections(items, sectionKey);
  return (
    <div className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          viewOnly ? null : (
            <Checkbox
              onClick={() => onToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          )
        }
        title={title}
        subheader={
          viewOnly ? null : `${numberOfChecked(items)}/${items.length} selected`
        }
      />
      <Divider />

      {Object.keys(sections).map((section, i) => (
        <List
          key={section + i}
          className={classes.list}
          dense
          component="div"
          role="list"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {section}
            </ListSubheader>
          }
        >
          {sections[section].map((item, i) => {
            const labelId = `transfer-list-all-item-${i}-label`;

            return (
              <ListItem
                key={String(section) + " item" + i}
                role="listitem"
                button
                onClick={viewOnly ? undefined : () => handleToggle(item)}
              >
                <ListItemIcon>
                  {viewOnly ? (
                    <Circle />
                  ) : (
                    <Checkbox
                      checked={checked.indexOf(item) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item[displayKey]}`} />
              </ListItem>
            );
          })}
        </List>
      ))}
    </div>
  );
};

interface SectionMap<T> {
  [key: string]: T[];
}
const formatSections = <T,>(items: T[], sectionKey: keyof T): SectionMap<T> => {
  const sections: SectionMap<T> = {};

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const tempSectionName = String(item[sectionKey]);
    const sectionName =
      tempSectionName === "SubTenants" ? "SubFranchises" : tempSectionName;
    //TODO: Find a better way of changing 'subTenants' to 'Sub Franchises"
    if (!sections[sectionName]) {
      sections[sectionName] = [item];
      continue;
    }

    sections[sectionName]?.push(item);
  }

  return sections;
};

export default TransferList;
