import React from "react";
import {
  makeStyles,
  createStyles,
  ButtonProps,
  Button,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      margin: (props: LoadingButtonProps) =>
        props.noMargins ? "0" : theme.spacing(1),
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  })
);

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  noMargins?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = props => {
  const { isLoading, ...buttonProps } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <Button disabled={isLoading} {...buttonProps} />
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default LoadingButton;
