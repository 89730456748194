import {
  CustomerContractType,
  CustomerAgreement,
} from "../../../apollo/agreements/interface";
import UnitFullStep, {
  getFullStepValidationSchema,
  getFullStepInitialValues,
} from "./UnitsFullStep";
import React from "react";
import UnitStepUpStep, {
  getStepUpStepValidationSchema,
  getStepUpStepInitialValues,
} from "./UnitsStepUpStep";
import UnitLeaseUpStep, {
  getLeaseUpStepValidationSchema,
  getLeaseUpStepInitialValues,
} from "./UnitsLeaseUpStep";
import UnitPhaseInStep, {
  getPhaseInStepValidationSchema,
  getPhaseInStepInitialValues,
} from "./UnitsPhaseInStep";
import { FormValues } from "./interfaces";
import { getInitialDateSelectionValues } from "./DateSelectionStep";
import { getSpecialServiceFormInitialValues } from "./SpecialServiceStep";
import { getSigningFormInitialValues } from "./SigningStep";
import { getTypeSelectionFormInitialValues } from "./TypeSelectionStep";
import { getFreeWritingStepInitialValues } from "./FreeWritingStep";

export const getUnitStep = (type: CustomerContractType) => {
  switch (type) {
    case "STEP-UP":
      return {
        component: <UnitStepUpStep />,
        validation: getStepUpStepValidationSchema(),
      };
    case "PHASE-IN":
      return {
        component: <UnitPhaseInStep />,
        validation: getPhaseInStepValidationSchema(),
      };
    case "LEASE-UP":
      return {
        component: <UnitLeaseUpStep />,
        validation: getLeaseUpStepValidationSchema(),
      };
    default:
      return {
        component: <UnitFullStep />,
        validation: getFullStepValidationSchema(),
      };
  }
};
export const getRandomComponentKey = () =>
  Math.random().toString(36).substring(7);

export const getUnitInitialValues = (
  type: CustomerContractType,
  agreement?: CustomerAgreement
) => {
  switch (type) {
    case "STEP-UP":
      return getStepUpStepInitialValues(agreement);
    case "LEASE-UP":
      return getLeaseUpStepInitialValues(agreement);
    case "PHASE-IN":
      return getPhaseInStepInitialValues(agreement);
    default:
      return getFullStepInitialValues(agreement);
  }
};

export const loadAgreementsValues = (
  propertyId: string,
  currentType: CustomerContractType,
  agreement?: CustomerAgreement
): FormValues => {
  return {
    ...getUnitInitialValues(currentType, agreement),
    ...getInitialDateSelectionValues(agreement),
    ...getSpecialServiceFormInitialValues(agreement),
    ...getSigningFormInitialValues(agreement),
    ...getTypeSelectionFormInitialValues(propertyId, agreement),
    ...getFreeWritingStepInitialValues(agreement),
  };
};
