import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormikContext } from "formik";

import React, { useEffect, useState } from "react";
import { SupplyItem } from "../../../apollo/supplyRequests/interfaces";
import { useGetSupplyItemsPaginated } from "../../../apollo/supplyRequests/queries";
import { SupplyItemsList } from "./SupplyItemsList";
import Image from "material-ui-image";
import Icon from "../../../shared/components/Icon";
import { mdiCubeOutline } from "@mdi/js";
import { DeleteRounded, ExpandLess, ExpandMore } from "@material-ui/icons";
import ValetSelectModal from "../../Valets/components/ValetSelectModal";
import RouteSelectModal from "../../ServiceRoutes/components/RoutesSelectModal";
import useUrlQuery from "../../../shared/hooks/useUrlQuery";
import { useGetValetSelectItemLazy } from "../../../apollo/valets/queries";
import { useGetRouteSelectItemLazy } from "../../../apollo/serviceRoutes/queries";
import * as Yup from "yup";
import AddItemFormModal, { SupplyRequestFormLineItem } from "./AddItemFormModal";


export interface SupplyRequestFormValues {
  route: { id: string; routeCode: string; propertyName: string } | null;
  valet: { name: string; id: string } | null;
  selections: SupplyRequestFormLineItem[];
}

export const getSupplyRequestFormValues = (): SupplyRequestFormValues => {
  return {
    route: null,
    valet: null,
    selections: []
  };
};

export const getSupplyRequestFormValidationSchema = () => {
  return Yup.object<SupplyRequestFormValues>({
    route: Yup.object<{ id: string; routeCode: string; propertyName: string }>()
      .required("This field is required.")
      .nullable(),
    valet: Yup.object<{ name: string; id: string }>()
      .required("This field is required.")
      .nullable(),
    selections: Yup.array<SupplyRequestFormLineItem>()
      .min(1, "You must include at least one item.")
      .required()
  });
};

const SupplyRequestForm: React.FC = ({}) => {
  const {
    items: fetchedItems,
    itemsError,
    refetch,
    itemsLoading
  } = useGetSupplyItemsPaginated();
  const [currentItem, setCurrentItem] = useState<SupplyItem | undefined>();
  const {
    values: { selections },
    errors,
    setFieldValue
  } = useFormikContext<SupplyRequestFormValues>();
  const query = useUrlQuery();
  const { getValet, valet } = useGetValetSelectItemLazy();
  const { getRoute, route } = useGetRouteSelectItemLazy();

  const onItemAddClick = (item: SupplyItem) => setCurrentItem(item);

  const onItemRemoveClick = (item: SupplyItem) =>
    setFieldValue(
      "selections",
      selections.filter(i => i.item.id !== item.id)
    );

  const onItemSelectionSubmit = (selection: SupplyRequestFormLineItem) => {
    setFieldValue("selections", [...selections,  selection ]);
    onModalClose();
  };

  const onModalClose = () => {
    setCurrentItem(undefined);
  };

  useEffect(() => {
    const routeId = query.get("routeId");
    const valetId = query.get("valetId");
    if (routeId) {
      getRoute(routeId);
    }
    if (valetId) {
      getValet(valetId);
    }
  }, []);

  useEffect(() => {
    if (valet) {
      setFieldValue("valet", valet);
    }
  }, [valet]);

  useEffect(() => {
    if (route) {
      setFieldValue("route", route);
    }
  }, [route]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, marginTop: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <RouteSelectModal
                name="route"
                variant="outlined"
                label="Service Route"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValetSelectModal
                name="valet"
                variant="outlined"
                label="Valet"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SelectedItemsTable items={selections} />
              {!!errors.selections && (
                <Typography
                  variant="caption"
                  color="error"
                  style={{ marginLeft: 14 }}
                >
                  {errors.selections}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <SupplyItemsList
          items={fetchedItems}
          error={itemsError}
          onItemAdd={onItemAddClick}
          onItemRemove={onItemRemoveClick}
          selectedItems={selections.map(i => i.item.id)}
          isForRequest
        />
      </Grid>

      <AddItemFormModal
        open={!!currentItem}
        onClose={onModalClose}
        item={currentItem}
        onSubmit={onItemSelectionSubmit}
      />
    </Grid>
  );
};



interface SelectedItemsTableProps {
  items:SupplyRequestFormLineItem[];
}
const SelectedItemsTable: React.FC<SelectedItemsTableProps> = ({ items }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <>
      <ButtonBase
        style={{ width: "100%", borderRadius: 20 }}
        onClick={() => setIsCollapsed(c => !c)}
      >
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6" color="primary">
            Selected {items.length}
          </Typography>
          {!isCollapsed ? (
            <ExpandLess color="primary" fontSize="large" />
          ) : (
            <ExpandMore fontSize="large" color="primary" />
          )}
        </Box>
      </ButtonBase>
      <Collapse in={!isCollapsed}>
        {items.length ? (
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Picture</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Variety</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(row => (
                  <TableRow key={row.item.id}>
                    <TableCell>
                      {row.item.image ? (
                        <Image
                          src={row.item.image.url}
                          imageStyle={{
                            width: "auto",
                            height: "100%",
                            objectFit: "scale"
                          }}
                          style={{
                            height: 50,
                            paddingTop: 0,
                            backgroundColor: "transparent"
                          }}
                        />
                      ) : (
                        <Icon path={mdiCubeOutline} />
                      )}
                    </TableCell>
                    <TableCell>{row.item.name}</TableCell>
                    <TableCell>{row.variety}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell align="right">
                      <IconButton>
                        <DeleteRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>You have not selected any item.</Typography>
        )}
      </Collapse>
    </>
  );
};
export default SupplyRequestForm;
