import React, { useState } from "react";
import { useGetManagementCompanies } from "../../../apollo/propertyManagers/queries";
import { Grid, Button, Typography } from "@material-ui/core";
import DescriptionCard from "./DescriptionCard";
import { AddRounded } from "@material-ui/icons";
import FormModal from "./FormModal";
import { PropertyManager } from "../../../apollo/propertyManagers/interfaces";
import DeleteModal from "../../../shared/components/DeleteModal";
import { DELETE_MANAGEMENT_COMPANIES } from "../../../apollo/propertyManagers/mutations";
import useAbility from "../../../shared/hooks/useAbility";

const useManagementCompanySection = (subTenantId: string | null) => {
  const { companies, loading, error, refetch } = useGetManagementCompanies(subTenantId);
  const ability = useAbility();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    selectedEditItem,
    setSelectedEditItem,
  ] = useState<null | PropertyManager>(null);
  const onCreateClick = () => {
    setSelectedEditItem(null);
    setShowFormModal(true);
  };
  const onCloseFormModalClick = () => {
    setShowFormModal(false);
  };

  const onSubmitSuccess = () => {
    refetch();
    setShowFormModal(false);
  };

  const onDeleteSuccess = () => {
    refetch();
    setShowDeleteModal(false);
  };

  const onDeleteClick = (item: PropertyManager) => {
    setSelectedEditItem(item);
    setShowDeleteModal(true);
  };

  const onDeleteFormModalClose = () => {
    setShowDeleteModal(false);
  };

  const onEditClick = (item: PropertyManager) => {
    setSelectedEditItem(item);
    setShowFormModal(true);
  };
  return {
    model: {
      loading,
      companies,
      showFormModal,
      selectedEditItem,
      showDeleteModal,
      ability,
    },
    commands: {
      onDeleteClick,
      onCreateClick,
      onDeleteFormModalClose,
      onCloseFormModalClick,
      onEditClick,
      onSubmitSuccess,
      onDeleteSuccess,
    },
  };
};

interface ManagementCompanySectionProps {
  subTenantId: string | null;
}

const ManagementCompanySection: React.FC<ManagementCompanySectionProps> = ({subTenantId}) => {
  const {
    model: {
      loading,
      companies,
      showFormModal,
      selectedEditItem,
      showDeleteModal,
      ability,
    },
    commands: {
      onCloseFormModalClick,
      onCreateClick,
      onEditClick,
      onSubmitSuccess,
      onDeleteClick,
      onDeleteFormModalClose,
      onDeleteSuccess,
    },
  } = useManagementCompanySection(subTenantId);
  const isEmpty = companies && !companies.length;

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <FormModal
        open={showFormModal}
        type="company"
        onClose={onCloseFormModalClick}
        item={selectedEditItem}
        onSuccess={onSubmitSuccess}
        subTenantId={subTenantId}
        // renderTenantSelect={ability.can("manage", "all")}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DELETE_MANAGEMENT_COMPANIES}
        ids={selectedEditItem ? [selectedEditItem.id] : []}
        description="Are you sure you want to delete this Management Company? This cannot be undone."
        title={"Delete Management Company"}
        onSuccess={onDeleteSuccess}
        onClose={onDeleteFormModalClose}
      />
      {ability.can("create", "PropertyManagers") && (
        <Button
          variant="contained"
          onClick={onCreateClick}
          color="primary"
          startIcon={<AddRounded />}
        >
          Add New
        </Button>
      )}

      {isEmpty ? (
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ marginTop: 10 }}
        >
          No management companies have been created.
        </Typography>
      ) : (
        <Grid
          container
          spacing={5}
          style={{ marginTop: 10 }}
          alignItems="stretch"
        >
          {companies?.map((company) => (
            <Grid key={company.id} item xs={12} sm={6} md={3}>
              <DescriptionCard
                item={company}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                canEdit={ability.can('update', 'PropertyManagers')}
                canDelete={ability.can('delete', 'PropertyManagers')}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ManagementCompanySection;
