import React, { useState, useEffect } from "react";
import { Container, Paper, Grid, Button } from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import FormikStepForm, {
  FormikStep
} from "../../shared/components/FormikStepForm";
import DateSelectionStep, {
  getDateSelectionValidationSchema
} from "./CustomerSteps/DateSelectionStep";
import SpecialServiceStep, {
  getSpecialServiceFormValidationSchema
} from "./CustomerSteps/SpecialServiceStep";
import SigningStep, {
  getSigningStepValidationSchema
} from "./CustomerSteps/SigningStep";
import { FormValues } from "./CustomerSteps/interfaces";
import TypeSelectionStep, {
  getTypeSelectionValidationSchema
} from "./CustomerSteps/TypeSelectionStep";
import { useParams, useHistory } from "react-router-dom";

import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import { CustomerContractType } from "../../apollo/agreements/interface";
import { getUnitStep, loadAgreementsValues } from "./CustomerSteps/utils";
import FreeWritingStep, {
  getFreeWritingStepValidationSchema
} from "./CustomerSteps/FreeWritingStep";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { ApolloError } from "@apollo/client";
import { useSubmitAgreement } from "../../apollo/agreements/mutation";
import {
  useGetCustomerWithAgreement,
  useGetPropertyWithAgreement
} from "../../apollo/agreements/query";
import LoadingPage from "../Home/LoadingPage";
import Link from "../../navigation/Link";

const useCustomerAgreementFormPage = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const [activeStep, setActiveStep] = useState(0);
  const [currentType, setCurrentType] = useState<CustomerContractType>("FULL");
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const history = useHistory();
  const onSubmitSuccess = () => {
    sendSuccessMessage("Agreement submitted successfully.");
    history.push("/dashboard/properties/" + propertyId);
  };
  const onSubmitError = (err: ApolloError) => {
    sendErrorMessage("Something went wrong submitting the agreement.");
    console.warn(err);
  };

  const { submitAgreement, submitAgreementLoading } = useSubmitAgreement(
    onSubmitSuccess,
    onSubmitError
  );
  const onFormSubmit = async (values: FormValues) => {
    submitAgreement({ variables: { agreement: values } });
  };
  const { property, loading, error } = useGetPropertyWithAgreement(propertyId);
  const initialValues: FormValues = loadAgreementsValues(
    propertyId,
    currentType,
    property?.agreement
  );

  useEffect(() => {
    if (property && property.agreement) {
      setCurrentType(property.agreement.type);
      console.log("setting step");
      setActiveStep(5);
    }
  }, [property, setActiveStep, setCurrentType]);
  return {
    model: {
      property,
      customerLoading: loading,
      submitLoading: submitAgreementLoading,
      initialValues,
      currentType,
      activeStep
    },
    commands: {
      onTypeChange: setCurrentType,
      onFormSubmit,
      onStepChange: setActiveStep
    }
  };
};

const CustomerAgreementFormPage: React.FC = () => {
  const {
    model: {
      initialValues,
      property,
      customerLoading,
      submitLoading,
      currentType,
      activeStep
    },
    commands: { onTypeChange, onFormSubmit, onStepChange }
  } = useCustomerAgreementFormPage();

  const unitStep = getUnitStep(currentType);
  const hasSingleSignature = () => {
    if (!property) return false;

    const { agreement } = property;
    if (!agreement) return false;

    return !!agreement.supervisorSignature || !!agreement.customerSignature;
  };
  const isSigned = () => {
    if (!property) return false;

    const { agreement } = property;
    if (!agreement) return false;

    return !!agreement.supervisorSignature && !!agreement.customerSignature;
  };

  if (customerLoading) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <LoadingBackdrop open={submitLoading} />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Properties", link: "/dashboard/properties" },
          {
            label: `${property?.name}`,
            link: "/dashboard/properties/" + property?.id
          },
          { label: "Agreement" }
        ]}
      />
      <PageHeader title="Customer Agreement" />

      <Paper>
        <Container>
          {isSigned() ? (
            <AgreementView
              agreementSrc={property?.agreement?.generatedFile?.url}
              propertyId={property ? property.id : ""}
            />
          ) : (
            <FormikStepForm
              activeStep={activeStep}
              onStepChange={onStepChange}
              initialValues={initialValues}
              onSubmit={onFormSubmit}
              validateOnChange={false}
              disableBack={hasSingleSignature()}
            >
              <FormikStep
                label="Customer Review & Ageement Type"
                validationSchema={getTypeSelectionValidationSchema()}
              >
                <TypeSelectionStep
                  property={property}
                  onTypeChange={onTypeChange}
                  currentType={currentType}
                />
              </FormikStep>
              <FormikStep
                label="Units & Charges"
                validationSchema={unitStep.validation}
              >
                {unitStep.component}
              </FormikStep>
              <FormikStep
                label="Dates and Times"
                validationSchema={getDateSelectionValidationSchema()}
              >
                <DateSelectionStep />
              </FormikStep>
              <FormikStep
                label="Special Service Requests"
                validationSchema={getSpecialServiceFormValidationSchema()}
              >
                <SpecialServiceStep />
              </FormikStep>
              <FormikStep
                label="Extra Information"
                validationSchema={getFreeWritingStepValidationSchema()}
              >
                <FreeWritingStep />
              </FormikStep>

              <FormikStep
                label="Preview and Signing"
                validationSchema={getSigningStepValidationSchema()}
              >
                <SigningStep agreement={property?.agreement} />
              </FormikStep>
            </FormikStepForm>
          )}
        </Container>
      </Paper>
    </Container>
  );
};

interface AgreementViewProps {
  agreementSrc?: string;
  customerId?: string;
  propertyId?: string;
}

const AgreementView: React.FC<AgreementViewProps> = ({
  agreementSrc,
  customerId,
  propertyId
}) => {
  return (
    <Grid container spacing={2} style={{ paddingBottom: 20 }}>
      <Grid item xs={12}>
        <img src={agreementSrc} style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12} justify="center" container>
        <Link to={"/dashboard/properties/" + propertyId}>
          <Button variant="contained" color="primary">
            Go to customer profile
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default CustomerAgreementFormPage;
