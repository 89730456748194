import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { title } from "process";
import { useUpdatePointsOfInterests } from "../../../apollo/customers/mutations";
import { green } from "@material-ui/core/colors";
import LoadingButton from "../../../shared/components/LoadingButton";
import {
  CreatePointOfInterest,
  PointOfInterest,
} from "../../../apollo/customers/interfaces";
import { Formik, Form } from "formik";
import MarkersField from "../../../shared/components/Formik/MarkersField";
import useSnackbar from "../../../shared/hooks/useSnackbar";

interface PointsFormModalProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  propertyId: string;
  initialPoints?: PointOfInterest[];
  onSubmitSuccess?: () => void;
  mapCenter?: { latitude: number; longitude: number };
}

interface PointsFormValues {
  points: CreatePointOfInterest[];
}

const PointsFormModal: React.FC<PointsFormModalProps> = ({
  open,
  onClose,
  title,
  propertyId,
  initialPoints = [],
  mapCenter,
  onSubmitSuccess,
}) => {
  const {
    submitPoints,
    points,
    submitPointsError,
    submitPointsLoading,
  } = useUpdatePointsOfInterests();
  const { sendSuccessMessage, sendErrorMessage } = useSnackbar();
  console.log({ initialPoints });
  const onSubmit = (values: PointsFormValues) => {
    submitPoints({
      variables: {
        points: values.points,
        propertyId,
      },
    });
  };

  useEffect(() => {
    if (points) {
      sendSuccessMessage("Points of interest updated successfully.");
      if (onSubmitSuccess) onSubmitSuccess();
      if (onClose) onClose();
    }
  }, [points]);

  useEffect(() => {
    if (submitPointsError) {
      sendErrorMessage(
        "There was a problem processing the submission: " +
          submitPointsError.message
      );
    }
  }, [submitPointsError]);
  return (
    <Dialog
      fullScreen
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik onSubmit={onSubmit} initialValues={{ points: initialPoints }}>
        {({ submitForm }) => (
          <Form
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent
              style={{ flex: 1, display: "flex", flexDirection: "column" }}
            >
              <DialogContentText>
                You can select one of the following types of points of interest
                to highlight the locations of places needed by valets and
                clicking on the map. Markers can be removed by right-clicking
                them.
              </DialogContentText>

              <MarkersField
                name="points"
                initialZoom={mapCenter? 20 : undefined}
                initialCenter={
                  mapCenter
                    ? { lat: mapCenter.latitude, lng: mapCenter.longitude }
                    : undefined
                }
                markers={mapCenter? [{ lat: mapCenter.latitude, lng: mapCenter.longitude, label: 'Property' }] : undefined}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                disabled={submitPointsLoading}
                color="primary"
              >
                close
              </Button>
              <LoadingButton
                isLoading={submitPointsLoading}
                color="primary"
                type="submit"
              >
                Update
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default PointsFormModal;
