import { Button, Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, useFormikContext } from "formik";
import { DatePicker } from "formik-material-ui-pickers";
import { Moment } from "moment";
import React from "react";
import ValetSelectModal from "../../Valets/components/NewValetSelectModal";
import DateFnsUtils from "@date-io/moment";
import * as Yup from "yup";
import { TimeOffRequest } from "../../../apollo/timeOffRequests/interfaces";
import moment from "moment";

export interface TimeOffRequestFormValues {
  id?: string;
  valetId: string | null;
  coverValetId: string | null;
  startDate: Moment | null;
  endDate: Moment | null;
}

export const getTimeOffRequestFormInitialValues = (
  request?: TimeOffRequest
): TimeOffRequestFormValues => {
  return {
    valetId: request ? request.valetId : null,
    coverValetId: request?.coverValetId ? request.coverValetId : null,
    startDate: request ? moment(request.startDate) : null,
    endDate: request ? moment(request.endDate) : null
  };
};

export const getTimeOffRequestFormValidationSchema = () => {
  return Yup.object<TimeOffRequestFormValues>({
    valetId: Yup.string()
      .required("You must select a valet.")
      .nullable(),
    coverValetId: Yup.string()
      .defined()
      .nullable()
      .test(
        "check if valets are equal",
        "You must select a different valet.",
        function(value) {
          const { valetId } = this.parent as TimeOffRequestFormValues;
          return valetId !== value;
        }
      ),
    startDate: Yup.object<Moment>()
      .required("You must specify a starting date.")
      .nullable(),
    endDate: Yup.object<Moment>()
      .required("You must specify a ending date.")
      .nullable()
  });
};

const TimeOffRequestForm: React.FC = ({}) => {
  const { values } = useFormikContext<TimeOffRequestFormValues>();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ValetSelectModal
            name="valetId"
            variant="outlined"
            label="Requesting Valet"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValetSelectModal
            name="coverValetId"
            variant="outlined"
            label="Covering Valet"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Field
            disablePast
            component={DatePicker}
            format="l"
            fullWidth
            inputVariant="outlined"
            name="startDate"
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            minDate={values.startDate ? values.startDate : undefined}
            minDateMessage="You must select a later date."
            component={DatePicker}
            format="l"
            fullWidth
            inputVariant="outlined"
            name="endDate"
            label="End Date"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default TimeOffRequestForm;
