import { createMuiTheme } from '@material-ui/core';
import { deepPurple, red, grey, purple } from '@material-ui/core/colors';
import { light } from '@material-ui/core/styles/createPalette';


const theme = createMuiTheme({
    palette: {
        primary:  {
            main: '#4592af',
            dark: '#006480', 
            light: '#79c2e1',
        },
        secondary: {
            main: '#e3c4a8',
            dark: '#b09479', 
            light: '#fff7da',
            "100": ""
        },
        background: { default: '#F6F8FB'}
    },
    shape: {
        borderRadius: 20
    },
    props: {
        // MuiPaper: {
        //     elevation: 0
        // }
    },
    overrides: {
        MuiPaper: {
            elevation4: {
                // boxShadow: 'none',
                paddingBottom: 20,
                paddingRight: 20,
                paddingLeft: 20
            }
        }
    }
    // typography: {
    //     fontFamily: "sans-serif"
    // }
    
});

export default theme;