import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  createMuiTheme,
  Grid,
  MuiThemeProvider,
  Paper,
  Typography,
  useTheme
} from "@material-ui/core";
import MaterialTable from "material-table";
import DataTable from "../../../shared/components/Datatable";
import {
  GET_AVAILABLE_SUB_TENANTS,
  GET_SHARED_SUB_TENANTS_TABLE
} from "../../../apollo/customers/queries";
import moment from "moment";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import { Close } from "@material-ui/icons";
import LoadingButton from "../../../shared/components/LoadingButton";
import { SubTenant } from "../../../apollo/tenants/interfaces";
import {
  useShareCustomer,
  useUnShareCustomer
} from "../../../apollo/customers/mutations";
import useSnackbar from "../../../shared/hooks/useSnackbar";

interface SharedSubTenantsViewProps {
  propertyId: string;
}

const SharedSubTenantsView: React.FC<SharedSubTenantsViewProps> = ({
  propertyId
}) => {
  const [showForm, setShowForm] = useState(false);
  const [subTenantRefresher, setSubTenantRefresher] = useState(true);
  const [subTenantId, setSubTenantId] = useState<null | string>(null);
  const theme = useTheme();

  const {
    data,
    shareCustomer,
    shareCustomerError,
    shareCustomerLoading
  } = useShareCustomer();

  const {
    unshareCustomer,
    unshareCustomerError,
    unshareCustomerLoading,
    unshareSuccess
  } = useUnShareCustomer();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const share = () => {
    if (subTenantId) shareCustomer(propertyId, subTenantId);
  };

  const unShare = (subTenantId: string) => () => {
    unshareCustomer(propertyId, subTenantId);
  };

  const onSubTenantChange = (subTenant: SubTenant | null) => {
    setSubTenantId(subTenant ? subTenant.id : null);
  };

  const toggleForm = () => {
    setShowForm(s => !s);
  };

  useEffect(() => {
    if (shareCustomerError) {
      sendErrorMessage("Error sharing customer: " + shareCustomerError.message);
    }
  }, [shareCustomerError]);

  useEffect(() => {
    if (unshareCustomerError) {
      sendErrorMessage(
        "Error sharing customer: " + unshareCustomerError.message
      );
    }
  }, [unshareCustomerError]);

  useEffect(() => {
    if (data) {
      sendSuccessMessage("Customer shared successfully.");
      setSubTenantRefresher(r => !r);
    }
  }, [data]);

  useEffect(() => {
    if (unshareSuccess) {
      sendSuccessMessage("Stopped sharing customer successfully.");
      setSubTenantRefresher(r => !r);
    }
  }, [unshareSuccess]);

  return (
    <>
    <Typography variant="h6" gutterBottom>Shared to</Typography>
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justify="flex-end">
          <Button variant="contained" color="primary" onClick={toggleForm}>
            {showForm ? <Close /> : "Share customer"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={showForm}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12}>
                <Typography variant="h6">
                  Share customer with a sub-franchise
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <SubTenantSearchBox
                  value={subTenantId}
                  onOptionChange={onSubTenantChange}
                  refresher={subTenantRefresher}
                  label="Available Sub-Franchises"
                  customQueryResultName="availableSubTenants"
                  variables={{ propertyId }}
                  customQuery={GET_AVAILABLE_SUB_TENANTS}
                />
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  disabled={!subTenantId}
                  isLoading={shareCustomerLoading}
                  onClick={share}
                  variant="outlined"
                  fullWidth
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider
            theme={createMuiTheme({
              ...theme,
              overrides: {
                MuiPaper: {
                  elevation4: {
                    boxShadow: ""
                  }
                }
              }
            })}
          >
            <DataTable
              query={GET_SHARED_SUB_TENANTS_TABLE}
              columns={[
                {
                  name: "subTenantId",
                  options: {
                    display: "excluded"
                  }
                },
                {
                  name: "subTenantName",
                  label: "Sub-Franchise"
                },
                {
                  name: "createdAt",
                  label: "Shared At",
                  options: {
                    customBodyRender: value => {
                      return moment(value).format("lll");
                    }
                  }
                },
                {
                  label: "Actions",
                  name: "actions",
                  options: {
                    customBodyRender: (value, tableMeta) => {
                      const subTenantId = tableMeta.rowData[0] as string;

                      return (
                        <Button
                          disabled={unshareCustomerLoading}
                          onClick={unShare(subTenantId)}
                        >
                          Stop sharing
                        </Button>
                      );
                    }
                  }
                }
              ]}
              variables={{ propertyId }}
              dateColumns={[]}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};

export default SharedSubTenantsView;
