import React, { useState, ReactNode } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {
  PinDropRounded,
  PhoneRounded,
  MoreVertRounded,
  CloseRounded,
  DeleteRounded,
  EditRounded
} from "@material-ui/icons";
import {
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon
} from "@material-ui/lab";
import { useParams, useHistory } from "react-router-dom";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useQuery } from "@apollo/react-hooks";
import { GET_SUB_TENANT } from "../../apollo/tenants/queries";
import { SubTenant } from "../../apollo/tenants/interfaces";
import { formatAddress } from "../../shared/utils";
import SubTenantDeleteModal from "./components/SubTenantDeleteModal";
import useAbility from "../../shared/hooks/useAbility";
import useUser from "../../shared/hooks/useUser";
import useIsAdmin from "../../shared/hooks/useIsAdmin";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    // bottom: 30,
    [theme.breakpoints.up("sm")]: {
      top: "-30px",
      right: 30
    },
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
      right: 0
    }
  },
  pageContainer: {
    height: "100%",
    position: "relative"
  },
  basicPaper: {
    [theme.breakpoints.up("sm")]: {
      position: "relative"
    }
  }
}));
const LoadingSkeleton: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={50} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Skeleton
            animation="wave"
            width="100%"
            height="50vh"
            style={{ marginTop: -80 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
const SubTenantPage: React.FC = () => {
  const { subTenantId } = useParams<{ subTenantId: string }>();
  const [showFabMenu, setShowFabMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const user = useUser();
  const isAdmin = useIsAdmin();
  const history = useHistory();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const handleFabOpen = () => {
    setShowFabMenu(true);
  };
  const handleFabClose = () => {
    setShowFabMenu(false);
  };
  const classes = useStyles();
  const { data, loading } = useQuery<
    { subTenant: SubTenant },
    { subTenantId: string }
  >(GET_SUB_TENANT, {
    variables: {
      subTenantId: subTenantId ? subTenantId : user ? user.subTenantId : ""
    },
    fetchPolicy: "cache-and-network"
  });

  const onDeleteSuccess = () => {
    history.replace("/dashboard/franchises");
  };
  if (loading) {
    return <LoadingSkeleton />;
  }

  const actions: FabMenuAction[] = [
    {
      name: "Delete",
      icon: <DeleteRounded />,

      onClick: () => {
        setShowDeleteModal(true);
      }
    },
    {
      name: "Edit",
      icon: <EditRounded />,
      onClick: () => {
        history.push(`/dashboard/subFranchises/${subTenantId}/edit`, {
          subTenant: data?.subTenant
        });
      }
    }
  ];
  const fabDirection = matchesXS ? "up" : "left";
  return (
    <Fade in={true}>
      <Container className={classes.pageContainer}>
        <SubTenantDeleteModal
          ids={[subTenantId]}
          open={showDeleteModal}
          name={data?.subTenant.name}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onSuccess={onDeleteSuccess}
        />
        {matchesXS && isAdmin && (
          <FabMenu
            actions={actions}
            open={showFabMenu}
            onClose={handleFabClose}
            onOpen={handleFabOpen}
            className={classes.fab}
            direction={fabDirection}
          />
        )}

        <BreadcrumbsSection
          sections={
            isAdmin
              ? [
                  { label: "Home", link: "/dashboard" },
                  { label: "Sub-Franchises", link: "/dashboard/subFranchises" },
                  { label: data ? data?.subTenant.name : "Sub-Franchise" }
                ]
              : [{ label: "Home", link: "/dashboard" }, { label: "Sub-Franchise" }]
          }
        />
        <PageHeader title={data ? data?.subTenant.name : "Sub-Franchise"} />
        <Paper className={classes.basicPaper}>
          {!matchesXS && isAdmin && (
            <FabMenu
              actions={actions}
              open={showFabMenu}
              onClose={handleFabClose}
              onOpen={handleFabOpen}
              className={classes.fab}
              direction={fabDirection}
            />
          )}
          <Container>
            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {" "}
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      <PinDropRounded color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Address"
                      primaryTypographyProps={{
                        color: "textSecondary"
                      }}
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {formatAddress(data?.subTenant.address)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      <PhoneRounded color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Phone"
                      primaryTypographyProps={{
                        color: "textSecondary"
                      }}
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {data?.subTenant.phoneNumber}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Container>
    </Fade>
  );
};

interface FabMenuAction {
  name: string;
  icon: React.ReactNode;
  onClick?: () => void;
}
interface FabMenuProps {
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  open: boolean;
  direction?: "left" | "right" | "up" | "down";
  actions?: FabMenuAction[];
}
const FabMenu: React.FC<FabMenuProps> = ({
  onOpen,
  onClose,
  open,
  actions,
  className,
  direction
}) => {
  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      className={className}
      hidden={false}
      icon={
        <SpeedDialIcon icon={<MoreVertRounded />} openIcon={<CloseRounded />} />
      }
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      direction={direction}
    >
      {actions?.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  );
};
export default SubTenantPage;
