import { IconButton, Tooltip } from "@material-ui/core";
import { mdiDownloadCircle } from "@mdi/js";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { Incident } from "../../../apollo/incidents/interfaces";
import Icon from "../../../shared/components/Icon";
import IncidentPDF from "./IncidentPDF";

interface IncidentPDFButtonProps {
  incident: Incident;
}
const IncidentPDFButton: React.FC<IncidentPDFButtonProps> = React.forwardRef<
  HTMLButtonElement,
  IncidentPDFButtonProps
>(({ incident }, ref) => {

  return (
    <PDFDownloadLink
      document={<IncidentPDF incident={incident} />}
      fileName={`Incident-Report-${incident.route.routeCode}-${incident.route.property.name}.pdf`}
    >
      {({ loading }) => (
        <Tooltip title="Download As PDF">
          <IconButton disabled={loading} ref={ref}>
            <Icon path={mdiDownloadCircle} />
          </IconButton>
        </Tooltip>
      )}
    </PDFDownloadLink>
  );
});

export default React.memo(IncidentPDFButton, (prevProps, newProps) => {
  if (prevProps.incident.id === newProps.incident.id) {
    return true;
  }

  return false;
});
