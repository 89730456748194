import { useQuery, QueryHookOptions, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "@apollo/client";
import { SubscriptionTypes } from "./interfaces";

const CHECK_SUBSCRIPTION = gql`
  query checkSubscription($id: String!, $type: String!) {
    checkSubscription(id: $id, type: $type)
  }
`;

export const useCheckSubscription = (id: string, type: SubscriptionTypes) => {
  const { data, loading } = useQuery<
    { checkSubscription: boolean },
    { id: string; type: SubscriptionTypes }
  >(CHECK_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    variables: { id, type }
  });

  return {
    isSubscribed: !!data?.checkSubscription,
    loading
  };
};
