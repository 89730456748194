import React, { useEffect } from "react";
import { Form, Field, useFormikContext } from "formik";
import { Grid, FormControlLabel } from "@material-ui/core";
import { TextField, Switch } from "formik-material-ui";
import { IncidentCategory } from "../../../apollo/incidents/interfaces";
import * as Yup from "yup";

export interface IncidentCategoryFormValues {
  id?: string;
  name: string;
  description: string;
  requiresPictures: boolean;
  shouldSendAlert: boolean;
  order?: number;
}

export const getIncidentCategoryFormInitialValues = (
  category?: IncidentCategory
): IncidentCategoryFormValues => {
  return {
    id: category ? category.id : undefined,
    name: category ? category.name : "",
    description: category ? category.description : "",
    requiresPictures: category ? category.requiresPictures : false,
    shouldSendAlert: category ? category.shouldSendAlert : false,
    order: category ? category.order : 0
  };
};

export const getIncidentCategoryFormValidationSchema = () => {
  return Yup.object<IncidentCategoryFormValues>({
    name: Yup.string().required("This field is required."),
    description: Yup.string().required("This field is required."),
    requiresPictures: Yup.boolean().required(),
    shouldSendAlert: Yup.boolean().required(),
    order: Yup.number()
  });
};

const useIncidentCategoryForm = (category?: IncidentCategory) => {
  const { setValues } = useFormikContext();

  useEffect(() => {
    if (category) {
      setValues(getIncidentCategoryFormInitialValues(category));
    }
  }, [category]);

  return { isEdit: !!category };
};

interface IncidentCategoryFormProps {
  category?: IncidentCategory;
}

const IncidentCategoryForm: React.FC<IncidentCategoryFormProps> = ({
  category
}) => {
  const { isEdit } = useIncidentCategoryForm(category);
  return (
    <Form style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={isEdit ? 5 : 6}>
          <Field
            component={TextField}
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={isEdit ? 5 : 6}>
          <Field
            component={TextField}
            name="description"
            label="Description"
            variant="outlined"
            fullWidth
          />
        </Grid>
        {isEdit && (
          <Grid item xs={2}>
            <Field
              type="number"
              component={TextField}
              name="order"
              label="Order"
              variant="outlined"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Field
                component={Switch}
                type="checkbox"
                name="requiresPictures"
              />
            }
            label="Requires Pictures"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Field
                component={Switch}
                type="checkbox"
                name="shouldSendAlert"
              />
            }
            label="Should send alerts"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default IncidentCategoryForm;
