import React from "react";
import { Customer } from "../../../apollo/customers/interfaces";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  PersonRounded,
  PanoramaFishEyeRounded,
  PinDropRounded,
  PhoneRounded,
  PhoneOutlined,
  EmailRounded,
} from "@material-ui/icons";
import { formatGender, formatAddress } from "../../../shared/utils";
interface CustomerDetailsSectionProps {
  customer: Customer;
}
const CustomerDetailsSection: React.FC<CustomerDetailsSectionProps> = ({
  customer,
}) => {
  return (
    <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Grid item xs={12} md={6}>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <PersonRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Managed By"
              primaryTypographyProps={{
                color: "textSecondary",
              }}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {customer.firstName + " " + customer.lastName}
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <PanoramaFishEyeRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Gender"
              primaryTypographyProps={{
                color: "textSecondary",
              }}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {formatGender(customer.gender)}
                </Typography>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <PinDropRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Address"
              primaryTypographyProps={{
                color: "textSecondary",
              }}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {customer.address.formatted
                    ? customer.address.formatted
                    : formatAddress(customer.address)}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12} md={6}>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <PhoneRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Property Main Phone"
              primaryTypographyProps={{
                color: "textSecondary",
              }}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {customer.mainPhone}
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <PhoneOutlined color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Property Emergency Phone"
              primaryTypographyProps={{
                color: "textSecondary",
              }}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {customer.emergencyPhone
                    ? customer.emergencyPhone
                    : "Not specified"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <EmailRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Property Email Contact"
              primaryTypographyProps={{
                color: "textSecondary",
              }}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {customer.email ? customer.email : "Not specified"}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerDetailsSection;
