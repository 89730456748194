import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLazyGetValetPreCreationForEdit } from "../../../apollo/preCreations/queries";
import Link from "../../../navigation/Link";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";
import PageHeader from "../../../shared/components/PageHeader";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import ValetPreCreationModal from "./ValetPreCreationModal";
import ValetPreCreationsTable from "./ValetPreCreationsTable";

const ValetPreCreationsSection: React.FC = ({}) => {
  const [showModal, setShowModal] = useState(false);
  const [refetchToggle, setRefetchToggle] = useState(false);
  const { sendErrorMessage } = useSnackbar();
  const [editingPreCreationId, setEditingPreCreationId] = useState<
    string | null
  >(null);
  const {
    error,
    getPreCreation,
    loading,
    preCreation
  } = useLazyGetValetPreCreationForEdit();
  const onEditClick = (id: string) => {
    setEditingPreCreationId(id);
  };

  const onFormCompletionSuccess = () => {
    setEditingPreCreationId(null);
    setRefetchToggle(r => !r);
  };

  const onFormModalClose = () => {
    setShowModal(false);
    setEditingPreCreationId(null);
  };

  useEffect(() => {
    if (editingPreCreationId) {
      getPreCreation(editingPreCreationId);
    }
  }, [editingPreCreationId]);

  useDidMountEffect(() => {
    if (!loading && preCreation && editingPreCreationId) {
      setShowModal(true);
    }
  }, [loading, preCreation, editingPreCreationId]);

  useEffect(() => {
    if (error) {
      sendErrorMessage(error.message);
    }
  }, [error]);

  return (
    <Box marginTop={4}>
      {/* <LoadingBackdrop open={loading} /> */}
      <PageHeader
        title={"Pre-Registrations"}
        actions={
          <Link to="/dashboard/valets/pre-registration/form">
            <Button
              variant="contained"
              color="primary"
              // onClick={() => setShowModal(true)}
            >
              Send Valet Registration
            </Button>
          </Link>
        }
      />
      <ValetPreCreationsTable
        refetchDependencies={[refetchToggle]}
        // onEditClick={onEditClick}
      />
      {/* <ValetPreCreationModal
        editingPreCreation={editingPreCreationId ? preCreation : undefined}
        open={showModal}
        onClose={onFormModalClose}
        onSuccess={onFormCompletionSuccess}
      /> */}
    </Box>
  );
};

export default ValetPreCreationsSection;
