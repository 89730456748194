import React, { useEffect, useCallback } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";

type GenericItem = { id: string };

interface SearchBoxProps<T extends GenericItem> extends BaseSearchBoxProps<T> {
  query: DocumentNode;
  variable: string;
  variables?: object;
  labelKey: keyof T;
}

export interface BaseSearchBoxProps<T extends GenericItem> {
  onChange?: (event: React.ChangeEvent<any>) => void;
  onItemsLoad?: (items: T[]) => void;
  onOptionChange?: (item: T | null) => void;
  error?: string | null;
  name?: string;
  label?: string;
  value?: T | null | string;
}
const useSearchBox = <T extends GenericItem>(
  query: DocumentNode,
  variable: string,
  variables?: object,

  onChange?: (event: React.ChangeEvent<any>) => void,
  name?: string,
  onOptionChange?: (item: T | null) => void,
  onItemsLoad?: (items: T[]) => void
) => {
  const { data } = useQuery(query, {
    variables,
    onCompleted: data => {
      if (onItemsLoad) {
        onItemsLoad(data[variable]);
      }
    },
    fetchPolicy: "cache-and-network"
  });

  const onSearchChange = (e: React.ChangeEvent<{}>, item: T | null) => {
    if (onChange && name) {
      const synteticEvent = {
        ...e,
        target: {
          value: item?.id,
          name
        }
      };
      onChange(synteticEvent);
    }

    if (onOptionChange) {
      onOptionChange(item);
    }
  };

  const items: T[] | undefined = data ? data[variable] : undefined;
  return {
    model: {
      items
    },
    commands: {
      onSearchChange
    }
  };
};
const SearchBox = <T extends GenericItem>({
  onOptionChange,
  error,
  onChange,
  name,
  label,
  onItemsLoad,
  value,
  query,
  variable,
  labelKey,
  variables
}: SearchBoxProps<T>) => {
  const {
    model: { items },
    commands: { onSearchChange }
  } = useSearchBox<T>(
    query,
    variable,
    variables,
    onChange,
    name,
    onOptionChange,
    onItemsLoad
  );

  const getValue = () => {
    if (typeof value === "string") {
      const item = items?.find(item => item.id === value);
      return item ? item : null;
    }
    return value;
  };
  return (
    <Autocomplete
      // id="combo-box-demo"
      // disableClearable={true}
      options={items ? items : []}
      getOptionLabel={option => String(option[labelKey])}
      onChange={onSearchChange}
      //   groupBy={(st) => (st.tenant ? st.tenant.name : "")}
      value={getValue()}
      fullWidth
      getOptionSelected={(option, value) => {
        if (!value) return false;
        return option.id === value.id;
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label ? label : "Items"}
          variant="outlined"
          error={!!error}
          helperText={error}
          fullWidth
        />
      )}
    />
  );
};

export default SearchBox;
