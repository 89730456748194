import { gql, useMutation } from "@apollo/client";
import { Valet } from "../valets/interfaces";
import {
  SelfValetCreation,
  ValetAgreementSelfSign,
  ValetPreCreation,
  ValetPreCreationStart
} from "./interfaces";

const GENERATE_VALE_PRE_CREATION = gql`
  mutation generateValetPreCreation($data: ValetPreCreationStartDTO!) {
    startValetPreCreation(data: $data) {
      id
    }
  }
`;

export const useGenerateValetPreCreation = () => {
  const [_submit, { loading, data, error }] = useMutation<
    { startValetPreCreation: ValetPreCreation },
    { data: ValetPreCreationStart }
  >(GENERATE_VALE_PRE_CREATION);

  const submit = (data: ValetPreCreationStart) => {
    _submit({ variables: { data } });
  };
  return {
    submit,
    loading,
    generatedPreCreation: data?.startValetPreCreation,
    error
  };
};

const SELF_CREATE_VALET = gql`
  mutation selfCreateValet($data: SelfValetCreationDTO!) {
    selfCreateValet(data: $data) {
      id
    }
  }
`;
export const useSelfCreateValet = () => {
  const [_create, { loading, error, data }] = useMutation<
    { selfCreateValet: Valet },
    { data: SelfValetCreation }
  >(SELF_CREATE_VALET);
  const create = (data: SelfValetCreation) => {
    _create({ variables: { data } });
  };

  return {
    create,
    loading,
    createdValet: data?.selfCreateValet,
    error
  };
};

const SELF_SIGN_VALET_AGREEMENT = gql`
  mutation selfSignValetAgreement($data: ValetAgreementSelfSignDTO!) {
    selfSignValetAgreement(data: $data)
  }
`;

export const useSelfSignValetAgreement = () => {
  const [_sign, { data, loading, error }] = useMutation<
    { selfSignValetAgreement: boolean },
    { data: ValetAgreementSelfSign }
  >(SELF_SIGN_VALET_AGREEMENT);

  const sign = (data: ValetAgreementSelfSign) => {
    _sign({ variables: { data } });
  };

  return {
    sign,
    success: data?.selfSignValetAgreement,
    loading,
    error
  };
};
