import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Typography,
  Divider,
  Box
} from "@material-ui/core";
import { Field, FormikErrors, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import { FormValues } from "./interfaces";
import {
  ValetAgreement,
  ValetContractStatus,
  ValetContractType
} from "../../../apollo/valetAgreements/interface";
import { Valet } from "../../../apollo/valets/interfaces";
import {
  ServiceRoute,
  WeekDay
} from "../../../apollo/serviceRoutes/interfaces";
import ServiceRouteSearchBox from "../../Tenants/components/ServiceRouteSearchbox";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import { SubTenant } from "../../../apollo/tenants/interfaces";
import RouteSelectModal from "../../ServiceRoutes/components/RoutesSelectModal";
import NewRouteSelectModal from "../../ServiceRoutes/components/NewRoutesSelectModal";
import useAbility from "../../../shared/hooks/useAbility";
import { useMemo } from "react";
import WorkingDayFields from "../../../shared/components/WorkingDaysField";

export interface TypeSelectionFormValues {
  id?: string;
  type: ValetContractType;
  contractorId: string;
  status?: ValetContractStatus;
  routeId?: string;
  subTenantId?: string;
  workingDays?: WeekDay[];
  requiredWhenever: boolean;
}

export const getTypeSelectionFormInitialValues = (
  contractorId: string,
  agreement?: ValetAgreement,
  valet?: Valet
): TypeSelectionFormValues => {
  return {
    id: agreement?.id,
    type: agreement ? agreement.type : "REGULAR",
    contractorId,
    status: agreement ? agreement.status : "INACTIVE",
    routeId:
      agreement && agreement.metadata.routeId ? agreement.metadata.routeId : "",
    subTenantId: valet ? valet.subTenantId : "",
    requiredWhenever: false,
    workingDays:
      agreement?.type === "COVER" ? agreement.metadata.workingDays : []
  };
};

export const getTypeSelectionValidationSchema = () => {
  const requiredField = "This field is required.";

  return Yup.object<TypeSelectionFormValues>({
    contractorId: Yup.string().required(requiredField),
    type: Yup.string()
      .oneOf(["REGULAR", "COVER"])
      .nullable()
      .required("You must specify an agreement type."),
    routeId: Yup.string().when("type", {
      is: "REGULAR",
      then: Yup.string().required(
        "You must specify a route for this type of agreement."
      ),
      otherwise: Yup.string().notRequired()
    }),
    workingDays: Yup.array<WeekDay>().when("type", {
      is: "COVER",
      then: Yup.array().required(
        "You must specify the days this valet will cover."
      ),
      otherwise: Yup.array().notRequired()
    }),
    requiredWhenever: Yup.boolean().required(),
    subTenantId: Yup.string()
      .nullable()
      .required("")
  });
};

interface TypeSelectionStepProps {
  valet?: Valet;
  route?: ServiceRoute;
  onTypeChange: (type: ValetContractType) => void;
  currentType: ValetContractType;
  errors?: FormikErrors<TypeSelectionFormValues>;
  onRouteChange?: (route: ServiceRoute | null) => void;
  onSubTenantChange?: (subTenant: SubTenant) => void;
}

const useTypeSelectionStep = (
  onTypeChange: (type: ValetContractType) => void,
  currentType: ValetContractType,
  onRouteChange: (route: ServiceRoute | null) => void,
  onSubTenantChange?: (subTenant: SubTenant | null) => void,
  route?: ServiceRoute | null,
  subTenant?: SubTenant | null
) => {
  const { values, touched, setValues } = useFormikContext<FormValues>();

  useEffect(() => {
    if (currentType !== values.type) {
      onTypeChange(values.type);
      setValues({
        ...values
      });
    }

    return () => {};
  }, [touched.type]);

  // useEffect(() => {
  //   if (route?.id !== values.routeId) {
  //     setValues({
  //       ...values,
  //       routeId: route?.id || ""
  //     });
  //   }
  //   return () => {};
  // }, [route]);

  useEffect(() => {
    if (subTenant?.id !== values.subTenantId) {
      setValues({
        ...values,
        subTenantId: subTenant?.id || ""
      });
    }
    return () => {};
  }, [subTenant]);

  // useEffect(() => {
  //   if (coverValet?.id !== values.subTenantId) {
  //     setValues({
  //       ...values,
  //       subTenantId: subTenant?.id || ""
  //     });
  //   }
  //   return () => {
  //   };
  // }, [subTenant]);
  return { values };
};
const TypeSelectionStep: React.FC<TypeSelectionStepProps> = ({
  valet,
  onTypeChange,
  currentType,
  errors,
  onRouteChange,
  onSubTenantChange
  // onCoverValetChange
}) => {
  const [route, setRoute] = useState<ServiceRoute | null>(null);
  const [subTenant, setSubTenant] = useState<SubTenant | null>(null);
  const handleSubTenantChange = (subTenant: SubTenant | null) => {
    setSubTenant(subTenant);
    if (onSubTenantChange && subTenant) {
      onSubTenantChange(subTenant);
    }
  };
  const handleRouteChange = (serviceRoute: ServiceRoute | null) => {
    setRoute(serviceRoute);
    if (onRouteChange) {
      onRouteChange(serviceRoute);
    }
  };
  const { values } = useTypeSelectionStep(
    onTypeChange,
    currentType,
    handleRouteChange,
    handleSubTenantChange,
    route,
    subTenant
  );

  const ability = useAbility();

  const isAdmin = useMemo(() => ability.can("manage", "SubTenants"), [ability]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box marginTop={3}>
          <Typography color="textSecondary">Valet</Typography>
          <Typography color="textPrimary" variant="h6" gutterBottom>
            {valet ? valet.user.firstName + " " + valet.user.lastName : ""}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Agreement Type</FormLabel>
          <Field
            component={RadioGroup}
            variant="outlined"
            name="type"
            label="Agreement Type"
            row
          >
            <FormControlLabel
              value="REGULAR"
              control={<Radio />}
              label="Regular"
            />
            <FormControlLabel value="COVER" control={<Radio />} label="Cover" />
          </Field>
        </FormControl>
      </Grid>

      {values.type === "REGULAR" ? (
        <>
          {isAdmin && (
            <Grid item xs={12}>
              <Typography>
                Only routes tied to the valet's sub-franchise will be shown.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <NewRouteSelectModal
              name="routeId"
              label="Service Route"
              fullWidth
              variant="outlined"
              showAvailableRoutesOnly={true}
              subTenantId={values?.subTenantId}
              // disabled={!values.subTenantId}
            />
            {/* <ServiceRouteSearchBox
          value={values?.routeId}
          error={errors?.routeId}
          subFranchiseId={subTenant?.id}
          onOptionChange={handleRouteChange}
          label="Select service route"
        /> */}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Field
              label="Days Required"
              component={WorkingDayFields}
              name="workingDays"
            />
          </Grid>
        </>
      )}

      {/* 
      <Grid item xs={12}>
        <Divider style={{ marginBottom: 10 }} />
      </Grid> */}
    </Grid>
  );
};

export default TypeSelectionStep;
