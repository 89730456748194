import React, { useEffect } from "react";
import {
  CustomerContractType,
  CustomerContractStatus,
  CustomerAgreement,
} from "../../../apollo/agreements/interface";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  ListItem,
  FormControl,
  FormLabel,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Box,
  RadioProps,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import { Customer, Property } from "../../../apollo/customers/interfaces";
import { FormValues } from "./interfaces";
import { getUnitInitialValues } from "./utils";

export interface TypeSelectionFormValues {
  id?: string;
  type: CustomerContractType;
  propertyId: string;
  status: CustomerContractStatus;
  supervisorId?: string;
}

export const getTypeSelectionFormInitialValues = (
  propertyId: string,
  agreement?: CustomerAgreement
): TypeSelectionFormValues => {
  return {
    id: agreement?.id,
    type: agreement ? agreement.type : "FULL",
    propertyId,
    status: agreement ? agreement.status : "SUSPENDED",
    supervisorId: agreement?.supervisorId
  };
};

export const getTypeSelectionValidationSchema = () => {
  const requiredField = "This field is required.";

  return Yup.object<TypeSelectionFormValues>({
    propertyId: Yup.string().required(requiredField),
    status: Yup.mixed<CustomerContractStatus>()
      .oneOf(["INACTIVE", "SUSPENDED", "ACTIVE"])
      .required(requiredField),
    type: Yup.string()
      .oneOf(["FULL", "PHASE-IN", "LEASE-UP", "STEP-UP"])
      .nullable()
      .required("You must specify an agreement type."),
  });
};

interface TypeSelectionStepProps {
  property?: Property;
  onTypeChange: (type: CustomerContractType) => void;
  currentType: CustomerContractType;
}
const useTypeSelectionStep = (
  onTypeChange: (type: CustomerContractType) => void,
  currentType: CustomerContractType,
  property?: Property
) => {
  const { values, touched, setValues } = useFormikContext<FormValues>();
  useEffect(() => {
    if (currentType !== values.type) {
      onTypeChange(values.type);
      setValues({
        ...values,
        ...getUnitInitialValues(values.type, property?.agreement),
      });
    }

    return () => {};
  }, [values.type, touched.type]);
  return {};
};
const TypeSelectionStep: React.FC<TypeSelectionStepProps> = ({
  property,
  onTypeChange,
  currentType,
}) => {
  const {} = useTypeSelectionStep(onTypeChange, currentType, property);
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={6}>
        <Box>
          <Typography color="textSecondary">Customer</Typography>
          <Typography color="textPrimary" variant="h6" gutterBottom>
            {property ? property.name : ""}
          </Typography>
        </Box>
      </Grid> */}

      <Grid item xs={12} md={6}>
        <Box>
          <Typography color="textSecondary">Property Name</Typography>
          <Typography color="textPrimary" variant="h6" gutterBottom>
            {property ? property.name : ""}
          </Typography>
        </Box>

        <Box marginTop={2.5}>
          <Typography color="textSecondary">Property Address</Typography>
          <Typography color="textPrimary" variant="h6" gutterBottom>
            {property ? property.address.formatted : ""}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {" "}
        <Divider style={{ marginBottom: 10 }} />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Agreement Type</FormLabel>
          <Field
            component={RadioGroup}
            variant="outlined"
            name="type"
            label="Agreement Type"
            row
          >
            <FormControlLabel value="FULL" control={<Radio />} label="Full" />
            <FormControlLabel
              value="PHASE-IN"
              control={<Radio />}
              label="Phase In"
            />
            <FormControlLabel
              value="STEP-UP"
              control={<Radio />}
              label="Step-Up"
            />
            <FormControlLabel
              value="LEASE-UP"
              control={<Radio />}
              label="Lease-Up"
            />
          </Field>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TypeSelectionStep;
