import React from "react";
import { Container, Button, Box } from "@material-ui/core";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import IncidentsTable from "./components/IncidentsTable";
import useAbility from "../../shared/hooks/useAbility";
import IncidentList from "./components/IncidentList";

const IncidentsListPage: React.FC = ({}) => {
  const ability = useAbility();
  return (
    <Container style={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Incidents" },
        ]}
      />

      <PageHeader
        title={"Incidents"}
        actions={
          <>
            {ability.can("read", "Incidents") && (
              <Link to="/dashboard/incidents/categories">
                <Button variant="contained" color="primary">
                  View Categories
                </Button>
              </Link>
            )}
          </>
        }
      />

      {/* <IncidentsTable /> */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        
      }}>

      <IncidentList/>
      </div>
    </Container>
  );
};

export default IncidentsListPage;
