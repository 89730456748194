import { AddressFormValues } from "../../shared/components/AddressForm/interfaces";
import { useState, useEffect, useMemo } from "react";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { useHistory, useParams } from "react-router-dom";
import { User, CreateUser, UserForForm } from "../../apollo/users/interfaces";
import { ApolloError } from "@apollo/client";

import { useFormik } from "formik";
import { getAddressFormValidationSchema } from "../../shared/components/AddressForm";
import { BreadcrumbSection } from "../../shared/components/BreadcrumbsSection";
import useAbility from "../../shared/hooks/useAbility";
import { UserFormValues } from "../Users/components/interfaces";
import { getUserFormValidationSchema } from "../Users/components/UserForm";
import { useGetValetUserLazy } from "../../apollo/valets/queries";
import { useCreateUpdateValet } from "../../apollo/valets/mutations";
import { Valet } from "../../apollo/valets/interfaces";
import { useCheckUsername } from "../../apollo/users/queries";

const initialUserFormValues: UserFormValues = {
  subTenantId: "",
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  password: "",
  roleId: ""
};

const initialAddressFormValues: AddressFormValues = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
  latitude: null,
  longitude: null
};
const useValetsFormPage = (isSubForm?: boolean,
                           onFormReady?: (valet: { user: UserFormValues, address: AddressFormValues }) => void
) =>
{
  const [activeStep, setActiveStep] = useState(0);
  const { sendSuccessMessage, sendErrorMessage } = useSnackbar();
  const history = useHistory();
  const { valetId } = useParams<{ valetId: string }>();
  const isEdit = !!valetId && !isSubForm;
  const ability = useAbility();
  //CallBacks & Actions

  const onUserLoadSuccess = (user: UserForForm) => {
    const { address, roles, ...userData } = user;
    userForm.setValues({ ...userData, roleId: roles[0].id, password: "" });
    if (address) addressForm.setValues(address);
  };

  const onUserLoadError = (error: ApolloError) => {
    sendErrorMessage("There was an error loading the valet's data.");
    console.warn(error);
  };

  const onSubmitSuccess = (valet: Valet) => {
    if (isSubForm) {
      return;
    }
    sendSuccessMessage(
      isEdit ? "Valet updated successfully" : "Valet created successfully."
    );
    const valetId = isEdit ? valet.id : valet.id;

    history.replace("/dashboard/valets/" + valetId);
  };

  const onSubmitError = (error: ApolloError) => {
    sendErrorMessage(error.message);
  };

  const onUserFormSubmit = (values: UserFormValues) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const onAddressFormSubmit = (values: AddressFormValues) => {
    const { roleId, ...user } = userForm.values;
    const address: AddressFormValues = addressForm.values;
    if (isSubForm) {
      if (onFormReady) {
        onFormReady({user: userForm.values, address})
      }
      return;
    }
    submitValet({
      variables: {
        valet: {
          ...user,
          roles: roleId ? [roleId] : [],
          address
        }

      }
    });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      userForm.submitForm();
    }

    if (activeStep === 1) {
      addressForm.submitForm();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //CallBacks & Actions

  //Apollo hooks

  const { submitValet, isSubmitingValet } = useCreateUpdateValet(onSubmitSuccess, onSubmitError, isEdit);
  const { getUser, user, isGettingUser } = useGetValetUserLazy(valetId, onUserLoadSuccess, onUserLoadError);
  const checkUsername = useCheckUsername()
  //Apollo hooks

  //Formik Hooks
  const userForm = useFormik<UserFormValues>({
    initialValues: initialUserFormValues,
    onSubmit: onUserFormSubmit,
    validationSchema: getUserFormValidationSchema(
      isEdit,
      checkUsername,
      ability.can("manage", "SubTenants"),
      !isEdit,
      false
    ),
    validateOnChange: false
  });

  const addressForm = useFormik<AddressFormValues>({
    initialValues: initialAddressFormValues,
    onSubmit: onAddressFormSubmit,
    validationSchema: getAddressFormValidationSchema(false),
    validateOnChange: false
  });
  //Formik Hooks

  //Effects
  useEffect(() => {
    if (isEdit) {
      getUser();
    }
  }, []);
  //Effects

  const sections = useMemo<BreadcrumbSection[]>(
    () => getLinkSections(valetId, user),
    [user]
  );

  return {
    model: {
      activeStep,
      userForm,
      addressForm,
      processLoading: isSubmitingValet || isGettingUser,
      isEdit,
      sections,
      ability
    },
    commands: {
      handleBack,
      handleNext
    }
  };
}
;

const getLinkSections = (valetId: string, user?: UserForForm) => {
  const sections: BreadcrumbSection[] = [
    { label: "Home", link: "/dashboard" },
    { label: "Valets", link: "/dashboard/valets" }
  ];

  if (user) {
    sections.push({ label: user.username, link: "/dashboard/valets" + valetId });
  }
  sections.push({ label: "Valet Form" });

  return sections;
};
export default useValetsFormPage;
