import React from "react";
import { Grid } from "@material-ui/core";

import CalculatedField from "../../../shared/components/CalculatedField";
import UnitBaseFields, {
  UnitsBaseFormValues,
  getUnitBaseValidationSchema,
  getUnitBaseInitialValues,
} from "./UnitBaseFields";

import * as Yup from "yup";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { CustomerAgreement } from "../../../apollo/agreements/interface";
export interface UnitsLeaseUpStepFormValues extends UnitsBaseFormValues {
  metadata: {
    maximumMonthlyCharge: number;
    maximumContainerUnits: number;
    minimumMonthlyCharge: number;
  };
}

interface BaseUnitsLeaseUpStepFormValues {
  metadata: {
    maximumMonthlyCharge: number;
    maximumContainerUnits: number;
    minimumMonthlyCharge: number;
  };
}

export const getLeaseUpStepValidationSchema = () => {
  return getUnitBaseValidationSchema().shape<BaseUnitsLeaseUpStepFormValues>({
    metadata: Yup.object({
      maximumMonthlyCharge: Yup.number()
        .moreThan(0, "You must specify a higher value.")
        .required("This field is required."),
      maximumContainerUnits: Yup.number()
        .integer("This value must be an integer.")
        .moreThan(0, "You must specify a higher value.")
        .required("This field is required."),
      minimumMonthlyCharge: Yup.number()
        .moreThan(0, "You must specify a higher value.")
        .lessThan(
          Yup.ref("maximumMonthlyCharge"),
          "This value must be less than the maximum monthly charge."
        )
        .required("This field is required."),
    }).required(),
  });
};

export const getLeaseUpStepInitialValues = (
  agreement?: CustomerAgreement
): UnitsLeaseUpStepFormValues => {
  return {
    ...getUnitBaseInitialValues(agreement),
    metadata: {
      maximumMonthlyCharge: agreement?.metadata?.maximumMonthlyCharge
        ? agreement.metadata.maximumMonthlyCharge
        : 0,
      maximumContainerUnits: agreement?.metadata?.maximumContainerUnits
        ? agreement.metadata.maximumContainerUnits
        : 0,
      minimumMonthlyCharge: agreement?.metadata?.minimumMonthlyCharge
        ? agreement.metadata.minimumMonthlyCharge
        : 0,
    },
  };
};

const UnitLeaseUpStep: React.FC = () => {
  return (
    <UnitBaseFields>
      <Grid item xs={12} md={6}>
        <CalculatedField<UnitsLeaseUpStepFormValues>
          firstValue="aptUnitsCharged"
          secondValue="aptUnitPrice"
          operation={(aptUnitsCharged: number, aptUnitPrice: number) => {
            return aptUnitsCharged * aptUnitPrice;
          }}
          inputProps={{
            readOnly: true,
          }}
          type="number"
          variant="outlined"
          name="metadata.maximumMonthlyCharge"
          fullWidth
          label="Maximum Monthly Charge"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          variant="outlined"
          name="metadata.maximumContainerUnits"
          fullWidth
          label="Maximum Container Units"
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          variant="outlined"
          name="metadata.minimumMonthlyCharge"
          fullWidth
          label="Minimum Monthly Charge"
          type="number"
        />
      </Grid>
    </UnitBaseFields>
  );
};

export default UnitLeaseUpStep;
