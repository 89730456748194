import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  FormControlLabel,
  MenuItem
} from "@material-ui/core";
import { Field, useFormikContext, FieldArray } from "formik";
import {
  SpecialRouteFormValues,
  RouteArea,
  RouteAreaPoint,
  SpecialServiceMakeUpReason,
  WeekDay
} from "./interfaces";
import {
  TextField,
  Checkbox,
  Switch,
  CheckboxWithLabel,
  Select
} from "formik-material-ui";
import { DatePicker, TimePicker } from "formik-material-ui-pickers";
import { WorkingDay } from "../../Agreements/CustomerSteps/interfaces";
import { ColorPickerField } from "../../../shared/components/ColorPicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import WorkingDayFields from "../../../shared/components/WorkingDaysField";
import DrawingMap from "../../../shared/components/Map/DrawingMap";
import * as Yup from "yup";
import { Moment } from "moment";
import { ErrorOutlineSharp, RefreshRounded } from "@material-ui/icons";
import {
  useGetServiceRouteCustomerDataLazy,
  useGetServiceRouteAgreementDataLazy,
  useIsRouteCodeAvailable,
  useGetServiceRouteForEdit,
  useGetServiceRoutePropertyDataLazy
} from "../../../apollo/serviceRoutes/queries";
import { property } from "lodash";
import useUrlQuery from "../../../shared/hooks/useUrlQuery";
import LoadingPage from "../../Home/LoadingPage";
import { MapApiWrapper } from "../../../shared/components/Map";
import moment from "moment";
import _ from "lodash";
import { getAreasFromRoutes } from "../utils";
import {
  isSpecialRoute,
  ServiceRoute
} from "../../../apollo/serviceRoutes/interfaces";
import { useParams } from "react-router-dom";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import { PropertySelectItem } from "../../../apollo/customers/interfaces";
import PropertySelectModal from "../../Customers/components/PropertySelectModal";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export const getSpecialRouteFormInitialValues = (
  route?: ServiceRoute
): SpecialRouteFormValues => {
  const extraData =
    route && isSpecialRoute(route.extraData) ? route.extraData : undefined;

  return {
    id: route?.id,
    routeCode: route ? route.routeCode : "",
    areas: route ? route.areas : [],
    canBeWorkedOn: route ? !!route.canBeWorkedOn : false,
    monthlyRoutePay: route ? route.monthlyRoutePay : 0.0,
    dailyRoutePay: route ? route.dailyRoutePay : 0,
    startTime: route ? moment(route.startTime) : moment('08:00 PM', 'hh:mm A'),
    offPropertyTime: route ? moment(route.offPropertyTime) : null,
    routeColor: route ? route.routeColor : "red",
    propertyId: route ? route.propertyId : "",
    isMakeUp: extraData ? !!extraData.isMakeUp : false,
    makeUpReason: extraData ? extraData.makeUpReason : "RESCHEDULE",
    recurrentDay:
      extraData && extraData.recurrentDays?.length
        ? (extraData.recurrentDays[0] as WeekDay)
        : null,
    scheduledDates: extraData
      ? extraData.scheduledDates.map(d => moment(d.date))
      : [moment()],
    isRecurrent: extraData ? extraData.isRecurrent : false,
    property: null
  };
};

export const getSpecialRouteFormValidationSchema = () => {
  return Yup.object<SpecialRouteFormValues>({
    areas: Yup.array(
      Yup.object<RouteArea>({
        name: Yup.string().required("This field is required"),
        points: Yup.array(
          Yup.object<RouteAreaPoint>({
            latitude: Yup.number().required(),
            longitude: Yup.number().required()
          }).required()
        ).required()
      }).required()
    )
      .min(1, "You must specify at least one area.")
      .required(),
    routeCode: Yup.string().required("You must specify a route code."),
    canBeWorkedOn: Yup.boolean().required(),

    startTime: Yup.object<Moment>()
      .nullable()
      .required(),
    offPropertyTime: Yup.object<Moment>()
      .nullable()
      .required(),
    routeColor: Yup.string().required(),
    isRecurrent: Yup.boolean().required(),

    recurrentDay: Yup.mixed<WeekDay>()

      .nullable()
      .when("isRecurrent", {
        is: true,
        then: Yup.mixed<WeekDay>().required("You must specify a day."),
        otherwise: Yup.mixed<WeekDay>().notRequired()
      }),
    dailyRoutePay: Yup.number().required("You must specify a daily rate."),
    monthlyRoutePay: Yup.number().required(),
    scheduledDates: Yup.array<Moment>()
      .when("isRecurrent", {
        is: false,
        then: Yup.array<Moment>().min(1, "You must add at least one date"),
        otherwise: Yup.array<Moment>().notRequired()
      })
      .required(),

    isMakeUp: Yup.bool().required(),

    makeUpReason: Yup.mixed<SpecialServiceMakeUpReason>()
      .when("isMakeUp", {
        is: true,
        then: Yup.mixed<SpecialServiceMakeUpReason>().required(),
        when: Yup.mixed<SpecialServiceMakeUpReason>().notRequired()
      })
      .nullable(),

    propertyId: Yup.string().required(),
    property: Yup.object<PropertySelectItem>().test(
      "check if property is selected",
      "You must specify a propery.",
      function() {
        const { parent } = this;
        const { propertyId } = parent as SpecialRouteFormValues;

        return !!propertyId;
      }
    ).nullable()
  });
};

const workingDays: WorkingDay[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const useSpecialRouteForm = () => {
  const { routeId } = useParams<{ routeId: string }>();
  const isEdit = !!routeId;
  const [isWeekly, setIsWeekly] = useState(true);
  const { sendErrorMessage } = useSnackbar();
  const [propertyId, setPropertyId] = useState(useUrlQuery().get("propertyId"));
  const isRouteCodeAvailable = useIsRouteCodeAvailable();
  const onFormSubmit = (values: SpecialRouteFormValues) => {};
  const { values, errors, setFieldValue, setValues } = useFormikContext<
    SpecialRouteFormValues
  >();

  const {
    getRoute,
    route,
    routeLoading,
    routeLoadingError
  } = useGetServiceRouteForEdit();

  const {
    getProperty,
    property,
    loadingProperty
  } = useGetServiceRoutePropertyDataLazy();

  const {
    getAgreement: _getAgreement,
    agreement,
    loadingAgreement,
    loadingAgreementError
  } = useGetServiceRouteAgreementDataLazy();

  const getAgreement = () => {
    if (values.property) _getAgreement(values.property.id);
    else if (values.propertyId) _getAgreement(values.propertyId);
  };

  const validateCode = async (value: string) => {
    if (!property || !value) return;
    console.log(routeId);
    const isAvailable = await isRouteCodeAvailable(value, property.id, routeId);
    if (isAvailable) return;

    return "This route code is being used by another route inside this property.";
  };

  useEffect(() => {
    if (routeId) {
      getRoute(routeId);
    }
  }, [routeId]);

  useEffect(() => {
    if (route) {
      setValues(getSpecialRouteFormInitialValues(route));
      if (route.property) setPropertyId(route.property.id);
    }
  }, [route]);

  useEffect(() => {
    if (routeLoadingError) {
      sendErrorMessage(
        "Error loading the route's info: " + routeLoadingError.message
      );
    }
  }, [routeLoadingError]);

  console.log({errors})

  useEffect(() => {
    if (propertyId) {
      getProperty(propertyId);
    } else if (values.propertyId) {
      getProperty(values.propertyId);
    }
  }, [propertyId, values.propertyId]);

  useEffect(() => {
    if (property && !isEdit) {
      setFieldValue("propertyId", property.id);
    }
  }, [property]);

  useEffect(() => {
    setValues({ ...values, isMakeUp: false });
  }, [values.isRecurrent]);

  useEffect(() => {
    if (values.property) setFieldValue("propertyId", values.property.id);
  }, [values.property]);

  useEffect(() => {
    if (agreement) {
      //   setValues({
      //     ...values,
      //     workingDays: agreement.workingDays,
      //     startTime: moment(agreement.serviceStartTime),
      //     offPropertyTime: moment(agreement.offPropertyTime)
      //   });
    }
  }, [agreement]);
  return {
    model: {
      property,
      values,
      errors,
      loadingProperty,
      loadingAgreement,
      isWeekly,
      isEdit,
      routeId,
      disableAgreementButton: !values.propertyId,
      fromProperty: !!propertyId
    },
    commands: {
      getAgreement,
      onFormSubmit,
      validateCode
    }
  };
};

const SpecialRouteForm: React.FC = () => {
  const {
    model: {
      values,
      errors,
      property,
      loadingProperty,
      loadingAgreement,
      isWeekly,
      routeId,
      isEdit,
      disableAgreementButton,
      fromProperty
    },
    commands: { onFormSubmit, getAgreement, validateCode }
  } = useSpecialRouteForm();

  const now = useMemo(() => moment(), []);

  const existingAreas = useMemo(() => {
    if (property) {
      return getAreasFromRoutes(property.serviceRoutes, routeId);
    }
    return [];
  }, [property]);

  const propertyCenter =
    property?.address.latitude && property?.address.longitude
      ? {
          lat: property.address.latitude,
          lng: property.address.longitude
        }
      : undefined;
  return (
    <MapApiWrapper>
      {loadingProperty && !property ? (
        <LoadingPage />
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={4}>
              <Box>
                <Typography color="textSecondary">Customer</Typography>
                <Typography color="textPrimary" variant="h6" gutterBottom>
                  {customer ? customer.firstName + " " + customer.lastName : ""}
                </Typography>
              </Box>
            </Grid> */}

            {fromProperty && (
              <>
                {" "}
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography color="textSecondary">Property Name</Typography>
                    <Typography color="textPrimary" variant="h6" gutterBottom>
                      {property ? property.name : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography color="textSecondary">
                      Property Address
                    </Typography>
                    <Typography color="textPrimary" variant="h6" gutterBottom>
                      {property ? property.address.formatted : ""}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            {!fromProperty && !isEdit && (
              <Grid item xs={12}>
                <PropertySelectModal
                  name="property"
                  fullWidth
                  variant="outlined"
                  label="Property"
                />
              </Grid>
            )}

            {/* <Grid item xs={12}>
              <Button
                endIcon={
                  loadingAgreement ? (
                    <CircularProgress size={24} />
                  ) : (
                    <RefreshRounded />
                  )
                }
                color="primary"
                disabled={loadingAgreement}
                onClick={() => getAgreement()}
              >
                Load information from agreement
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Field
                validate={validateCode}
                fullWidth
                component={TextField}
                variant="outlined"
                name="routeCode"
                label="Route Code"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                component={TimePicker}
                inputVariant="outlined"
                variant="inline"
                name="startTime"
                label="Start Time"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                component={TimePicker}
                inputVariant="outlined"
                variant="inline"
                name="offPropertyTime"
                label="Off Property Time"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                variant="outlined"
                name="dailyRoutePay"
                label="Daily Pay Rate"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Field
                    component={Switch}
                    type="checkbox"
                    name="isRecurrent"
                  />
                }
                label="Is recurrent?"
              />
            </Grid>

            {values.isRecurrent ? (
              <Grid item xs={12}>
                <Field
                  label="Working Day"
                  select
                  component={TextField}
                  name="recurrentDay"
                  variant="outlined"
                  fullWidth
                >
                  {days.map(day => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Field
                    Label={{
                      label: "is it a make up service?",
                      labelPlacement: "end"
                    }}
                    component={CheckboxWithLabel}
                    name="isMakeUp"
                    type="checkbox"
                  />
                </Grid>
                {values.isMakeUp && (
                  <Grid item xs={12}>
                    <Field
                      label="Reason"
                      select
                      component={TextField}
                      name="makeUpReason"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value={"HOLIDAY"}>Holiday</MenuItem>
                      <MenuItem value={"RESCHEDULE"}>Reschedule</MenuItem>
                      <MenuItem value={"BAD_WEATHER"}>Bad Weather</MenuItem>
                      <MenuItem value={"OTHER"}>Other</MenuItem>
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FieldArray
                    name="scheduledDates"
                    render={arrayHelpers => (
                      <div>
                        {values.scheduledDates.map((friend, index) => (
                          <Grid
                            container
                            key={index}
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item sm={10} xs={8}>
                              <Field
                                inputVariant="outlined"
                                fullWidth
                                name={`scheduledDates.${index}`}
                                component={DatePicker}
                                minDate={isEdit ? undefined : now}
                              />
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              xs={2}
                              container
                              alignItems="center"
                              justify="center"
                            >
                              <Button
                                disabled={values.scheduledDates.length === 1}
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                -
                              </Button>
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              xs={2}
                              container
                              alignItems="center"
                              justify="center"
                            >
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.insert(index, moment())
                                } // insert an empty string at a position
                              >
                                +
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    )}
                  />
                </Grid>
              </>
            )}
            {/* <Grid item xs={12}>
              <Field
                Label={{ label: "Can be worked on?", labelPlacement: "end" }}
                component={CheckboxWithLabel}
                name="canBeWorkedOn"
                type="checkbox"
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Route areas</Typography>
            <Typography gutterBottom>
              Here you must outline the areas on the map by creating a polygon.
              You can delete an area by right clicking.
            </Typography>
            <Grid item xs={12}>
              <Field
                component={ColorPickerField}
                name="routeColor"
                label="Route Color"
              />
              <FieldArray
                name="areas"
                render={arrayHelpers => (
                  <>
                    <div style={{ height: "500px" }}>
                      <DrawingMap
                        displayOnlyAreas={existingAreas}
                        markers={
                          propertyCenter
                            ? [{ ...propertyCenter, label: "Property" }]
                            : undefined
                        }
                        initialCenter={propertyCenter}
                        initialZoom={
                          property?.address.latitude ? 20 : undefined
                        }
                        areas={values.areas}
                        onAreaAdded={area => arrayHelpers.push(area)}
                        color={values.routeColor}
                        onAreaRemoved={i => arrayHelpers.remove(i)}
                      />
                    </div>
                    {errors.areas && typeof errors.areas === "string" && (
                      <Typography variant="caption" color="error">
                        {errors.areas}
                      </Typography>
                    )}
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                      {values.areas.map((area, i) => (
                        <Grid item xs={12} key={i}>
                          <Typography gutterBottom>Area {i + 1} </Typography>
                          <Field
                            variant={"outlined"}
                            fullWidth
                            component={TextField}
                            label="Area Name"
                            name={`areas[${i}].name`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid>
        </MuiPickersUtilsProvider>
      )}
    </MapApiWrapper>
  );
};

export default SpecialRouteForm;
