import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import { BlockOutlined } from "@material-ui/icons";

const ForbiddenPage: React.FC = () => {
  return (
    <Container style={{ height: "100%", paddingTop: 100 }}>
      <Grid
        container

        // alignItems="flex-start"
        // justify="flex-start"
      >
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h3" gutterBottom>
            Forbidden
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <BlockOutlined color="error" style={{ height: 100, width: 100 }} />
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h6" align="center">
            You are not allowed to access this page.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForbiddenPage;
