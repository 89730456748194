import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { SupplyItem, SupplyRequest } from "./interfaces";

const GET_SUPPLY_ITEMS_PAGINATED = gql`
  query getSupplyItemsPaginated {
    supplyRequestItemsPaginated {
      cursor
      items {
        id
        tenantId
        image {
          id
          url
        }
        name
        varieties
        description
      }
    }
  }
`;

export const useGetSupplyItemsPaginated = (tenantId?: string) => {
  const [get, { data, error, loading, fetchMore, refetch }] = useLazyQuery<
    {
      supplyRequestItemsPaginated: {
        cursor: string | null;
        items: SupplyItem[];
      };
    },
    { tenantId?: string }
  >(GET_SUPPLY_ITEMS_PAGINATED);

  const getMore = () => {};

  useEffect(() => {
    get({ variables: { tenantId } });
  }, [tenantId]);

  return {
    refetch,
    items: data?.supplyRequestItemsPaginated.items,
    getMore,
    itemsLoading: loading,
    itemsError: error
  };
};

export const GET_SUPPLY_REQUESTS_TABLE = gql`
  query getSupplyRequestsTable(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $subTenantId: String
    $search: String # $subTenantId: String
  ) {
    supplyRequestsTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId
    ) {
      nodes {
        createdAt
        valet
        valetId
        subTenantId
        tenantId
        subTenant
        tenant
        id
        routeId
        routeCode
        supervisorId
        supervisor
        status
        propertyName
      }
    }
  }
`;

const GET_SUPPLY_REQUEST_VIEW = gql`
  query getSupplyRequestView($id: String!) {
    supplyRequest(id: $id) {
      id
      createdAt
      supervisor {
        firstName
        lastName
        id
      }
      items {
        id
        image {
          url
        }
        quantity
        variety
        name
        reason
      }
      route {
        routeCode
        id
        property {
          name
        }
      }
      status
      valet {
        id
        name
      }
    }
  }
`;

export const useGetSupplyRequestView = (id: string) => {
  const { data, error } = useQuery<
    { supplyRequest: SupplyRequest },
    { id: string }
  >(GET_SUPPLY_REQUEST_VIEW, { variables: { id } });
  return {
    request: data?.supplyRequest,
    loadingRequestError: error
  };
};
