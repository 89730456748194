import { Address } from "../apollo/shared/interfaces";
import Moment from "moment";
export const formatAddress = (address?: Address) => {
  if (!address) return "Not specified";

  let formattedAddress = address.line1;
  if (address.line2) {
    formattedAddress += ", " + address.line2;
  }
  formattedAddress +=
    ", " + address.city + ", " + address.state + " " + address.zip + ".";
  return formattedAddress;
};

export const formatGender = (gender: string) => {
  switch (gender) {
    case "F":
      return "Female";
    case "M":
      return "Male";
    default:
      return "Other";
  }
};

export const formatDate = (date: string | Date | null) => {
  if (!date) return null;
  return Moment(date).format("lll");
};

export const capitalize = (s: string) => {
  
  return s.charAt(0).toUpperCase() + s.slice(1)
}