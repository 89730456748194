import React, { useState, useEffect } from "react";
import Map, { VanillaMapProps, VanillaMap } from "./";
import {
  DrawingManager,
  Polygon,
  InfoBox,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import {
  Menu,
  MenuItem,
  Popover,
  TextField,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import ColorPicker from "../ColorPicker";
import useValue from "../../hooks/useValue";
import "./styles.scss";
import { Area } from "./interfaces";
import AreaPolygon from "./AreaPolygon";

export interface DrawingMapProps extends VanillaMapProps {
  color?: string;
  areas?: Area[];
  displayOnlyAreas?: Area[];
  useAreaNames?: boolean;
  onAreaAdded?: (newArea: Area) => void;
  onAreaRemoved?: (position: number, area: Area) => void;
  // onAreasChange?: (areas: Area[]) => void;
}

const DrawingMap: React.FC<DrawingMapProps> = ({
  color = "red",
  onAreaRemoved,
  onAreaAdded,
  useAreaNames,
  displayOnlyAreas = [],
  areas = [],
  ...props
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapsPolygons] = useValue<google.maps.Polygon[]>([]);
  const [menuAnchor, setMenuAnchor] = useState<{
    x: number;
    y: number;
    item: number;
  } | null>(null);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const onDeleteClick = () => {
    if (menuAnchor) {
      if (onAreaRemoved) onAreaRemoved(menuAnchor.item, areas[menuAnchor.item]);
    }
    setMenuAnchor(null);
  };
  const onAreaRightClick = (position: number, area: Area) => () => {
    if (onAreaRemoved) onAreaRemoved(position, area);
    mapsPolygons.splice(position, 1);
  };
  const onPolygonCreate = (poligon: google.maps.Polygon) => {
    mapsPolygons.push(poligon);
    poligon.setVisible(false);
    const points: { latitude: number; longitude: number }[] = [];
    poligon.getPaths().forEach((e) => {
      e.getArray().forEach((position) => {
        points.push({
          latitude: position.lat(),
          longitude: position.lng(),
        });
      });
    });

    if (onAreaAdded) onAreaAdded({ points, name: "" });
  };

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      style={{ height: "100%" }}
    >
      <VanillaMap {...props} disableMarking={true} areas={displayOnlyAreas}>
        <Menu
          keepMounted
          open={!!menuAnchor}
          onClose={handleMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            menuAnchor ? { top: menuAnchor.y, left: menuAnchor.x } : undefined
          }
        >
          <MenuItem onClick={onDeleteClick}>Delete</MenuItem>
        </Menu>

        <>
          {!props.mapOnly && (
            <DrawingManager
              onPolygonComplete={onPolygonCreate}
              options={{
                drawingControlOptions: {
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                },
                polygonOptions: {
                  fillColor: color,
                  strokeColor: color,
                },
              }}
              drawingMode={google.maps.drawing.OverlayType.POLYGON}
            />
          )}

          {areas.map((area, i) => (
            <AreaPolygon
              key={area.points[0].latitude}
              area={area}
              altName={`Area ${i + 1}`}
              onRightClick={onAreaRightClick(i, area)}
              color={color}
              useAreaNames={useAreaNames}
            />
          ))}
        </>
      </VanillaMap>
    </div>
  );
};

export default DrawingMap;
