import { gql, ApolloError } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import { Valet, CreateValet, UpdateValet } from "./interfaces";

const CREATE_VALET = gql`
  mutation createValet($valet: CreateValetDTO!) {
    createValet(valet: $valet) {
      id
    }
  }
`;

const UPDATE_VALET = gql`
  mutation updateValet($valet: UpdateValetDTO!) {
    updateValet(valet: $valet) {
      id
    }
  }
`;
export const useCreateUpdateValet = (
  onSuccess: (valet: Valet) => void,
  onError: (error: ApolloError) => void,
  isEdit?: boolean
) => {
  const [submit, { loading }] = useMutation<
    { createValet: Valet; updateValet: Valet },
    {
      valet: CreateValet | UpdateValet;
    }
  >(isEdit ? UPDATE_VALET : CREATE_VALET, {
    onCompleted: ({ updateValet, createValet }) => {
      onSuccess(isEdit ? updateValet : createValet);
    },
    onError
  });

  return { submitValet: submit, isSubmitingValet: loading };
};

const CHANGE_VALET_DISABLED_STATUS = gql`
  mutation changeValetDisabledStatus($valetId: String!, $isDisabled: Boolean!) {
    changeValetDisabledStatus(isDisabled: $isDisabled, valetId: $valetId)
  }
`;

export const useChangeValetDisabledStatus = () => {
  const [change, { data, error, loading }] = useMutation<
    { changeValetDisabledStatus: boolean },
    { valetId: string; isDisabled: boolean }
  >(CHANGE_VALET_DISABLED_STATUS);

  const changeDisabledStatus = (valetId: string, isDisabled: boolean) => {
    change({ variables: { isDisabled, valetId } });
  };

  return {
    changeDisabledStatus,
    changeDisabledStatusSuccess: data?.changeValetDisabledStatus,
    changeDisabledStatsLoading: loading,
    changeDisabledStatusError: error
  };
};
