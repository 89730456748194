import React, { forwardRef, useState, useEffect } from "react";
import MaterialTable, { Icons } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowUp from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDown from "@material-ui/icons/ArrowDownwardRounded";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { Box, Switch, colors, IconButton } from "@material-ui/core";
import { IncidentSubCategory } from "../../../apollo/incidents/interfaces";
import {
  useUpdateIncidentSubCategory,
  useCreateIncidentSubCategory,
  useDeleteIncidentSubCategories,
  useChangeIncidentSubCategoryOrder
} from "../../../apollo/incidents/mutations";
import { useGetIncidentCategorySubsLazy } from "../../../apollo/incidents/queries";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const useIncidentSubCategoryTable = (categoryId: string) => {
  const [data, setData] = useState<IncidentSubCategory[]>([]);
  const update = useUpdateIncidentSubCategory();
  const create = useCreateIncidentSubCategory();
  const deleteSubs = useDeleteIncidentSubCategories();
  const { getSubCategories, subCategories } = useGetIncidentCategorySubsLazy(
    categoryId
  );

  const onSubCategoryOrderChange = () => {
    getSubCategories();
  };
  const { changeOrder } = useChangeIncidentSubCategoryOrder(
    onSubCategoryOrderChange
  );

  const onOrderChange = (
    direction: "up" | "down",
    subCategoryId: string
  ) => () => {
    changeOrder(categoryId, subCategoryId, direction === "down");
  };

  const onSubCategoryCreate = async ({ id, ...rest }: IncidentSubCategory) => {
    const sub = await create({ categoryId, ...rest });
    if (sub) {
      setData(d => [...d, sub]);
    }
  };

  const onSubCategoryUpdate = async (
    newData: IncidentSubCategory,
    oldData?: IncidentSubCategory
  ) => {
    const updatedSub = await update({ ...newData, categoryId });
    if (updatedSub) {
      const old = oldData as typeof oldData & { tableData: { id: number } };
      const dataUpdate = [...data];
      const index = old?.tableData.id;
      dataUpdate[index] = updatedSub;
      setData(dataUpdate);
    }
  };

  const onSubCategoryDelete = async (oldData: IncidentSubCategory) => {
    deleteSubs([oldData.id]);
    const old = oldData as typeof oldData & { tableData: { id: number } };
    const dataDelete = [...data];
    const index = old.tableData.id;
    dataDelete.splice(index, 1);
    setData([...dataDelete]);
  };

  useEffect(() => {
    if (categoryId) {
      getSubCategories();
    }
  }, [categoryId]);

  useEffect(() => {
    if (subCategories) {
      setData(subCategories);
    } else {
      setData([]);
    }
  }, [subCategories]);

  return {
    model: {
      data
    },
    commands: {
      onSubCategoryCreate,
      onSubCategoryUpdate,
      onSubCategoryDelete,
      onOrderChange
    }
  };
};
interface IncidentSubCategoryTableProps {
  categoryId: string;
  canDelete?: boolean;
  canEdit?: boolean;
  canCreate?: boolean;
}
const IncidentSubCategoryTable: React.FC<IncidentSubCategoryTableProps> = ({
  categoryId,
  canCreate,
  canEdit,
  canDelete
}) => {
  const {
    model: { data },
    commands: {
      onSubCategoryCreate,
      onSubCategoryUpdate,
      onSubCategoryDelete,
      onOrderChange
    }
  } = useIncidentSubCategoryTable(categoryId);
  return (
    <MaterialTable<IncidentSubCategory>
      icons={tableIcons}
      columns={[
        {
          hidden: true,
          field: "id"
        },
        {
          title: "Name",
          field: "name",
          initialEditValue: "",
          validate: rowData => rowData.name !== ""
        },
        {
          title: "Description",
          field: "description",
          initialEditValue: "",
          validate: rowData => rowData.description !== ""
        },
        {
          title: "Should Alert",
          field: "shouldSendAlert",
          initialEditValue: false,
          render: data => {
            return data.shouldSendAlert ? (
              <Check style={{ color: colors.green[300] }} />
            ) : (
              <Clear style={{ color: colors.red[300] }} />
            );
          },
          editComponent: props => (
            <Switch
              type="checkbox"
              checked={props.value}
              value={props.value}
              onChange={(e, value) => props.onChange(value)}
            />
          )
        },
        {
          title: "Requires Pictures",
          field: "requiresPictures",
          initialEditValue: false,
          render: data => {
            return data.requiresPictures ? (
              <Check style={{ color: colors.green[300] }} />
            ) : (
              <Clear style={{ color: colors.red[300] }} />
            );
          },
          editComponent: props => (
            <Switch
              checked={props.value}
              type="checkbox"
              value={props.value}
              onChange={(e, value) => props.onChange(value)}
            />
          )
        },
        {
          title: "Order",
          field: "order",
          defaultSort: "asc",
          editable: 'never',
          render: data => {
            return (
              <Box display="flex">
                <IconButton onClick={onOrderChange("up", data.id)}>
                  {" "}
                  <ArrowUp />
                </IconButton>
                <IconButton onClick={onOrderChange("down", data.id)}>
                  {" "}
                  <ArrowDown />
                </IconButton>
              </Box>
            );
          }
        }
      ]}
      title="Sub-Categories"
      components={{
        Container: props => <Box {...props} />
      }}
      data={data.map(i => ({ ...i }))}
      editable={{
        onRowAdd: canCreate ? onSubCategoryCreate : undefined,
        onRowUpdate: canEdit ? onSubCategoryUpdate : undefined,
        onRowDelete: canDelete ? onSubCategoryDelete : undefined
      }}
    />
  );
};

export default IncidentSubCategoryTable;
