import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  colors,
  Box,
  GridList,
  GridListTile,
  makeStyles,
  Portal,
  Button,
  Chip
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import IncidentsTable from "./components/IncidentsTable";
import { useGetIncidentView } from "../../apollo/incidents/queries";
import { useHistory, useParams } from "react-router-dom";
import LoadingPage from "../Home/LoadingPage";
import ErrorPage from "../../shared/components/ErrorPage";
import InfoCard from "../../shared/components/InfoCard";
import Link from "../../navigation/Link";
import moment from "moment";
import ImageFolderIlustration from "./components/ImageFolderIlustration";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const useStyles = makeStyles(theme => ({
  image: {
    height: 200,
    width: 200,
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
    cursor: "pointer"
  }
}));
const useIncidentPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { incident, incidentError, incidentLoading } = useGetIncidentView(
    incidentId
  );

  return {
    model: {
      incident,
      incidentError,
      incidentLoading
    }
  };
};

const IncidentPage: React.FC = ({}) => {
  const {
    model: { incident, incidentError, incidentLoading }
  } = useIncidentPage();

  const [currentImage, setCurrentImage] = useState<number | null>(null);
  const onImageClick = (position: number) => () => setCurrentImage(position);
  const onBoxClose = () => setCurrentImage(null);
  const history = useHistory();

  const classes = useStyles();

  if (incidentError) {
    return <ErrorPage />;
  }
  if (!incident) {
    return <LoadingPage />;
  }

  const images = incident?.pictures
    ? incident.pictures.map(pic => pic.url)
    : [];

  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Incidents", link: "/dashboard/incidents" },
          { label: "Incident Report" }
        ]}
      />
      <PageHeader title={"Incident Report"} />

      <Grid
        container
        spacing={3}
        alignItems="stretch"
        style={{ marginBottom: 30 }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <InfoCard color={colors.deepOrange[300]}>
            <Box marginY={2}>
              <Button
              fullWidth
                color="primary"
                variant="outlined"
                onClick={() =>
                  history.push(
                    `/dashboard/serviceRoutes/${incident.route.id}/reports/${incident.serviceReportId}`
                  )
                }
              >
                {" "}
                Go to Service Report
              </Button>
            </Box>

            <Typography>Reported By</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/valets/" + incident.valet?.id}>
                {incident.valet?.name}
              </Link>
            </Typography>
            <Typography>Reported on service route</Typography>
            <Typography variant="h6" gutterBottom>
              <Link to={"/dashboard/serviceRoutes/" + incident.route.id}>
                {incident.route.property.name} - {incident.route.routeCode}
              </Link>
            </Typography>

            <Typography>Reported On</Typography>
            <Typography variant="h6" gutterBottom>
              {moment(incident.createdAt).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            </Typography>
          </InfoCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InfoCard color={colors.purple[300]}>
            <Typography>Category</Typography>
            <Typography variant="h6" gutterBottom>
              <Chip label={incident.category.name} />
              {incident.subCategory && (
                <>
                  {" > "} <Chip label={incident.subCategory.name} />
                </>
              )}
            </Typography>

            {incident.apartmentNumber && (
              <>
                <Typography>Apartment Number</Typography>
                <Typography variant="h6" gutterBottom>
                  {incident.apartmentNumber}
                </Typography>
              </>
            )}

            <Typography>Valet's Notes</Typography>
            <Typography variant="h6">
              {incident.notes ? incident.notes : "Nothing specified"}
            </Typography>
          </InfoCard>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom>
        Uploaded Images
      </Typography>
      {incident.pictures && incident.pictures.length ? (
        <Grid container spacing={3}>
          {incident.pictures.map((pic, i) => (
            <Grid item key={pic.id}>
              <img
                src={pic.url}
                alt={"incident-file" + i}
                className={classes.image}
                onClick={onImageClick(i)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingY={10}
        >
          <ImageFolderIlustration style={{ height: "150px", marginBottom: 10}} />
          <Typography color="textSecondary" >Nothing was uploaded by the valet.</Typography>
        </Box>
      )}

      {currentImage !== null && (
        <Portal>
          <Lightbox
            mainSrc={images[currentImage]}
            nextSrc={images[(currentImage + 1) % images.length]}
            prevSrc={images[(currentImage + images.length - 1) % images.length]}
            onCloseRequest={onBoxClose}
            onMovePrevRequest={() =>
              setCurrentImage(
                (currentImage + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setCurrentImage((currentImage + 1) % images.length)
            }
            reactModalStyle={{ zIndex: 99999 }}
          />
        </Portal>
      )}

      {/* <IncidentsTable /> */}
    </Container>
  );
};

export default IncidentPage;
