import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { String } from "lodash";
import { ValetAgreementSelfSign, ValetPreCreation } from "./interfaces";

export const VALET_PRE_CREATIONS_TABLE = gql`
  query getValetPreCreationsTable(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $tenantId: String
    $subTenantId: String
  ) {
    valetPreCreationsTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      startDate: $startDate
      endDate: $endDate
      tenantId: $tenantId
      subTenantId: $subTenantId
    ) {
      nodes {
        id
        name
        email
        userCreated
        agreementSigned
        subTenant
        tenant
        createdAt
      }
    }
  }
`;

const LOAD_VALET_PRE_CREATION = gql`
  query loadValetPreCreation($hash: String!) {
    loadValetPreCreation(hash: $hash) {
      id
      firstName
      lastName
      email
      addressCity
      addressLine1
      addressLine2
      addressState
      addressZip
      dailyPayRate
      monthlyPayRate
      agreementBonus
      agreementEndDate
      agreementStartDate
      assignedRoute {
        id
        routeColor
        routeCode
        property {
          name
          address {
            id
            formatted
          }
        }
      }

      createdValetUser {
        id
        username
        address {
          formatted
        }
      }

      createdAgreementId
      createdAgreement {
        id,
        
        createdAt

      }
    }
  }
`;
export const useLoadValetPreCreation = (hash: string) => {
  const { data, loading, error, refetch } = useQuery<
    { loadValetPreCreation: ValetPreCreation },
    { hash: string }
  >(LOAD_VALET_PRE_CREATION, {
    variables: {
      hash
    }
  });

  return {
    preCreation: data?.loadValetPreCreation,
    loading,
    error,
    refetch
  };
};

const GET_VALET_PRE_CREATION_FOR_EDIT = gql`
  query getValetPreCreationForEdit($id: String!) {
    valetPreCreation(id: $id) {
      subTenantId
      agreementEndDate
      agreementStartDate
      agreementTerm
      agreementBonus
      startWorkingDate
      monthlyPayRate
      dailyPayRate
      assignedRoute {
        id
        routeCode
        property {
          id
          name
        }
      }
      id
      assignedRoute {
        id
      }
      firstName
      lastName
      email
    }
  }
`;

export const useLazyGetValetPreCreationForEdit = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    { valetPreCreation: ValetPreCreation },
    { id: string }
  >(GET_VALET_PRE_CREATION_FOR_EDIT, {
    fetchPolicy: "network-only"
  });

  const getPreCreation = (id: string) => {
    get({ variables: { id } });
  };

  return {
    getPreCreation,
    loading,
    error,
    preCreation: data?.valetPreCreation
  };
};

const GET_VALET_AGREEMENT_PREVIEW = gql`
  query getValetSelfAgreementPreview($data: ValetAgreementSelfSignDTO!) {
    previewValetSelfAgreement(data: $data)
  }
`;
export const useGetValetAgreementPreviewLazy = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    { previewValetSelfAgreement: string },
    { data: ValetAgreementSelfSign }
  >(GET_VALET_AGREEMENT_PREVIEW);

  const getPreview = (data: ValetAgreementSelfSign) => {
    get({ variables: { data } });
  };

  return {
    getPreview,
    preview: data?.previewValetSelfAgreement,
    loading,
    error
  };
};
