import React, { useEffect } from "react";
import { FormikValues, useFormikContext, useField } from "formik";
import { TextFieldProps, TextField } from "@material-ui/core";

interface CalculatedFieldProps<T> {
    firstValue: keyof T;
    secondValue: keyof T;
    operation: (firstValue: any, secondValue: any) => string | number | null;
  }
  const CalculatedField = <T extends FormikValues>({
    name,
    operation,
    firstValue,
    secondValue,
    ...props
  }: CalculatedFieldProps<T> & TextFieldProps) => {
    const nameString = name as string;
    const { values, touched, setFieldValue } = useFormikContext<T>();
    const [field, meta] = useField({ name: nameString });
  
    useEffect(() => {
      // set the value of textC, based on textA and textB
  
      setFieldValue(
        nameString,
        operation(values[firstValue], values[secondValue])
      );
    }, [
      values[firstValue],
      values[secondValue],
      touched[firstValue],
      touched[secondValue],
      setFieldValue,
      name,
    ]);
  
    return <TextField {...props} {...field} />;
  };

  export default CalculatedField;