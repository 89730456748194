import React from "react";
import useUrlQuery from "../../shared/hooks/useUrlQuery";
import RegularRouteFormPage from "./RegularRouteFormPage";
import SpecialRouteFormPage from "./SpecialRouteFormPage";

const ServiceRouteFormPage: React.FC = ({}) => {
  const query = useUrlQuery();
  if (query.get("type") === "special") return <SpecialRouteFormPage />;

  return <RegularRouteFormPage />;
};

export default ServiceRouteFormPage;
