import {
  Box,
  BoxProps,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import moment from "moment";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableState,
  MUIDataTableOptions
} from "mui-datatables";

import React, { useEffect, useMemo } from "react";
import { string } from "yup";
import {
  IncidentCategory,
  IncidentSubCategory
} from "../../../apollo/incidents/interfaces";
import { useGetPropertyIncidentAlert } from "../../../apollo/incidents/queries";
import IncidentCategoryItemTags from "../IncidentCategoryItemTags";
import PropertyIncidentAlertItem from "./PropertyIncidentAlertItem";

interface PropertyIncidentAlertProps {
  propertyId: string;
  onIncidentAlertStatusChange?: (hasIncidents: boolean) => void;
}

const ItemContainer = styled(Box)(({ theme }) => ({
  padding: "0.5rem",
  width: "33%",

  [theme.breakpoints.down("sm")]: {
    width: "50%"
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%"
  }
})) as React.ComponentType<BoxProps & { "data-index": number }>;

const ListContainer = styled(Box)({
  display: "flex",
  "flex-wrap": "wrap"
});

const columns: MUIDataTableColumn[] = [
  {
    name: "subCategory",
    options: {
      display: "excluded",
      filter: false
    }
  },
  {
    name: "category",
    options: {
      display: "excluded",
      filter: false
    }
  },
  {
    name: "subCategoryName",
    label: "Sub-Category",
    options: {
      display: "excluded"
    }
  },
  {
    name: "route",
    label: "Affected Route"
  },
  {
    name: "unit",
    label: "Affected Unit"
  },
  {
    name: "categoryName",
    label: "Category",
    options: {
      customBodyRender(value, tableMeta, updateValue) {
        const subCategory = tableMeta.rowData[0];
        const category = tableMeta.rowData[1];

        return (
          <IncidentCategoryItemTags
            category={category}
            subCategory={subCategory}
          />
        );
      }
    }
  },
  {
    name: "reporter",
    label: "Reported By"
  },
  {
    name: "createdAt",
    label: "Created At",
    options: {
      customBodyRender(value, tableMeta, updateValue) {
        return moment(value).calendar();
      }
    }
  }
];

interface IncidentTableItem {
  route: string;
  unit: string;
  category: IncidentCategory;
  categoryName: string;
  subCategoryName?: string;
  subCategory?: IncidentSubCategory;
  reporter: string;
  createdAt: Date;
}

const PropertyIncidentAlert: React.FC<PropertyIncidentAlertProps> = ({
  propertyId,
  onIncidentAlertStatusChange
}) => {
  const { incidents } = useGetPropertyIncidentAlert(propertyId);

  const formattedIncidents: IncidentTableItem[] = useMemo(() => {
    if (!incidents) return [];

    return incidents.map<IncidentTableItem>(incident => ({
      category: incident.category,
      categoryName: incident.category.name,
      route: incident.route.routeCode,
      createdAt: incident.createdAt,
      reporter: incident.reporter?.name ?? "Not specified",
      unit: incident.isBuilding ? "Building" : incident.apartmentNumber,
      subCategory: incident.subCategory,
      subCategoryName: incident.subCategory?.name
    }));
  }, [incidents]);

  useEffect(() => {
    if (incidents) onIncidentAlertStatusChange?.(!!incidents.length);
  }, [incidents, onIncidentAlertStatusChange]);

  if (!incidents || !incidents.length) return null;
  return (
    <Box
      id="property-incident-alert-container"
      marginTop="30px"
      marginBottom={"100px"}
      // height={"20vh"}
    >
      <Typography variant="h5">Incident Alerts</Typography>

      <MUIDataTable
        options={{
          count: formattedIncidents.length,
          rowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 20],
          selectableRows: 'none'
        }}
        title=""
        columns={columns}
        data={formattedIncidents}
      />
    </Box>
  );
};

export default PropertyIncidentAlert;
