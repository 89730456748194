import { gql, ApolloError } from "@apollo/client";
import {
  CreateCustomer,
  UpdateCustomer,
  Customer,
  CreateProperty,
  UpdateProperty,
  PointOfInterest,
  CreatePointOfInterest,
  Property
} from "./interfaces";
import { useMutation } from "@apollo/react-hooks";

const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $customer: CreateCustomerDTO!
    $property: CreatePropertyDTO
  ) {
    createCustomer(customer: $customer, property: $property) {
      id
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($customer: UpdateCustomerDTO!) {
    updateCustomer(customer: $customer) {
      id
    }
  }
`;

const SET_PROPERTY_MANAGER = gql`
  mutation setPropertyManager($userId: String, $propertyId: String!) {
    setPropertyManager(managerId: $userId, propertyId: $propertyId)
  }
`;
export const useSetPropertyManager = () => {
  const [setPropertyManager, { loading, error }] = useMutation<boolean, {userId?: string | null, propertyId: string} >(
    SET_PROPERTY_MANAGER
  );

  return {
    setPropertyManager,
    loading,
    error
  };
};

export const useCreateUpdateCustomer = (
  onSuccess: (customer: Customer) => void,
  onError: (error: ApolloError) => void,
  isEdit?: boolean
) => {
  const [create, { loading }] = useMutation<
    { createCustomer: Customer; updateCustomer: Customer },
    {
      customer: CreateCustomer | UpdateCustomer;
      property?: CreateProperty | UpdateProperty;
    }
  >(isEdit ? UPDATE_CUSTOMER : CREATE_CUSTOMER, {
    onCompleted: ({ updateCustomer, createCustomer }) => {
      onSuccess(isEdit ? updateCustomer : createCustomer);
    },
    onError
  });

  return { submitCustomer: create, loading };
};
export const DELETE_CUSTOMERS = gql`
  mutation deleteCustomers($ids: [String!]!) {
    deleteCustomers(ids: $ids)
  }
`;

export const DELETE_PROPERTIES = gql`
  mutation deleteProperties($ids: [String!]!) {
    deleteProperties(ids: $ids)
  }
`;

const UPDATE_POINTS_OF_INTEREST = gql`
  mutation updatePointsOfInterest(
    $propertyId: String!
    $points: [CreatePointOfInterestDTO!]!
  ) {
    updatePointsOfInterest(propertyId: $propertyId, points: $points) {
      id
    }
  }
`;

export const useUpdatePointsOfInterests = () => {
  const [submitPoints, { loading, error, data }] = useMutation<
    { updatePointsOfInterest: PointOfInterest[] },
    { propertyId: string; points: CreatePointOfInterest[] }
  >(UPDATE_POINTS_OF_INTEREST);

  return {
    submitPoints,
    points: data?.updatePointsOfInterest,
    submitPointsLoading: loading,
    submitPointsError: error
  };
};

const UPDATE_SPECIAL_NOTICE = gql`
  mutation updateSpecialNotice($propertyId: String!, $specialNotice: String) {
    updateSpecialNotice(propertyId: $propertyId, specialNotice: $specialNotice)
  }
`;

export const useUpdateSpecialNotice = () => {
  const [submitSpecialNotice, { loading, error, data }] = useMutation<
    { updateSpecialNotice: boolean },
    { propertyId: string; specialNotice: string | null }
  >(UPDATE_SPECIAL_NOTICE);

  return {
    submitSpecialNotice,
    success: data?.updateSpecialNotice,
    submitNoticeLoading: loading,
    submitNoticeError: error
  };
};

const SHARE_CUSTOMER = gql`
  mutation shareCustomer($propertyId: String!, $subTenantId: String!) {
    shareCustomer(subTenantId: $subTenantId, propertyId: $propertyId) {
      id
    }
  }
`;

const UNSHARE_CUSTOMER = gql`
  mutation unShareCustomer($propertyId: String!, $subTenantId: String!) {
    unshareCustomer(subTenantId: $subTenantId, propertyId: $propertyId)
  }
`;
export const useShareCustomer = () => {
  const [share, { loading, data, error }] = useMutation<
    any,
    { propertyId: string; subTenantId: string }
  >(SHARE_CUSTOMER);

  const shareCustomer = (propertyId: string, subTenantId: string) => {
    share({
      variables: {
        propertyId,
        subTenantId
      }
    });
  };

  return {
    shareCustomer,
    shareCustomerLoading: loading,
    data,
    shareCustomerError: error
  };
};

export const useUnShareCustomer = () => {
  const [share, { loading, data, error }] = useMutation<
    { unshareCustomer: boolean },
    { propertyId: string; subTenantId: string }
  >(UNSHARE_CUSTOMER);

  const unshareCustomer = (propertyId: string, subTenantId: string) => {
    share({
      variables: {
        propertyId,
        subTenantId
      }
    });
  };

  return {
    unshareCustomer,
    unshareCustomerLoading: loading,
    unshareSuccess: data?.unshareCustomer,
    unshareCustomerError: error
  };
};

const CREATE_UPDATE_PROPERTY = gql`
  mutation createUpdateProperty($property: UpdatePropertyDTO!) {
    createUpdateProperty(property: $property) {
      id
    }
  }
`;

export const useCreateUpdateProperty = () => {
  const [get, { data, loading, error }] = useMutation<
    { createUpdateProperty: Property },
    { property: UpdateProperty }
  >(CREATE_UPDATE_PROPERTY);

  const submitProperty = (property: UpdateProperty) => {
    get({ variables: { property } });
  };

  return {
    submitProperty,
    property: data?.createUpdateProperty,
    loading,
    error
  };
};
