import React from "react";
import { TextField, Grid, makeStyles, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Map, { VanillaMap } from "../Map";
import states from "./states";
import {
  AddressFormErrors,
  AddressFormProps,
  AddressFormValues
} from "./interfaces";

import * as Yup from "yup";
import { Address } from "../../../apollo/shared/interfaces";

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: (props: AddressFormProps) => props.searchOnly? "inherit": "50vh",
    marginBottom: theme.spacing(2)
    // "& > div > div > div": {
    //   borderRadius: theme.shape.borderRadius,
    // },
  }
}));

export const addressFormValidator = (values: AddressFormValues) => {
  const errors: AddressFormErrors = {};
  if (!values.line1) {
    errors.line1 = "This field is required.";
  }
  if (!values.city) {
    errors.city = "This field is required.";
  }
  if (!values.state) {
    errors.state = "This field is required.";
  }
  if (!values.zip) {
    errors.zip = "This field is required.";
  }
  return errors;
};
export const getAddressFormInitialValues = (address?: Address) => {
  return {
    line1: address ? address.line1 : "",
    line2: address ? address.line2 : "",
    city: address ? address.city : "",
    state: address ? address.state : "",
    zip: address ? address.zip : "",
    latitude: address && address.latitude ? address.latitude : null,
    longitude: address && address.longitude ? address.longitude : null
  };
};
export const getAddressFormValidationSchema = (requiresPoint?: boolean) =>
  Yup.object<AddressFormValues>({
    line1: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    state: Yup.string().required("This field is required"),
    zip: Yup.string().required("This field is required"),
    line2: Yup.string().nullable(),
    latitude: requiresPoint
      ? Yup.number()
          .required("You must pinpoint the location on the map.")
          .nullable()
      : Yup.number().notRequired().nullable()
  });
const AddressForm: React.FC<AddressFormProps> = (props) => {
  const {
    handleChange,
    values,
    setFieldValue,
    parentForm,
    setValues,
    errors,
    title,
    searchOnly,
    loadMapApi = true
  } = props;
  const classes = useStyles(props);

  const onAddressFound = (addr: AddressFormValues) => {
    console.log("addr", setFieldValue);

    if (setFieldValue && parentForm) {
      setFieldValue(parentForm, addr, true);
      return;
    }

    if (setValues) {
      setValues(addr);
    }
  };

  const getCurrentStateValue = () =>
    states.find(state => state.abbreviation === values?.state);
  const getCurrentLocation = () => {
    if (values?.latitude && values?.longitude) {
      return {
        lat: values.latitude,
        lng: values.longitude
      };
    }
    return null;
  };
  return (
    <Grid container spacing={2}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.mapContainer}>
        {loadMapApi ? (
          <Map
            searchOnly={searchOnly}
            onGeolocationFound={onAddressFound}
            currentLocation={getCurrentLocation()}
          />
        ) : (
          <VanillaMap
            searchOnly={searchOnly}
            onGeolocationFound={onAddressFound}
            currentLocation={getCurrentLocation()}
          />
        )}
        {errors?.latitude && (
          <Typography variant="caption" color="error" gutterBottom>
            {errors?.latitude}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name={`${parentForm ? parentForm + "." : ""}line1`}
          id="line1"
          label="Line 1"
          variant="outlined"
          color="primary"
          fullWidth
          value={values?.line1}
          onChange={handleChange}
          helperText={errors?.line1}
          error={!!errors?.line1}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name={`${parentForm ? parentForm + "." : ""}line2`}
          id="line2"
          label="Line 2"
          variant="outlined"
          color="primary"
          value={values?.line2}
          onChange={handleChange}
          helperText={errors?.line2}
          error={!!errors?.line2}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name={`${parentForm ? parentForm + "." : ""}city`}
          id="city"
          label="City"
          variant="outlined"
          color="primary"
          value={values?.city}
          onChange={handleChange}
          helperText={errors?.city}
          error={!!errors?.city}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="state"
          options={states}
          // classes={{
          //   option: classes.option,
          // }}

          autoHighlight
          onChange={(e, value) => {
            const synteticEvent: React.ChangeEvent<any> = {
              ...e,
              target: {
                name: `${parentForm ? parentForm + "." : ""}state`,
                value: value?.abbreviation
              }
            };
            if (handleChange) handleChange(synteticEvent);
          }}
          value={values?.state ? getCurrentStateValue() : null}
          getOptionLabel={option => option.name}
          renderOption={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              label="Choose a state"
              variant="outlined"
              helperText={errors?.state}
              error={!!errors?.state}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password" // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          name={`${parentForm ? parentForm + "." : ""}zip`}
          id="zip"
          label="ZIP Code"
          variant="outlined"
          color="primary"
          value={values?.zip}
          onChange={handleChange}
          helperText={errors?.zip}
          error={!!errors?.zip}
        />
      </Grid>
    </Grid>
  );
};

export default AddressForm;
