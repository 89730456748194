import { gql, ApolloError } from "@apollo/client";
import { CreateValetAgreement, ValetAgreement } from "./interface";
import { useMutation } from "@apollo/react-hooks";

const SUBMIT_AGREEMENT = gql`
  mutation submitValetAgreement($agreement: CreateValetAgreementDTO!) {
    submitValetAgreement(agreement: $agreement) {
      id
    }
  }
`;

export const useSubmitAgreement = (
  onSuccess: (agreement: ValetAgreement) => void,
  onError: (error: ApolloError) => void
) => {
  const [submitAgreement, { loading }] = useMutation<
    { submitAgreement: ValetAgreement },
    { agreement: CreateValetAgreement }
  >(SUBMIT_AGREEMENT, {
    onCompleted: ({ submitAgreement }) => {
      onSuccess(submitAgreement);
    },
    onError,
  });

  return {
    submitAgreement,
    submitAgreementLoading: loading,
  };
};
