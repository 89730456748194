import React, { useRef, RefObject, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Box,
  useTheme,
  useMediaQuery, Grid
} from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { FormValues } from "../../pages/ValetAgreements/steps/interfaces";

interface SigningModalProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  onSave: (image: string, ssn?: string) => void;
  isValet?: boolean;
}

const useStyles = makeStyles((theme) => ({
  canvas: {
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,

    border: "solid 3px",
    width: "100%",
    height: "10em",
  },
}));

const SigningModal: React.FC<SigningModalProps> = ({
  open,
  onClose,
  onSave,
  title = "Please sign this document.",
  isValet
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [canSave, setCanSave] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const pad = useRef<SignatureCanvas | null>(null);
  const {values} = useFormikContext<FormValues>();

  const checkCanSave = () => {
    if (pad.current) {
      console.log({ isEmpty: pad.current.isEmpty() });

      return setCanSave(!pad.current.isEmpty() && (!isValet || Boolean(values?.valetSsn)));
    }

    return setCanSave(false);
  };
  const onSaveClick = () => {
    const image = pad.current?.toDataURL();
    onSave(image ? image : "");
    if (onClose) onClose();
  };

  const onClearClick = () => {
    pad.current?.clear();
    setCanSave(false);
  };

  return (
    <Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isSmall}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {isValet && (
            <>
              <Field
                component={TextField}
                variant="outlined"
                name="valetSsn"
                label="Social Security or tax ID"
                fullWidth
                multiline
                rows={2}
                rowsMax={4}
                style={{marginBottom: 10}}
              />
              
              </>
          )}
          <SignatureCanvas
            onEnd={checkCanSave}
            ref={pad}
            canvasProps={{
              className: classes.canvas,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClearClick} color="primary" disabled={!canSave}>
          Clear
        </Button>
        <Button onClick={onSaveClick} color="primary" disabled={!canSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SigningModal;
