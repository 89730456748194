import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Box,
  makeStyles,
} from "@material-ui/core";
import EditableText from "../../../shared/components/EditableText";
import { EditRounded } from "@material-ui/icons";
import { useUpdateSpecialNotice } from "../../../apollo/customers/mutations";
import useOngoingProcess from "../../../shared/hooks/useOngoingProcess";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 20,
    background: theme.palette.primary.main,
    color: "white",
  },
  iconButton: {
    marginLeft: 4,
    color: "white",
  },
}));

interface SpecialNoticeSectionProps {
  propertyId: string;
  specialNotice?: string | null;
}
const SpecialNoticeSection: React.FC<SpecialNoticeSectionProps> = ({
  propertyId,
  specialNotice: specialNoticeProp,
}) => {
  const {
    submitSpecialNotice,
    submitNoticeError,
    submitNoticeLoading,
  } = useUpdateSpecialNotice();
  const { setOngoingProcess } = useOngoingProcess();
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();

  const onChange = (value: string | null) => {
    submitSpecialNotice({
      variables: {
        propertyId,
        specialNotice: value,
      },
    });
    setIsEditing(false);
  };

  useEffect(() => {
    setOngoingProcess(submitNoticeLoading);
  }, [submitNoticeLoading]);

  return (
    <Paper className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginBottom="10px"
      >
        <Typography variant="h6">Special Notice</Typography>

        <IconButton
          size="small"
          className={classes.iconButton}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <EditRounded fontSize="small" />
        </IconButton>
      </Box>

      <EditableText
        value={specialNoticeProp?  specialNoticeProp : ""}
        isEditing={isEditing}
        onChange={onChange}
      />
    </Paper>
  );
};

export default SpecialNoticeSection;
