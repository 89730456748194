import React from "react";
import { useGetIncidentCategoryDetails } from "../../../apollo/incidents/queries";
import {
  Container,
  Paper,
  Typography,
  Box,
  colors,
  Fade,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Icon from "@mdi/react";
import { mdiCheckBold, mdiClose } from "@mdi/js";
import FabMenu from "../../../shared/components/FabMenu";
import { EditRounded, DeleteRounded } from "@material-ui/icons";
import { IncidentCategory } from "../../../apollo/incidents/interfaces";
import IncidentSubCategoryTable from "./IncidentSubCategoryTable";
import useAbility from "../../../shared/hooks/useAbility";

const useIncidentCategoryDetails = (categoryId: string) => {
  const {
    category,
    categoryError,
    categoryLoading,
  } = useGetIncidentCategoryDetails(categoryId);

  return {
    model: {
      category,
      categoryError,
      categoryLoading,
    },
  };
};
interface IncidentCategoryDetailsProps {
  category?: IncidentCategory | null;
  onEditClick?: (category: IncidentCategory) => void;
  onDeleteClick?: (category: IncidentCategory) => void;
}
const IncidentCategoryDetails: React.FC<IncidentCategoryDetailsProps> = ({
  category,
  onEditClick,
  onDeleteClick,
}) => {
  const ability = useAbility();

  //   const {
  //     model: { category },
  //   } = useIncidentCategoryDetails(categoryId ? categoryId : "");
  return (
    <Paper style={{ paddingTop: 10, paddingBottom: 10, position: "relative" }}>
      {!!category && ability.can("manage", "all") && (
        <FabMenu
          actions={[
            {
              icon: <EditRounded />,
              name: "Edit",
              onClick: () => {
                if (onEditClick) onEditClick(category);
              },
              //    disabled: !ability.can('update', 'Roles')
            },
            {
              icon: <DeleteRounded />,
              name: "Delete",
              onClick: () => {
                if (onDeleteClick) onDeleteClick(category);
              },
              //    disabled: !ability.can('delete', 'Roles')
            },
          ]}
        />
      )}

      <Container>
        <Typography variant="h5" gutterBottom>
          {category?.name || <Skeleton />}
        </Typography>
        <Typography gutterBottom color={"textSecondary"}>
          {category?.description || <Skeleton />}
        </Typography>
        {category ? (
          <BooleanItem
            label="Requires pictures"
            isPositive={category?.requiresPictures}
          />
        ) : (
          <Box paddingY={1}>
            <Skeleton />
          </Box>
        )}
        {category ? (
          <BooleanItem
            label="Should send alert"
            isPositive={category?.shouldSendAlert}
          />
        ) : (
          <Box paddingY={1}>
            <Skeleton />
          </Box>
        )}

        <IncidentSubCategoryTable
          canCreate={ability.can("manage", "all")}
          canEdit={ability.can("manage", "all")}
          canDelete={ability.can("manage", "all")}
          categoryId={category?.id || ""}
        />
      </Container>
    </Paper>
  );
};
interface BooleanItem {
  isPositive?: boolean;
  label?: string;
}
const BooleanItem: React.FC<BooleanItem> = ({ isPositive, label }) => {
  return (
    <Box display="flex" alignItems="strech" paddingY={1}>
      <Box>
        <Typography>{label}</Typography>
      </Box>
      <Box marginLeft={1}>
        <Icon
          path={isPositive ? mdiCheckBold : mdiClose}
          color={isPositive ? colors.green[300] : colors.red[300]}
          size={1}
        />
      </Box>
    </Box>
  );
};

export default IncidentCategoryDetails;
