import React from "react";
import { Field } from "formik";
import {
  Grid,
  TextField as MTextField,
  TextFieldProps,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import CalculatedField from "../../../shared/components/CalculatedField";
import * as Yup from "yup";
import { CustomerAgreement } from "../../../apollo/agreements/interface";

export interface UnitsBaseFormValues {
  aptUnitsTotal: number;
  aptUnitPercentage: number;
  aptUnitsCharged: number;
  aptUnitPrice: number;
}

export const getUnitBaseValidationSchema = () => {
  const requiredField = "This field is required.";
  return Yup.object<UnitsBaseFormValues>({
    aptUnitPercentage: Yup.number()
      .moreThan(0, "You must specify a higher percentage.")
      .required(requiredField),
    aptUnitsTotal: Yup.number()
      .integer("This value must be an integer.")
      .moreThan(0, "You must specify a higher total.")
      .required(requiredField),

    aptUnitsCharged: Yup.number()
      .integer("This value must be an integer.")
      .moreThan(0, "You must specify a higher quantity.")
      .required(requiredField),

    aptUnitPrice: Yup.number()
      .moreThan(0, "You must specify a higher price.")
      .required(requiredField),
  });
};

export const getUnitBaseInitialValues = (
  agreement?: CustomerAgreement
): UnitsBaseFormValues => {
  return {
    aptUnitPercentage: agreement ? agreement.aptUnitPercentage * 100 : 0,
    aptUnitPrice: agreement ? agreement.aptUnitPrice : 0,
    aptUnitsCharged: agreement ? agreement.aptUnitsCharged : 0,
    aptUnitsTotal: agreement ? agreement.aptUnitsTotal : 0,
  };
};

const UnitBaseFields: React.FC = ({ children }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Field
          component={TextField}
          variant="outlined"
          name="aptUnitsTotal"
          fullWidth
          label="Total Apt. Units"
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          component={TextField}
          variant="outlined"
          name="aptUnitPercentage"
          fullWidth
          label="Percentage of units to charge"
          type="number"
          inputProps={{ min: "0", max: "100", step: "0.1" }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CalculatedField<UnitsBaseFormValues>
          firstValue="aptUnitsTotal"
          secondValue="aptUnitPercentage"
          operation={(aptUnitsTotal: number, aptUnitPercentage: number) => {
            return Math.round(aptUnitsTotal * (aptUnitPercentage / 100));
          }}
          inputProps={{
            readOnly: true,
          }}
          variant="outlined"
          name="aptUnitsCharged"
          fullWidth
          label="Total units to charge"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          variant="outlined"
          name="aptUnitPrice"
          fullWidth
          label="Unit Price"
          type="number"
        />
      </Grid>

      {children}
    </Grid>
  );
};

export default UnitBaseFields;
