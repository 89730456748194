import { gql } from "@apollo/client";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { AuthUser, AuthPermissions } from "./interfaces";
import { useEffect, useCallback, useState } from "react";
import useDefineAbilityFor from "../../shared/hooks/useDefineAbilityFor";
import { clearAbility } from "../../shared/contexts/AbilityContext";
import useAuthToken from "../../shared/hooks/useAuthToken";

// export const GET_USER = gql`
// {}
// `;

export const SIGN_IN = gql`
  query signIn($username: String!, $password: String!) {
    signIn(signInData: { username: $username, password: $password }) {
      token
      user {
        id
      }
    }
  }
`;

// export const AUTH_STATE = gql`
//   {
//     authState @client {
//       isAuthenticated
//     }
//   }
// `;

const AUTH_USER = gql`
  query me {
    me {
      user {
        id
        tenantId
        subTenantId
        firstName
        lastName
        username
        email
        isCustomer
        roles {
          name
        }
      }
      permissions
    }
  }
`;

// export const useAuthUserQuery = () => {
//   const [tokenMissingError, setTokenMissingError] = useState<Error | null>(null)
//   const [getUser, { data, loading, error }] = useLazyQuery<{
//     me: { user: AuthUser; permissions: AuthPermissions };
//   }>(AUTH_USER);

//   const [token] = useAuthToken();
//   useEffect(() => {
//     if (token) {
//       getUser();
//     } else {
//       setTokenMissingError(new Error('Token not found'))
//     }
//   }, [token]);
//   return {
//     user: data?.me.user,
//     permissions: data?.me.permissions,
//     loading,
//     error: tokenMissingError || error,
//   };
// };

export const useGetAuthUserLazyQuery = () => {
  const [get, { data, loading, error }] = useLazyQuery<{
    me: { user: AuthUser; permissions: AuthPermissions };
  }>(AUTH_USER);

  // const getUser = useCallback(() => {
  //   get();
  // }, []);

  return {
    getUser: get,
    user: data?.me.user,
    permissions: data?.me.permissions,
    loading,
    error,
  };
};

// export const useInitializeAuth = (authToken: string | null) => {
//   const { getUser, user, permissions } = useAuthUserLazyQuery();
//   const ability = useDefineAbilityFor(user, permissions);

//   useEffect(() => {
//     if (authToken) {
//       getUser();
//     } else {
//       clearAbility(ability);
//     }
//   }, [authToken]);

//   return ability;
// };
