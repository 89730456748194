import React, { useState } from "react";
import {
  Container,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  ListItemAvatar
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useParams, useHistory } from "react-router-dom";
import {
  useGetFullCustomer,
  useGetCustomerView
} from "../../apollo/customers/queries";
import { Skeleton } from "@material-ui/lab";
import {
  EditRounded,
  DeleteRounded,
  AddLocationRounded
} from "@material-ui/icons";

import CustomerDetailsSection from "./components/CustomerDetailsSection";
import PropertyDetailsSection from "./components/PropertyDetailsSection";
import FabMenu from "../../shared/components/FabMenu";
import useAbility from "../../shared/hooks/useAbility";
import { DELETE_CUSTOMERS } from "../../apollo/customers/mutations";
import DeleteModal from "../../shared/components/DeleteModal";
import AgreementDetailsSection from "./components/AgreementDetailsSection";
import ServiceRoutesTable from "../ServiceRoutes/components/ServiceRoutesTable";
import Link from "../../navigation/Link";
import ErrorPage from "../../shared/components/ErrorPage";
import PointsFormModal from "./components/PointsFormModal";
import { MapApiWrapper } from "../../shared/components/Map";
import ReactStickies from "react-stickies";
import "./styles.scss";
import moment from "moment";
import EditableText from "../../shared/components/EditableText";
import SpecialNoticeSection from "./components/SpecialNoticeSection";
import { property } from "lodash";
import SharedSubTenantsView from "./components/SharedSubTenantsView";

const useStyles = makeStyles(theme => ({
  customerSectionContainer: {
    position: "relative",
    height: "100%"
  }
}));

const LoadingSkeleton: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={50} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Skeleton
            animation="wave"
            width="100%"
            height="50vh"
            style={{ marginTop: -80 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
const useCustomerPage = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { customer, loading, error, refetch } = useGetCustomerView(customerId);
  const ability = useAbility();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPointsOfInterestModal, setShowPointsOfInterestModal] = useState(
    false
  );
  const handleEditClick = () => {
    history.push("/dashboard/customers/" + customerId + "/edit");
  };

  const onDeleteSuccess = () => {
    history.replace("/dashboard/customers/");
  };
  const onDeleteFormModalClose = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };
  const handlePointsClick = () => {
    setShowPointsOfInterestModal(true);
  };
  const onPointsModalClose = () => {
    setShowPointsOfInterestModal(false);
  };

  const onPointsSubmitSuccess = () => {
    refetch();
  };

  const checkCanCreateServiceRoutes = () => {
    if (
      ability.cannot("create", "ServiceRoutes") &&
      ability.cannot("manage", "Admin")
    )
      return false;

    if (!customer) return false;
    if (customer.paperContract) return true;
    const { agreement } = customer;
    return !!agreement?.supervisorSignature && !!agreement?.customerSignature;
  };

  const onPaperContractSubmitSuccess = () => {
    refetch();
  };

  return {
    model: {
      error,
      customer,
      loading,
      ability,
      showDeleteModal,
      showPointsOfInterestModal,
      canCreateServiceRoutes: checkCanCreateServiceRoutes()
    },
    commands: {
      onDeleteSuccess,
      onDeleteFormModalClose,
      handleEditClick,
      handleDeleteClick,
      handlePointsClick,
      onPointsModalClose,
      onPointsSubmitSuccess,
      onPaperContractSubmitSuccess
    }
  };
};

const CustomerPage: React.FC = ({}) => {
  const {
    model: {
      customer,
      error,
      loading,
      ability,
      showDeleteModal,
      showPointsOfInterestModal,
      canCreateServiceRoutes
    },
    commands: {
      handlePointsClick,
      handleEditClick,
      handleDeleteClick,
      onDeleteSuccess,
      onDeleteFormModalClose,
      onPointsModalClose,
      onPointsSubmitSuccess,
      onPaperContractSubmitSuccess
    }
  } = useCustomerPage();
  const [showRouteSelection, setShowRouteSelection] = useState(false);
  const canManage =
    !customer?.isShared ||
    (customer.isShared && ability.can("manage", "SubTenants"));
  const onRouteSelectionClose = () => {
    setShowRouteSelection(false);
  };

  const classes = useStyles();
  if (!customer) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <MapApiWrapper>
      <Container>
        <DeleteModal
          open={showDeleteModal}
          mutation={DELETE_CUSTOMERS}
          ids={[customer.id]}
          description="Are you sure you want to delete this Customer? This cannot be undone."
          title={"Delete Customer"}
          onSuccess={onDeleteSuccess}
          onClose={onDeleteFormModalClose}
          successMessage="Customer deleted successfully."
        />
        <PointsFormModal
          propertyId={customer.property.id}
          title={"Points of Interest"}
          open={showPointsOfInterestModal}
          onClose={onPointsModalClose}
          initialPoints={customer.property.pointsOfInterest}
          onSubmitSuccess={onPointsSubmitSuccess}
          mapCenter={
            customer.property.address.latitude &&
            customer.property.address.longitude
              ? {
                  latitude: customer.property.address.latitude,
                  longitude: customer.property.address.longitude
                }
              : undefined
          }
        />

        <BreadcrumbsSection
          sections={[
            { label: "Home", link: "/dashboard" },
            { label: "Customers", link: "/dashboard/customers" },
            { label: "Customer Page" }
          ]}
        />

        <RouteSelectionDialog
          open={showRouteSelection}
          customerId={customer.id}
          onClose={onRouteSelectionClose}
        />

        <PageHeader title={<><Typography variant="h3">Customer Details</Typography>
        {!canManage &&<Typography variant="h6" color="textSecondary">Belongs to the "{customer.subTenant.name}" Sub-Franchise</Typography>}
        </>} />
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={9}>
            <Paper className={classes.customerSectionContainer}>
              <FabMenu
                hidden={!canManage}
                actions={[
                  {
                    icon: <AddLocationRounded />,
                    name: "Points of Interest",
                    onClick: handlePointsClick,
                    disabled: !ability.can("update", "Customers")
                  },
                  {
                    icon: <EditRounded />,
                    name: "Edit",
                    onClick: handleEditClick,
                    disabled: !ability.can("update", "Customers")
                  },
                  {
                    icon: <DeleteRounded />,
                    name: "Delete",
                    onClick: handleDeleteClick,
                    disabled: !ability.can("delete", "Customers")
                  }
                ]}
              />
              <Container>
                <PropertyDetailsSection property={customer.property} />
                <CustomerDetailsSection customer={customer} />
              </Container>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <SpecialNoticeSection
              propertyId={customer.property.id}
              specialNotice={customer.property.specialNotice}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          alignItems="stretch"
          spacing={3}
        >
          {canManage && (
            <Grid item xs={12} md={5} container direction={"column"}>
              <PageHeader
                title="Agreement"
                variant="h6"
                containerStyle={{ marginTop: 30 }}
              />
              <Paper style={{ flex: 1 }}>
                <Container>
                  <AgreementDetailsSection
                    paperContract={customer.paperContract}
                    onPaperContractSubmitSuccess={onPaperContractSubmitSuccess}
                    customerId={customer.id}
                    agreement={customer.agreement}
                  />
                </Container>
              </Paper>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={canManage ? 7 : 12}
            container
            direction={"column"}
          >
            <PageHeader
              title="Service Routes"
              variant="h6"
              containerStyle={{ marginTop: 30 }}
              actions={
                <>
                  {canCreateServiceRoutes && canManage && (
                    <Button
                      variant={"contained"}
                      color="primary"
                      onClick={() => setShowRouteSelection(true)}
                    >
                      New Route
                    </Button>
                  )}
                </>
              }
            />
            <ServiceRoutesTable propertyId={customer.property.id} />
          </Grid>

          {ability.can("manage", "SubTenants") && (
            <Grid item xs={12}>
              <SharedSubTenantsView propertyId={customer.id} />
            </Grid>
          )}
        </Grid>
      </Container>
    </MapApiWrapper>
  );
};

export interface RouteSelectionDialogProps {
  open: boolean;
  customerId: string;
  onClose: () => void;
}

const RouteSelectionDialog: React.FC<RouteSelectionDialogProps> = props => {
  const classes = useStyles();
  const { onClose, open, customerId } = props;
  const history = useHistory();

  const onRegularClick = () => {
    history.push("/dashboard/serviceRoutes/create?customerId=" + customerId);
  };
  const onSpecialClick = () => {
    history.push(
      "/dashboard/serviceRoutes/create?type=special&customerId=" + customerId
    );
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select the route type</DialogTitle>
      <List>
        <ListItem autoFocus button onClick={onRegularClick}>
          <ListItemText primary="Regular Route" />
        </ListItem>

        <ListItem autoFocus button onClick={onSpecialClick}>
          <ListItemText primary="Special Request Route" />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default CustomerPage;
