import React from "react";
import { Box, Breadcrumbs, Link as MLink, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
// import { LinkOff } from "@material-ui/icons";

export interface BreadcrumbSection {
  label: string;
  link?: string;
}

interface Props {
  sections: BreadcrumbSection[];
}

const BreadcrumbsSection: React.FC<Props> = ({ sections }) => {
  // const size = sections.length;

  return (
    <Box marginBottom={2}>
      <Breadcrumbs aria-label="breadcrumb">
        {sections.map((section) =>
          section.link ? (
            <MLink
              key={section.label}
              to={section.link}
              component={Link}
              color="primary"
            >
              {section.label}
            </MLink>
          ) : (
            <Typography key={section.label} color="textPrimary">
              {section.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsSection;
