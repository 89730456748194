import { GeocodeResult, Address } from "./interfaces";

export const getAddressFromGeo = (result: GeocodeResult) => {
  const initialValue: Address = {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: "",
    latitude: result.geometry.location.lat,
    longitude: result.geometry.location.lng,
  };
  return result.address_components.reduce((address, component, index) => {
    if (index === 0) {
    }
    if (component.types.includes("street_number")) {
      address.line1 += component.short_name + " ";
    }

    if (component.types.includes("route")) {
      address.line1 += component.short_name;
    }

    // if(component.types.includes("neighborhood")) {
    //     address.line1 += component.short_name;
    // }

    if (component.types.includes("postal_code")) {
      address.zip = component.short_name;
    }

    if (component.types.includes("administrative_area_level_1")) {
      address.state = component.short_name;
    }
    if (component.types.includes("locality")) {
      address.city = component.short_name;
    }

    return address;
  }, initialValue);
};
