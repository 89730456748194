import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  Button,
  Tooltip
} from "@material-ui/core";

import * as Yup from "yup";
import { UserFormValues, UserFormProps } from "./interfaces";
import RoleSearchbox from "../../Roles/components/RoleSearchbox";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import { Field } from "formik";
import { TextField as FormikTextField } from "formik-material-ui";
const useStyles = makeStyles(theme => ({
  titleContainer: {
    // marginTop: theme.spacing(3),
  }
}));

export const getUserFormValidationSchema = (
  isEdit = false,
  checkUsername: (username: string) => Promise<Boolean>,
  isAdmin?: boolean,
  requirePassword?: boolean,
  requireRole = true
) =>
  Yup.object<UserFormValues>({
    subTenantId: isAdmin
      ? Yup.string().required("You must specified a Sub-Franchise.")
      : Yup.string(),
    firstName: Yup.string().required("This field is required."),
    lastName: Yup.string().required("This field is required."),
    username: Yup.string()
      .required("This field is requied.")
      .test(
        "check username availability",
        "This username is taken.",
        async function(value) {
          if (isEdit) return true;
          if (!value) return true;
          const isTaken = await checkUsername(value);
          return !isTaken;
        }
      ),
    email: Yup.string()
      .required("This field is required.")
      .email("This is not a valid email format."),
    password: requirePassword
      ? Yup.string().required("This field is required.")
      : Yup.string(),
    roleId: requireRole
      ? Yup.string().required("This field is required.")
      : Yup.string().notRequired()
  });

const UserForm: React.FC<UserFormProps> = ({
  handleChange,
  values,
  errors,
  showSubmitButton,
  title,
  isEdit,
  renderSubTenantSelect,
  renderRolesSelect = true
}) => {
  // const {
  //   model: { values, errors },
  //   commands: { onInputChange, onInputBlur, handleSubmit },
  // } = useUserForm(onSubmit, submitFormRef);
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      {title && (
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </Grid>
      )}
      {renderSubTenantSelect && (
        <Grid item xs={12}>
          <SubTenantSearchBox
            name="subTenantId"
            label="Franchise/Sub-Franchise*"
            onChange={handleChange}
            error={errors?.subTenantId}
            value={values?.subTenantId}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="First Name*"
          name="firstName"
          onChange={handleChange}
          // onBlur={onInputBlur}
          value={values?.firstName}
          helperText={errors?.firstName}
          error={!!errors?.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Last Name*"
          name="lastName"
          onChange={handleChange}
          // onBlur={onInputBlur}
          value={values?.lastName}
          helperText={errors?.lastName}
          error={!!errors?.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Phone Number"
          name="phone"
          onChange={handleChange}
          // onBlur={onInputBlur}
          value={values?.phone}
          helperText={errors?.phone}
          error={!!errors?.phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Emergency Phone Number"
          name="emergencyPhone"
          onChange={handleChange}
          // onBlur={onInputBlur}
          value={values?.emergencyPhone}
          helperText={errors?.emergencyPhone}
          error={!!errors?.emergencyPhone}
        />
      </Grid>
      <Grid item xs={12} sm={renderRolesSelect ? 6 : 12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Email*"
          name="email"
          onChange={handleChange}
          // onBlur={onInputBlur}
          value={values?.email}
          helperText={errors?.email}
          error={!!errors?.email}
        />
      </Grid>
      {renderRolesSelect && (
        <Grid item xs={12} sm={6}>
          <RoleSearchbox
            label="Role*"
            onChange={handleChange}
            name="roleId"
            error={errors?.roleId}
            value={values?.roleId}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Username*"
          name="username"
          onChange={handleChange}
          // onBlur={onInputBlur}
          value={values?.username}
          helperText={errors?.username}
          error={!!errors?.username}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Password*"
          name="password"
          type="password"
          onChange={handleChange}
          // onBlur={onInputBlur}

          value={values?.password}
          helperText={
            errors?.password
              ? errors.password
              : isEdit
              ? "Changing will replace the current password."
              : undefined
          }
        />
      </Grid>

      {showSubmitButton && (
        <Grid item xs={12} container justify="flex-end" alignItems="center">
          <Button variant="contained" color="primary" type="submit">
            Create User
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default UserForm;
