import { Box, Chip } from "@material-ui/core";
import React from "react";
import {
  IncidentCategory,
  IncidentSubCategory
} from "../../apollo/incidents/interfaces";

interface IncidentCategoryItemTagsProps {
  category: IncidentCategory;
  subCategory?: IncidentSubCategory;
}

const IncidentCategoryItemTags: React.FC<IncidentCategoryItemTagsProps> = ({
  category,
  subCategory
}) => {
  return (
    <Box display="flex" marginY="10px">
      <Chip
        label={category.name}
        // className={classes.category}
        size="small"
      />

      {subCategory && (
        <Chip
          style={{marginLeft: '10px'}}
          label={subCategory.name}
          // className={classes.category}
          size="small"
        />
      )}
    </Box>
  );
};

export default IncidentCategoryItemTags;
