import { gql } from "@apollo/client";

export const CREATE_ROLE = gql`
  mutation createRole($role: CreateRoleDTO!) {
    createRole(role: $role) {
      id
      name
      description
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($role: UpdateRoleDTO!, $id: String!) {
    updateRole(role: $role, id: $id) {
      id
      name
      description
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation deleteRoles($ids: [String!]!) {
    deleteRoles(ids: $ids)
  }
`
export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation UpdateRolePermissions($roleId: String!, $permissionIds: [String!]!) {
    setRolePermissions(permissionIds: $permissionIds, roleId: $roleId) {
      id
    }
  }
`;
