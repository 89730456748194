import React, {useEffect} from "react";
import {Grid, MenuItem} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {DatePicker} from "formik-material-ui-pickers";
import DateFnsUtils from "@date-io/moment";
import {Field, useFormikContext} from "formik";
import {TextField} from "formik-material-ui";
import {Moment} from "moment";
import * as Yup from "yup";
import {FormValues} from "./interfaces";
import moment from "moment";
import {ValetAgreement} from "../../../apollo/valetAgreements/interface";

export interface DateSelectionFormValues {
    currentTerm: 'MONTH-TO-MONTH' | '1YEAR' | '2YEAR' | '3MONTHS' | '6MONTHS';
    startDate: Moment | null;
    endDate: Moment | null;
}

export const getInitialDateSelectionValues = (
    agreement?: ValetAgreement
): DateSelectionFormValues => {

    return {
        startDate: agreement ? moment(agreement.startDate) : moment(),
        endDate: agreement ? moment(agreement.endDate) : null,
        currentTerm: agreement ? agreement.currentTerm : "MONTH-TO-MONTH",
    };
};
export const getDateSelectionValidationSchema = () => {
    const requiredField = "This field is required.";
    return Yup.object<DateSelectionFormValues>({
        startDate: Yup.object<Moment>()
            .required(requiredField)
            .nullable()
            .test(
                "same-date",
                "The service start date cannot be the same as the expiration date.",
                function (value: Moment | null) {
                    const {endDate} = this.parent as FormValues;
                    if (!value || !endDate) return true;
                    console.log(endDate.isSame(value, "day"));
                    return !endDate.isSame(value, "day");
                }
            ),
        endDate: Yup.object<Moment>().required(requiredField).nullable().test(
            "exact-date",
            "The service end date must be as specified by the term.",
            function (value: Moment | null) {
                const {startDate ,endDate, currentTerm} = this.parent as FormValues;
                if (!value || !endDate || !startDate || !currentTerm) return true;
                let comparison = false;
                let currentValue;
                switch (currentTerm) {
                    case "MONTH-TO-MONTH":
                       currentValue = startDate.clone();
                        currentValue.add(1, "M");
                         comparison = value.isSame(currentValue, "day");
                        break;
                    case "1YEAR":
                        currentValue = startDate.clone();
                        currentValue.add(1, "y");
                        comparison = value.isSame(currentValue, "day");
                        break;
                    case "2YEAR":
                        currentValue = startDate.clone();
                        currentValue.add(2, "y");
                        comparison = value.isSame(currentValue, "day");
                        break;
                    case "3MONTHS":
                        currentValue = startDate.clone();
                        currentValue.add(3, "M");
                        comparison = value.isSame(currentValue, "day");
                        break;
                    case "6MONTHS":
                        currentValue = startDate.clone();
                        currentValue.add(6, "M");
                        comparison = value.isSame(currentValue, "day");
                        break;
                }
                console.log('comparison', comparison, startDate, endDate, currentValue, currentTerm);
                return comparison;
            }
          ),
      currentTerm: Yup.string()
            .oneOf(["MONTH-TO-MONTH", "1YEAR", "2YEAR", "3MONTHS", "6MONTHS"])
            .required(requiredField)
    });
};

const useDateSelectionStep = () => {
    const {
        values,
        touched,
        errors,
        setFieldValue,
    } = useFormikContext<FormValues>();
    const {currentTerm, startDate} = values;

    const calculateEndDate = () => {
        const newEndDate = startDate?.clone();
            switch (currentTerm) {
                case "MONTH-TO-MONTH":
                    newEndDate?.add(1, "M");
                    break;
                case "1YEAR":
                    newEndDate?.add(1, "y");
                    break;
                case "2YEAR":
                    newEndDate?.add(2, "y");
                    break;
                case "3MONTHS":
                    newEndDate?.add(3, "M");
                    break;
                case "6MONTHS":
                    newEndDate?.add(6, "M");
                    break;
            }
        setFieldValue("endDate", newEndDate);
    };

    useEffect(() => {
            calculateEndDate();
    }, [
        currentTerm,
        startDate,
        setFieldValue,
    ]);

    return {
        model: {
            errors
        },
    };
};
const DateSelectionStep: React.FC = () => {
    const {
        model: {errors},
    } = useDateSelectionStep();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
                container
                spacing={2}>
                <Grid
                    item
                    xs={12}>
                    <Field
                        select
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        name="currentTerm"
                        label="Term Type"
                    >
                        <MenuItem value="MONTH-TO-MONTH">MONTH-TO-MONTH</MenuItem>
                        <MenuItem value="3MONTHS">3MONTHS</MenuItem>
                        <MenuItem value="6MONTHS">6MONTHS</MenuItem>
                        <MenuItem value="1YEAR">1YEAR</MenuItem>
                        <MenuItem value="2YEAR">2YEAR</MenuItem>
                    </Field>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}>
                    <Field
                        format="l"
                        component={DatePicker}
                        fullWidth
                        inputVariant="outlined"
                        name="startDate"
                        label="Agreement Start Date"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}>
                    <Field
                        format="l"
                        component={DatePicker}
                        fullWidth
                        inputVariant="outlined"
                        name="endDate"
                        label="End date"
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default DateSelectionStep;
