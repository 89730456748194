import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import {
  Box,
  CircularProgress,
  Collapse,
  createStyles,
  Divider,
  List,
  makeStyles,
  Slide,
  TextField,
  Typography
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Incident,
  IncidentPaginatedFilter
} from "../../../apollo/incidents/interfaces";
import { GET_INCIDENTS_PAGINATED } from "../../../apollo/incidents/queries";
import { ServiceRouteTableView } from "../../../apollo/serviceRoutes/interfaces";
import useDebounce from "../../../shared/hooks/useDebounce";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import IncidentDetails from "./IncidentDetails";
import IncidentListFilter, { IncidentFilterValues } from "./IncidentListFilter";
import IncidentListItem from "./IncidentListItem";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 500,
      overflowY: "scroll",
      // width: "100%",
      width: 360,
      padding: 0,

      backgroundColor: theme.palette.background.paper
    },
    listContainer: {
      borderRadius: 20,
      overflow: "hidden",
      display: "flex"
    },
    detailsContainer: {
      flex: 1,
      height: 500,
      overflowY: "scroll",
      backgroundColor: theme.palette.background.paper
    }
  })
);
const getIncidentFilterInitialValue = (
  defaultRouteId?: string,
  defaultReportId?: string
): IncidentFilterValues => ({
  route: defaultRouteId
    ? ({ id: defaultRouteId } as ServiceRouteTableView)
    : null,
  report: defaultReportId || null,
  valet: null,
  fromDate: null,
  toDate: null,
  category: null,
  property: null,
  sortProperty: "createdAt",
  sortDirection: "DESC"
});

const useIncidentList = (defaultRouteId?: string, defaultReportId?: string) => {
  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery<
    {
      incidentsPaginated: {
        nodes: Incident[];
        cursor?: Date;
      };
    },
    {
      limit: number;
      cursor?: Date | null;
      search?: string;
      filter: IncidentPaginatedFilter;
    }
  >(GET_INCIDENTS_PAGINATED, {
    // pollInterval: 1000,
    fetchPolicy: 'network-only',
    partialRefetch: true,
    variables: {
      limit: 10,
      filter: {
        ...getIncidentFilterInitialValue(defaultRouteId, defaultReportId),
        route: null
      }
    }
  });
  // const [gettingMore, setGettingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [filterValues, setFilterValues] = useState(
    getIncidentFilterInitialValue(defaultRouteId, defaultReportId)
  );
  const [selectedIncident, setSelectedIncident] = useState<string | null>(null);

  const debouncedSearch = useDebounce(search, 500);

  const getMore = () => {
    console.log("getting more");
    let list = data ? data.incidentsPaginated.nodes : [];

    const cursor = list.length ? list[list.length - 1].createdAt : null;
    fetchMore({
      variables: {
        cursor: cursor,
        limit: 10,
        search: debouncedSearch
      }
    });
  };

  useEffect(() => {
    console.log({ data });
  }, [data]);

  useDidMountEffect(() => {
    console.log("refetching");
    refetch({
      cursor: null,
      limit: 10,
      search: debouncedSearch,
      filter: { ...filterValues, route: filterValues.route?.id }
    });
  }, [debouncedSearch, filterValues]);

  return {
    model: {
      gettingMore: networkStatus === NetworkStatus.fetchMore,
      incidents: data?.incidentsPaginated.nodes,
      selectedIncident,
      loading,
      error
    },
    commands: {
      getMore,
      onSearchChange: setSearch,
      onFilterValuesChange: setFilterValues,
      onIncidentClick: setSelectedIncident
    }
  };
};

interface IncidentListProps {
  defaultRouteId?: string;
  defaultReportId?: string;
  forCustomer?: boolean;
}
const IncidentList: React.FC<IncidentListProps> = ({
  defaultRouteId,
  defaultReportId,
  forCustomer
}) => {
  const {
    model: { incidents, gettingMore, selectedIncident, loading },
    commands: { getMore, onSearchChange, onFilterValuesChange, onIncidentClick }
  } = useIncidentList(defaultRouteId, defaultReportId);

  const listRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      if (
        target.scrollHeight - target.scrollTop === target.clientHeight &&
        !gettingMore
      ) {
        console.log("Scrolled to bottom");
        getMore();
        // setShowIndicator(true);
      }
    },
    [gettingMore, getMore]
  );
  const renderContent = () => {
    // return ITEM_SKELETON_LENGTH.map((a, i) => <ItemSkeleton key={i} />);
  
    if (loading) {
      return ITEM_SKELETON_LENGTH.map((a, i) => <ItemSkeleton key={i} />);
    }

    if (!loading && incidents && incidents.length === 0) {
      return (
        <Box padding={3} width="100%" textAlign="center">
          <Typography color="textSecondary">No incidents found</Typography>
        </Box>
      );
    }

    return incidents?.map(incident => (
      <React.Fragment key={incident.id}>
        <IncidentListItem
          incident={incident}
          onItemClick={onIncidentClick}
          selected={selectedIncident === incident.id}
        />
        <Divider />
      </React.Fragment>
    ));
  };
  return (
    <div>
      <Box>
        {!defaultReportId && (
          <IncidentListFilter
            renderRouteFilter={!defaultRouteId}
            initialValues={getIncidentFilterInitialValue(
              defaultRouteId,
              defaultReportId
            )}
            onValuesChange={onFilterValuesChange}
          />
        )}
        <TextField
          style={{ width: 360, marginBottom: 16 }}
          variant="outlined"
          placeholder="Search"
          onChange={e => {
            onSearchChange(e.target.value);
          }}
        />
      </Box>
      <div className={classes.listContainer}>
        <div onScroll={onScroll} ref={listRef}>
          <List className={classes.root}>
            {renderContent()}

            <LoadingItemsIndicator visible={gettingMore} />
          </List>
        </div>
        <div style={{width: 5}}/>
        <div className={classes.detailsContainer}>
          <IncidentDetails
            forCustomer={forCustomer}
            incidentId={selectedIncident}
            renderReportButton={!defaultReportId}
          />
        </div>
      </div>
    </div>
  );
};
const ITEM_SKELETON_LENGTH = Array.from(Array(20).keys());
const ItemSkeleton: React.FC = () => {
  return (
    <Box paddingLeft={3} height={"100px"}>
      <Skeleton variant="text" width="90%" height={30} />
      <Skeleton variant="text" width="30%" />
      <Skeleton variant="text" width="30%" />
      <Skeleton variant="text" width="30%" style={{ float: "right" }} />
    </Box>
  );
};
interface LoadingItemsIndicatorProps {
  visible: boolean;
  onEntered?: () => void;
}
const LoadingItemsIndicator: React.FC<LoadingItemsIndicatorProps> = ({
  visible,
  onEntered
}) => {
  const ref = useRef<HTMLElement>();

  return (
    <Collapse
      ref={ref}
      in={visible}
      timeout={0}
      onEntered={() => {
        ref.current?.scrollIntoView();
        console.log("entered");
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={2}
      >
        <CircularProgress size={20} />
        <Typography style={{ fontSize: 12 }}>Getting more items...</Typography>
      </Box>
    </Collapse>
  );
};

export default IncidentList;
