import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  makeStyles,
  Paper,
  Fade,
} from "@material-ui/core";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";

import { Role } from "../../apollo/roles/interfaces";
import RoleSearchbox, { LoadRolesFunction } from "./components/RoleSearchbox";
import RoleBasePage from "./components/RoleBasePage";
import RoleFormModal from "./components/RoleFormModal";
import useValue from "../../shared/hooks/useValue";
// import { ApolloQueryResult } from "@apollo/client";
import DeleteModal from "../../shared/components/DeleteModal";
import { DELETE_ROLES } from "../../apollo/roles/mutations";
import useAbility from "../../shared/hooks/useAbility";

const useStyles = makeStyles((theme) => ({
  createButtonContainer: {
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      marginBottom: theme.spacing(0),
    },
  },
  topBar: {
    margin: theme.spacing(3, 0, 5, 0),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  emptyMessage: {
    marginTop: theme.spacing(10),
  },
}));

const useRoleListPage = () => {
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [editRole, setEditRole] = useState<Role | undefined>();
  const [showNoRolesMessage, setShowNoRolesMessage] = useState(false);
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [
    loadRoles,
    changeLoadRolesFunction,
  ] = useValue<LoadRolesFunction | null>(null);
  const ability = useAbility();

  const onSelectionLoad = (roles: Role[]) => {
    if (roles.length > 0) {
      setSelectedRole(roles[0]);
    } else {
      setShowNoRolesMessage(true);
    }
  };

  const onRoleChange = (role: Role | null) => {
    setSelectedRole(role);
  };

  const onCreateRoleClick = () => {
    setEditRole(undefined);
    setShowRoleForm(true);
  };
  const onRoleFormClose = () => {
    setShowRoleForm(false);
  };

  const onEditRoleClick = (role: Role) => {
    setEditRole(role);
    setShowRoleForm(true);
  };
  const onRoleCreationSuccess = async (role: Role) => {
    if (loadRoles) {
      await loadRoles();
      setSelectedRole(role);
    }
  };

  const onRoleDeleteSuccess = () => {
    if (loadRoles) loadRoles(true);
  };

  const onDeleteRoleModalClose = () => {
    setShowDeleteRoleModal(false);
  };

  const onDeleteRoleClick = () => {
    setShowDeleteRoleModal(true);
  };
  return {
    model: {
      editRole,
      selectedRole,
      showNoRolesMessage,
      showRoleForm,
      showDeleteRoleModal,
      ability,
    },
    commands: {
      onSelectionLoad,
      onRoleChange,
      onRoleFormClose,
      onCreateRoleClick,
      onRoleCreationSuccess,
      changeLoadRolesFunction,
      onEditRoleClick,
      onDeleteRoleModalClose,
      onDeleteRoleClick,
      onRoleDeleteSuccess,
    },
  };
};

const RolesListPage: React.FC = () => {
  const classes = useStyles();

  const {
    model: {
      selectedRole,
      showNoRolesMessage,
      showRoleForm,
      editRole,
      showDeleteRoleModal,
      ability,
    },
    commands: {
      onSelectionLoad,
      onRoleChange,
      onRoleFormClose,
      onCreateRoleClick,
      onRoleCreationSuccess,
      changeLoadRolesFunction,
      onEditRoleClick,
      onDeleteRoleModalClose,
      onDeleteRoleClick,
      onRoleDeleteSuccess,
    },
  } = useRoleListPage();
  const canCreateRole = ability.can("create", "Roles");
  return (
    <Container>
      <RoleFormModal
        open={showRoleForm}
        onClose={onRoleFormClose}
        onSuccess={onRoleCreationSuccess}
        editRole={editRole}
      />
      <DeleteModal
        open={showDeleteRoleModal}
        onClose={onDeleteRoleModalClose}
        mutation={DELETE_ROLES}
        ids={[selectedRole ? selectedRole.id : ""]}
        title="Delete Role"
        description="Are you sure you want to delete this role? This action cannot be undone."
        onSuccess={onRoleDeleteSuccess}
      />
      <BreadcrumbsSection
        sections={[{ label: "Home", link: "/dashboard" }, { label: "Roles" }]}
      />

      <PageHeader title="Roles" />

      <Paper className={classes.topBar}>
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={canCreateRole ? 8 : 12}
              md={canCreateRole ? 10 : 12}
            >
              <RoleSearchbox
                onRolesLoad={onSelectionLoad}
                onOptionChange={onRoleChange}
                value={selectedRole}
                fetchMoreRef={changeLoadRolesFunction}
              />
            </Grid>

            {canCreateRole && (
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                container
                justify="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCreateRoleClick}
                >
                  New Role
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </Paper>

      {showNoRolesMessage ? (
        <NoRolesMessage
          canCreate={canCreateRole}
          className={classes.emptyMessage}
        />
      ) : (
        <div>
          <RoleBasePage
            roleId={selectedRole?.id}
            onEditClick={onEditRoleClick}
            onDeleteClick={onDeleteRoleClick}
          />
        </div>
      )}
    </Container>
  );
};

interface NoRolesMessageProps {
  className?: string;
  containerStyle?: React.CSSProperties;
  canCreate?: boolean;
}
const NoRolesMessage: React.FC<NoRolesMessageProps> = ({
  className,
  containerStyle,
  canCreate,
}) => {
  return (
    <Fade in={true} timeout={500}>
      <Grid container style={containerStyle} className={className}>
        <Grid
          item
          xs={12}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Typography variant="h4">There are no roles</Typography>
          {canCreate && (
            <Typography variant="h6">
              {" "}
              You can create a new role by clicking{" "}
              <Link to="/dashboard/roles/create">here</Link>.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Fade>
  );
};

export default RolesListPage;
