import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextFieldProps,
  ListItemIcon
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  useGetValetSelectItemLazy,
  useGetValetsSelect
} from "../../../apollo/valets/queries";
import SearchInput from "../../../shared/components/Datatable/SearchInput";
import { ValetTableView } from "../../../apollo/valets/interfaces";

import { useField } from "formik";
import { CheckRounded } from "@material-ui/icons";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

interface ValetSelectModalProps {
  name: string;
  onValetChange?: (valet: ValetSelectItem) => void;
  initialValue?: string;
}

export type ValetSelectItem = {
  id: string;
  name: string;
};

const limit = 5;
const ValetSelectModal: React.FC<ValetSelectModalProps & TextFieldProps> = ({
  name,
  initialValue,
  onValetChange,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValet, setSelectedValet] = useState<ValetSelectItem | null>();
  const [{ value: selectedValetId }, meta, helpers] = useField<string | null>(
    name
  );

  const [showModal, setShowModal] = useState(false);
  const { getValet, valet: foundValet } = useGetValetSelectItemLazy();

  const { total, valets } = useGetValetsSelect({
    search,
    limit,
    sortDirection: "ASC",
    sortProperty: "name",
    offset: limit * (currentPage - 1)
  });

  const onValetClick = (valet: ValetTableView) => () => {
    helpers.setValue(valet.id);
    setSelectedValet(valet);
    if (onValetChange) onValetChange(valet);
    onModalClose();
  };

  const onInputClick = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (meta.initialValue) {
      getValet(meta.initialValue);
    } else if (initialValue) {
      getValet(initialValue);
    }
  }, [meta.initialValue, initialValue]);

  useEffect(() => {
    if (foundValet && !selectedValet) {
      // helpers.setValue(foundValet.id);
      setSelectedValet(foundValet);
    }
  }, [foundValet, selectedValet]);

  useDidMountEffect(() => {
    if (!selectedValetId && selectedValet) {
      setSelectedValet(null);
    }
  }, [selectedValetId, selectedValet]);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pick a Valet</DialogTitle>
        <DialogContent>
          <SearchInput
            onChange={e => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Search a valet"
          />

          {valets && valets.length ? (
            <>
              <List>
                {valets?.map(valet => (
                  <ListItem
                    key={valet.id}
                    button
                    onClick={onValetClick(valet)}
                    style={{ borderRadius: 20 }}
                  >
                    <ListItemAvatar>
                      <Avatar>{valet.name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={valet.name}
                      secondary={"@" + valet.username}
                    />
                    {selectedValetId === valet.id && (
                      <ListItemIcon>
                        <CheckRounded color="secondary" />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.round(total / limit)}
                page={currentPage}
                onChange={(e, v) => setCurrentPage(v)}
              />
            </>
          ) : (
            <Typography>No valets have been found</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        error={!!meta.error}
        helperText={meta.error}
        {...props}
        InputProps={{
          ...props.InputProps,
          readOnly: true
        }}
        onClick={onInputClick}
        value={selectedValet ? selectedValet.name : ""}
      />
    </>
  );
};

interface VanillaSelectModalProps {
  open: boolean;
  onClose: () => void;
  initialValue?: string;
  subTenantId?: string;
  onValetChange: (valet: ValetSelectItem) => void;
}
export const VanillaSelectModal: React.FC<VanillaSelectModalProps> = ({
  open,
  initialValue,
  onValetChange,
  onClose,
  subTenantId,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValet, setSelectedValet] = useState<ValetSelectItem | null>();

  const { getValet, valet: foundValet } = useGetValetSelectItemLazy();

  const { total, valets } = useGetValetsSelect({
    subTenantId,
    search,
    limit,
    sortDirection: "ASC",
    sortProperty: "name",
    offset: limit * (currentPage - 1)
  });

  console.log({ total, pages: Math.ceil(total / limit) });

  const onValetClick = (valet: ValetTableView) => () => {
    setSelectedValet(valet);
    if (onValetChange) onValetChange(valet);
    onClose();
  };

  useEffect(() => {
    if (initialValue) {
      getValet(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (foundValet && !selectedValet) {
      // helpers.setValue(foundValet.id);
      setSelectedValet(foundValet);
    }
  }, [foundValet, selectedValet]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Pick a Valet</DialogTitle>
      <DialogContent>
        <SearchInput
          onChange={e => setSearch(e.target.value)}
          variant="outlined"
          placeholder="Search a valet"
        />

        {valets && valets.length ? (
          <>
            <List>
              {valets?.map(valet => (
                <ListItem
                  key={valet.id}
                  button
                  onClick={onValetClick(valet)}
                  style={{ borderRadius: 20 }}
                >
                  <ListItemAvatar>
                    <Avatar>{valet.name.charAt(0)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={valet.name}
                    secondary={"@" + valet.username}
                  />
                  {selectedValet?.id === valet.id && (
                    <ListItemIcon>
                      <CheckRounded color="secondary" />
                    </ListItemIcon>
                  )}
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(total / limit)}
              page={currentPage}
              onChange={(e, v) => setCurrentPage(v)}
            />
          </>
        ) : (
          <Typography>No valets have been found</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ValetSelectModal;
