import { ServiceRoute } from "../../apollo/serviceRoutes/interfaces";
import { Area } from "../../shared/components/Map/interfaces";

export const getAreasFromRoutes = (
  routes?: ServiceRoute[],
  editingRouteId?: string
) => {
  if (!routes) return [];

  const routesAreas = routes.reduce<Area[]>((areas, route, i) => {
    if (route.id === editingRouteId) return areas;
    const thisRouteAreas = route.areas.map(area => ({
      ...area,
      name: "Route - " + route.routeCode,
      color: route.routeColor
    }));

    return [...areas, ...thisRouteAreas];
  }, []);

  return routesAreas;
};
