import React from "react";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

export interface RouteSelectionModalProps {
  open: boolean;
  propertyId?: string;
  customerId?: string;
  onClose: () => void;
}

const RouteSelectionModal: React.FC<RouteSelectionModalProps> = props => {
  const { onClose, open, propertyId } = props;
  const history = useHistory();

  const onRegularClick = () => {
    history.push(
      `/dashboard/serviceRoutes/create${
        propertyId ? "?propertyId=" + propertyId : ""
      }`
    );
  };
  const onSpecialClick = () => {
    history.push(
      `/dashboard/serviceRoutes/create?type=special&${
        propertyId ? "propertyId=" + propertyId : ""
      }`
    );
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select the route type</DialogTitle>
      <List>
        <ListItem autoFocus button onClick={onRegularClick}>
          <ListItemText primary="Regular Route" />
        </ListItem>

        <ListItem autoFocus button onClick={onSpecialClick}>
          <ListItemText primary="Special Request Route" />
        </ListItem>
      </List>
    </Dialog>
  );
};
export default RouteSelectionModal;
