import { Box, Container } from "@material-ui/core";
import React from "react";
import TopBar from "../../navigation/TopBar";
import ServiceReportPage from "../ServiceRoutes/ServiceReportPage";

const CustomerServiceReportPage: React.FC = ({}) => {
  return (
    <Box width="100%">
      <TopBar
        showDrawerButton={false}
        showNotificationsButton={false}
        showRoleToggle={false}
      />
      <Box marginY={4}>
        <Container>{<ServiceReportPage forCustomer />}</Container>
      </Box>
    </Box>
  );
};

export default CustomerServiceReportPage;
