import React from "react";
import {
  Grid,
  Button,
  Typography,
  TextField as MuiTextField,
  Divider,
  Box,
} from "@material-ui/core";

import CalculatedField from "../../../shared/components/CalculatedField";
import UnitBaseFields, {
  UnitsBaseFormValues,
  getUnitBaseValidationSchema,
  getUnitBaseInitialValues,
} from "./UnitBaseFields";

import * as Yup from "yup";
import {
  CreateAgreementStep,
  CustomerAgreement,
} from "../../../apollo/agreements/interface";
import moment, { Moment } from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { FieldArray, useFormikContext, Field } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import _ from "lodash";
import { request } from "http";

export interface UnitsStepUpStepFormValues extends UnitsBaseFormValues {
  metadata: {
    steps?: CreateAgreementStep[];
    maximumMonthlyCharge: number;
  };
}

interface BaseUnitsStepUpStepFormValues {
  metadata: UnitStepUpMetadata;
}

export interface UnitStepUpMetadata {
  steps?: CreateAgreementStep[];
  maximumMonthlyCharge: number;
}

export const getStepUpStepValidationSchema = () => {
  const requiredField = "This field is required.";
  return getUnitBaseValidationSchema().shape<BaseUnitsStepUpStepFormValues>({
    metadata: Yup.object({
      maximumMonthlyCharge: Yup.number()
        .moreThan(0, "You must specify a higher value")
        .required(requiredField),
      steps: Yup.array(
        Yup.object<CreateAgreementStep>({
          startDate: Yup.object<Moment>().required(requiredField).nullable(),
          units: Yup.number()
            .integer("You must specify an integer.")
            .required(requiredField),
          endDate: Yup.object<Moment>().required(requiredField).nullable(),
        }).required()
      )
        .min(1)
        .test("check-if min year", "", function (steps: CreateAgreementStep[]) {
          const startDate = steps[0].startDate;
          const endDate = steps[3].endDate;
          if (!startDate || !endDate) return true;
          const duration = moment.duration(endDate.diff(startDate));
          if (duration.asYears() > 1) return true;

          return new Yup.ValidationError(
            "There must be minimum a year difference between the first step's start date and the last step's end date.",
            steps[0].endDate,
            "metadata.steps"
          );
        })
        .required("You must specify at least 1 step."),
    }).required(),
  });
};

export const getStepUpStepInitialValues = (
  agreement?: CustomerAgreement
): UnitsStepUpStepFormValues => {
  let steps: CreateAgreementStep[];
  if (agreement && agreement.metadata.steps) {
    steps = _.map(
      agreement.metadata.steps,
      (step): CreateAgreementStep => ({
        id: step.id,
        startDate: moment(step.startDate),
        endDate: moment(step.endDate),
        units: step.units,
      })
    );
  } else {
    steps = [1, 2, 3, 4].map((i) => ({
      startDate: null,
      endDate: null,
      units: 0,
    }));
  }

  return {
    ...getUnitBaseInitialValues(agreement),
    metadata: {
      maximumMonthlyCharge: agreement?.metadata?.maximumMonthlyCharge
        ? agreement.metadata.maximumMonthlyCharge
        : 0,
      steps,
    },
  };
};
const monthSteps = [
  "First Period",
  "Second Period",
  "Third Period",
  "Final Period",
];
const useUnitStepUpStep = () => {
  const {
    values: {
      aptUnitPrice,
      metadata: { steps = [] },
    },
    errors,
  } = useFormikContext<UnitsStepUpStepFormValues>();
  const getStepsError = () => {
    if (errors.metadata?.steps && typeof errors.metadata?.steps === "string") {
      return errors.metadata?.steps;
    }
  };
  return {
    model: {
      steps,
      aptUnitPrice,
      stepsError: getStepsError(),
    },
  };
  // const [keys] = useValue<string>([])
};
const UnitStepUpStep: React.FC = () => {
  const {
    model: { steps, aptUnitPrice, stepsError },
  } = useUnitStepUpStep();
  return (
    <UnitBaseFields>
      <Grid item xs={12} md={6}>
        <CalculatedField<UnitsStepUpStepFormValues>
          firstValue="aptUnitsCharged"
          secondValue="aptUnitPrice"
          operation={(aptUnitsCharged: number, aptUnitPrice: number) => {
            return aptUnitsCharged * aptUnitPrice;
          }}
          inputProps={{
            readOnly: true,
          }}
          type="number"
          variant="outlined"
          name="metadata.maximumMonthlyCharge"
          fullWidth
          label="Maximum Monthly Charge"
        />
      </Grid>

      <Grid item xs={12}>
        <Box marginY={2.5}></Box>

        <Typography variant="h5">Steps</Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          This type of agreement requires multiple increments to be specified. A
          minimum 1-year contract is required to exercise this option.
        </Typography>
        {stepsError && (
          <Typography color="error" variant="caption">
            {stepsError}
          </Typography>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FieldArray
            name="metadata.steps"
            render={(arrayHelpers) => (
              <>
                {steps.map((step, i) => {
                  const prevStep = i - 1;
                  const prevDate =
                    prevStep > -1
                      ? steps[prevStep].endDate
                        ? steps[prevStep].endDate
                        : undefined
                      : undefined;
                  return (
                    <StepItem
                      step={step}
                      aptUnitPrice={aptUnitPrice}
                      key={i}
                      position={i}
                      prevEndDate={prevDate}
                    />
                  );
                })}
              </>
            )}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </UnitBaseFields>
  );
};

interface StepItemProps {
  position: number;
  aptUnitPrice: number;
  step: CreateAgreementStep;
  prevEndDate?: Moment | null;
}
const StepItem: React.FC<StepItemProps> = ({
  position,
  aptUnitPrice,
  step,
  prevEndDate,
}) => {
  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <Typography>{monthSteps[position]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Field
          variant="inline"
          component={DatePicker}
          format="l"
          inputVariant="outlined"
          name={`metadata.steps[${position}].startDate`}
          fullWidth
          label="Start Date"
          minDate={prevEndDate}
          minDateMessage="You must select a start date that's after the previous step's end date."
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Field
          variant="inline"
          minDate={step.startDate ? step.startDate : undefined}
          component={DatePicker}
          format="l"
          inputVariant="outlined"
          name={`metadata.steps[${position}].endDate`}
          fullWidth
          label="End Date"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Field
          component={TextField}
          variant="outlined"
          name={`metadata.steps[${position}].units`}
          fullWidth
          type="number"
          label="Units"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MuiTextField
          value={(step.units * aptUnitPrice).toFixed(2)}
          variant="outlined"
          inputProps={{
            readOnly: true,
          }}
          fullWidth
          type="number"
          label="Total"
        />
      </Grid>
    </Grid>
  );
};

export default UnitStepUpStep;
