import React from "react";
import {
  Container,
  Typography,
  Paper,
  ListItem,
  Chip,
  Box,
  Grid
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import {
  MoreVertRounded,
  EditRounded,
  DeleteRounded
} from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useServiceReportView } from "../../apollo/serviceRoutes/queries";
import ErrorPage from "../../shared/components/ErrorPage";
import LoadingPage from "../Home/LoadingPage";
import Link from "../../navigation/Link";
import moment from "moment";
import InfoCard from "../../shared/components/InfoCard";
import Map from "../../shared/components/Map";
import IncidentList from "../Incidents/components/IncidentList";

const useServiceReportPage = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const { report, reportError } = useServiceReportView(reportId);

  return {
    model: {
      report,
      reportError
    },
    commands: {}
  };
};

interface ServiceReportPageProps {
  forCustomer?: boolean
}
const ServiceReportPage: React.FC<ServiceReportPageProps> = ({forCustomer}) => {
  const {
    model: { report, reportError }
  } = useServiceReportPage();

  if (reportError) return <ErrorPage />;

  if (!report) return <LoadingPage />;
  console.log({
    lat: report.serviceRoute.latitude,
    lng: report.serviceRoute.longitude
  });
  const breadcrumbsSections = forCustomer? [{ label: "Home", link: "/myProperty"}] : [
    { label: "Home", link: "/dashboard" },
    { label: "Service Routes", link: "/dashboard/serviceRoutes/" },
    {
      label: report.serviceRoute.routeCode,
      link: "/dashboard/serviceRoutes/" + report.serviceRouteId
    },
    { label: "Report" }
  ]
  return (
    <Container>
      <BreadcrumbsSection
        sections={breadcrumbsSections}
      />
      <PageHeader
        title={
          <div>
            {/* <div style={{ width: "100%" }}>
                {getStatusChip(route?.todaysReport)}
              </div> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h4">Service Report</Typography>
            </div>
          </div>
        }
      />
      <Grid container alignItems="stretch" spacing={2}>
        <Grid item xs={12} md={4}>
          <InfoCard color={report.serviceRoute.routeColor}>
            <Box marginTop="20px">
              <Typography>Worked by</Typography>
              <Typography variant="h6" gutterBottom>
                {forCustomer? (report.user.name) :   <Link to={`/dashboard/${report.valetId? 'valets' : 'users'}/${report.valetId || report.userId}`}>
                  {report.user.name}
                </Link>}
              
              </Typography>
            </Box>
            <Box marginTop="20px">
              <Typography>Status</Typography>
              <Typography variant="h6" gutterBottom>
                {report.isCompleted ? (
                  <Chip label="Completed" />
                ) : (
                  <Chip label="Not Completed" />
                )}
              </Typography>
            </Box>

            <Box marginTop="20px">
              <Typography>Started at</Typography>
              <Typography variant="h6" gutterBottom>
                {moment(report.startTime).format("lll")}
              </Typography>
            </Box>
            <Box marginTop="20px">
              <Typography>Completed at</Typography>
              <Typography variant="h6" gutterBottom>
                {report.endTime
                  ? moment(report.endTime).format("lll")
                  : "Not completed"}
              </Typography>
            </Box>
          </InfoCard>
        </Grid>
        <Grid item xs={12} md={8} style={{ minHeight: 300 }}>
          <Map
            track={{
              color: report.serviceRoute.routeColor,
              paths: report.userTracks.map(t => ({
                lat: t.latitude,
                lng: t.longitude,
                timestamp: t.timestamp
              }))
            }}
            mapOnly={true}
            areas={report.serviceRoute.areas.map(a => ({
              ...a,
              color: report.serviceRoute.routeColor
            }))}
            initialZoom={20}
            initialCenter={{
              lat: report.serviceRoute.latitude,
              lng: report.serviceRoute.longitude
            }}
            markers={[
              {
                lat: report.serviceRoute.latitude,
                lng: report.serviceRoute.longitude,
                title: "Property"
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: 20 }}>
          <Typography gutterBottom variant="h5">Reported Incidents</Typography>
          <IncidentList defaultReportId={report.id} forCustomer/>
        </Grid>
      </Grid>

      {/* <Paper></Box>
        <Container>

        </Container>
      </Paper> */}
    </Container>
  );
};

export default ServiceReportPage;
