import { Container } from "@material-ui/core";
import React from "react";

const MobileAppPolicyPage: React.FC = () => {
  return (
    <Container>
      <h1>
        Privacy Policy of <b>Disposal Valet</b>
      </h1>
      <p>This Application collects some Personal Data from its Users.</p>
      <p>
        This document can be printed for reference by using the print command in
        the settings of any browser.
      </p>
      <hr />
      <h3>Policy summary</h3>
      <hr />
      <h2>
        Personal Data collected for the following purposes and using the
        following services:
      </h2>
      <div>
        <i />
        <h4>Device permissions for Personal Data access</h4>
        <p>Device permissions for Personal Data access</p>
        <p>Personal Data: Camera permission; Storage permission</p>
      </div>
      <div>
        <i />
        <h4>Location-based interactions</h4>
        <p>Geolocation</p>
        <p>Personal Data: geographic position</p>
      </div>
      <hr />
      <h2>Further information about Personal Data</h2>
      <div>
        <i />
        <h4>Push notifications</h4>
        <p>
          This Application may send push notifications to the User to achieve
          the purposes outlined in this privacy policy.
        </p>
        <p>
          Users may in most cases opt-out of receiving push notifications by
          visiting their device settings, such as the notification settings for
          mobile phones, and then change those settings for this Application,
          some or all of the apps on the particular device.
          <br />
          Users must be aware that disabling push notifications may negatively
          affect the utility of this Application.
        </p>
      </div>
      <hr />
      <h3>Full policy</h3>
      <hr />
      <h2>Owner and Data Controller</h2>
      <hr />
      <br />
      <h2>Types of Data collected</h2>
      <p>
        Among the types of Personal Data that this Application collects, by
        itself or through third parties, there are: geographic position; Camera
        permission; Storage permission.
      </p>
      <p>
        Complete details on each type of Personal Data collected are provided in
        the dedicated sections of this privacy policy or by specific explanation
        texts displayed prior to the Data collection. Personal Data may be
        freely provided by the User, or, in case of Usage Data, collected
        automatically when using this Application.
      </p>
      <p>
        Unless specified otherwise,all Data requested by this Application is
        mandatory and failure to provide this Data may make it impossible for
        this Application to provide its services. In cases where this
        Application specifically states that some Data is not mandatory, Users
        are free not to communicate this Data without consequences to the
        availability or the functioning of the Service.
      </p>
      <p>
        Users who are uncertain about which Personal Data is mandatory are
        welcome to contact the Owner. Any use of Cookies – or of other tracking
        tools – by this Application or by the owners of third-party services
        used by this Application serves the purpose of providing the Service
        required by the User, in addition to any other purposes described in the
        present document and in the Cookie Policy, if available.
      </p>
      <p>
        Users are responsible for any third-party Personal Data obtained,
        published or shared through this Application and confirm that they have
        the third party's consent to provide the Data to the Owner.
      </p>
      <hr />
      <h2>Mode and place of processing the Data</h2>
      <h4>Methods of processing</h4>
      <p>
        The Owner takes appropriate security measures to prevent unauthorized
        access, disclosure, modification, or unauthorized destruction of the
        Data.
        </p>
       <p>
        The Data processing is carried out using computers and/or IT enabled
        tools, following organizational procedures and modes strictly related to
        the purposes indicated. In addition to the Owner, in some cases, the
        Data may be accessible to certain types of persons in charge, involved
        with the operation of this Application (administration, sales,
        marketing, legal, system administration) or external parties (such as
        third-party technical service providers, mail carriers, hosting
        providers, IT companies, communications agencies) appointed, if
        necessary, as Data Processors by the Owner. The updated list of these
        parties may be requested from the Owner at any time.
      </p>
      <h4>Legal basis of processing</h4>
      <p>

      The Owner may process Personal Data relating to Users if one of the
      following applies:
      </p>
      <ul>
        <li>
          Users have given their consent for one or more specific purposes.
          Note: Under some legislations the Owner may be allowed to process
          Personal Data until the User objects to such processing (“opt-out”),
          without having to rely on consent or any other of the following legal
          bases. This, however, does not apply, whenever the processing of
          Personal Data is subject to European data protection law;
        </li>
        <li>
          provision of Data is necessary for the performance of an agreement
          with the User and/or for any pre-contractual obligations thereof;
        </li>
        <li>
          processing is necessary for compliance with a legal obligation to
          which the Owner is subject;
        </li>
        <li>
          processing is related to a task that is carried out in the public
          interest or in the exercise of official authority vested in the Owner;
        </li>
        <li>
          processing is necessary for the purposes of the legitimate interests
          pursued by the Owner or by a third party.
        </li>
      </ul>
      <p>
        In any case, the Owner will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <h4>Place</h4>
      <p>
        The Data is processed at the Owner's operating offices and in any other
        places where the parties involved in the processing are located.
      </p>
      <p>
        Depending on the User's location, data transfers may involve
        transferring the User's Data to a country other than their own. To find
        out more about the place of processing of such transferred Data, Users
        can check the section containing details about the processing of
        Personal Data.
      </p>
      <p>
        Users are also entitled to learn about the legal basis of Data transfers
        to a country outside the European Union or to any international
        organization governed by public international law or set up by two or
        more countries, such as the UN, and about the security measures taken by
        the Owner to safeguard their Data.
      </p>
      <p>
        If any such transfer takes place, Users can find out more by checking
        the relevant sections of this document or inquire with the Owner using
        the information provided in the contact section.
      </p>
      <h4>Retention time</h4>
      <p>
        Personal Data shall be processed and stored for as long as required by
        the purpose they have been collected for.
      </p>
      <p>Therefore:</p>
      <ul>
        <li>
          Personal Data collected for purposes related to the performance of a
          contract between the Owner and the User shall be retained until such
          contract has been fully performed.
        </li>
        <li>
          Personal Data collected for the purposes of the Owner’s legitimate
          interests shall be retained as long as needed to fulfill such
          purposes. Users may find specific information regarding the legitimate
          interests pursued by the Owner within the relevant sections of this
          document or by contacting the Owner.
        </li>
      </ul>
      <p>
        The Owner may be allowed to retain Personal Data for a longer period
        whenever the User has given consent to such processing, as long as such
        consent is not withdrawn. Furthermore, the Owner may be obliged to
        retain Personal Data for a longer period whenever required to do so for
        the performance of a legal obligation or upon order of an authority.
      </p>
      <p>
        Once the retention period expires, Personal Data shall be deleted.
        Therefore, the right to access, the right to erasure, the right to
        rectification and the right to data portability cannot be enforced after
        expiration of the retention period.
      </p>
      <hr />
      <h2>The purposes of processing</h2>
      <p>
        The Data concerning the User is collected to allow the Owner to provide
        its Service, comply with its legal obligations, respond to enforcement
        requests, protect its rights and interests (or those of its Users or
        third parties), detect any malicious or fraudulent activity, as well as
        the following: Location-based interactions and Device permissions for
        Personal Data access.
      </p>
      <p>
        For specific information about the Personal Data used for each purpose,
        the User may refer to the section “Detailed information on the
        processing of Personal Data”.
      </p>
      <hr />
      <h2>Device permissions for Personal Data access</h2>
      <p>
        Depending on the User's specific device, this Application may request
        certain permissions that allow it to access the User's device Data as
        described below.
      </p>
      <p>
        By default, these permissions must be granted by the User before the
        respective information can be accessed. Once the permission has been
        given, it can be revoked by the User at any time. In order to revoke
        these permissions, Users may refer to the device settings or contact the
        Owner for support at the contact details provided in the present
        document. The exact procedure for controlling app permissions may be
        dependent on the User's device and software.
      </p>
      <p>
        Please note that the revoking of such permissions might impact the
        proper functioning of this Application.
      </p>
      <p>
        If User grants any of the permissions listed below, the respective
        Personal Data may be processed (i.e accessed to, modified or removed) by
        this Application.
      </p>
      <h4>Camera permission</h4>
      <p>
        Used for accessing the camera or capturing images from the
        device.
      </p>
      <h4>Storage permission</h4>
      <p>
        Used for accessing shared external storage, including the reading and
        adding of any images.
      </p>
      <hr />
      <h2>Detailed information on the processing of Personal Data</h2>
      <p>
        Personal Data is collected for the following purposes and using the
        following services:
      </p>
      <ul>
        <li>Device permissions for Personal Data access</li>
        <li>Location-based interactions</li>
      </ul>
      <hr />
      <h2>Further information about Personal Data</h2>
      <ul>
        <li>Push notifications</li>
      </ul>
      <hr />
      <h2>The rights of Users</h2>
      <p>
        Users may exercise certain rights regarding their Data processed by the
        Owner.
      </p>
      <p>In particular, Users have the right to do the following:</p>
      <ul>
        <li>
          <p>
            <b>Withdraw their consent at any time.</b> Users have the right to
            withdraw consent where they have previously given their consent to
            the processing of their Personal Data.
          </p>
        </li>
        <li>
          <p>
            <b>Object to processing of their Data.</b> Users have the right to
            object to the processing of their Data if the processing is carried
            out on a legal basis other than consent. Further details are
            provided in the dedicated section below.
          </p>
        </li>
        <li>
          <p>
            <b>Access their Data.</b> Users have the right to learn if Data is
            being processed by the Owner, obtain disclosure regarding certain
            aspects of the processing and obtain a copy of the Data undergoing
            processing.
          </p>
        </li>
        <li>
          <p>
            <b>Verify and seek rectification.</b> Users have the right to verify
            the accuracy of their Data and ask for it to be updated or
            corrected.
          </p>
        </li>
        <li>
          <p>
            <b>Restrict the processing of their Data.</b> Users have the right,
            under certain circumstances, to restrict the processing of their
            Data. In this case, the Owner will not process their Data for any
            purpose other than storing it.
          </p>
        </li>
        <li>
          <p>
            <b>Have their Personal Data deleted or otherwise removed.</b> Users
            have the right, under certain circumstances, to obtain the erasure
            of their Data from the Owner.
          </p>
        </li>
        <li>
          <p>
            <b>
              Receive their Data and have it transferred to another controller.
            </b>{" "}
            Users have the right to receive their Data in a structured, commonly
            used and machine readable format and, if technically feasible, to
            have it transmitted to another controller without any hindrance.
            This provision is applicable provided that the Data is processed by
            automated means and that the processing is based on the User's
            consent, on a contract which the User is part of or on
            pre-contractual obligations thereof.
          </p>
        </li>
        <li>
          <p>
            <b>Lodge a complaint.</b> Users have the right to bring a claim
            before their competent data protection authority.
          </p>
        </li>
      </ul>
      <h4>Details about the right to object to processing</h4>
      <p>
        Where Personal Data is processed for a public interest, in the exercise
        of an official authority vested in the Owner or for the purposes of the
        legitimate interests pursued by the Owner, Users may object to such
        processing by providing a ground related to their particular situation
        to justify the objection.
      </p>
      <p>
        Users must know that, however, should their Personal Data be processed
        for direct marketing purposes, they can object to that processing at any
        time without providing any justification. To learn, whether the Owner is
        processing Personal Data for direct marketing purposes, Users may refer
        to the relevant sections of this document.
      </p>
      <h4>How to exercise these rights</h4>
      <p>
        Any requests to exercise User rights can be directed to the Owner
        through the contact details provided in this document. These requests
        can be exercised free of charge and will be addressed by the Owner as
        early as possible and always within one month.
      </p>
      <hr />
      <h2>Additional information about Data collection and processing</h2>
      <h4>Legal action</h4>
      <p>
        The User's Personal Data may be used for legal purposes by the Owner in
        Court or in the stages leading to possible legal action arising from
        improper use of this Application or the related Services. The User
        declares to be aware that the Owner may be required to reveal personal
        data upon request of public authorities.
      </p>
      <h4>Additional information about User's Personal Data</h4>
      <p>
        In addition to the information contained in this privacy policy, this
        Application may provide the User with additional and contextual
        information concerning particular Services or the collection and
        processing of Personal Data upon request.
      </p>
      <h4>System logs and maintenance</h4>
      <p>
        For operation and maintenance purposes, this Application and any
        third-party services may collect files that record interaction with this
        Application (System logs) use other Personal Data (such as the IP
        Address) for this purpose.
      </p>
      <h4>Information not contained in this policy</h4>
      <p>
        More details concerning the collection or processing of Personal Data
        may be requested from the Owner at any time. Please see the contact
        information at the beginning of this document.
      </p>
      <h4>How “Do Not Track” requests are handled</h4>
      <p>
        This Application does not support “Do Not Track” requests. To determine
        whether any of the third-party services it uses honor the “Do Not Track”
        requests, please read their privacy policies.
      </p>
      <h4>Changes to this privacy policy</h4>
      <p>
        The Owner reserves the right to make changes to this privacy policy at
        any time by notifying its Users on this page and possibly within this
        Application and/or - as far as technically and legally feasible -
        sending a notice to Users via any contact information available to the
        Owner. It is strongly recommended to check this page often, referring to
        the date of the last modification listed at the bottom.
      </p>
      <p>
        Should the changes affect processing activities performed on the basis
        of the User’s consent, the Owner shall collect new consent from the
        User, where required.
      </p>
    </Container>
  );
};

export default MobileAppPolicyPage;
