import React from "react";
import {
  Paper,
  Grid,
  Container,
  Typography,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    minHeight: 100,
    display: "flex",
    flexDirection: "column"
  },
  mainGrid: {
    flex: 1
  },
  leftSide: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius
  }
}));

interface InfoCardProps {
  color?: string;
  className?: string;
  fixedColorWidth?: number | string;
}
const InfoCard: React.FC<InfoCardProps> = ({
  children,
  color = "blue",
  className,
  fixedColorWidth
}) => {
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.root, className)} elevation={0}>
      <Grid container className={classes.mainGrid} alignItems="stretch">
        <Grid
          item
          xs={fixedColorWidth ? undefined : 1}
          sm={fixedColorWidth ? undefined : 2}
          md={fixedColorWidth ? undefined : 3}
          className={classes.leftSide}
          style={{ backgroundColor: color, width: fixedColorWidth }}
        ></Grid>
        <Grid item xs={11} sm={10} md={9}>
          <Container style={{ paddingTop: 10, paddingBottom: 10 }}>
            <>{children}</>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InfoCard;
