import { gql, useMutation, useQuery } from "@apollo/client";
import { User } from "./interfaces";

export const GET_USERS = gql`
  query GetUsers(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $subTenantId: String
    $role: String,
  ) {
    users(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      subTenantId: $subTenantId,
      role: $role
    ) {
      nodes {
        id
        username
        name
        firstName
        lastName
        email
        subTenantId
        createdAt
        roles {
          name
        }
      }
      currentCount
      totalsCount
    }
  }
`;

export const useGetUsersForSelect = (params: {
  search?: string;
  subTenantId?: string;
  offset: number;
  limit: number
  role?: string
}) => {
  const { loading, data, error } = useQuery<
    { users: { nodes: User[]; currentCount: number; totalsCount: number } },
    { search?: string; subTenantId?: string; offset: number, limit: number }
  >(GET_USERS, { variables: params });

  return {
    loading,
    total: data?.users.totalsCount,
    users: data?.users.nodes,
    error
  };
};

export const ADMIN_GET_USER_FOR_FORM = gql`
  query getUserForForm($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      username
      phone
      emergencyPhone
      # password
      subTenantId
      address {
        line1
        line2
        city
        state
        zip
        latitude
        longitude
      }
      roles {
        id
        name
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      username
      phone
      emergencyPhone
      addressFormatted
      address {
        line1
        line2
        city
        state
        zip
        latitude
        longitude
      }
      roles {
        id
        name
      }
    }
  }
`;
const CHECK_USERNAME = gql`
  mutation checkUsername($username: String!) {
    checkUsernameAvailability(username: $username)
  }
`;

export const useCheckUsername = () => {
  const [check, { data }] = useMutation<
    { checkUsernameAvailability: boolean },
    {
      username: string;
    }
  >(CHECK_USERNAME);

  const checkUsername = async (username: string) => {
    const result = await check({
      variables: {
        username
      }
    });

    return !!result.data?.checkUsernameAvailability;
  };
  return checkUsername;
};
