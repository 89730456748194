import { Box, Container, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useGetMyProperty } from "../../apollo/customers/queries";
import TopBar from "../../navigation/TopBar";
import ErrorPage from "../../shared/components/ErrorPage";
import PropertyIncidentAlert from "../../shared/components/PropertyIncidentAlert";
import PropertyInicidentAlertBanner from "../../shared/components/PropertyIncidentAlert/PropertyInicidentAlertBanner";
import LoadingPage from "../Home/LoadingPage";
import ServiceReportsTable from "../ServiceRoutes/components/ServiceReportsTable";
import ServiceRoutesTable from "../ServiceRoutes/components/ServiceRoutesTable";
import PropertyDetailsSection from "./components/PropertyDetailsSection";

const PropertyCustomerPage: React.FC = ({}) => {
  const { property, loading, error } = useGetMyProperty();
  const [hasIncidentAlert, setHasIncidentAlert] = useState(false);

  const renderContent = () => {
    if (error) {
      return <ErrorPage message={error.message} />;
    }

    if (!property && !loading) {
      return (
        <ErrorPage code={404} message="There's no property assigned to you." />
      );
    }

    if (!property) {
      return <LoadingPage />;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Welcome to the Disposal Valet App
          </Typography>

          <Grid item xs={12}>
            <Typography variant="h4">Your property's info</Typography>
            <PropertyInicidentAlertBanner visible={hasIncidentAlert} />
            <PropertyDetailsSection property={property} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Service Reports
            </Typography>
            <ServiceReportsTable
              showTitle={false}
              showDateFilter={true}
              propertyId={property.id}
              forCustomer
            />
          </Grid>
          <Grid item xs={12}>
            <PropertyIncidentAlert
              propertyId={property.id}
              onIncidentAlertStatusChange={setHasIncidentAlert}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box width="100%">
      <TopBar
        showDrawerButton={false}
        showNotificationsButton={false}
        showRoleToggle={false}
      />
      <Box marginY={4} paddingBottom={2}>
        <Container>{renderContent()}</Container>
      </Box>
    </Box>
  );
};

export default PropertyCustomerPage;
