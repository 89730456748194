import React, { useState } from "react";
import DataTable from "../../../shared/components/Datatable";
import { SERVICE_REPORTS_DATATABLE } from "../../../apollo/serviceRoutes/queries";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { MUIDataTableColumn } from "mui-datatables";
import { Button } from "@material-ui/core";
import Link from "../../../navigation/Link";

const getColumns = (
  routeId?: string,
  forCustomer?: boolean
): MUIDataTableColumn[] => {
  let columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "View",
      options: {
        filter: false,
        sort: false,

        customBodyRender: value => (
          <Link
            to={`${
              !forCustomer
                ? `/dashboard/serviceRoutes/${routeId}`
                : "myProperty"
            }/reports/${value}`}
          >
            <Button color="primary">View</Button>
          </Link>
        )
      }
    },
    {
      name: "routeId",
      options: {
        display: "excluded"
      }
    },
    {
      name: "valetId",
      options: {
        display: "excluded"
      }
    },
    {
      name: "userId",
      options: {
        display: "excluded"
      }
    },
    {
      name: 'propertyName',
      label: 'Property',
    },
    {
      name: "routeCode",
      label: "Service Route",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (forCustomer) return value;
          const id = tableMeta.rowData[1];

          return <Link to={"/dashboard/serviceRoutes/" + id}>{value}</Link>;
        },
        display: routeId ? "excluded" : "true"
      }
    },
    {
      name: "userName",
      label: "Worked By",

      options: {
        customBodyRender: (value, tableMeta) => {
          if (forCustomer) return value;
          const userId = tableMeta.rowData[3];
          const valetId = tableMeta.rowData[2];
          const link = `/dashboard/${valetId ? "valets" : "users"}/${valetId ||
            userId}`;
          return <Link to={link}>{value}</Link>;
        }
      }
    },
    {
      name: "isCompleted",
      label: "Completed",
      options: {
        customBodyRender: (value, tableMeta) => (value ? "Yes" : "No")
      }
    }
  ];

  return columns;
};

interface ServiceReportsTableProps {
  routeId?: string;
  showTitle?: boolean;
  showDateFilter?: boolean;
  showTenantFilter?: boolean;
  propertyId?: string;
  forCustomer?: boolean;
  showSharedRoutesFilter?: boolean;
}

const ServiceReportsTable: React.FC<ServiceReportsTableProps> = ({
  routeId,
  showTitle = true,
  showDateFilter = false,
  showTenantFilter = false,
  forCustomer = false,
  showSharedRoutesFilter,
  propertyId
}) => {
  const [showSharedRoutes, setShowSharedRoutes] = useState(false);
  return (
    <DataTable
      showDateFilter={showDateFilter}
      title={
        showTitle ? (
          <Typography variant="h6">Service Reports</Typography>
        ) : (
          undefined
        )
      }
      customArea={
        showSharedRoutesFilter && (
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                onChange={(e, checked) => {
                  console.log(checked);
                  setShowSharedRoutes(checked);
                }}
              />
            }
            label="Show reports from shared properties / customers"
            labelPlacement="end"
          />
        )
      }
      dateColumns={[
        { name: "startTime", label: "Started At" },
        { name: "endTime", label: "Completed At" }
      ]}
      columns={getColumns(routeId, forCustomer)}
      query={SERVICE_REPORTS_DATATABLE}
      subTenantFilter={{ show: showTenantFilter }}
      includeTenants={showTenantFilter || showSharedRoutesFilter}
      variables={{ routeId, propertyId, showSharedRoutes }}
      // onDelete={onDelete}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
        selectToolbarPlacement: "none"
      }}
    />
  );
};

export default ServiceReportsTable;
