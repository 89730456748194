import { gql, useMutation } from "@apollo/client";
import { SubscriptionTypes } from "./interfaces";

const ADD_SUB = gql`
  mutation addSub($type: String!, $id: String!) {
    addSubscription(id: $id, type: $type)
  }
`;

const REMOVE_SUB = gql`
  mutation removeSub($type: String!, $id: String!) {
    removeSubscription(id: $id, type: $type)
  }
`;

export const useAddOrRemoveSubscription = (
  add: boolean,
  id: string,
  type: SubscriptionTypes
) => {
  const [handleSub, {data}] = useMutation<
    { addSubscription: boolean; removeSubscription: boolean },
    { id: string; type: SubscriptionTypes }
  >(add ? ADD_SUB : REMOVE_SUB, {variables: {id, type}});

    return {
        handleSub,
        success: add? !!data?.addSubscription : !!data?.removeSubscription
    }
};
