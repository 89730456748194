import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { mdiAppleIos, mdiGooglePlay } from "@mdi/js";
import React from "react";
import { useParams } from "react-router-dom";
import { useLoadValetPreCreation } from "../../apollo/preCreations/queries";
import ErrorPage from "../../shared/components/ErrorPage";
import Icon from "../../shared/components/Icon";
import InfoCard from "../../shared/components/InfoCard";
import LoadingPage from "../Home/LoadingPage";
import ValetPreCreationDetails from "./components/ValetPreCreationDetails";
import ValetSelfAgreementForm from "./components/ValetSelfAgreementForm";
import ValetSelfCreationForm from "./components/ValetSelfCreationForm";

const useClasses = makeStyles(theme => ({
  primaryHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem"
    }
  }
}));

const ValetPreRegistrationPage: React.FC = ({}) => {
  let { hash } = useParams<{ hash: string }>();

  const { preCreation, loading, error, refetch } = useLoadValetPreCreation(
    hash
  );
  const classes = useClasses();

  const onValetCreationSuccess = () => {
    refetch();
  };

  if (error) {
    return <ErrorPage code={400} message={error.message} />;
  }

  if (!preCreation) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Typography
            variant="h2"
            color="primary"
            className={classes.primaryHeading}
          >
            Welcome to Disposal Valet
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <ValetPreCreationDetails preCreation={preCreation} />
        </Grid>

        {!preCreation.createdValetUser && (
          <>
            <Grid item xs={12}>
              <Typography>
                You complete this form to have access to the system.
              </Typography>
            </Grid>
            <ValetSelfCreationForm
              preCreation={preCreation}
              onSuccess={onValetCreationSuccess}
            />
          </>
        )}

        {preCreation.createdValetUser &&
          preCreation.agreementStartDate &&
          !preCreation.createdAgreementId && (
            <ValetSelfAgreementForm preCreation={preCreation} onSuccess={onValetCreationSuccess}/>
          )}
      </Grid>
    </Container>
  );
};

export default ValetPreRegistrationPage;
