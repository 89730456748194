import { Container, Button } from "@material-ui/core";
import React from "react";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import useAbility from "../../shared/hooks/useAbility";
import SupplyRequestsTable from "./components/SupplyRequestsTable";

const SupplyRequestsPage: React.FC = ({}) => {
  const ability = useAbility();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Supply Requests" },
        ]}
      />

      <PageHeader
        title={"Supply Requests"}
        actions={
          <>
            {ability.can("create", "SupplyRequests") && (
              <Link to="/dashboard/supplyRequests/form">
                <Button variant="contained" color="primary" style={{marginRight: 4}}>
                  Generate Request
                </Button>
              </Link>
            )}
            {ability.can("read", "SupplyRequests") && (
              <Link to="/dashboard/supplyRequests/items">
                <Button variant="contained" color="primary">
                  View available items
                </Button>
              </Link>
            )}
          </>
        }
      />

      <SupplyRequestsTable />
    </Container>
  );
};

export default SupplyRequestsPage;
