import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback
} from "react";

// import {
//   makeStyles,
// } from "@material-ui/core";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableState,
  MUIDataTableOptions
} from "mui-datatables";
import { DocumentNode } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { PaginatedVariables } from "../../../apollo/shared/interfaces";
import SearchInput from "./SearchInput";
import _ from "lodash";
import useAbility from "../../hooks/useAbility";
import { AppAbility } from "../../contexts/AbilityContext";
import { formatDate } from "../../utils";
import useIsAdmin from "../../hooks/useIsAdmin";
import useUser from "../../hooks/useUser";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import SubTenantSearchBox from "../../../pages/Tenants/components/SubTenantsSearchbox";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { fileURLToPath } from "url";
import useDidMountEffect from "../../hooks/useDidMountEffect";
// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

// const getMuiTheme = () =>
//   createMuiTheme({
//     overrides: {
//       MuiPaper: {
//         root: {
//           backgroundColor: "transparent",
//         },
//       },
//       MuiTableCell: {
//         stickyHeader: {
//           root: {
//             backgroundColor: "transparent",
//           },
//         },
//       },
//     },
//   });

interface SubTenantFilterOptions {
  show: boolean;
  label?: string;
  variableKey?: string;
}
interface Props {
  title?: React.ReactNode;
  showDateFilter?: boolean;
  customArea?: React.ReactNode
  columns: MUIDataTableColumn[];
  query: DocumentNode;
  subTenantFilter?: SubTenantFilterOptions;
  subTenantFilterLabel?: string;
  refetchDependencies?: any[];
  options?: MUIDataTableOptions;
  onDelete?: (selectedItemIds: string[]) => void;
  variables?: { [key: string]: string | number | null | undefined | boolean};
  fetchMore?: (fetchMoreAction: () => void) => void;
  includeTenants?: boolean;
  dateColumns?: { name: string; label?: string }[];
}
const getQueryParent = (data: any) => {
  if (data) {
    const keys = Object.keys(data);
    //we're going to assume that the first key is the one needed, in case of mixed queries being passed to
    //this component.
    if (keys.length) {
      return keys[0];
    }
  }

  return "";
};
const useDataTable = (
  query: DocumentNode,
  initialLimit: number,
  subTenantFilter: SubTenantFilterOptions,
  showDateFilter = false,
  onDelete?: (selectedItemIds: string[]) => void,
  variables?: { [key: string]: string | number | null | undefined| boolean },
  setFetchMore?: (fetchMore: () => void) => void,
  refetchDepenencies?: any[]
) => {
  const subTenantFilterKey = subTenantFilter.variableKey
    ? subTenantFilter.variableKey
    : "subTenantId";
  const [selectedRows, setSelectedRows] = useState<String[]>([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(initialLimit);
  const [count, setCount] = useState(0);
  const [subTenantId, setSubTenantId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState<{
    property?: string;
    direction?: string;
  } | null>(null);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);

  const ability = useAbility();

  const initialRender = useRef(true);
  const queryParent = useRef("");
  const { loading, data, error, fetchMore, refetch } = useQuery<
    any,
    PaginatedVariables
  >(query, {
    variables: {
      offset,
      limit,
      [subTenantFilterKey]: subTenantId,
      ...variables
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      queryParent.current = getQueryParent(data);
    }
  });
  const onRowsDeleted = (selectedRowsData: DeletedRowsParam) => {
    if (!onDelete) return;
    const selectedIndexes = _.chain(selectedRowsData.lookup)
      .pickBy(r => r)
      .keys()
      .value();
    setSelectedRows(selectedIndexes);
    const selectedItems = _.map(
      selectedIndexes,
      i => data[queryParent.current].nodes[i].id
    );
    onDelete(selectedItems);
    return false;
  };
  const getMore = useCallback(() => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
        sortDirection: sort?.direction?.toUpperCase(),
        sortProperty: sort?.property,
        search: searchQuery,
        [subTenantFilterKey]: subTenantId,
        ...variables
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      }
    });
  }, [
    offset,
    limit,
    sort,
    variables,
    subTenantFilterKey,
    subTenantId,
    searchQuery
  ]);

  const afterDelete = useCallback(() => {
    setSelectedRows([]);
    refetch();
  }, [setSelectedRows]);

  useEffect(() => {
    //We're getting the total of elements in the server.
    if (data) {
      //since we don't know the query response key ex. user: {}, roles: {} we need to find it.
      const keys = Object.keys(data);
      //we're going to assume that the first key is the one needed, in case of mixed queries being passed to
      //this component.
      if (keys.length) {
        setCount(c => data[keys[0]].totalsCount);
      }
    }
    if (setFetchMore) setFetchMore(afterDelete);
  }, [data, afterDelete]);

  useLayoutEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    getMore();
  }, [getMore, initialRender.current]);

  const onDateFilterChange = (type: "start" | "end", date: Date | null) => {
    if (type === "start") {
      setStartDateFilter(date);
    } else {
      setEndDateFilter(date);
    }
  };
  const onDateSubmitClick = () => {
    let finalVariables: any = {
      offset: offset,
      limit: limit,
      sortDirection: sort?.direction?.toUpperCase(),
      sortProperty: sort?.property,
      search: searchQuery,
      [subTenantFilterKey]: subTenantId,
      ...variables
    };
    if (showDateFilter) {
      finalVariables = {
        ...finalVariables,
        startDate: startDateFilter,
        endDate: endDateFilter
      };
      console.log("test", finalVariables);
    }
    refetch(finalVariables);
  };

  useDidMountEffect(() => {
    refetch()
  }, refetchDepenencies? refetchDepenencies : []);
  return {
    model: {
      data,
      isLoading: loading,
      error,
      total: count,
      searchQuery: searchQuery,
      queryParent: queryParent.current,
      limit,
      selectedRows,
      ability,
      startDateFilter,
      endDateFilter
    },
    operations: {
      onDateSubmitClick,
      onDateFilterChange,
      changeSearchQuery: setSearchQuery,
      changeSort: setSort,
      getMoreItems: getMore,
      changeOffset: setOffset,
      changeLimit: setLimit,
      onRowsDeleted,
      onSubTenantIdChange: setSubTenantId
    }
  };
};
interface DeletedRowsParam {
  lookup: {
    [dataIndex: number]: boolean;
  };
  data: {
    index: number;
    dataIndex: number;
  }[];
}
const DataTable: React.FC<Props> = ({
  columns,
  query,
  options,
  customArea,
  variables,
  title = null,
  showDateFilter = false,
  onDelete,
  fetchMore,
  includeTenants,
  dateColumns,
  refetchDependencies,
  subTenantFilter = {
    show: false
  },
  subTenantFilterLabel
}) => {
  // const classes = useStyles();
  const {
    model: {
      data,
      queryParent,
      total,
      limit,
      selectedRows,
      ability,
      startDateFilter,
      endDateFilter
    },
    operations: {
      onDateFilterChange,
      onDateSubmitClick,
      changeLimit,
      changeOffset,
      changeSearchQuery,
      changeSort,
      onRowsDeleted,
      onSubTenantIdChange
    }
  } = useDataTable(
    query,
    options?.rowsPerPage ? options.rowsPerPage : 10,
    subTenantFilter,
    showDateFilter,
    onDelete,
    variables,
    fetchMore,
    refetchDependencies
  );
  let finalColumns = getExtraColumns(
    columns,
    ability,
    includeTenants,
    dateColumns
  );

  return (
    <MUIDataTable
      title={
        <>
          {title}
          {showDateFilter && (
            <Box marginY={2}>
              <DateFilter
                onSubmitClick={onDateSubmitClick}
                onDateChange={onDateFilterChange}
                startDate={startDateFilter}
                endDate={endDateFilter}
              />
            </Box>
          )}
          {subTenantFilter.show && (
            <SubTenantFilter
              onSubTenantIdChange={onSubTenantIdChange}
              label={subTenantFilter.label}
            />
          )}

          {customArea && <Box marginY={2}>{customArea}</Box>}
        </>
      }
      data={data ? data[queryParent]?.nodes : []}
      columns={finalColumns}
      options={{
        ...options,

        serverSide: true,
        // elevation: 0,
        onRowsDelete: onRowsDeleted,
        customSearchRender: (
          searchText,
          handleSearch: (text: string) => void,
          hideSearch: (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => void,
          options
        ) => {
          return (
            <SearchInput
              variant="outlined"
              onChange={e => handleSearch(e.target.value)}
              onCloseClick={hideSearch}
            />
          );
        },
        rowsSelected: selectedRows,
        count: total,
        // responsive: "scrollFullHeight",
        onTableChange: (action, tableState: MUIDataTableState) => {
          switch (action) {
            case "sort":
              changeSort({
                property: tableState.sortOrder?.name,
                direction: tableState.sortOrder?.direction
              });
              break;
            case "search":
              const query = tableState.searchText ? tableState.searchText : "";
              changeSearchQuery(query);
              break;
            case "changePage":
              const page = tableState.page;
              changeOffset(page * limit);
              break;
            case "changeRowsPerPage":
              changeLimit(tableState.rowsPerPage);
              changeOffset(tableState.rowsPerPage * tableState.page);
              break;
            default:
              break;
          }
        }
      }}
    />
  );
};

const getExtraColumns = (
  columns: MUIDataTableColumn[],
  ability: AppAbility,
  includeTenants?: boolean,
  dateColumns?: { name: string; label?: string }[]
) => {
  let newColumns = [...columns];
  if (includeTenants) {
    if (ability.can("manage", "all")) {
      newColumns.push({
        name: "tenant",
        label: "Franchise"
      });
    }

    if (ability.can("manage", "SubTenants")) {
      newColumns.push({
        name: "subTenant",
        label: "Sub-Franchise"
      });
    }
  }
  if (dateColumns) {
    const dColumns: MUIDataTableColumn[] = dateColumns.map(
      ({ name, label }): MUIDataTableColumn => ({
        name,
        label,
        options: {
          customBodyRender: value => formatDate(value)
        }
      })
    );
    newColumns = [...newColumns, ...dColumns];
  }
  return newColumns;
};

interface DateFilterProps {
  startDate: Date | null;
  endDate: Date | null;
  onSubmitClick: () => void;
  onDateChange: (type: "start" | "end", date: Date | null) => void;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  onDateChange: onDateChangeProp,
  startDate,
  endDate,
  onSubmitClick
}) => {
  const onDateChange = (type: "start" | "end") => (
    date: MaterialUiPickersDate
  ) => {
    onDateChangeProp(type, date ? date.toDate() : null);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Filter by dates</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <DatePicker
            fullWidth
            format="L"
            label="Start Date"
            inputVariant="outlined"
            variant="inline"
            value={startDate}
            onChange={onDateChange("start")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <DatePicker
            fullWidth
            format="L"
            label="End Date"
            inputVariant="outlined"
            variant="inline"
            minDateMessage="You must pick a later date."
            minDate={startDate}
            value={endDate}
            onChange={onDateChange("end")}
          />
        </Grid>
        <Grid item xs={12} md={2} container>
          <Button
            onClick={onSubmitClick}
            fullWidth
            variant="outlined"
            color="primary"
            disabled={!startDate && !endDate}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
interface SubTenantFilterProps {
  label?: string;
  value?: string;
  loadInitialSubTenant?: boolean;
  onSubTenantIdChange: (subTenantId: string | null) => void;
}
export const SubTenantFilter: React.FC<SubTenantFilterProps> = ({
  onSubTenantIdChange,
  value,
  loadInitialSubTenant,
  label = "Filter by Sub-Franchises"
}) => {
  const isAdmin = useIsAdmin();
  const user = useUser();
  const [subTenantId, setSubTenantId] = useState<null | string>(null);
  useEffect(() => {
    if (!isAdmin && user) {
      onSubTenantIdChange(user.subTenantId);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (isAdmin && loadInitialSubTenant && user) {
      onSubTenantIdChange(user.subTenantId);
      setSubTenantId(user.subTenantId);
    }
  }, []);

  if (isAdmin)
    return (
      <Box marginTop={4}>
        <SubTenantSearchBox
          label={label}
          value={subTenantId}
          onOptionChange={subTenant => {
            onSubTenantIdChange(subTenant ? subTenant.id : null);
            setSubTenantId(subTenant ? subTenant.id : null);
          }}
        />
      </Box>
    );

  return null;
};
export default DataTable;
