import { WorkingDay } from "../../pages/Agreements/CustomerSteps/interfaces";
import { Property } from "../customers/interfaces";
import { Valet } from "../valets/interfaces";
import { Moment } from "moment";
import { User } from "../users/interfaces";
import { SubTenant } from "../tenants/interfaces";

export interface CreateServiceRoute {
  startTime: Moment | null;

  offPropertyTime: Moment | null;

  routeColor: string;

  canBeWorkedOn: boolean;

  propertyId: string;

  areas: CreateServiceRouteArea[];

  monthlyRoutePay: number;

  dailyRoutePay: number;
}

export interface CreateServiceRouteArea {
  name: string;
  points: CreateAreaPoint[];
}

export interface CreateAreaPoint {
  latitude: number;
  longitude: number;
}



export interface CreateRegularServiceRoute extends CreateServiceRoute {
  workingDays: WorkingDay[];
}

export interface UpdateRegularServiceRoute extends CreateRegularServiceRoute {
  id: string;
}

export interface CreateSpecialServiceRoute extends CreateServiceRoute {
  isRecurrent: boolean;
  recurrentDay?: WeekDay | null;
  scheduledDates?: Moment[];
  isMakeUp: boolean;
  makeUpReason?: SpecialServiceMakeUpReason | null;
}


export interface UpdateSpecialServiceRoute extends CreateSpecialServiceRoute {
  id: string;
}

export type ServiceRouteType = "REGULAR" | "SPECIAL";

export interface ServiceRoute {
  id: string;
  routeCode: string;
  isShared: boolean;
  type: ServiceRouteType;
  startTime: Date;
  offPropertyTime: Date;
  routeColor: string;
  canBeWorkedOn: boolean;
  property: Property;
  defaultValet?: Valet;
  sharedDefaultValet?: Valet;
  areas: ServiceRouteArea[];
  supervisor?: User;
  todaysReport?: ServiceReport;
  lastReport?: ServiceReport;
  latitude: number;
  longitude: number;
  text?: string;
  extraData: RegularServiceRoute | SpecialServiceRoute;
  neighborRoutes: ServiceRoute[];
  monthlyRoutePay: number;
  dailyRoutePay: number;
  subTenant: SubTenant;
  propertyId: string;
}

export interface ServiceRouteArea {
  points: { latitude: number; longitude: number }[];

  name: string;
}

export interface RegularServiceRoute {
  workingDays: WorkingDay[];
}

export const isRegularRoute = (
  extraData: SpecialServiceRoute | RegularServiceRoute
): extraData is RegularServiceRoute => {
  return (extraData as RegularServiceRoute).workingDays !== undefined;
};

export const isSpecialRoute = (
  extraData: SpecialServiceRoute | RegularServiceRoute
): extraData is SpecialServiceRoute => {
  return (extraData as SpecialServiceRoute).isRecurrent !== undefined;
};
export type SpecialServiceMakeUpReason =
  | "HOLIDAY"
  | "RESCHEDULE"
  | "BAD_WEATHER"
  | "OTHER";

export type WeekDay =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

interface SpecialServiceRouteDate {
  date: Date;
  isCompleted: boolean;
}
export interface SpecialServiceRoute {
  isRecurrent: boolean;
  recurrentDays?: WorkingDay[];
  singleDate?: Date;
  scheduledDates: SpecialServiceRouteDate[];
  isCompleted: boolean;

  completedDate: Date;

  isMakeUp?: boolean;

  makeUpReason?: SpecialServiceMakeUpReason;
}
export interface ServiceReport {
  id: string;
  startTime: Date;
  endTime: Date;
  isCompleted: boolean;
  userTracks: ServiceReportUserTrack[];
  serviceRouteId: string;
  serviceRoute: ServiceRoute;
  userId: string;
  user: User;
  valet?: Valet;
  valetName: string;
  valetId: string;
}

export interface ServiceReportUserTrack {
  latitude: number;

  longitude: number;

  timestamp: Date;
}

export interface ServiceRouteTableView {
  id: string;

  type: string;

  routeCode: string;

  propertyName: string;

  valetName: string;

  valetId: string;

  customerName: string;

  customerId: string;
}
