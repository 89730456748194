import create from "zustand";
import {
  Modules,
  AuthUser,
  AuthPermissions
} from "../../apollo/auth/interfaces";
import { AnyAbility, Ability, AbilityBuilder, RawRuleOf } from "@casl/ability";
import _ from "lodash";
export type Actions =
  | "create"
  | "read"
  | "update"
  | "delete"
  | "manage"
  | "createOthers"
  | "readOthers"
  | "updateOthers"
  | "deleteOthers"
  | "toggle";
export type Subjects = Modules | "all";
export type AppAbility = Ability<[Actions, Subjects]>;

export type AuthStore = {
  user: AuthUser | null;
  ability: AppAbility;
  isAsSupervisor: boolean;
  isLoading: boolean;
  authError: null | Error;
  authToken: string | null;
  loadUser: (user: AuthUser, permissions: AuthPermissions) => void;
  toggleAdminPermissions: () => void,
  clearUser: () => void;
  updateAuthToken: (token: string | null) => void;
  setAuthError: (error: null | Error) => void;
};

const useAuthStore = create<AuthStore>((set, get) => ({
  authToken: localStorage.getItem("token"),
  isLoading: true,
  authError: null,
  isAsSupervisor: false,
  user: null,
  ability: new Ability<[Actions, Subjects]>(),
  toggleAdminPermissions: () => {
    const ability = get().ability;
    if (ability.can("toggle", "Admin")) {
      const newRules = [...ability.rules];
      
      const foundRuleIndex = _.findIndex(newRules, {
        action: "manage",
        subject: "SubTenants"
      });
     
      if (foundRuleIndex > -1) {
        newRules.splice(foundRuleIndex, 1);
        set({
          ability: new Ability<[Actions, Subjects]>(newRules),
          isAsSupervisor: true
        });
      } else {
        newRules.push({
          action: "manage",
          subject: "SubTenants"
        });
        set({
          ability: new Ability<[Actions, Subjects]>(newRules),
          isAsSupervisor: false
        });
      }
    }
  },
  clearUser: () => {
    if (get().user) {
      set({
        ability: new Ability<[Actions, Subjects]>(),
        user: null,
        authToken: null
      });
      localStorage.removeItem("token");
    }
  },
  loadUser: (user, permissions) => {
    const ability = defineAbility(user, permissions);
    set({ ability, user, isLoading: false, authError: null });
  },
  updateAuthToken: token => {
    set({ authToken: token });
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  },
  setAuthError: error => {
    set({ authError: error, isLoading: false, authToken: null });
  }
}));

const defineAbility = (user: AuthUser, userPermissions: AuthPermissions) => {
  let rules: RawRuleOf<AppAbility>[] = [];
  if (userPermissions.Admin && userPermissions.Admin[0] === "isSuperAdmin") {
    rules = [{ action: "manage", subject: "all" }];
  } else {
    if (userPermissions.Admin && userPermissions.Admin[0] === "isAdmin") {
      rules = [
        { action: "manage", subject: "SubTenants" },
        { action: "toggle", subject: "Admin" }
      ];
    }
    if (userPermissions.Roles) {
      rules = [...rules, ...getModuleRules("Roles", userPermissions.Roles)];
    }
    if (userPermissions.Users) {
      rules = [...rules, ...getModuleRules("Users", userPermissions.Users)];
    }
    if (userPermissions.SubTenants) {
      rules = [
        ...rules,
        ...getModuleRules("SubTenants", userPermissions.SubTenants)
      ];
    }

    if (userPermissions.PropertyManagers) {
      rules = [
        ...rules,
        ...getModuleRules("PropertyManagers", userPermissions.PropertyManagers)
      ];
    }

    if (userPermissions.Customers) {
      rules = [
        ...rules,
        ...getModuleRules("Customers", userPermissions.Customers)
      ];
    }
    if (userPermissions.Valets) {
      rules = [...rules, ...getModuleRules("Valets", userPermissions.Valets)];
    }
    if (userPermissions.ServiceRoutes) {
      rules = [
        ...rules,
        ...getModuleRules("ServiceRoutes", userPermissions.ServiceRoutes)
      ];
    }
    if (userPermissions.Incidents) {
      rules = [
        ...rules,
        ...getModuleRules("Incidents", userPermissions.Incidents)
      ];
    }
    if (userPermissions.SupplyRequests) {
      rules = [
        ...rules,
        ...getModuleRules("SupplyRequests", userPermissions.SupplyRequests)
      ];
    }
    if (userPermissions.TimeOffRequests) {
      rules = [
        ...rules,
        ...getModuleRules("TimeOffRequests", userPermissions.TimeOffRequests)
      ];
    }
    if (userPermissions.SharedCustomers) {
      rules = [
        ...rules,
        ...getModuleRules("SharedCustomers", userPermissions.SharedCustomers)
      ];
    }
  }

  return new Ability<[Actions, Subjects]>(rules);
};

const getModuleRules = (subject: Subjects, permissions: string[]) => {
  const rules = permissions.map<RawRuleOf<AppAbility>>(p => ({
    action: p as Actions,
    subject
  }));

  return rules;
};
const defineAbilityFor = (
  user?: AuthUser,
  userPermissions?: AuthPermissions
) => {
  const { can, rules } = new AbilityBuilder<AppAbility>();
  if (!user || !userPermissions) return new Ability<[Actions, Subjects]>(rules);

  if (userPermissions.Admin && userPermissions.Admin[0] === "isSuperAdmin") {
    can("manage", "all");
  } else {
    if (userPermissions.Admin && userPermissions.Admin[0] === "isAdmin") {
      can("manage", "SubTenants");
      can("toggle", "Admin");
    }
    if (userPermissions.Roles) {
      setModulePermissions("Roles", userPermissions.Roles, can);
    }
    if (userPermissions.Users) {
      setModulePermissions("Users", userPermissions.Users, can);
    }
    if (userPermissions.SubTenants) {
      setModulePermissions("SubTenants", userPermissions.SubTenants, can);
    }

    if (userPermissions.PropertyManagers) {
      setModulePermissions(
        "PropertyManagers",
        userPermissions.PropertyManagers,
        can
      );
    }

    if (userPermissions.Customers) {
      setModulePermissions("Customers", userPermissions.Customers, can);
    }
    if (userPermissions.Valets) {
      setModulePermissions("Valets", userPermissions.Valets, can);
    }
    if (userPermissions.ServiceRoutes) {
      setModulePermissions("ServiceRoutes", userPermissions.ServiceRoutes, can);
    }
    if (userPermissions.Incidents) {
      setModulePermissions("Incidents", userPermissions.Incidents, can);
    }
    if (userPermissions.SupplyRequests) {
      setModulePermissions(
        "SupplyRequests",
        userPermissions.SupplyRequests,
        can
      );
    }
  }

  return new Ability<[Actions, Subjects]>(rules);
};

const setModulePermissions = (
  subject: Subjects,
  permissions: string[],
  can: any //TODO: add the real type
) => {
  permissions.forEach(permission => {
    can(permission, subject);
  });
};

export default useAuthStore;
