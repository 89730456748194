import React from "react";
import { Container, Button, Box } from "@material-ui/core";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import DataTable from "../../shared/components/Datatable";
import { GET_TENANTS } from "../../apollo/tenants/queries";

import SubTenantsTable from "./components/SubTenantsTable";

// const useStyles = makeStyles((theme) => ({
//   createButtonContainer: {
//     justifyContent: "flex-start",
//     marginBottom: theme.spacing(1),
//     [theme.breakpoints.up("sm")]: {
//       justifyContent: "flex-end",
//       marginBottom: theme.spacing(0),
//     },
//   },
// }));

const TenantsListPage: React.FC = () => {
  // const classes = useStyles();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Franchises" },
        ]}
      />

      <PageHeader
        title={"Franchises"}
        actions={
          <Link to="/dashboard/franchises/create">
            <Button variant="contained" color="primary">
              Create Franchise
            </Button>
          </Link>
        }
      />
      <DataTable
        columns={[
          {
            name: "id",
            options: {
              display: "false",
              filter: false,
            },
          },
          {
            name: "name",
            label: "Name",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <Link to={"/dashboard/franchises/" + tableMeta.rowData[0]}>
                    {value}
                  </Link>
                );
              },
            },
          },
          {
            name: "createdAt",
            label: "Created At",
          },
        ]}
        query={GET_TENANTS}
        options={{
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 30],
          download: false,
          print: false,
          filter: false,
        }}
      />
      <Box marginTop={10}>
        <SubTenantsTable />
      </Box>
    </Container>
  );
};

export default TenantsListPage;
