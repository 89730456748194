import { useMemo } from "react"
import useAbility from "./useAbility"

export default () => {
    const ability = useAbility()
    
    const isAdmin = useMemo(() => {
        return ability.can('manage', 'SubTenants') 
    }, [ability])

    return isAdmin;
}