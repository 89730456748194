import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { EditRounded, DeleteRounded } from "@material-ui/icons";
import { Permission, Role } from "../../../apollo/roles/interfaces";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_ROLE } from "../../../apollo/roles/queries";
import TransferList from "../../../shared/components/TransferList";
import useDebounce from "../../../shared/hooks/useDebounce";
import useValue from "../../../shared/hooks/useValue";
import { UPDATE_ROLE_PERMISSIONS } from "../../../apollo/roles/mutations";
import FabMenu from "../../../shared/components/FabMenu";
import useAbility from "../../../shared/hooks/useAbility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0, 3, 0),
    position: "relative",
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
  },
}));

interface RoleBasePageProps {
  roleId?: string;
  onEditClick?: RoleActionFunction;
  onDeleteClick?: RoleActionFunction;
}
type RoleActionFunction = (role: Role) => void;
const useRoleBasePage = (
  roleId: string = "",
  onEditClick?: RoleActionFunction,
  onDeleteClick?: RoleActionFunction
) => {
  const [unsetPermissions, setUnsetPermissions] = useState<Permission[]>([]);
  const [setPermissions, setSetPermissions] = useState<Permission[]>([]);
  const debouncedPermissions = useDebounce(setPermissions, 1000);
  const [initialRoleLoad, setInitialRoleLoad] = useValue(true);
  const [updatePermissions] = useMutation<
    any,
    { roleId: string; permissionIds: String[] }
  >(UPDATE_ROLE_PERMISSIONS);
  const { data } = useQuery<{ role: Role }, { roleId: string }>(
    GET_ROLE,
    {
      variables: {
        roleId: roleId ? roleId : "",
      },
      onCompleted: ({ role: { unsetPermissions, permissions } }) => {
        setInitialRoleLoad(true);
        if (unsetPermissions) setUnsetPermissions(unsetPermissions);

        if (permissions) setSetPermissions(permissions);
      },
    }
  );
  const ability = useAbility();

  const handleEditClick = () => {
    if (onEditClick && data?.role) onEditClick(data.role);
  };

  const handleDeleteClick = () => {
    if (onDeleteClick && data?.role) onDeleteClick(data.role);
  };
  const onUpdatePermissions = (permissions: Permission[]) => {
    const permissionIds = permissions.map((p) => p.id);
    updatePermissions({
      variables: {
        roleId: roleId,
        permissionIds,
      },
    });
  };

  useEffect(() => {
    if (!initialRoleLoad) {
      console.log("changed");
      onUpdatePermissions(debouncedPermissions);
    } else {
      setInitialRoleLoad(false);
    }
    return () => {};
  }, [debouncedPermissions]);

  return {
    model: {
      unsetPermissions,
      setPermissions,
      role: data?.role,
      ability
    },
    commands: {
      onUnsetPermissionsChange: setUnsetPermissions,
      onSetPermissionsChange: setSetPermissions,
      handleEditClick,
      handleDeleteClick,
    },
  };
};
const RoleBasePage: React.FC<RoleBasePageProps> = ({
  roleId,
  onDeleteClick,
  onEditClick,
}) => {
  const {
    model: { role, unsetPermissions, setPermissions, ability },
    commands: {
      onSetPermissionsChange,
      onUnsetPermissionsChange,
      handleEditClick,
      handleDeleteClick,
      
    },
  } = useRoleBasePage(roleId, onEditClick, onDeleteClick);
  const classes = useStyles();

  if (!roleId) {
    return null;
  }

  const description = `Here you can ${ability.can('update', 'Roles') ? 'set or unset' : 'view'} this role's permissions.`

  return (
    <Paper className={classes.root}>
      <FabMenu
       
        actions={[
          {
            icon: <EditRounded />,
            name: "Edit",
            onClick: handleEditClick,
            disabled: !ability.can('update', 'Roles')
          },
          {
            icon: <DeleteRounded />,
            name: "Delete",
            onClick: handleDeleteClick,
            disabled: !ability.can('delete', 'Roles')
          },
        ]}
      />
      <Container>
        <div className={classes.headerContainer}>
          <Typography variant="h4" gutterBottom>
            {role?.name}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {role?.description}
          </Typography>
        </div>
        <Typography variant="h5" gutterBottom>
          Permissions
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {description}
        </Typography>
        <TransferList<Permission>
          leftItems={unsetPermissions}
          rightItems={setPermissions}
          sectionKey={"module"}
          onLeftItemsChange={onUnsetPermissionsChange}
          onRightItemsChange={onSetPermissionsChange}
          displayKey="name"
          leftTitle="Unset Permisions"
          rightTitle="Set Permissions"
          viewOnly={!ability.can('update', 'Roles')}
        />
      </Container>
    </Paper>
  );
};

interface PermissionsListProps {
  permissions: Permission[],

}

export default RoleBasePage;
