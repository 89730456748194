import React from "react";
import SearchBox, {
  BaseSearchBoxProps
} from "../../../shared/components/SearchBox";
import { RegionalManager } from "../../../apollo/propertyManagers/interfaces";
import { GET_REGIONAL_MANAGERS_SELECT } from "../../../apollo/propertyManagers/queries";

interface RegionalManagersSelectProps
  extends BaseSearchBoxProps<RegionalManager> {
  subTenantId?: string | null;
}

const RegionalManagersSelect: React.FC<RegionalManagersSelectProps> = props => {
  return (
    <SearchBox<RegionalManager>
      {...props}
      variables={
        props.subTenantId ? { subTenantId: props.subTenantId } : undefined
      }
      query={GET_REGIONAL_MANAGERS_SELECT}
      variable="regionalManagers"
      labelKey="name"
    />
  );
};

export default RegionalManagersSelect;
