import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  makeStyles,
  createStyles,
  Button,
} from "@material-ui/core";
const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);
export interface ChildStep {
  label: string;
  node: React.ReactNode;
  onBackPress: () => void;
  onNextPress: () => void;
  backLabel?: string;
  nextLabel?: string;
}
interface StepperProps {
  steps: ChildStep[];
  activeStep?: number;
}
const CustomStepper: React.FC<StepperProps> = ({ activeStep = 0, steps }) => {
  if (steps.length === 1) {
    return <>{steps[0].node}</>;
  }

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, i) => (
        <Step key={i} active={activeStep === i}>
          <StepLabel>
            <Typography variant="h6">{step.label}</Typography>
          </StepLabel>
          <StepContent>
            {step.node}
            <StepsActions
              onBackPress={step.onBackPress}
              onNextPress={step.onNextPress}
              activeStep={activeStep}
              backLabel={step.backLabel}
              nextLabel={step.nextLabel}
            />
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};
interface StepsActionsProps {
  activeStep: number;
  onBackPress: () => void;
  onNextPress: () => void;
  backLabel?: string;
  nextLabel?: string;
}
const StepsActions: React.FC<StepsActionsProps> = ({
  activeStep,
  onBackPress,
  onNextPress,
  backLabel = "back",
  nextLabel = "next",
}) => {
  const classes = useStyles();
  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          disabled={activeStep === 0}
          onClick={onBackPress}
          className={classes.button}
        >
          {backLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onNextPress}
          className={classes.button}
        >
          {nextLabel}
        </Button>
      </div>
    </div>
  );
};
export default CustomStepper;
