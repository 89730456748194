import React, { useEffect } from "react";
import { Button, Container, Fab, Paper } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useCreateUpdateTimeOffRequest } from "../../apollo/timeOffRequests/mutations";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import PageHeader from "../../shared/components/PageHeader";
import useSnackbar from "../../shared/hooks/useSnackbar";
import TimeOffRequestForm, {
  getTimeOffRequestFormInitialValues,
  getTimeOffRequestFormValidationSchema,
  TimeOffRequestFormValues
} from "./components/TimeOffRequestForm";
import { useHistory, useParams } from "react-router-dom";
import { useGetTimeOffRequestForForm } from "../../apollo/timeOffRequests/queries";
import ErrorPage from "../../shared/components/ErrorPage";
import LoadingPage from "../Home/LoadingPage";

const TimeOffRequestFormPage: React.FC = ({}) => {
  const { requestId } = useParams<{ requestId?: string }>();
  const {
    request,
    submit,
    submitError,
    submitLoading
  } = useCreateUpdateTimeOffRequest(!!requestId);
  const history = useHistory();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const { foundRequest, foundRequestError } = useGetTimeOffRequestForForm(
    requestId
  );

  const onSubmit = (values: TimeOffRequestFormValues) => {
    submit({ ...values, id: requestId });
  };

  useEffect(() => {
    if (submitError) {
      sendErrorMessage("Error submitting the request: " + submitError.message);
    }
    return () => {};
  }, [submitError]);

  useEffect(() => {
    if (request) {
      sendSuccessMessage("The request was submitted successfully.");
      history.push("/dashboard/timeOffRequests/" + request.id);
    }
    return () => {};
  }, [request]);

  if (requestId && foundRequestError) return <ErrorPage />;

  if (requestId && !foundRequest) return <LoadingPage />;

  return (
    <Container>
      <LoadingBackdrop open={submitLoading} />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Time-Off Requests", link: "/dashboard/timeOffRequests/" },
          { label: "Time-Off Requests Form" }
        ]}
      />
      <PageHeader title="Generate Time-Off Request" />
      <Formik
        onSubmit={onSubmit}
        initialValues={getTimeOffRequestFormInitialValues(foundRequest)}
        validationSchema={getTimeOffRequestFormValidationSchema()}
      >
        {({ submitForm }) => (
          <Paper style={{ padding: 20, marginTop: 10 }}>
            <Form>
              <TimeOffRequestForm />
            </Form>
          </Paper>
        )}
      </Formik>
    </Container>
  );
};

export default TimeOffRequestFormPage;
