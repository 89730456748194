import React from "react";
import MarkersMap, { LocationMarker, MarkersMapProps } from "../Map/MarkersMap";
import { Box, Typography } from "@material-ui/core";
import { useField } from "formik";

interface MarkersFieldProps
  extends Pick<
    MarkersMapProps,
    "areas" | "initialCenter" | "currentLocation" | "initialZoom" | "markers"
  > {
  name: string;
}

const MarkersField: React.FC<MarkersFieldProps> = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const onLocationsChange = (markers: LocationMarker[]) => {
    helpers.setValue(
      markers.map(({ key, latitude, longitude }) => ({
        key,
        latitude,
        longitude,
      }))
    );
  };
  return (
    <Box flex={0.95}>
      <MarkersMap
        onLocationsChange={onLocationsChange}
        initialMarkers={meta.initialValue}
        {...props}
      />
      {meta.error && (
        <Typography gutterBottom variant="caption" color="error">
          {meta.error}
        </Typography>
      )}
    </Box>
  );
};

export default MarkersField;
