import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import {
  ButtonBase,
  Box,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
  Divider,
  LinearProgress,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import {
  PersonRounded,
  SettingsRounded,
  PowerSettingsNewRounded,
  NotificationsRounded,
  MenuRounded
} from "@material-ui/icons";
import jwtDecode from "jwt-decode";
import useOngoingProcess from "../shared/hooks/useOngoingProcess";
import useAuthToken from "../shared/hooks/useAuthToken";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import useAuthStore from "../shared/hooks/useAuthStore";

interface UserData {
  name: string;
  sub: string;
  username: string;
  email: string;
}

const useStyles = makeStyles(theme => {
  return {
    toolbar: {
      display: "flex",
      flexDirection: "column"
    },
    mainContainer: {
      flex: 1
    },
    avatar: {
      // color: theme.palette.secondary.light,
      // backgroundColor: theme.palette.secondary.dark,
    },
    navButton: {
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
      borderRadius: theme.shape.borderRadius
    },
    notificationButton: {
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(4)
      }
    }
  };
});

interface Props {
  showDrawerButton?: boolean;
  showRoleToggle?: boolean;
  showNotificationsButton?: boolean;
  onMenuClick?: () => void;
}
const TopBar: React.FC<Props> = ({
  onMenuClick,
  showDrawerButton = true,
  showNotificationsButton = true,
  showRoleToggle = true
}) => {
  const {
    user,
    updateAuthToken,
    ability,
    isAsSupervisor,
    toggleAdminPermissions
  } = useAuthStore(
    ({
      authToken,
      user,
      isAsSupervisor,
      updateAuthToken,
      ability,
      toggleAdminPermissions
    }) => ({
      isAsSupervisor,
      authToken,
      user,
      updateAuthToken,
      ability,
      toggleAdminPermissions
    })
  );
  // const user: UserData | undefined = token? jwtDecode(token) : undefined;
  const client = useApolloClient();
  const classes = useStyles();
  const { ongoingProcess } = useOngoingProcess();
  const history = useHistory();
  const signOut = () => {
    updateAuthToken(null);
    history.push("/");
    client.clearStore();
  };
  return (
    <Toolbar className={classes.toolbar}>
      <Grid container alignItems="stretch" className={classes.mainContainer}>
        {showDrawerButton && (
          <Grid xs={2} item container alignItems="center">
            <IconButton onClick={onMenuClick}>
              <MenuRounded />
            </IconButton>
          </Grid>
        )}

        <Grid xs={10} justify="flex-end" alignItems="center" item container>
          {showRoleToggle && (
            <FormControlLabel
              control={
                <Switch
                  checked={isAsSupervisor}
                  onChange={toggleAdminPermissions}
                  color="primary"
                />
              }
              label="View as Sub-Franchise Owner"
            />
          )}

          {showNotificationsButton && (
            <IconButton className={classes.notificationButton}>
              <NotificationsRounded />
            </IconButton>
          )}

          <Box>
            <ResponsiveMenu
              anchor={
                <>
                  <Typography variant="subtitle1" display="inline">
                    Hello, {user?.firstName}
                  </Typography>
                  <Avatar
                    style={{ marginLeft: 5 }}
                    className={classes.avatar}
                  />
                </>
              }
              anchorSmall={<Avatar className={classes.avatar} />}
              items={[
                // { label: "Profile", icon: <PersonRounded /> },
                // { label: "Settings", icon: <SettingsRounded /> },
                {
                  label: "Sign Out",
                  icon: <PowerSettingsNewRounded />,
                  onClick: signOut
                }
              ]}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", marginTop: 7 }} />
      <LinearProgress
        style={{
          width: "100%",
          visibility: ongoingProcess ? "visible" : "hidden"
        }}
      />
    </Toolbar>
  );
};

interface ResponsiveMenuItem {
  onClick?: () => void;
  icon: React.ReactNode;
  label: string;
}
interface ResponsiveMenuProps {
  anchor: React.ReactNode | string;
  anchorSmall: React.ReactNode | string;
  items: ResponsiveMenuItem[];
}
const ResponsiveMenu: React.FC<ResponsiveMenuProps> = ({
  anchor,
  anchorSmall,
  items
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  };
  const hideMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <ButtonBase className={classes.navButton} onClick={showMenu}>
        {isXs ? anchorSmall : anchor}
      </ButtonBase>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={hideMenu}
      >
        {items.map(item => (
          <MenuItem
            key={item.label}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              hideMenu();
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TopBar;
