import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

import { initialAuthData, AuthMutations } from "./auth/resolvers";
import { InicidentPaginatedPolicy } from "./incidents/queries";
import { remoteLog } from "../logger";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    let fileVariables: any = {};
    for (const parentKey in operation.variables) {
      const parent = operation.variables[parentKey];
      if (Array.isArray(parent)) {
      } else if (typeof parent === "object") {
        for (const childKey in parent) {
          const possibleFile = parent[childKey];
          if (possibleFile instanceof File) {
            console.log("isFile");
            fileVariables[parentKey] = {
              ...fileVariables[parentKey],
              [childKey]: possibleFile
            };
          }
        }
      }
    }
    const omitTypename = (key: string | number, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );

    for (const parentKey in fileVariables) {
      const parent = fileVariables[parentKey];

      for (const childKey in parent) {
        const file = parent[childKey];
        operation.variables = {
          ...operation.variables,
          [parentKey]: {
            ...operation.variables[parentKey],
            [childKey]: file
          }
        };
      }
    }
  }
  return forward(operation).map(data => {
    return data;
  });
});

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        ...InicidentPaginatedPolicy
      }
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
    {
      remoteLog(message,   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` );
      console.warn( `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    });

  if (networkError){
    remoteLog(networkError.name, networkError.message, networkError.stack)
    console.error(`[Network error]: ${networkError}`)
  };
});

const httpLink = (createUploadLink({
  uri: "/graphql"
}) as unknown) as ApolloLink;

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      timezone: timezone,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});
const client = new ApolloClient({
  cache,
  // typeDefs: localSchema,

  link: cleanTypeName.concat(authLink.concat(errorLink.concat(httpLink)))
});

// cache.writeData({
//   data: {
//     ...initialAuthData,
//   },
// });

export default client;
