import React from "react";
import { PropertyManager } from "../../../apollo/propertyManagers/interfaces";
import { DocumentNode } from "graphql";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  makeStyles,
  createStyles,
  MenuItem,
  Box,
} from "@material-ui/core";

import { FieldArray, Formik, FormikErrors } from "formik";

import useFormModal, { getFieldError, getFormValidation } from "./useFormModal";
import TenantSearchbox from "../../Tenants/components/TenantSearchbox";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface FormModalProps {
  type: "company" | "manager";
  item?: PropertyManager | null;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  subTenantId?: string | null;
  renderTenantSelect?: boolean;
}

const FormModal: React.FC<FormModalProps> = ({
  type,
  item,
  open,
  onClose,
  onError,
  onSuccess,
  renderTenantSelect,
  subTenantId
}) => {
  const {
    model: { isSubmitting, isEdit, initialFormValues },
    commands: { onSubmit },
  } = useFormModal(type, item, onSuccess, onError, subTenantId);
  const title =
    type === "company"
      ? `${isEdit ? "Update" : "New"} Management Company`
      : `${isEdit ? "Update" : "New"} Regional Manager`;

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      keepMounted={false}
      maxWidth="lg"
      fullWidth
      onClose={isSubmitting ? undefined : onClose}
    >
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={getFormValidation(renderTenantSelect)}
        validateOnMount={false}
        validateOnBlur={false}
      >
        {({ handleChange, handleSubmit, submitForm, errors, values }) => (
          <>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* {renderTenantSelect && (
                    <Grid item xs={12}>
                      <SubTenantSearchBox
                        value={values.subTenantId}
                        onChange={handleChange}
                        name="subTenantId"
                        label="Sub-Franchise"
                        error={errors.subTenantId}
                      />
                    </Grid>
                  )} */}
                  <Grid item xs={12}>
                    <TextField
                      value={values.name}
                      onChange={handleChange}
                      fullWidth
                      name="name"
                      id="form-name"
                      label="Name"
                      variant="outlined"
                      helperText={errors.name}
                      error={!!errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="contacts"
                      render={(arrayHelpers) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <Box marginY={2}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  arrayHelpers.push({
                                    value: "",
                                    type: "phone",
                                  })
                                }
                              >
                                Add Contact
                              </Button>
                            </Box>
                          </Grid>

                          {values.contacts.map((contact, index) => (
                            <ContactItemField
                              index={index}
                              key={index}
                              handleChange={handleChange}
                              contactType={contact.type}
                              contactValue={contact.value}
                              disableRemove={values.contacts.length <= 1}
                              onRemove={() => arrayHelpers.remove(index)}
                              errors={errors.contacts}
                            />
                          ))}
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>

            <DialogActions>
              <Button color="primary" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isEdit ? "Update" : "Create"}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
interface ContactItemFieldProps {
  index: number;
  contactValue: string;
  contactType: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  errors?:
    | string
    | string[]
    | FormikErrors<{
        type: "phone" | "email";
        value: string;
      }>[]
    | undefined;
  onRemove: () => void;
  disableRemove: boolean;
}

const ContactItemField: React.FC<ContactItemFieldProps> = ({
  index,
  contactValue,
  contactType,
  handleChange,
  errors,
  disableRemove,
  onRemove,
}) => {
  const error = getFieldError(index, "value", errors);
  return (
    <Grid item xs={12} container spacing={1} style={{ marginBottom: 20 }}>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          select
          name={`contacts.${index}.type`}
          value={contactType}
          variant="outlined"
          label="Type"
          onChange={handleChange}
        >
          <MenuItem value={"email"}>Email</MenuItem>
          <MenuItem value={"phone"}>phone</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          value={contactValue}
          onChange={handleChange}
          fullWidth
          name={`contacts.${index}.value`}
          label="Value"
          variant="outlined"
          helperText={error}
          error={!!error}
        />
      </Grid>
      <Grid item xs={12} md={2} container>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          type="button"
          disabled={disableRemove}
          onClick={onRemove} // remove a friend from the list
        >
          -
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormModal;
