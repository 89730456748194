import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Grid,
  makeStyles,
  createStyles,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_ROLE, UPDATE_ROLE } from "../../../apollo/roles/mutations";
import { CreateRole, Role } from "../../../apollo/roles/interfaces";
import useSnackbar from "../../../shared/hooks/useSnackbar";

interface RoleFormValues {
  name: string;
  description: string;
  isAdmin: boolean;
}
interface RoleFormModalProps {
  onClose?: () => void;
  open: boolean;
  onSuccess?: (newRole: Role) => void;
  editRole?: Role;
}

const RoleFormValidationSchema = Yup.object<RoleFormValues>({
  isAdmin: Yup.boolean().required(),
  name: Yup.string().required("This field is required"),
  description: Yup.string()
    .required("This field is required")
    .min(10, "This description is too short."),
});

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const useRoleFormModal = (
  onSuccess?: (role: Role) => void,
  onClose?: () => void,
  editRole?: Role
) => {
  const isEdit = !!editRole;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const [submitRole] = useMutation<
    { createRole: Role; updateRole: Role },
    { role: CreateRole; id?: string }
  >(isEdit ? UPDATE_ROLE : CREATE_ROLE, {
    onCompleted: ({ createRole, updateRole }) => {
      sendSuccessMessage(`Role ${isEdit ? "updated" : "created"} succesfully.`);

      if (onSuccess) onSuccess(isEdit ? updateRole : createRole);
      if (onClose) onClose();
      setIsSubmitting(false);
      setValues(
        {
          name: "",
          description: "",
          isAdmin: false,
        },
        false
      );
    },
    onError: (error) => {
      setIsSubmitting(false);
      sendErrorMessage(error.message);
    },
  });

  //   const { ongoingProcess, setOngoingProcess } = useOngoingProcess();
  const onFormSubmit = (values: RoleFormValues) => {
    setIsSubmitting(true);
    submitRole({
      variables: {
        role: values,
        id: editRole?.id,
      },
    });
  };
  const onSubmitClick = () => {
    submitForm();
  };

  const {
    handleSubmit,
    handleChange,
    submitForm,
    values,
    errors,
    setValues,
  } = useFormik<RoleFormValues>({
    initialValues: {
      name: editRole ? editRole.name : "",
      description: editRole ? editRole.description : "",
      isAdmin: editRole ? editRole.isAdmin : false,
    },
    onSubmit: onFormSubmit,
    validationSchema: RoleFormValidationSchema,
  });

  useEffect(() => {
    if (editRole) {
      setValues({
        name: editRole.name,
        description: editRole.description,
        isAdmin: editRole.isAdmin,
      });
    }
  }, [editRole, setValues]);
  return {
    model: {
      values,
      errors,
      isSubmitting: isSubmitting,
      isEdit: !!editRole,
    },
    commands: {
      handleSubmit,
      handleChange,
      onSubmitClick,
    },
  };
};
const RoleFormModal: React.FC<RoleFormModalProps> = ({
  open,
  onClose,
  onSuccess,
  editRole,
}) => {
  const {
    model: { values, errors, isSubmitting, isEdit },
    commands: { onSubmitClick, handleChange, handleSubmit },
  } = useRoleFormModal(onSuccess, onClose, editRole);

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      keepMounted={false}
      maxWidth="lg"
      onClose={isSubmitting ? undefined : onClose}
    >
      <DialogTitle id="form-dialog-title">
        {isEdit ? "Edit Role" : "New Role"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={values.name}
                onChange={handleChange}
                fullWidth
                name="name"
                id="role-name"
                label="Name"
                variant="outlined"
                helperText={errors.name}
                error={!!errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={values.description}
                fullWidth
                onChange={handleChange}
                name="description"
                id="role-description"
                label="Description"
                variant="outlined"
                helperText={errors.description}
                error={!!errors.description}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label="is Admin?"
                control={
                  <Switch
                    checked={values.isAdmin}
                    onChange={handleChange}
                    name="isAdmin"
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={onSubmitClick}
            disabled={isSubmitting}
          >
            {isEdit ? "Update" : "Create"}
          </Button>
          {isSubmitting && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RoleFormModal;
