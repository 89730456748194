import React from "react";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { CustomerAgreement } from "../../../apollo/agreements/interface";

export interface FreeWritingStepFormValues {
  extraData?: string | null;
  bonus?: string | null;
}


export const getFreeWritingStepValidationSchema = () => {
  return Yup.object<FreeWritingStepFormValues>({
    extraData: Yup.string().optional().nullable(),
    bonus: Yup.string().optional().nullable(),
  })
};

export const getFreeWritingStepInitialValues = (agreement?: CustomerAgreement): FreeWritingStepFormValues => {
  return {
    extraData:agreement? agreement.extraData : '',
    bonus: agreement?.bonus || ''
  };
};

const FreeWritingStep: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">Here you can add any other information that should be on the agreement.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          variant="outlined"
          name="extraData"
          fullWidth
          multiline
          rows={2}
          rowsMax={4}

        />
      </Grid>

      <Grid item xs={12}>
        <Field
          component={TextField}
          variant="outlined"
          name="bonus"
          label="Bonus"
          fullWidth
          multiline
          rows={2}
          rowsMax={4}

        />
      </Grid>
    </Grid>
  );
};

export default FreeWritingStep;
