import React from "react";
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import UserForm from "./components/UserForm";
import AddressForm from "../../shared/components/AddressForm";
import PageHeader from "../../shared/components/PageHeader";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import useUsersFormPage from "./useUsersFormPage";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

const UsersFormPage: React.FC = () => {
  const {
    model: {
      activeStep,
      userForm,
      addressForm,
      processLoading,
      isEdit,
      sections,
      ability
    },
    commands: { handleBack, handleNext },
  } = useUsersFormPage();

  const classes = useStyles();
  return (
    <Container>
      <LoadingBackdrop open={processLoading} />
      <BreadcrumbsSection sections={sections} />
      <PageHeader title={isEdit ? "Updating User" : "Creating User"} />
      <Paper>
        <Container>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step active={activeStep === 0}>
              <StepLabel>
                <Typography variant="h6">{"Basic Information"}</Typography>
              </StepLabel>
              <StepContent>
                <form onSubmit={userForm.handleSubmit}>
                  <UserForm
                    handleChange={userForm.handleChange}
                    values={userForm.values}
                    errors={userForm.errors}
                    isEdit={isEdit}
                    renderSubTenantSelect={ability.can('manage', 'SubTenants')}
                  />
                </form>
                <StepsActions
                  onBackPress={handleBack}
                  onNextPress={handleNext}
                  activeStep={activeStep}
                  isEdit={isEdit}
                />
              </StepContent>
            </Step>
            <Step active={activeStep === 1}>
              <StepLabel>
                <Typography variant="h6">{"Address Information"}</Typography>
              </StepLabel>
              <StepContent>
                <AddressForm
                  searchOnly={true}
                  handleChange={addressForm.handleChange}
                  values={addressForm.values}
                  errors={addressForm.errors}
                  setValues={addressForm.setValues}
                />
                <StepsActions
                  onBackPress={handleBack}
                  onNextPress={handleNext}
                  activeStep={activeStep}
                  isEdit={isEdit}
                />
              </StepContent>
            </Step>
          </Stepper>
        </Container>
      </Paper>
    </Container>
  );
};

interface StepsActionsProps {
  activeStep: number;
  onBackPress: () => void;
  onNextPress: () => void;
  isEdit: boolean;
}
const StepsActions: React.FC<StepsActionsProps> = ({
  activeStep,
  onBackPress,
  onNextPress,
  isEdit,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          disabled={activeStep === 0}
          onClick={onBackPress}
          className={classes.button}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onNextPress}
          className={classes.button}
        >
          {activeStep === 1 ? (isEdit ? "Update" : "Create") : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default UsersFormPage;
