import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextFieldProps,
  ListItemIcon
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";

import SearchInput from "./Datatable/SearchInput";

import { useField } from "formik";
import { CheckRounded } from "@material-ui/icons";
import useDebounce from "../hooks/useDebounce";
import useDidMountEffect from "../hooks/useDidMountEffect";

interface ItemSelectModalProps<T> {
  name?: string;
  items?: T[];
  value?: T | null;
  onItemClick?: (item: T) => void;
  matchingKey: keyof T;
  title?: keyof T | ((item: T) => string);
  subtitle?: keyof T | ((item: T) => string);
  onSearchChange?: (search: string) => void;
  total?: number;
  limit?: number;
  onOffsetChange: (page: number) => void;
  InputProps?: TextFieldProps;
  noItemsText?: string;
  modalTitle?: string;
}

type Props<T> = ItemSelectModalProps<T>;
const ItemSelectModal = <T,>({
  name,
  items,
  value,
  onItemClick: onItemClickProp,
  title,
  subtitle,
  matchingKey,
  total = 0,
  limit = 5,
  onSearchChange,
  onOffsetChange,
  InputProps,
  noItemsText,
  modalTitle
}: Props<T>): React.ReactElement => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(value);
  const debouncedSearch = useDebounce(search, 500);
  const [showModal, setShowModal] = useState(false);

  const onItemClick = (item: T) => () => {
    if (value !== undefined) setSelectedItem(item);
    if (onItemClickProp) onItemClickProp(item);
    onModalClose();
  };

  const onInputClick = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const onPageChange = (page: number) => {
    onOffsetChange(limit * (page - 1));
    setCurrentPage(page);
  };

  const renderTitle = (item: T) => {
    if (typeof title === "function") {
      return title(item);
    }
    return (item[title as keyof T] as unknown) as string;
  };

  const renderSubtitle = (item: T) => {
    if (!subtitle) return;
    if (typeof subtitle === "function") {
      return subtitle(item);
    }
    return (item[subtitle as keyof T] as unknown) as string;
  };

  useDidMountEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearch);
    }
  }, [onSearchChange, debouncedSearch]);

  useDidMountEffect(() => {
    if (value !== undefined) {
      setSelectedItem(value);
    }
  }, [value]);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modalTitle || "Select an item"}
        </DialogTitle>
        <DialogContent>
          <SearchInput
            onChange={e => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Search a valet"
          />

          {items && items.length ? (
            <>
              <List>
                {items.map((item, i) => (
                  <ListItem
                    key={renderTitle(item) + i}
                    button
                    onClick={onItemClick(item)}
                    style={{ borderRadius: 20 }}
                  >
                    <ListItemAvatar>
                      <Avatar>{renderTitle(item).charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={renderTitle(item)}
                      secondary={renderSubtitle(item)}
                    />
                    {selectedItem &&
                      selectedItem[matchingKey] === item[matchingKey] && (
                        <ListItemIcon>
                          <CheckRounded color="secondary" />
                        </ListItemIcon>
                      )}
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.round(total / limit)}
                page={currentPage}
                onChange={(e, v) => onPageChange(v)}
              />
            </>
          ) : (
            <Typography>{noItemsText || "No items found."}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        {...InputProps}
        InputProps={{
          ...InputProps?.InputProps,
          readOnly: true
        }}
        onClick={onInputClick}
        value={selectedItem ? renderTitle(selectedItem) : ""}
      />
    </>
  );
};

export default ItemSelectModal;
