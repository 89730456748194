import React, { useState } from "react";
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Paper,
  makeStyles,
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import ManagementCompanySection from "./components/ManagementCompanySection";
import RegionalManagerSection from "./components/RegionalManagerSection";
import { SubTenantFilter } from "../../shared/components/Datatable";
import { subtract } from "lodash";

const useStyles = makeStyles(theme => ({
  tabsBar: {
    // padding: theme.spacing(0, 3, 0, 3),
  }
}));

const PropertyManagersPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("0");
  const [subTenantId, setSubTenantId] = useState<null | string>(null);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
  };

  const classes = useStyles();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          {
            label: "Home",
            link: "/dashboard"
          },
          {
            label: "Property Managers"
          }
        ]}
      />

      <PageHeader title="Property Managers" />
      <SubTenantFilter onSubTenantIdChange={setSubTenantId} loadInitialSubTenant />
      <TabContext value={activeTab}>
        {/* <Paper className={classes.tabsBar}> */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          variant={"scrollable"}
          scrollButtons={matchesXs ? "on" : "off"}
        >
          <Tab label="Management Companies" wrapped value={"0"} />
          <Tab label="Regional Managers" wrapped value={"1"} />
        </Tabs>

        {/* </Paper> */}

        <TabPanel value={"0"} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <ManagementCompanySection subTenantId={subTenantId} />
        </TabPanel>
        <TabPanel value={"1"} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <RegionalManagerSection subTenantId={subTenantId} />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default PropertyManagersPage;
