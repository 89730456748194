import React, { useState } from "react";
import { Button, ButtonProps, Typography } from "@material-ui/core";
import moment from "moment";
import ImageContainer from "./ImageContainer";
import SigningModal from "./SigningModal";
import { FieldAttributes, FormikState } from "formik";

interface SignatureFieldProps extends Pick<SignatureProps, 'isFinal' | 'title' | 'buttonProps' | 'name' | 'createLabel' > {
 
 
  field?: FieldAttributes<null | string>;
  form?: FormikState<any>;
}

const SignatureField: React.FC<SignatureFieldProps> = ({ form, field, ...rest }) => {
  const [showSigningModal, setShowSigningModal] = useState(false);

  const onSave = (image: string) => {
    const syntethicEvent = {
      target: {
        value: image,
        name: field?.name
      }
    } as React.ChangeEvent<
      HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement
    >;
    if (field && field.onChange) {
      field.onChange(syntethicEvent);
    }
  };

  return (
    <>
      <SigningModal
        onSave={onSave}
        open={showSigningModal}
        onClose={() => setShowSigningModal(false)}
      />
      <Signature
      
        src={field?.value}
        
        onDeleteClick={() => onSave("")}
        onCreateClick={() => setShowSigningModal(true)}
        {...rest}
      />
    </>
  );
};

interface SignatureProps {
  isFinal?: boolean;
  name?: string;
  title?: string;
  createLabel: string;
  src?: string | null;
  date?: string | Date;
  onCreateClick?: () => void;
  onDeleteClick?: () => void;
  buttonProps?: ButtonProps
}

const Signature: React.FC<SignatureProps> = ({
  src,
  title,
  onDeleteClick,
  onCreateClick,
  createLabel,
  date,
  isFinal,
  buttonProps
}) => {
  if (isFinal) {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: 10, width: "100%" }}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <img
            src={src === null ? "" : src}
            alt={title}
            style={{ width: "100%" }}
          />
          <hr />
        </div>
        <div>
          {/* <Typography variant="h6">{name}</Typography>
            <hr /> */}
          <Typography align="center" color="textSecondary">
            {moment(date).format("L")}
          </Typography>
        </div>
      </div>
    );
  }
  if (src) {
    return (
      <>
        <Typography variant="h6">{title}</Typography>
        <ImageContainer src={src} onClose={onDeleteClick} />
      </>
    );
  }
  return (
    <Button variant="contained" color="secondary" onClick={onCreateClick} {...buttonProps}>
      {createLabel}
    </Button>
  );
};

export default SignatureField;
