import React, { useState, useEffect, useCallback } from "react";
import SubTenantForm, {
  getSubTenantFormValidator
} from "./components/SubTenantForm";
import { SubTenantFormValues } from "./components/interfaces";
import { useFormik } from "formik";
import { Paper, Container } from "@material-ui/core";
import BreadcrumbsSection, {
  BreadcrumbSection
} from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  CREATE_SUB_TENANT,
  UPDATE_SUB_TENANT
} from "../../apollo/tenants/mutations";
import { CreateSubTenant, SubTenant } from "../../apollo/tenants/interfaces";
import { useHistory, useParams } from "react-router-dom";
import { GET_SUB_TENANT } from "../../apollo/tenants/queries";
import useAbility from "../../shared/hooks/useAbility";

const getFormValues = (subTenant?: SubTenant): SubTenantFormValues => ({
  name: subTenant ? subTenant.name : "",
  phoneNumber: subTenant ? subTenant.phoneNumber : "",
  address: {
    city: subTenant ? subTenant.address.city : "",
    line1: subTenant ? subTenant.address.line1 : "",
    line2: subTenant
      ? subTenant.address.line2
        ? subTenant.address.line2
        : ""
      : "",
    state: subTenant ? subTenant.address.state : "",
    zip: subTenant ? subTenant.address.zip : ""
  }
});

const useSubTenantForm = () => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const history = useHistory();
  const { subTenantId } = useParams<{ subTenantId: string }>();
  const [isFetchingSubTenant, setIsFetchingSubTenant] = useState(!!subTenantId);
  const ability = useAbility();
  const [getSubTenant, getSubTenantMeta] = useLazyQuery<
    { subTenant: SubTenant },
    { subTenantId: string }
  >(GET_SUB_TENANT, {
    variables: {
      subTenantId
    },
    onError: error => {
      setIsFetchingSubTenant(false);
      sendErrorMessage(error.message);
    }
  });
  const [submitSubTenant, { loading }] = useMutation<
    any,
    { subTenant: CreateSubTenant; tenantId?: String; subTenantId?: String }
  >(subTenantId ? UPDATE_SUB_TENANT : CREATE_SUB_TENANT, {
    onCompleted: data => {
      const message = subTenantId
        ? "Sub-Franchise updated successfully."
        : "Sub-Franchise created successfully.";
      sendSuccessMessage(message);

      history.push("/dashboard/subFranchises/" + data.id);
    },
    onError: error => {
      sendErrorMessage(error.message);
    }
  });

  const subTenantForm = useFormik<SubTenantFormValues>({
    onSubmit: values => {
      //This is for admin only
      //TODO: change this logic once permissions are implemented to only send tenantId if admin
      const { tenantId, ...rest } = values;

      const variables = subTenantId
        ? { subTenant: rest, subTenantId }
        : { subTenant: rest, tenantId };
      submitSubTenant({
        variables
      });
    },
    initialValues: getFormValues(),
    validate: getSubTenantFormValidator(
      !subTenantId && ability.can("manage", "all")
    ),
    validateOnChange: false
  });
  const loadSubTenant = useCallback(
    (subTenant: SubTenant) => {
      subTenantForm.setValues(getFormValues(subTenant));
      setIsFetchingSubTenant(false);
    },
    [subTenantForm]
  );

  useEffect(() => {
    if (!!subTenantId) {
      console.log("getting tenant");
      getSubTenant();
    }
  }, []);

  useEffect(() => {
    if (getSubTenantMeta.data?.subTenant) {
      loadSubTenant(getSubTenantMeta.data.subTenant);
    }
  }, [getSubTenantMeta.data]);

  return {
    model: {
      subTenantForm,
      formLoading: loading || isFetchingSubTenant,
      isEdit: !!subTenantId,
      existingSubTenant: getSubTenantMeta.data?.subTenant,
      ability
    },
    commands: {}
  };
};

const getHamburgerSections = (isEdit: boolean, subTenant?: SubTenant) => {
  const sections: BreadcrumbSection[] = [
    { label: "Home", link: "../../" },
    { label: "Sub-Franchises", link: "../" }
  ];
  if (isEdit) {
    sections.push({
      label: subTenant ? subTenant.name : "...",
      link: subTenant ? `/dashboard/subFranchises/${subTenant.id}` : ""
    });
    sections.push({ label: "Edit Form" });
  } else {
    sections.push({ label: "Sub-Franchise Form" });
  }

  return sections;
};

const SubTenantFormPage: React.FC = () => {
  const {
    model: { subTenantForm, formLoading, isEdit, existingSubTenant, ability },
    commands: {}
  } = useSubTenantForm();
  return (
    <Container>
      <LoadingBackdrop open={formLoading} />
      <BreadcrumbsSection
        sections={getHamburgerSections(isEdit, existingSubTenant)}
      />
      <PageHeader
        title={isEdit ? "Editing Sub-Franchise" : "Creating a Sub-Franchise"}
      />
      <Paper>
        <Container>
          <SubTenantForm
            onSubmit={subTenantForm.handleSubmit}
            errors={subTenantForm.errors}
            values={subTenantForm.values}
            handleChange={subTenantForm.handleChange}
            setFieldValue={subTenantForm.setFieldValue}
            renderSubmitButton
            isEdit={isEdit}
            renderTenantSelection={!isEdit && ability.can("manage", "all")}
          />
        </Container>
      </Paper>
    </Container>
  );
};

export default SubTenantFormPage;
