import { gql, ApolloError } from "@apollo/client";
import {
  IncidentCategory,
  IncidentSubCategory,
  CreateIncidentCategory,
  UpdateIncidentCategory,
  CreateIncidentSubCategory,
  UpdateIncidentSubCategory
} from "./interfaces";
import { useMutation } from "@apollo/react-hooks";

const CREATE_INCIDENT_CATEGORY = gql`
  mutation createIncidentCategory($category: CreateIncidentCategoryDTO!) {
    createIncidentCategory(category: $category) {
      id
      name
      order
      shouldSendAlert
      requiresPictures
      description
    }
  }
`;

const UPDATE_INCIDENT_CATEGORY = gql`
  mutation updateIncidentCategory($category: UpdateIncidentCategoryDTO!) {
    updateIncidentCategory(category: $category) {
      id
      name
      shouldSendAlert
      requiresPictures
      order
      description
    }
  }
`;

export const useCreateUpdateIncidentCategory = (
  isEdit: boolean,
  onSuccess?: (category: IncidentCategory) => void,
  onError?: (error: ApolloError) => void
) => {
  const [submit, { loading, error }] = useMutation<
    {
      createIncidentCategory: IncidentCategory;
      updateIncidentCategory: IncidentCategory;
    },
    { category: CreateIncidentCategory | UpdateIncidentCategory }
  >(!isEdit ? CREATE_INCIDENT_CATEGORY : UPDATE_INCIDENT_CATEGORY, {
    onCompleted: data => {
      if (onSuccess) {
        onSuccess(
          !isEdit ? data.createIncidentCategory : data.updateIncidentCategory
        );
      }
    },
    onError
  });

  return {
    submit,
    loading,
    error
  };
};

export const DELETE_INCIDENT_CATEGORIES = gql`
  mutation deleteCategories($ids: [String!]!) {
    deleteIncidentCategories(ids: $ids)
  }
`;

const CREATE_SUB_CATEGORY = gql`
  mutation createIncidentSubCategory(
    $subCategory: CreateIncidentSubCategoryDTO!
  ) {
    createIncidentSubCategory(subCategory: $subCategory) {
      id
      name
      order
      shouldSendAlert
      requiresPictures
      description
    }
  }
`;
export const useCreateIncidentSubCategory = () => {
  const [create] = useMutation<
    { createIncidentSubCategory: IncidentSubCategory },
    { subCategory: CreateIncidentSubCategory }
  >(CREATE_SUB_CATEGORY);

  const createSubCategory = async (subCategory: CreateIncidentSubCategory) => {
    const results = await create({ variables: { subCategory } });
    return results.data?.createIncidentSubCategory;
  };
  return createSubCategory;
};

const UPDATE_SUB_CATEGORY = gql`
  mutation updateIncidentSubCategory(
    $subCategory: UpdateIncidentSubCategoryDTO!
  ) {
    updateIncidentSubCategory(subCategory: $subCategory) {
      id
      name
      order
      shouldSendAlert
      requiresPictures
      description
    }
  }
`;
export const useUpdateIncidentSubCategory = () => {
  const [update] = useMutation<
    { updateIncidentSubCategory: IncidentSubCategory },
    { subCategory: UpdateIncidentSubCategory }
  >(UPDATE_SUB_CATEGORY);

  const updateSubCategory = async (subCategory: UpdateIncidentSubCategory) => {
    const results = await update({ variables: { subCategory } });
    return results.data?.updateIncidentSubCategory;
  };

  return updateSubCategory;
};

const DELETE_SUB_CATEGORIES = gql`
  mutation deleteSubCategories($ids: [String!]!) {
    deleteIncidentSubCategories(ids: $ids)
  }
`;

export const useDeleteIncidentSubCategories = () => {
  const [deleteSub] = useMutation<
    { deleteIncidentSubCategories: string[] },
    { ids: string[] }
  >(DELETE_SUB_CATEGORIES);

  const deleteSubCategories = async (ids: string[]) => {
    const results = await deleteSub({ variables: { ids } });
    return results.data?.deleteIncidentSubCategories;
  };

  return deleteSubCategories;
};

const CHANGE_SUB_CATEGORY_ORDER = gql`
  mutation changeSubCategoryOrder(
    $categoryId: String!
    $subCategoryId: String!
    $increase: Boolean!
  ) {
    changeSubCategoryOrder(
      increase: $increase
      subCategoryId: $subCategoryId
      categoryId: $categoryId
    ) {
      order
    }
  }
`;
export const useChangeIncidentSubCategoryOrder = (onSuccess: () => void) => {
  const [change, {data, loading, error} ] = useMutation<
    { changeSubCategoryOrder: IncidentSubCategory[] },
    { categoryId: string, subCategoryId: string, increase: boolean }
  >(CHANGE_SUB_CATEGORY_ORDER, {onCompleted:onSuccess});

  const changeOrder = (categoryId: string, subCategoryId: string, increase: boolean) => {
    change({variables: {categoryId, subCategoryId, increase}})
  }
  return {
    changeOrder,
    loading,
    data
  }

};
