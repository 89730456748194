import {useRef} from 'react'

export default <T,>(initialValue:T) => {
    const value = useRef(initialValue);
    const changeValue = (newValue: T) => {
        value.current = newValue;
    }

    return [value.current, changeValue ] as const;

}