import { gql, ApolloError, useLazyQuery } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { CreateCustomerAgreement, CustomerAgreement } from "./interface";
import { Fragments as CustomerFragments } from "../customers/queries";
import { Customer, Property } from "../customers/interfaces";
export const Fragments = {
  customerAgreementFull: gql`
    fragment CustomerAgreementFull on CustomerAgreementDTO {
      id
      aptUnitPercentage
      aptUnitPrice
      aptUnitsCharged
      aptUnitsTotal
      createdAt
      currentTerm
      currentTermExpirationDate
      propertyId
      customerId
      customerName
      customerSignatureBase64
      customerSignature {
        id
        createdAt
        url
      }
      expirationDate
      extraData
      generatedFile {
        id
        createdAt
        url
      }
      metadata
      offPropertyTime
      serviceStartDate
      serviceStartTime
      specialServiceRequests {
        type
        id
        singleDate
        workingDay
        comment
      }
      status
      supervisorId
      supervisorSignatureBase64
      supervisorSignature {
        id
        createdAt
      }
      type
      workingDays
    }
  `
};

const GET_CUSTOMER_WITH_AGREEMENT = gql`
  query getCustomerWithAgreement($id: String!) {
    customer(id: $id) {
      ...CustomerDisplayInfo
      property {
        name
        address {
          formatted
        }
      }
      agreement {
        ...CustomerAgreementFull
      }
    }
  }
  ${Fragments.customerAgreementFull}
  ${CustomerFragments.customerDisplayInfo}
`;
export const useGetCustomerWithAgreement = (
  customerId: string,
  onError?: (error: ApolloError) => void
) => {
  const { data, loading, error } = useQuery<
    { customer: Customer },
    { id: string }
  >(GET_CUSTOMER_WITH_AGREEMENT, {
    onError,
    variables: { id: customerId }
    // fetchPolicy: "cache-and-network",
  });

  return {
    customer: data?.customer,
    loading: loading,
    error
  };
};

const GET_PROPERTY_WITH_AGREEMENT = gql`
  query getPropertyWithAgreement($id: String!) {
    property(id: $id) {
      id
      name
      mainPhone
      contactName
      email
      address {
        id
        formatted
      }

      agreement {
        ...CustomerAgreementFull
      }
    }
  }
  ${Fragments.customerAgreementFull}
`;
export const useGetPropertyWithAgreement = (
  propertyId: string,
  onError?: (error: ApolloError) => void
) => {
  const { data, loading, error } = useQuery<
    { property: Property },
    { id: string }
  >(GET_PROPERTY_WITH_AGREEMENT, {
    onError,
    variables: { id: propertyId }
    // fetchPolicy: "cache-and-network",
  });

  return {
    property: data?.property,
    loading: loading,
    error
  };
};

const GET_CUSTOMER_AGREEMENT_PREVIEW = gql`
  query getCustomerAgreementPreview($agreement: CreateCustomerAgreementDTO!) {
    customerAgreementPreview(agreement: $agreement)
  }
`;

export const useGetCustomerAgreementPreview = (
  agreement: CreateCustomerAgreement
) => {
  const { data, loading, error } = useQuery<
    { customerAgreementPreview: string },
    { agreement: CreateCustomerAgreement }
  >(GET_CUSTOMER_AGREEMENT_PREVIEW, {
    fetchPolicy: "cache-and-network",
    variables: {
      agreement
    }
  });

  return {
    agreementPreview: data?.customerAgreementPreview,
    previewLoading: loading,
    previewError: error
  };
};

const GET_EXTERNAL_CUSTOMER_AGREEMENT_PREVIEW = gql`
  query getExternalCustomerAgreementPreview(
    $agreement: CreateCustomerAgreementDTO!
  ) {
    externalCustomerAgreementPreview(agreement: $agreement)
  }
`;

export const useGetCustomerAgreementPreviewLazy = (
  useExternalQuery = false
) => {
  const [getPreview, { data, loading, error }] = useLazyQuery<
    {
      customerAgreementPreview: string;
      externalCustomerAgreementPreview: string;
    },
    { agreement: CreateCustomerAgreement }
  >(
    useExternalQuery
      ? GET_EXTERNAL_CUSTOMER_AGREEMENT_PREVIEW
      : GET_CUSTOMER_AGREEMENT_PREVIEW,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  return {
    getPreview,
    agreementPreview: useExternalQuery
      ? data?.externalCustomerAgreementPreview
      : data?.customerAgreementPreview,
    previewLoading: loading,
    previewError: error
  };
};

const GET_EXTERNAL_CUSTOMER_AGREEMENT = gql`
  query getExternalCustomerAgreement($hash: String!) {
    externalCustomerAgreement(hash: $hash) {
      ...CustomerAgreementFull
      property {
        id
        name
        mainPhone
        contactName
        email
        address {
          id
          formatted
        }
      }
    }
  }
  ${Fragments.customerAgreementFull}
`;

export const useGetExternalCustomerAgreement = (hash: string) => {
  const { data, loading, error, refetch } = useQuery<
    { externalCustomerAgreement: CustomerAgreement },
    { hash: string }
  >(GET_EXTERNAL_CUSTOMER_AGREEMENT, {
    variables: { hash }
    // fetchPolicy: "cache-and-network",
  });

  return {
    refetch,
    agreement: data?.externalCustomerAgreement,
    loading: loading,
    error
  };
};
