import { gql } from "@apollo/client";

export const CREATE_TENANT = gql`
  mutation createTenant(
    $tenant: CreateTenantDTO!
    $subTenant: CreateSubTenantDTO
  ) {
    createTenant(tenant: $tenant, subTenant: $subTenant) {
      id
      name
    }
  }
`;
export const CREATE_SUB_TENANT = gql`
  mutation createSubTenant($subTenant: CreateSubTenantDTO!, $tenantId: String) {
    createSubTenant(subTenant: $subTenant, tenantId: $tenantId) {
      id
      name
    }
  }
`;

export const UPDATE_SUB_TENANT = gql`
  mutation updateSubTenant(
    $subTenant: CreateSubTenantDTO!
    $subTenantId: String!
  ) {
    updateSubTenant(subTenant: $subTenant, subTenantId: $subTenantId) {
      id
      name
    }
  }
`;

export const DELETE_SUB_TENANTS = gql`
  mutation deleteSubTenants($subTenantIds: [String!]!) {
    deleteSubTenants(subTenantIds: $subTenantIds)
  }
`