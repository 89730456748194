import React from "react";
import {
  List,
  Divider,
  
  Typography,
  useMediaQuery,
  Drawer,
} from "@material-ui/core";

// import { useHistory } from "react-router-dom";
import links from "./drawerLinks";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
// import { ReactComponent as Logo } from "../../LogoDark.svg";
import useStyles from "./styles";
import DrawerItem from "./DrawerItem";
import Logo from "../../theme/Logo";

interface Props {
  open: boolean;
  onClose: () => void;
}
const AppDrawer: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Drawer
      variant={isXS ? "temporary" : "permanent"}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerContent, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, 
      }}
    >
      <div className={classes.header}>
        <Logo />
        <Typography
          className={clsx({
            [classes.hide]: !open,
          })}
          variant="h6"
        >
          Disposal Valet
        </Typography>
      </div>

      <Divider />
      <List className={classes.list}>
        {links.map((link, i) => (
         
          <DrawerItem key={'darwerRoot'+i} link={link} renderPopover={!open}/>
        ))}
       
      </List>
    </Drawer>
  );
};

export default AppDrawer;
