import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetIncidentView } from "../../apollo/incidents/queries";
import ErrorPage from "../../shared/components/ErrorPage";
import IncidentPDF from "./components/IncidentPDF";

const useIncidentPDFPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { incident, incidentError, incidentLoading } = useGetIncidentView(
    incidentId
  );

  return {
    model: {
      incident,
      incidentError,
      incidentLoading
    }
  };
};
const IncidentPDFPage: React.FC = ({}) => {
  const {
    model: { incident, incidentError }
  } = useIncidentPDFPage();

  if (incidentError) {
    return <ErrorPage />;
  }

  if(incident)
  return (
    <PDFViewer style={{width: '100%'}}>
      <IncidentPDF incident={incident} />
    </PDFViewer>
  );
  return null;
};

export default IncidentPDFPage;
