import { gql } from "@apollo/client";

export const GET_ROLES_PAGINATED = gql`
  query GetRolesPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
  ) {
    rolesPaginated(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
    ) {
      totalsCount
      nodes {
        id
        createdAt
        name,
        description
      }
    }
  }
`;

export const GET_ROLES_SIMPLE = gql`
  query roles{
    roles {
      id,
      name,
    }
  }
`

export const GET_ROLE = gql`
    query GetRole($roleId: String!) {
        role(id: $roleId) {
            id,
            description,
            name,
            isAdmin,
            permissions {
                id, 
                module, 
                name
            }
            unsetPermissions {
                id, 
                module, 
                name
            }
        }
    }
`

