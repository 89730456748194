import { gql, ApolloError, useLazyQuery } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { Valet } from "../valets/interfaces";
import {
  CoverValetAgreement,
  CreateValetAgreement,
  RegularValetAgreement,
  ValetAgreement
} from "./interface";

export const useGetAgreementValet = (
  valetId: string,
  agreementId?: string,
  onCompleted?: (data: any) => void,
  onError?: (error: ApolloError) => void
) => {
  const { data, loading, error } = useQuery<
    { getAgreementAndValet: { agreement: ValetAgreement; valet: Valet } },
    { agreementId?: string; valetId?: string }
  >(GET_AGREEMENT_VALET, {
    onError,
    onCompleted,
    variables: { agreementId, valetId },
    fetchPolicy: "cache-and-network"
  });

  return {
    valet: data?.getAgreementAndValet?.valet,
    agreement: data?.getAgreementAndValet?.agreement,
    loading: loading,
    error
  };
};

export const useGetValetAgreementPreview = (
  agreement: CreateValetAgreement
) => {
  const { data, loading, error } = useQuery<
    { valetAgreementPreview: string },
    { agreement: CreateValetAgreement }
  >(GET_VALET_AGREEMENT_PREVIEW, {
    // fetchPolicy: 'cache-and-network',
    variables: {
      agreement
    }
  });

  return {
    agreementPreview: data?.valetAgreementPreview,
    previewLoading: loading,
    previewError: error
  };
};

export const useGetValetAgreementPreviewLazy = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    { valetAgreementPreview: string },
    { agreement: CreateValetAgreement }
  >(GET_VALET_AGREEMENT_PREVIEW);
  const generatePreview = (agreement: CreateValetAgreement) => {
    get({ variables: { agreement } });
  };

  return {
    generatePreview,
    agreementPreview: data?.valetAgreementPreview,
    previewLoading: loading,
    previewError: error
  };
};

const GET_AGREEMENT_VALET = gql`
  query getAgreementAndValet($agreementId: String, $valetId: String!) {
    getAgreementAndValet(agreementId: $agreementId, valetId: $valetId) {
      agreement {
        id
        createdAt
        dailyPayRate
        monthlyPayRate
        currentTerm
        contractorId
        valetSignatureBase64
        valetSignature {
          id
          createdAt
          url
        }
        endDate
        startDate
        extraData
        generatedFile {
          id
          createdAt
          url
        }
        metadata
        status
        supervisorId
        supervisorSignatureBase64
        supervisorSignature {
          id
          createdAt
          url
        }
        type
      }
      valet {
        subTenantId
        user {
          firstName
          lastName
          subTenantId
        }
      }
    }
  }
`;

export const GET_VALET_AGREEMENTS = gql`
  query getValetAgreements($valetId: String!) {
    getValetAgreements(valetId: $valetId) {
      nodes {
        id
        status
        propertyName
        type
        currentTerm
        startDate
        endDate
        isMissingSignatures
        supervisorName
        supervisorId
        createdAt
        customerId
      }
    }
  }
`;

const GET_VALET_AGREEMENT_PREVIEW = gql`
  query getValetAgreementPreview($agreement: CreateValetAgreementDTO!) {
    valetAgreementPreview(agreement: $agreement)
  }
`;

const GET_REGULAR_VALET_AGREEMENTS = gql`
  query getRegularValetAgreements($valetId: String!) {
    getValetRegularAgreements(valetId: $valetId) {
      id
      status

      type
      currentTerm
      startDate
      endDate
      isMissingSignatures
      supervisor {
        id
        name
      }
      supervisorId
      createdAt
      isMissingSignatures

      createdAt
      routeId
      route {
        id
        routeCode
        routeColor
        property {
          id
          name
        }
      }
    }
  }
`;

export const useGetRegularValetAgreements = (valetId: string) => {
  const { loading, error, data } = useQuery<
    { getValetRegularAgreements: RegularValetAgreement[] },
    { valetId: string }
  >(GET_REGULAR_VALET_AGREEMENTS, {
    variables: {
      valetId
    }
  });

  return {
    regularAgreements: data?.getValetRegularAgreements,
    loading,
    error
  };
};


const GET_COVER_VALET_AGREEMENTS = gql`
  query getCoverValetAgreements($valetId: String!) {
    getValetCoverAgreements(valetId: $valetId) {
      id
      status

      type
      currentTerm
      startDate
      endDate
      isMissingSignatures
      supervisor {
        id
        name
      }
      supervisorId
      createdAt
      isMissingSignatures
      workingDays
      requiredWhenever
      createdAt
    
    }
  }
`;

export const useGetCoverValetAgreements = (valetId: string) => {
  const { loading, error, data } = useQuery<
    { getValetCoverAgreements: CoverValetAgreement[] },
    { valetId: string }
  >(GET_COVER_VALET_AGREEMENTS, {
    variables: {
      valetId
    }
  });

  return {
    coverAgreements: data?.getValetCoverAgreements,
    loading,
    error
  };
};
