import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Color from "color";

const drawerWidth = 275;

export default makeStyles((theme: Theme) =>
  createStyles({
    // menuButton: {
    //   marginRight: 36,
    // },
    hide: {
      display: "none",
    },
    drawerItemIcon: {
      // color: "rgba(255,255,255, 0.54)",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",

      backgroundColor: "none",
    },
    activeLink: {
      "& >div": {
        background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.secondary.light} 120%)`,
        boxShadow: `0 3px 5px 2px ${Color(theme.palette.primary.dark)
          .alpha(0.5)
          .rgb()
          .string()}`,
      },
      "& div": {
        color: "white",
      },
    },
    nested: {
      backgroundColor: Color(theme.palette.primary.light).alpha(0.3).toString(),
      "&:hover": {
        backgroundColor: Color(theme.palette.primary.light)
          .alpha(0.1)
          .toString(),
      },
    },
    drawerContent: {
      zIndex: 1000,
      // background: `linear-gradient(0deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.dark} 120%)`,
      backgroundColor: theme.palette.background.paper,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 2),

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    list: {
      padding: 10,
    },
    listItem: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    brand: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingLeft: theme.spacing(1),
    },
    icon: { width: 24, minHeight: 24, height: 24, position: "fixed" },
  })
);
