import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Box,
  IconButton,
  makeStyles,
  FormHelperText
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  FieldArrayRenderProps
} from "formik";
import { title } from "process";
import MarkersField from "../../../shared/components/Formik/MarkersField";
import LoadingButton from "../../../shared/components/LoadingButton";
import { useCreateUpdateSupplyItem } from "../../../apollo/supplyRequests/mutations";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import useAbility from "../../../shared/hooks/useAbility";
import TenantSearchbox from "../../Tenants/components/TenantSearchbox";
import { SupplyItem } from "../../../apollo/supplyRequests/interfaces";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles( theme => ({
  dropzone: {
    height: '100%'
  }
}));
interface SupplyItemFormModalProps {
  open: boolean;
  onClose?: () => void;
  onSuccess: () => void;
  editingItem?: SupplyItem;
}

interface SupplyItemFormValues {
  id?: string | null;
  tenantId: string | null;
  name: string;
  image?: File | null;
  description?: string ;
  varieties: string[] | null;
}

const getSupplyItemFormValidationSchema = (isAdmin = false, isEdit = false) => {
  return Yup.object<SupplyItemFormValues>({
    tenantId: isAdmin
      ? Yup.string()
          .required("You must specify a Franchise.")
          .nullable()
      : Yup.string()
          .nullable()
          .defined(),
    name: Yup.string().required("This field is required."),
    description: Yup.string().optional(),
    image: !isEdit? Yup.mixed<File>().nullable().required() : Yup.mixed<File>().nullable().notRequired(),
    varieties: Yup.array<string>()
      .of(Yup.string().required('You must specify a value.'))
      .max(4)
      .nullable()
      .defined()
  });
};

const getSupplyItemFormInitialValues = (
  editingItem?: SupplyItem
): SupplyItemFormValues => {
  return {
    id: editingItem?.id,
    tenantId: editingItem ? editingItem.tenantId : null,
    description:  editingItem?.description ||  "",
    image: null,
    name:  editingItem?.name || "",
    varieties: editingItem?.varieties || []
  };
};

const useSupplyItemFormModal = (isEdit: boolean, onSuccess: () => void) => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const ability = useAbility();
  const {
    submitItem,
    item,
    submitError,
    submitLoading
  } = useCreateUpdateSupplyItem(isEdit);

  const onSubmit = (values: SupplyItemFormValues) => {
    submitItem({
      variables: {
        item: values
      }
    });
  };

  useEffect(() => {
    if (item) {
      sendSuccessMessage(
        `Item ${isEdit ? "updated" : "created"} successfully.`
      );
      if (onSuccess) onSuccess();
    }
  }, [item]);

  useEffect(() => {
    if (submitError) {
      sendErrorMessage(
        "There was an error submitting the item: " + submitError.message
      );
    }
  }, [submitError]);

  return {
    model: {
      submitError,
      submitLoading,
      ability
    },
    commands: {
      onSubmit
    }
  };
};
const SupplyItemFormModal: React.FC<SupplyItemFormModalProps> = ({
  open,
  onClose,
  editingItem,
  onSuccess
}) => {
  const {
    model: { submitLoading, ability },
    commands: { onSubmit }
  } = useSupplyItemFormModal(!!editingItem, onSuccess);

  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        onSubmit={onSubmit}
        validationSchema={getSupplyItemFormValidationSchema(
          ability.can("manage", "all"),
          !!editingItem
        )}
        initialValues={getSupplyItemFormInitialValues(editingItem)}
      >
        {({ submitForm, setFieldValue, errors, handleChange, values }) => (
          <Form
          // style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            {console.log(errors)}
            <DialogTitle id="alert-dialog-title">
              {editingItem ? "Update" : "Create"} an Item
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  spacing={2}
                  container
                  // direction="column"
                >
                  {ability.can("manage", "all") && (
                    <Grid item xs={12}>
                      <TenantSearchbox
                        value={values.tenantId}
                        label="Select a Franchise"
                        error={errors.tenantId}
                        name="tenantId"
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label="Name"
                      name="name"
                    />
                  </Grid>

                  {/* <Grid item style={{ flex: 1 }}>
                    <Field
                      InputProps={{
                        style: {
                          height: "100%"
                        }
                      }}
                      inputProps={{
                        style: {
                          height: "100%"
                        }
                      }}
                      style={{
                        height: "100%"
                      }}
                      //   rows={7}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      label="Description"
                      name="description"
                      multiline
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <FieldArray
                      name="varieties"
                      render={arrayHelpers => (
                        <VarietiesInput helpers={arrayHelpers} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropzoneArea
                   
                    dropzoneClass={classes.dropzone}
                    acceptedFiles={["image/*"]}
                    filesLimit={1}
                    onChange={files => {
                      setFieldValue("image", files.length ? files[0] : null);
                    }}
                  />
                  {errors.image && <FormHelperText style={{color: 'red'}}>{errors.image}</FormHelperText>}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                disabled={submitLoading}
                color="primary"
              >
                close
              </Button>
              <LoadingButton
                isLoading={submitLoading}
                color="primary"
                type="submit"
              >
                {editingItem ? "Update" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const VarietiesInput: React.FC<{ helpers: FieldArrayRenderProps }> = ({
  helpers
}) => {
  const values = helpers.form.values as SupplyItemFormValues;
  const items = values.varieties;
  console.log({errors: helpers.form.errors});
  return (
    <Grid container  spacing={2}>
      <Grid item xs={12}>
        <Box marginY={2}>
          <Button
            variant="contained"
            color="primary"
            disabled={ !!items && (items.length >= 4)}
            onClick={() => helpers.push("")  }
          >
            Add Variety
          </Button>
        </Box>
      </Grid>
      {items?.map((item, i) => (
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
          <Field
            component={TextField}
            fullWidth
            variant="outlined"
            label={'Variety ' + (i+1)}
            name={"varieties." + i}
          />
          <IconButton onClick={() => helpers.remove(i)}>
            <Close/>
          </IconButton>
          </Box>
         
        </Grid>
      ))}
    </Grid>
  );
};

export default SupplyItemFormModal;
