import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  Fade
} from "@material-ui/core";
import SigningModal from "../../../shared/components/SigningModal";
import { Field, useFormikContext } from "formik";
import { DeleteRounded } from "@material-ui/icons";
import * as Yup from "yup";
import {
  useGetCustomerAgreementPreview,
  useGetCustomerAgreementPreviewLazy
} from "../../../apollo/agreements/query";
import { FormValues } from "./interfaces";
import {
  CreateCustomerAgreement,
  CustomerAgreement
} from "../../../apollo/agreements/interface";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { CheckboxWithLabel, Switch, TextField } from "formik-material-ui";
import useDeepCompareEffect from "use-deep-compare-effect";
import useDebounce from "../../../shared/hooks/useDebounce";
export interface SigningFormValues {
  customerSignature: string | null;
  supervisorSignature: string | null;
  customerName?: string | null;
  customerEmail: string | null;
  sendMail: boolean;
}
export const getSigningStepValidationSchema = () => {
  return Yup.object<SigningFormValues>({
    sendMail: Yup.boolean().required(),
    customerEmail: Yup.string()
      .when("sendMail", {
        is: true,
        then: Yup.string()
          .email("This is not a valid email address.")
          .required("You must specify an email address.")
          .nullable(),
        otherwise: Yup.string()
          .notRequired()
          .nullable()
          .defined()
      })
      .nullable()
      .defined(),
    customerName: Yup.string()
      .nullable()
      .optional(),
    customerSignature: Yup.string()
      .nullable()
      .defined(),
    supervisorSignature: Yup.string()
      .nullable()
      .defined()
  });
};

export const getSigningFormInitialValues = (
  agreement?: CustomerAgreement
): SigningFormValues => {
  console.log(agreement?.customerName);
  return {
    sendMail: false,
    customerEmail: null,
    customerName: agreement?.customerName || "",
    customerSignature: agreement?.customerSignatureBase64
      ? agreement?.customerSignatureBase64
      : null,
    supervisorSignature: agreement?.supervisorSignatureBase64
      ? agreement?.supervisorSignatureBase64
      : null
  };
};
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 1)
  }
}));

// const formatFormValues = (values: FormValues) : CreateCustomerAgreement => {
//   return {
//     ...values,

//   }
// }
const useSigningStep = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { sendMail, customerEmail, customerName, ...previewDeps } = values;
  const [showSigningModal, setShowSigningModal] = useState(false);
  const [currentSignature, setCurrentSignature] = useState<
    "CUSTOMER" | "SUPERVISOR"
  >("CUSTOMER");

  const {
    getPreview,
    agreementPreview,
    previewError,
    previewLoading
  } = useGetCustomerAgreementPreviewLazy();

  // const {} = useGetCustomerAgreementPreview(values)
  const onAddCustomerSignature = () => {
    setCurrentSignature("CUSTOMER");
    setShowSigningModal(true);
  };
  const onAddSupervisorSignature = () => {
    setCurrentSignature("SUPERVISOR");
    setShowSigningModal(true);
  };
  const onCustomerSignatureChange = (image: string) => {
    setFieldValue("customerSignature", image);
  };
  const onSupervisorSignatureChange = (image: string) => {
    setFieldValue("supervisorSignature", image);
  };
  const onSigningModalClose = () => {
    setShowSigningModal(false);
  };

  const customerNameDebounced = useDebounce(customerName, 1000);
  useDeepCompareEffect(() => {
    getPreview({
      variables: {
        agreement: { ...previewDeps, customerName: customerNameDebounced }
      }
    });
    return () => {};
  }, [previewDeps, customerNameDebounced]);
  return {
    model: {
      customerSignature: values.customerSignature,
      supervisorSignature: values.supervisorSignature,
      showSigningModal,
      currentSignature,
      agreementPreview,
      previewError,
      previewLoading,
      values
    },
    commands: {
      onSigningModalClose,
      onCustomerSignatureChange,
      onSupervisorSignatureChange,
      onAddCustomerSignature,
      onAddSupervisorSignature
    }
  };
};

interface SigningStepProps {
  agreement?: CustomerAgreement;
}
const SigningStep: React.FC<SigningStepProps> = ({ agreement }) => {
  const {
    model: {
      customerSignature,
      supervisorSignature,
      showSigningModal,
      currentSignature,
      agreementPreview,
      previewError,
      previewLoading,
      values
    },
    commands: {
      onSigningModalClose,
      onCustomerSignatureChange,
      onSupervisorSignatureChange,
      onAddSupervisorSignature,
      onAddCustomerSignature
    }
  } = useSigningStep();

  const classes = useStyles();

  return (
    <Grid container alignItems="stretch" spacing={2} className={classes.root}>
      <SigningModal
        open={showSigningModal}
        onSave={
          currentSignature === "CUSTOMER"
            ? onCustomerSignatureChange
            : onSupervisorSignatureChange
        }
        onClose={onSigningModalClose}
      />
      <Grid item xs={12}>
        <Typography variant="body2">
          Here you can preview the agreement and add the signatures. Click
          'Finish' to save the contract with the current information.
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Fade in={!!agreementPreview && !previewLoading} unmountOnExit={true}>
          <img
            src={agreementPreview}
            style={{ width: "100%" }}
            alt="agreement-preview"
          />
        </Fade>

        {previewLoading && <CircularProgress size={50} />}
        {previewError && (
          <Alert severity="error">
            Someting went wrong trying to generate the preview!
          </Alert>
        )}
      </Grid>

      {!customerSignature && (
        <>
          <Grid item xs={12}>
            <Field
              component={TextField}
              name="customerName"
              label="Customer's Name"
              placeholder="(Optional)"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={CheckboxWithLabel}
              name="sendMail"
              type="checkbox"
              Label={{ label: "Send agreement via email?" }}
            />
            <Field
              disabled={!values.sendMail}
              component={TextField}
              name="customerEmail"
              label="Customer's email"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </>
      )}

      {!!!agreement?.customerSignature && (
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="flex-end"
        >
          <Signature
            isFinal={!!agreement?.customerSignature}
            date={agreement?.customerSignature?.createdAt}
            src={customerSignature}
            createLabel="Add Customer Signature"
            title="Customer's Signature"
            onClose={() => onCustomerSignatureChange("")}
            onCreateClick={onAddCustomerSignature}
          />
        </Grid>
      )}

      {!!!agreement?.supervisorSignature && (
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="flex-end"
        >
          <Signature
            isFinal={!!agreement?.supervisorSignature}
            date={agreement?.supervisorSignature?.createdAt}
            src={supervisorSignature}
            createLabel="Add Supervisor Signature"
            title="Supervisor/Manager's Signature"
            onClose={() => onSupervisorSignatureChange("")}
            onCreateClick={onAddSupervisorSignature}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface SignatureProps {
  isFinal?: boolean;
  name?: string;
  title?: string;
  createLabel: string;
  src?: string | null;
  date?: string | Date;
  onCreateClick?: () => void;
  onClose?: () => void;
}
const Signature: React.FC<SignatureProps> = ({
  src,
  title,
  onClose,
  onCreateClick,
  createLabel,
  date,
  name,
  isFinal
}) => {
  if (isFinal) {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: 10, width: "100%" }}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <img
            src={src === null ? "" : src}
            alt={title}
            style={{ width: "100%" }}
          />
          <hr />
        </div>
        <div>
          {/* <Typography variant="h6">{name}</Typography>
          <hr /> */}
          <Typography align="center" color="textSecondary">
            {moment(date).format("L")}
          </Typography>
        </div>
      </div>
    );
  }
  if (src) {
    return (
      <>
        <Typography variant="h6">{title}</Typography>
        <ImageContainer src={src} onClose={onClose} />
      </>
    );
  }
  return (
    <Button variant="contained" color="secondary" onClick={onCreateClick}>
      {createLabel}
    </Button>
  );
};

interface ImageContainerProps {
  onClose?: () => void;
  src?: string;
}
const useImageContainerStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  image: {
    width: "100%",
    borderColor: theme.palette.secondary.main,
    border: "solid 3px",
    borderRadius: theme.shape.borderRadius
  },
  icon: {
    top: -theme.spacing(2),
    right: -theme.spacing(2),
    background: theme.palette.secondary.main,
    position: "absolute"
  }
}));
const ImageContainer: React.FC<ImageContainerProps> = ({ src, onClose }) => {
  const classes = useImageContainerStyles();

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="delete"
        className={classes.icon}
        onClick={onClose}
      >
        <DeleteRounded />
      </IconButton>
      <img src={src} className={classes.image} />
    </div>
  );
};

export default SigningStep;
