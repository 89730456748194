import React from "react";
import { DataGrid, GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { useGetCoverValetAgreements, useGetRegularValetAgreements } from "../../../apollo/valetAgreements/query";
import { ServiceRoute } from "../../../apollo/serviceRoutes/interfaces";
import Link from "../../../navigation/Link";
import { User } from "../../../apollo/users/interfaces";
import moment from "moment";
import { Paper } from "@material-ui/core";



const getColumns = (valetId: string): GridColumns => [
  {
    field: "id",
    headerName: "View",
    
    flex: 0.7,
    resizable: false,
    hideSortIcons: true,
    sortable: false,
    filterable: false,
    renderCell: params => {
      return (
        <Link to={`/dashboard/valets/${valetId}/agreements/${params.value}`}>
          View
        </Link>
      );
    }
  },
  // {
  //   field: "requiredWhenever",
  //   flex: 1,
  //   headerName: "Always Required?",
  //   valueGetter: params => params? 'Yes' : 'No'
  // },
  {
    field: "workingDays",
    flex: 0.8,
    headerName: "Working Days",
    renderCell: params => {
      if(!params.value) return '-';
      return (params.value as String[]).concat(' - ')
    },
    sortable: false
  
  },
  {
    field: "supervisor",
    flex: 1,
    headerName: "Supervisor",
    renderCell: params => {
      const supervisor = params.value as User;
      if (!supervisor) return "Not Specified";
      return (
        <Link to={`/dashboard/users/${supervisor.id}/`}>{supervisor.name}</Link>
      );
    }
  },

  {
    flex: 0.7,
    field: "status",
    headerName: "Status"
  },
  {
    flex: 0.7,
    field: "currentTerm",
    headerName: "Term"
  },
  {
    flex: 1,
    field: "startDate",
    headerName: "Start Date",

    renderCell: params => {
      return moment(params.value as string).format("ll");
    }
  },
  {
    flex: 1,
    field: "endDate",
    headerName: "End Date",

    renderCell: params => {
      return moment(params.value as string).format("ll");
    }
  },
  {
    flex: 1,
    field: "isMissingSignatures",
    headerName: "Missing Signatures?",

    renderCell: params => {
      return params.value ? "Yes" : "No";
    }
  }
];

interface CoverAgreementsTableProps {
  valetId: string;
}
const CoverAgreementsTable: React.FC<CoverAgreementsTableProps> = ({
  valetId
}) => {
  const { loading, error, coverAgreements } = useGetCoverValetAgreements(
    valetId
  );
  return (
    <Paper elevation={0}  style={{ height: 250, width: "100%", }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            columns={getColumns(valetId)}
            rows={coverAgreements || []}
            loading={loading}
          />
        </div>
      </div>
    </Paper>
  );
};

export default CoverAgreementsTable;
