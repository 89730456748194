import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  CircularProgress,
  Fade,
  Box,
  InputAdornment,
  MenuItem
} from "@material-ui/core";
import { Formik, useFormikContext, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Moment } from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { ValetPreCreation } from "../../../apollo/preCreations/interfaces";
import { useGenerateValetPreCreation } from "../../../apollo/preCreations/mutations";
import { useLazyGetValetPreCreationForEdit } from "../../../apollo/preCreations/queries";
import {
  isRegularRoute,
  ServiceRouteTableView
} from "../../../apollo/serviceRoutes/interfaces";
import { useGetServiceRouteForValetAgreement } from "../../../apollo/serviceRoutes/queries";
import ErrorPage from "../../../shared/components/ErrorPage";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";
import LoadingButton from "../../../shared/components/LoadingButton";
import useAbility from "../../../shared/hooks/useAbility";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import LoadingPage from "../../Home/LoadingPage";
import RoutesSelectModal, {
  ServiceRouteSelectItem
} from "../../ServiceRoutes/components/RoutesSelectModal";
import { RoleAwareSubTenantSearchbox } from "../../Tenants/components/SubTenantsSearchbox";
import { DatePicker } from "formik-material-ui-pickers";
import DateFnsUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import { ValetContractTerm } from "../../../apollo/valetAgreements/interface";
import moment from "moment";

interface ValetPreCreationFormValues {
  id: string | null;
  subTenantId: string | null;
  firstName: string;
  lastName: string;
  email: string;
  route: ServiceRouteSelectItem | null;
  agreementFill: boolean;
  monthlyPayRate?: number | null;
  dailyPayRate?: number | null;
  agreementStartDate: Moment | null;
  agreementTerm: ValetContractTerm;
  agreementEndDate: Moment | null;
  startWorkingDate: Moment | null;
  agreementBonus?: string | null;
}

const getValetPreCreationFormInitialValues = (
  editingPreCreation?: ValetPreCreation
): ValetPreCreationFormValues => {
  return {
    id: editingPreCreation?.id || null,
    email: editingPreCreation?.email || "",
    lastName: editingPreCreation?.lastName || "",
    firstName: editingPreCreation?.firstName || "",
    subTenantId: editingPreCreation?.subTenantId || null,
    route: editingPreCreation?.assignedRoute
      ? {
          id: editingPreCreation.assignedRoute.id,
          propertyName: editingPreCreation.assignedRoute.property.name,
          routeCode: editingPreCreation.assignedRoute.routeCode
        }
      : null,
    agreementStartDate: editingPreCreation?.agreementStartDate
      ? moment(editingPreCreation.agreementStartDate)
      : null,
    agreementEndDate: editingPreCreation?.agreementEndDate
      ? moment(editingPreCreation.agreementEndDate)
      : null,
    startWorkingDate: editingPreCreation?.startWorkingDate
      ? moment(editingPreCreation.startWorkingDate)
      : null,
    dailyPayRate: editingPreCreation?.dailyPayRate
      ? editingPreCreation.dailyPayRate
      : null,
    monthlyPayRate: editingPreCreation?.monthlyPayRate
      ? editingPreCreation.monthlyPayRate
      : null,
    agreementFill: editingPreCreation?.agreementStartDate ? true : false,
    agreementTerm: editingPreCreation?.agreementTerm
      ? editingPreCreation.agreementTerm
      : "MONTH-TO-MONTH",
    agreementBonus: editingPreCreation?.agreementBonus
      ? editingPreCreation.agreementBonus
      : ""
  };
};
const getValetPreCreationFormValidation = (isAdmin?: boolean) => {
  const requiredString = "This field is required.";
  return Yup.object<ValetPreCreationFormValues>({
    id: Yup.string()
      .nullable()
      .defined(),
    subTenantId: isAdmin
      ? Yup.string()
          .nullable()
          .required(requiredString)
      : Yup.string()
          .nullable()
          .defined(),
    firstName: Yup.string().required(requiredString),
    lastName: Yup.string().required(requiredString),
    email: Yup.string().required(requiredString),
    agreementFill: Yup.boolean()
      .defined()
      .required(),
    route: Yup.object<ServiceRouteTableView>()
      .required(requiredString)
      .nullable(),
    agreementStartDate: Yup.mixed<Moment>()
      .when("agreementFill", {
        is: false,
        then: Yup.object<Moment>().notRequired(),
        otherwise: Yup.object<Moment>().required(requiredString)
      })
      .nullable(),
    agreementEndDate: Yup.mixed<Moment>()
      .when("agreementFill", {
        is: false,
        then: Yup.object<Moment>().notRequired(),
        otherwise: Yup.object<Moment>().required(requiredString)
      })
      .nullable(),
    startWorkingDate: Yup.mixed<Moment>()
      .when("agreementFill", {
        is: false,
        then: Yup.object<Moment>().notRequired(),
        otherwise: Yup.object<Moment>().required(requiredString)
      })
      .nullable(),
    dailyPayRate: Yup.number()
      .when("agreementFill", {
        is: false,
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
          .required(requiredString)
          .moreThan(0, "You must specify a higher value")
      })
      .nullable(),
    monthlyPayRate: Yup.number()
      .when("agreementFill", {
        is: false,
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
          .required(requiredString)
          .moreThan(0, "You must specify a higher value")
      })
      .nullable(),
    agreementBonus: Yup.string()
      .nullable()
      .notRequired(),
    agreementTerm: Yup.string()
      .oneOf(["MONTH-TO-MONTH", "1YEAR", "2YEAR", "3MONTHS", "6MONTHS"])
      .required()
  });
};
interface ValetPreCreationFormProps {
  editingPreCreationId?: string;
}
const ValetPreCreationForm: React.FC<ValetPreCreationFormProps> = ({
  editingPreCreationId
}) => {
  const {
    error,
    getPreCreation,
    loading,
    preCreation: editingPreCreation
  } = useLazyGetValetPreCreationForEdit();
  const {
    submit,
    loading: submitLoading,
    error: submitError,
    generatedPreCreation
  } = useGenerateValetPreCreation();

  const history = useHistory();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();

  const ability = useAbility();
  const onSubmit = (values: ValetPreCreationFormValues) => {
    const { route, agreementFill, ...rest } = values;
    submit({
      ...rest,
      assignedRouteId: route?.id
    });
  };

  useEffect(() => {
    if (submitError) {
      sendErrorMessage(submitError.message);
    }
  }, [submitError]);

  useEffect(() => {
    if (generatedPreCreation) {
      sendSuccessMessage("Pre-Registration submitted successfully.");
      history.push("/dashboard/valets");
    }
  }, [generatedPreCreation]);

  useEffect(() => {
    if (editingPreCreationId) {
      getPreCreation(editingPreCreationId);
    }
  }, [editingPreCreationId]);

  if (editingPreCreationId && error) {
    return <ErrorPage message={error.message} />;
  }

  if (editingPreCreationId && !editingPreCreation) {
    return <LoadingPage />;
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={getValetPreCreationFormInitialValues(editingPreCreation)}
      validationSchema={getValetPreCreationFormValidation(
        ability.can("manage", "SubTenants")
      )}
    >
      {({ handleSubmit }) => (
        <>
          <LoadingBackdrop open={submitLoading} />
          <ValetPreCreationFormInputs editingPreCreation={editingPreCreation} />

          <LoadingButton
            isLoading={false}
            color="primary"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Submit
          </LoadingButton>
        </>
      )}
    </Formik>
  );
};

interface ValetPreCreationFormInputsProps {
  isAdmin?: boolean;
  editingPreCreation?: ValetPreCreation;
}

const ValetPreCreationFormInputs: React.FC<ValetPreCreationFormInputsProps> = ({
  isAdmin,
  editingPreCreation
}) => {
  const {
    setValues,
    setFieldValue,
    values: {
      route,
      monthlyPayRate,
      dailyPayRate,
      agreementStartDate,
      agreementEndDate,
      agreementTerm,
      startWorkingDate
    }
  } = useFormikContext<ValetPreCreationFormValues>();

  const {
    getRoute,
    route: foundRoute,
    getRouteLoading,
    getRouteError
  } = useGetServiceRouteForValetAgreement();
  //   useEffect(() => {
  //     if (editingPreCreation) {
  //       setValues(getValetPreCreationFormInitialValues(editingPreCreation));
  //     }
  //   }, [editingPreCreation]);

  const calculateEndDate = () => {
    if (!agreementStartDate) return;
    const newEndDate = agreementStartDate?.clone();
    switch (agreementTerm) {
      case "MONTH-TO-MONTH":
        newEndDate?.add(1, "M");
        break;
      case "1YEAR":
        newEndDate?.add(1, "y");
        break;
      case "2YEAR":
        newEndDate?.add(2, "y");
        break;
      case "3MONTHS":
        newEndDate?.add(3, "M");
        break;
      case "6MONTHS":
        newEndDate?.add(6, "M");
        break;
    }
    setFieldValue("agreementEndDate", newEndDate);
  };

  useEffect(() => {
    if (route) {
      getRoute(route.id);
    }
  }, [route]);

  useEffect(() => {
    calculateEndDate();
  }, [agreementStartDate, agreementTerm]);

  useEffect(() => {
    setFieldValue("startWorkingDate", agreementStartDate);
  }, [agreementStartDate]);

  useEffect(() => {
    if (foundRoute) {
      if (!monthlyPayRate)
        setFieldValue("monthlyPayRate", foundRoute.monthlyRoutePay, false);
      if (!dailyPayRate)
        setFieldValue("dailyPayRate", foundRoute.dailyRoutePay, false);
    }
  }, [foundRoute]);

  useDidMountEffect(() => {
    if (
      !!monthlyPayRate ||
      !!dailyPayRate ||
      !!agreementStartDate ||
      !!agreementEndDate ||
      !!startWorkingDate
    ) {
      setFieldValue("agreementFill", true);
    } else {
      setFieldValue("agreementFill", false);
    }
  }, [
    monthlyPayRate,
    dailyPayRate,
    agreementStartDate,
    agreementEndDate,
    agreementTerm,
    startWorkingDate
  ]);

  const renderRouteSection = () => {
    if (!foundRoute) {
      return null;
    }

    if (isRegularRoute(foundRoute.extraData)) {
      return (
        <Box>
          <Typography color="textSecondary">Route Working Days</Typography>
          <Typography style={{ textTransform: "capitalize" }}>
            {foundRoute.extraData.workingDays.join(" - ")}
          </Typography>
        </Box>
      );
    }
  };

  const renderAgreementSection = () => {
    if (!foundRoute) return null;
    const isRegular = foundRoute.type === "REGULAR";

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item xs={12} sm={12} md={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Monthly Pay Rate"
            name="monthlyPayRate"
            InputProps={{
              type: "number",
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            label="Daily Pay Rate"
            name="dailyPayRate"
            InputProps={{
              type: "number",
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            select
            component={TextField}
            fullWidth
            variant="outlined"
            name="agreementTerm"
            label="Term Type"
          >
            <MenuItem value="MONTH-TO-MONTH">MONTH-TO-MONTH</MenuItem>
            <MenuItem value="3MONTHS">3MONTHS</MenuItem>
            <MenuItem value="6MONTHS">6MONTHS</MenuItem>
            <MenuItem value="1YEAR">1YEAR</MenuItem>
            <MenuItem value="2YEAR">2YEAR</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            format="l"
            component={DatePicker}
            fullWidth
            inputVariant="outlined"
            name="agreementStartDate"
            label="Agreement Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            format="l"
            component={DatePicker}
            fullWidth
            disabled
            inputVariant="outlined"
            name="agreementEndDate"
            label="End date"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Field
            minDate={agreementStartDate ? agreementStartDate : undefined}
            format="l"
            minDateMessage="The start working day must be after the agreement start date."
            component={DatePicker}
            fullWidth
            inputVariant="outlined"
            name="startWorkingDate"
            label="Work Start Date"
          />
        </Grid>

        <Grid item xs={12}>
          <Field
           
          component={TextField}
          variant="outlined"
          fullWidth
          placeholder="Type any bonus information you want to be placed in the agreement."
          label="Bonus"
          name="agreementBonus"
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  return (
    <Form autoComplete="false">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            component={RoleAwareSubTenantSearchbox}
            label="Sub-Franchise"
            name="subTenantId"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            name="firstName"
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            name="lastName"
            label="Last Name"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            name="email"
            label="Email"
          />
        </Grid>
        <Grid item xs={12}>
          <RoutesSelectModal
            name="route"
            variant="outlined"
            fullWidth
            label="Assigned Route"
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Typography variant="h5" gutterBottom>
            Agreement Information
          </Typography>
          {!route && !getRouteLoading && (
            <Typography color="textSecondary">
              Select a route to fill this section.
            </Typography>
          )}
          {getRouteLoading && <CircularProgress />}
          {renderRouteSection()}
        </Grid>
        {foundRoute && renderAgreementSection()}
      </Grid>
    </Form>
  );
};
export default ValetPreCreationForm;
