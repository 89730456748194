import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  TextField
} from "@material-ui/core";
import { Visibility, VisibilityOff, UpdateOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useChangeUserPassword } from "../../../apollo/auth/mutations";
import useOngoingProcess from "../../../shared/hooks/useOngoingProcess";
import useSnackbar from "../../../shared/hooks/useSnackbar";
interface PasswordChangeModalProps {
  userId: string;
  onSuccess?: () => void;
}
const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
  onSuccess,
  userId
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const { setOngoingProcess, ongoingProcess } = useOngoingProcess();
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const {
    changePassword,
    changePasswordError,
    changePasswordSuccess,
    changePasswordLoading
  } = useChangeUserPassword();

  const onOpenClick = () => {
    generatePassword();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onToggleClick = () => {
    setShowPassword(s => !s);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (changePasswordError) {
      sendErrorMessage(
        "Error updating the password: " + changePasswordError.message
      );
    }

    if (changePasswordSuccess) {
      sendSuccessMessage("User password updated successfully");
    }
  }, [changePasswordError, changePasswordSuccess]);

  useEffect(() => {
    if (changePasswordLoading && !ongoingProcess) {
      setOngoingProcess(true);
    }

    if (!changePasswordLoading && ongoingProcess) {
      setOngoingProcess(false);
    }
  }, [changePasswordLoading, ongoingProcess]);

  const generatePassword = () => {
    let password = "",
      characters = "abcdefghijklmnopqrstuvwxyz1234567890",
      length = 6;
    for (let i = 0; i < length; i++) {
      password += characters[Math.floor(Math.random() * characters.length)];
    }
    setPassword(password);
  };

  const onSubmit = () => {
    changePassword(userId, password);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpenClick} variant="outlined" color="primary">
        Change Password
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change User's Password</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText> */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={onPasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onToggleClick}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={generatePassword}>
                <UpdateOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary" disabled={!password}>
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PasswordChangeModal;
