import React from "react";
interface ColorDotProps {
  color: string;
  size?: number;
}
const ColorDot: React.FC<ColorDotProps> = ({ color, size = 36 }) => {
  return (
    <div
      style={{
        background: color,
        width: size,
        height: size,
        borderRadius: size,
      }}
    />
  );
};

export default ColorDot;
