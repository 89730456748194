import { Container, Grid } from "@material-ui/core";
import React from "react";
import { Skeleton } from "@material-ui/lab";

const LoadingSkeleton: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={50} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Skeleton
            animation="wave"
            width="100%"
            height="50vh"
            style={{ marginTop: -80 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoadingSkeleton;
