import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_SUB_TENANTS } from "../../../apollo/tenants/mutations";
import useSnackbar from "../../../shared/hooks/useSnackbar";
import useOngoingProcess from "../../../shared/hooks/useOngoingProcess";

interface Props {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  ids: string[];
  name?: string;
}

const SubTenantDeleteModal: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onError,
  name,
  ids,
}) => {
  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();
  const {setOngoingProcess} = useOngoingProcess()
  const [deleteSubs] = useMutation<any, { subTenantIds: string[] }>(
    DELETE_SUB_TENANTS,
    {
      onCompleted: () => {
        setOngoingProcess(false);
        sendSuccessMessage("Sub-Tenant(s) deleted successfully.");
        if(onSuccess) onSuccess();
      },
      onError: (error) => {
        setOngoingProcess(false)
        sendErrorMessage(error.message);
        if(onError) onError();
      },
    }
  );

  let description = name
    ? `Are you sure you want to delete the '${name}' sub-franchise? `
    : `Are you sure you want to delete ${ids.length} sub-franchise(s)? `;
  description += "This action cannot be undone.";

  const onDelete = () => {
    setOngoingProcess(true)
    deleteSubs({
      variables: { subTenantIds: ids },
    });
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Delete Sub-Franchise"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubTenantDeleteModal;
