import {
  Box,
  ButtonBase,
  Chip,
  createStyles,
  List,
  makeStyles,
  Typography
} from "@material-ui/core";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Incident } from "../../../apollo/incidents/interfaces";
import Color from "color";
import IncidentCategoryItemTags from "../../../shared/components/IncidentCategoryItemTags";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      height: 150,
      textAlign: "start",
      backgroundColor: (props: IncidentListItemProps & { read: boolean }) =>
        props.selected ? theme.palette.secondary.light : "transparent"
    },
    content: {
      flex: 1,
      padding: 10
    },
    routeText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      flex: 2
    },
    dateText: { float: "right", fontSize: 12 },
    apartmentText: {
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: 14
    },
    category: {},
    valetText: { fontSize: 14 },
    line: {
      height: "100%",
      width: 10,
      backgroundColor: (props: IncidentListItemProps & { read: boolean }) =>
        props.read
          ? Color(props.incident.route.routeColor)
              .lighten(0.7)
              .string()
          : props.incident.route.routeColor
    }
  })
);

interface IncidentListItemProps {
  incident: Incident;
  selected?: boolean;
  onItemClick?: (id: string) => void;
}
const IncidentListItem: React.FC<IncidentListItemProps> = props => {
  const { incident, selected, onItemClick } = props;
  const [read, setRead] = useState(props.incident.read);
  const classes = useStyles({ ...props, read });
  const date = useMemo(() => moment(incident.createdAt).calendar(), [
    incident.createdAt
  ]);
  const onClick = () => {
    if (onItemClick) onItemClick(incident.id);
    if (!read) setRead(true);
  };
  return (
    <ButtonBase className={classes.root} onClick={onClick}>
      <div className={classes.line} />
      <div className={classes.content}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          // justifyContent="space-between"
        >
          <Typography className={classes.routeText}>
            {incident.route.routeCode} - {incident.route.property.name}
          </Typography>
          <IncidentCategoryItemTags
            category={incident.category}
            subCategory={incident.subCategory}
          />
        </Box>
        <Typography className={classes.apartmentText}>
          {incident.isBuilding
            ? "Whole Building"
            : `Apartment: ${incident.apartmentNumber}`}
        </Typography>
        <Typography color="textSecondary" className={classes.valetText}>
          {incident.valet?.name || incident.reporter?.name}
        </Typography>

        <Typography color="textSecondary" className={classes.dateText}>
          {date}
        </Typography>
      </div>
    </ButtonBase>
  );
};

export default IncidentListItem;
