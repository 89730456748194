import React, { useState } from "react";
import Datatable from "../../../shared/components/Datatable";
import { GET_SUB_TENANTS_PAGINATED } from "../../../apollo/tenants/queries";
import PageHeader from "../../../shared/components/PageHeader";
import { Button, Box, IconButton } from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import TenantSearchbox from "./TenantSearchbox";
import Link from "../../../navigation/Link";
import SubTenantDeleteModal from "./SubTenantDeleteModal";
import useValue from "../../../shared/hooks/useValue";
import { useHistory } from "react-router-dom";

const SubFranchiseTable: React.FC = () => {
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(null);

  // const onTenantChange = (tenantId : string) => {
  //     setCurrentTenantId()
  // }
  return (
    <>
      <PageHeader
        title="Sub-Franchises"
        actions={
          <Link to="/dashboard/subFranchises/create">
            <Button variant="contained" color="primary">
              Create Sub-Franchise
            </Button>
          </Link>
        }
      />

      <SubTenantsTableOnly
        tenantId={currentTenantId}
        title={
          <Box marginY={4}>
            <TenantSearchbox onOptionChange={setCurrentTenantId} />
          </Box>
        }
      />
    </>
  );
};
interface SubTenantsTableOnly {
  title: React.ReactNode;
  tenantId: string | null;
  onEditClick?: (subTenantId: string) => void;
}
export const SubTenantsTableOnly: React.FC<SubTenantsTableOnly> = ({
  title,
  tenantId,
  onEditClick,
}) => {
  const history = useHistory()
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [ fetchMore, setFetchMore] = useValue<null| Function>(null)
  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  }
  const onDeleteSuccess = () => {
    if(fetchMore) fetchMore();
  }


  return (
    <>
    <SubTenantDeleteModal open={showDeleteModal} ids={selectedIds} onClose={onDeleteModalClose} onSuccess={onDeleteSuccess}/>
    <Datatable
      fetchMore={setFetchMore}
      title={title}
      onDelete={(ids) => {
        setSelectedIds(ids);
        setShowDeleteModal(true)
      }}
      query={GET_SUB_TENANTS_PAGINATED}
      variables={{ tenantId }}
      columns={[
        {
          name: "id",
          options: {
            display: "false",
            
          },
        },
        {
          name: "name",
          label: "Name",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <Link to={'/dashboard/subFranchises/' + tableMeta.rowData[0]}>{value}</Link>
            }
          }
        },
        {
          name: "createdAt",
          label: "Created At",
        },
        {
          name: "actions",
          label: "Actions",
          options: {
            
            customBodyRender: (_, tableMeta) => {
              const onClick = () => {
                if (onEditClick) {
                  onEditClick(tableMeta.rowData[0]);
                }
                history.push('/dashboard/subFranchises/'+tableMeta.rowData[0]+'/edit')

                
              };
              return (
                <IconButton color="primary" onClick={onClick}>
                  <EditRounded />{" "}
                </IconButton>
              );
            },
          },
        },
      ]}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
      }}
    />
    </>
  );
};
export default SubFranchiseTable;
