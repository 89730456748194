import React, { useRef, useState } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  IconButton,
  TextField,
  Popper,
  withStyles,
  createMuiTheme,
  colors,
  ThemeProvider,
} from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const theme = createMuiTheme({
  shape: {
    borderRadius: 20,
  },
  palette: {
    text: {
      primary: "#fff",
    },
    primary: { main: "#fff" },
  },
});

interface EditableTextProps {
  value: string | null;
  onChange: (value: string | null) => void;
  isEditing?: boolean;
}
const EditableText: React.FC<EditableTextProps> = ({
  value: valueProp,
  onChange,
  isEditing,
}) => {
  const classes = useStyles();
  //   const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(valueProp);
  const ref = useRef<HTMLElement | null>(null);

  return (
    <div>
      {isEditing ? (
        <ThemeProvider theme={theme}>
          <TextField
            style={{
              borderColor: "white",
            }}
            fullWidth
            variant="outlined"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onChange(value);
              }
            }}
            onBlur={() => {
              onChange(value);
            }}
          />
        </ThemeProvider>
      ) : (
        <div style={{ width: "100%", position: "relative" }}>
          <Typography innerRef={ref}>
            {value || "Nothing specified..."}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default EditableText;
