import React, { createContext, useState, useEffect } from "react";
import { useGetAuthUserLazyQuery } from "../../apollo/auth/queries";
import AbilityContext from "./AbilityContext";
import useAuthStore from "../hooks/useAuthStore";

interface AppContext {
  model: {
    ongoingProcess: boolean;
    // authToken: null | string;
  };
  commands: {
    setOngoingProcess: (isOngoing: boolean) => void;
    // setAuthToken: (token: null | string) => void;
  };
}
const initialValue: AppContext = {
  model: {
    ongoingProcess: false,
    // authToken: localStorage.getItem("token")
  },
  commands: {
    setOngoingProcess: (isOngoing: boolean) => {},
    // setAuthToken: (token: null | string) => {}
  }
};

const AppContext = createContext<AppContext>(initialValue);

export const AppContextProvider: React.FC = ({ children }) => {
  const [ongoingProcess, setOngoingProcess] = useState(false);
  const {
    loadUser,
    setAuthError,
    authToken
  } = useAuthStore(({ setAuthError, authToken, loadUser }) => ({
    loadUser,
    setAuthError,
    authToken
  }));
  const { getUser, loading, error, user, permissions } = useGetAuthUserLazyQuery();

  useEffect(() => {
    if (authToken) {
      getUser();
    } else {
      setAuthError(new Error('No token found.'))
    }
  }, [authToken]);

  

  useEffect(() => {
    if(user && permissions) {
      loadUser(user, permissions)
    }

    if(error) {
      setAuthError(error)
    }
  }, [user, permissions, error])

  return (
    <AppContext.Provider
      value={{
        model: {
          ongoingProcess,
        },
        commands: {
          setOngoingProcess,
        }
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
