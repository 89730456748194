import React, { useState, useRef } from "react";
import {
  Container,
  Tabs,
  Tab,
  makeStyles,
  Paper,
  Grid,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";

import { TabPanel, TabContext } from "@material-ui/lab";
import IncidentCategorySearchbox, {
  IncidentCategorySearchboxRef,
} from "./components/IncidentCategorySearchbox";
import IncidentCategoryFormModal from "./components/IncidentCategoryFormModal";
import { IncidentCategory } from "../../apollo/incidents/interfaces";
import IncidentCategoryDetails from "./components/IncidentCategoryDetails";
import DeleteModal from "../../shared/components/DeleteModal";
import { DELETE_INCIDENT_CATEGORIES } from "../../apollo/incidents/mutations";
import useAbility from "../../shared/hooks/useAbility";

const useStyles = makeStyles((theme) => ({
  searchboxContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const useIncidentCategoriesList = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const categorySearchbox = useRef<IncidentCategorySearchboxRef>(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const ability = useAbility();
  const [editingCategory, setEditingCategory] = useState<IncidentCategory>();
  const [
    selectedCategory,
    setSelectedCategory,
  ] = useState<IncidentCategory | null>(null);

  const openModal = () => {
    setEditingCategory(undefined);
    setIsFormModalOpen(true);
  };

  const onModalClose = () => {
    setIsFormModalOpen(false);
  };

  const onEditClick = (category: IncidentCategory) => {
    setEditingCategory(category);
    console.log(category);
    setIsFormModalOpen(true);
  };

  const onDeleteClick = () => {
    setShowDeleteModal(true);
  };
  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };
  const onDeleteSuccess = () => {
    categorySearchbox.current?.reloadCategories(true);
    setShowDeleteModal(false);
    setSelectedCategory(null);
  };

  const onCategorySubmit = (category: IncidentCategory) => {
    categorySearchbox?.current?.reloadCategories();
    setSelectedCategory(category);
    setIsFormModalOpen(false);
  };
  // const onCategoryChange = (category: IncidentCategory)
  const onCategoriesLoad = (categories: IncidentCategory[]) => {
    if (!categories.length) {
      setIsEmpty(true);
      return;
    }

    setSelectedCategory(categories[0]);
  };
  return {
    model: {
      isFormModalOpen,
      editingCategory,
      selectedCategory,
      categorySearchbox,
      isEmpty,
      showDeleteModal,
      ability,
    },
    commands: {
      onEditClick,
      onDeleteClick,
      openModal,
      onModalClose,
      onCategorySubmit,
      onCategoryChange: setSelectedCategory,
      onCategoriesLoad,
      onDeleteModalClose,
      onDeleteSuccess,
    },
  };
};
const IncidentCategoriesList: React.FC = ({}) => {
  const {
    model: {
      isFormModalOpen,
      selectedCategory,
      categorySearchbox,
      isEmpty,
      editingCategory,
      showDeleteModal,
      ability,
    },
    commands: {
      openModal,
      onModalClose,
      onCategorySubmit,
      onCategoryChange,
      onCategoriesLoad,
      onEditClick,
      onDeleteClick,
      onDeleteModalClose,
      onDeleteSuccess,
    },
  } = useIncidentCategoriesList();

  const classes = useStyles();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Incidents", link: "/dashboard/valets" },
          { label: "Incidents Categories" },
        ]}
      />
      <DeleteModal
        open={showDeleteModal}
        onClose={onDeleteModalClose}
        mutation={DELETE_INCIDENT_CATEGORIES}
        ids={[selectedCategory ? selectedCategory.id : ""]}
        title="Delete category"
        description="Are you sure you want to delete this category? This action cannot be undone."
        onSuccess={onDeleteSuccess}
      />
      <IncidentCategoryFormModal
        category={editingCategory}
        onSubmitSuccess={onCategorySubmit}
        onClose={onModalClose}
        open={isFormModalOpen}
      />
      <Paper className={classes.searchboxContainer}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md>
              <IncidentCategorySearchbox
                ref={categorySearchbox}
                onOptionChange={onCategoryChange}
                value={selectedCategory}
                onCategoriesLoad={onCategoriesLoad}
              />
            </Grid>
            {ability.can("manage", "all") && (
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                container
                justify="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={openModal}
                >
                  Add New
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </Paper>

      {isEmpty && !selectedCategory ? (
        <Container style={{ marginTop: 20 }}>
          <Typography variant="h6">
            No categories have been created yet. Please click the 'Add New'
            button to start creating one.
          </Typography>{" "}
        </Container>
      ) : (
        <Box marginTop={5}>
          <IncidentCategoryDetails
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            category={selectedCategory}
          />
        </Box>
      )}
    </Container>
  );
};

export default IncidentCategoriesList;
