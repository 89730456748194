import React, { useState } from "react";
import {
  FormikConfig,
  FormikValues,
  Formik,
  Form,
  FormikHelpers,
} from "formik";
import {
  Stepper,
  Step,
  StepContent,
  StepLabel,
  makeStyles,
  createStyles,
  Button,
} from "@material-ui/core";
const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    stepContent: {},
    actionsContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

interface FormikStepFormProps<Values> extends FormikConfig<Values> {
  activeStep?: number;
  onStepChange?: (step: number) => void;
  disableBack?: boolean;
}

const FormikStepForm = <Values extends FormikValues>({
  children,
  activeStep: activeStepProp,
  onStepChange,
  disableBack,
  ...props
}: FormikStepFormProps<Values>) => {
  const steps = React.Children.toArray(children) as React.ReactElement<
    FormikStepProps<Values>
  >[];
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const finalActiveStep = activeStepProp
    ? activeStepProp >= steps.length
      ? steps.length - 1
      : activeStepProp
    : activeStep;
  const currentStep = steps[finalActiveStep];
  const handleBack = () => {
    if (onStepChange && activeStepProp) {
      onStepChange(activeStepProp - 1);
      return;
    }
    setActiveStep((s) => s - 1);
  };

  const isLastStep = () => {
    return finalActiveStep === steps.length - 1;
  };
  const onSubmit = async (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => {
    if (isLastStep()) {
      await props.onSubmit(values, formikHelpers);
    } else {
      if (onStepChange && activeStepProp) {
        onStepChange(activeStepProp + 1);
        return;
      }
      setActiveStep((s) => s + 1);
    }
  };

  return (
    <Formik
      {...props}
      onSubmit={onSubmit}
      validationSchema={currentStep.props.validationSchema}
    >
      <Form autoComplete="off">
        <Stepper activeStep={finalActiveStep} orientation="vertical">
          {steps.map((step, i) => (
            <Step key={i}>
              <StepLabel>
                {step.props.label ? step.props.label : "lol"}
              </StepLabel>

              <StepContent className={classes.stepContent}>
                {step}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={finalActiveStep === 0 || disableBack}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                    >
                      {isLastStep() ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Form>
    </Formik>
  );
};

export interface FormikStepProps<Values extends FormikValues>
  extends Pick<FormikConfig<Values>, "children" | "validationSchema"> {
  label: string;
}

export const FormikStep = <Values extends FormikValues>({
  children,
  ...props
}: FormikStepProps<Values>) => {
  return <>{children}</>;
};

export default FormikStepForm;
