import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  InputAdornment
} from "@material-ui/core";
import { useFormikContext, Field } from "formik";
import { FormValues } from "./interfaces";
import { useGetServiceRouteForValetAgreement } from "../../../apollo/serviceRoutes/queries";
import { TextField } from "formik-material-ui";
import LoadingPage from "../../Home/LoadingPage";
import {
  isRegularRoute,
  isSpecialRoute
} from "../../../apollo/serviceRoutes/interfaces";
import moment from "moment";
import * as Yup from "yup";
import { ValetAgreement } from "../../../apollo/valetAgreements/interface";

export interface RatesFormValues {
  monthlyPayRate: number;
  dailyPayRate: number;
}

export const getRatesFormValidationSchema = () => {
  return Yup.object<RatesFormValues>({
    monthlyPayRate: Yup.number()
      .defined()
      .test("check if exists", "You must specify a higher value.", function(
        value: number
      ) {
        const { dailyPayRate, monthlyPayRate } = this.parent as RatesFormValues;

        if (dailyPayRate <= 0 && monthlyPayRate <= 0) return false;

        return true;
      }),
    dailyPayRate: Yup.number()
      .defined()
      .test(
        "check if other exists",
        "You must speciy a higher value.",
        function(value: number) {
          const { dailyPayRate, monthlyPayRate } = this
            .parent as RatesFormValues;

          if (dailyPayRate <= 0 && monthlyPayRate <= 0) return false;

          return true;
        }
      )
  });
};

export const getRatesFormInitialValues = (
  agreement?: ValetAgreement
): RatesFormValues => {
  return {
    monthlyPayRate: agreement ? agreement.monthlyPayRate : 0,
    dailyPayRate: agreement ? agreement.dailyPayRate : 0
  };
};
const RatesStep: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const {
    getRoute,
    route,
    getRouteError,
    getRouteLoading
  } = useGetServiceRouteForValetAgreement();

  useEffect(() => {
    if (values.type === "REGULAR") {
      getRoute(values.routeId || "");
    }
  }, []);

  useEffect(() => {
    if (route) {
      if (!values.monthlyPayRate)
        setFieldValue("monthlyPayRate", route.monthlyRoutePay);
      if (!values.dailyPayRate)
        setFieldValue("dailyPayRate", route.dailyRoutePay);
    }
  }, [route]);

  if (values.type === "REGULAR") {
    if (getRouteError)
      return <Typography>Error loading the route's info.</Typography>;
    if (!route) return <CircularProgress size={100} />;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography color="textSecondary">Route</Typography>
            <Typography>{route.routeCode}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography color="textSecondary">Route Type</Typography>
            <Typography>{route.type}</Typography>
          </Grid>

          {isRegularRoute(route.extraData) && (
            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">Working Days</Typography>
              <Typography>{route.extraData.workingDays.join(" - ")}</Typography>
            </Grid>
          )}

          {isSpecialRoute(route.extraData) && (
            <>
              <Grid item xs={12} sm={6}>
                {route.extraData.isRecurrent ? (
                  <>
                    <Typography color="textSecondary">Runs every</Typography>
                    <Typography>
                      {route.extraData.recurrentDays &&
                        route.extraData.recurrentDays[0]}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography color="textSecondary">Scheduled for</Typography>
                    {route.extraData.scheduledDates &&
                      route.extraData.scheduledDates.map(date => (
                        <Typography key={date.date.toString()}>
                          {moment(date.date).format("ll")}
                        </Typography>
                      ))}
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
        {isSpecialRoute(route.extraData) && !route.extraData.isRecurrent ? (
          <Grid item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              label="Daily Pay Rate"
              name="dailyPayRate"
              InputProps={{
                type: "number",
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                label="Monthly Pay Rate"
                name="monthlyPayRate"
                InputProps={{
                  type: "number",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom>
          Ammount that will be paid to the valet for each route covered in a
          day.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          InputProps={{
            type: "number",
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          component={TextField}
          name="dailyPayRate"
          variant="outlined"
          label="Per Day Pay Rate"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default RatesStep;
