import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField as MuiTextField,
  MenuItem,
  makeStyles,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { WorkingDay } from "./interfaces";
import { useFormikContext, FieldArray, Field } from "formik";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { TextField } from "formik-material-ui";
import Color from "color";
import { CloseRounded } from "@material-ui/icons";
import useValue from "../../../shared/hooks/useValue";
import * as Yup from "yup";
import moment, { Moment } from "moment";
import { getRandomComponentKey } from "./utils";
import { CustomerAgreement } from "../../../apollo/agreements/interface";
import _ from "lodash";

export interface SpecialServiceRequest {
  type: "DATE" | "DAY";
  workingDay?: WorkingDay;
  singleDate?: Moment | null;
  comment: string;
  id?: string;
}
export interface SpecialServiceFormValues {
  specialServiceRequests?: SpecialServiceRequest[];
}
export const getSpecialServiceFormInitialValues = (
  agreement?: CustomerAgreement
): SpecialServiceFormValues => {
  return {
    specialServiceRequests:
      agreement && agreement.specialServiceRequests
        ? agreement.specialServiceRequests
        : [],
  };
};

export const getSpecialServiceFormValidationSchema = () => {
  return Yup.object<SpecialServiceFormValues>({
    specialServiceRequests: Yup.array(
      Yup.object<SpecialServiceRequest>({
        type: Yup.string().oneOf(["DATE", "DAY"]).required(),
        workingDay: Yup.string()
          .oneOf([
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ])
          .test("workingDay-optional", "This field is required.", function (
            value
          ) {
            const { singleDate }: SpecialServiceRequest = this.parent;
            return value || singleDate;
          }),

        singleDate: Yup.object<Moment>()
          .nullable()
          .test("singleDate-optional", "This field is required.", function (
            value
          ) {
            const { workingDay }: SpecialServiceRequest = this.parent;
            return value || workingDay;
          }),
        comment: Yup.string().required("This field is required."),
        id: Yup.string().notRequired(),
      }).required()
    ).optional(),
  });
};

const useStyles = makeStyles((theme) => ({
  requesItemRoot: {
    margin: theme.spacing(1, 0),
    backgroundColor: Color(theme.palette.secondary.light).lighten(0.05).hex(),
    padding: theme.spacing(5, 3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    position: "relative",
  },
  closeBtnContainer: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
}));
const useSpecialServiceStep = () => {
  const {
    values: { specialServiceRequests = [] },
  } = useFormikContext<SpecialServiceFormValues>();
  const [keys] = useValue<string[]>(
    specialServiceRequests.map((r) => (r.id ? r.id : getRandomComponentKey()))
  );
  return {
    model: {
      specialServiceRequests,
      keys,
    },
  };
};

const SpecialServiceStep: React.FC = ({}) => {
  const {
    model: { specialServiceRequests, keys },
  } = useSpecialServiceStep();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom>
          Here you can specify any special service request needed by the
          customer.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldArray
          name="specialServiceRequests"
          render={(arrayHelpers) => (
            <>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => {
                  keys.push(getRandomComponentKey());
                  arrayHelpers.push({
                    workingDay: "",
                    singleDate: null,
                    comment: "",
                    type: "DAY",
                  });
                }}
              >
                Add Request
              </Button>
              {specialServiceRequests.map((request, i) => (
                <SpecialRequestItem
                  key={keys[i]}
                  type={request.type}
                  position={i}
                  isLastOne={i === specialServiceRequests.length - 1}
                  onRemove={() => {
                    arrayHelpers.remove(i);
                    keys.splice(i, 1);
                  }}
                />
              ))}
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};
const workingDays: WorkingDay[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
interface SpecialRequestItemProps {
  onRemove: () => void;
  isLastOne: boolean;
  position: number;
  type: "DAY" | "DATE";
}
const SpecialRequestItem: React.FC<SpecialRequestItemProps> = ({
  onRemove,
  position,
  type,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setIsMounted(true);
  }, [setIsMounted]);
  const onCloseClick = () => {
    setIsMounted(false);
  };
  return (
    <Collapse
      in={isMounted}
      onExited={() => {
        onRemove();
      }}
      // unmountOnExit={true}
    >
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        className={classes.requesItemRoot}
      >
        <IconButton
          className={classes.closeBtnContainer}
          onClick={onCloseClick}
        >
          <CloseRounded />
        </IconButton>
        <Grid item xs={12} md={3}>
          <Field
            component={TextField}
            select
            variant="outlined"
            fullWidth
            label={"Type"}
            name={`specialServiceRequests[${position}].type`}
          >
            <MenuItem value="DATE">Single Date</MenuItem>
            <MenuItem value="DAY">Recurrent Day</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={12} md={9}>
          {type === "DATE" ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Field
                format="l"
                component={DatePicker}
                fullWidth
                inputVariant="outlined"
                label="Date"
                name={`specialServiceRequests[${position}].singleDate`}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <Field
              component={TextField}
              select
              variant="outlined"
              fullWidth
              label={"Day"}
              name={`specialServiceRequests[${position}].workingDay`}
            >
              {workingDays.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Field>
          )}
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Comment"
            component={TextField}
            multiline
            variant="outlined"
            rowsMax={4}
            fullWidth
            placeholder="Just the pool area."
            name={`specialServiceRequests[${position}].comment`}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
export default SpecialServiceStep;
