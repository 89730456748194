import { gql, ApolloError } from "@apollo/client";
import { CreateCustomerAgreement, CustomerAgreement } from "./interface";
import { useMutation } from "@apollo/react-hooks";
import { File as ServerFile } from "../shared/interfaces";

const SUBMIT_AGREEMENT = gql`
  mutation submitAgreement($agreement: CreateCustomerAgreementDTO!) {
    submitCustomerAgreement(agreement: $agreement) {
      id
    }
  }
`;

export const useSubmitAgreement = (
  onSuccess: (agreement: CustomerAgreement) => void,
  onError: (error: ApolloError) => void
) => {
  const [submitAgreement, { loading }] = useMutation<
    { submitAgreement: CustomerAgreement },
    { agreement: CreateCustomerAgreement }
  >(SUBMIT_AGREEMENT, {
    onCompleted: ({ submitAgreement }) => {
      onSuccess(submitAgreement);
    },
    onError
  });

  return {
    submitAgreement,
    submitAgreementLoading: loading
  };
};

const EXTERNAL_CREATE_AGREEMENT = gql`
  mutation externalCreateCustomerAgreement(
    $agreement: CreateCustomerAgreementDTO!
  ) {
    externalCreateCustomerAgreement(agreement: $agreement)
  }
`;

export const useExternalCreateCustomerAgreement = () => {
  const [submitAgreement, { loading, data, error }] = useMutation<
    { externalCreateCustomerAgreement: boolean },
    { agreement: CreateCustomerAgreement }
  >(EXTERNAL_CREATE_AGREEMENT);

  return {
    submitAgreement,
    submitSuccess: data?.externalCreateCustomerAgreement,
    submitAgreementLoading: loading, 
    submitError: error
  };
};
const UPLOAD_PAPER_CONTRACT = gql`
  mutation uploadPaperContract($data: CreatePaperContractDTO!) {
    uploadPaperContract(data: $data) {
      id
      url
      filetype
    }
  }
`;

export const useUploadPaperContract = () => {
  const [upload, { data, error, loading }] = useMutation<
    { uploadPaperContract: ServerFile },
    { data: { customerId?: string; contract: File; propertyId?: string } }
  >(UPLOAD_PAPER_CONTRACT);

  const uploadContract = (data: {
    customerId?: string;
    contract: File;
    propertyId?: string;
  }) => {
    upload({ variables: { data } });
  };

  return {
    uploadContract,
    contract: data?.uploadPaperContract,
    uploadLoading: loading,
    uploadError: error
  };
};
