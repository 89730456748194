import React, { useState } from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Box, Switch, FormControlLabel } from "@material-ui/core";
import useValue from "../../../shared/hooks/useValue";
import { SubTenant, Tenant } from "../../../apollo/tenants/interfaces";
import DeleteModal from "../../../shared/components/DeleteModal";
import { DELETE_USERS } from "../../../apollo/users/mutations";
import DataTable from "../../../shared/components/Datatable";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import { GET_USERS } from "../../../apollo/users/queries";
import Link from "../../../navigation/Link";
import { GET_VALETS_PAGINATED } from "../../../apollo/valets/queries";
import { User } from "../../../apollo/users/interfaces";

const columns: MUIDataTableColumn[] = [
  {
    name: "id",
    options: {
      display: "excluded"
    }
  },
  {
    name: "name",
    label: "Name",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Link to={`/dashboard/valets/${tableMeta.rowData[0]}`}>{value}</Link>
        );
      }
    }
  },
  {
    name: "username",
    label: "Username"
  },
  {
    name: "email",
    label: "Email"
  }
];
const useValetsTable = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fetchMore, setFetchMore] = useValue<null | Function>(null);
  const [subTenant, setSubTenant] = useState<null | SubTenant>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const onDelete = (ids: string[]) => {
    setSelectedIds(ids);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    if (fetchMore) fetchMore();

    setSelectedIds([]);
  };

  const onSubTenantChange = (subTenant: SubTenant | null) => {
    setSubTenant(subTenant);
  };

  const handleSwitchChange = () => {
    setIsDisabled(!isDisabled);
  };

  return {
    model: {
      showDeleteModal,
      selectedIds,
      subTenant,
      isDisabled
    },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange,
      handleSwitchChange,
      setIsDisabled
    }
  };
};

interface ValetsTableProps {
  canDelete?: boolean;
  renderFilter?: boolean;
}
const ValetsTable: React.FC<ValetsTableProps> = ({
  canDelete,
  renderFilter
}) => {
  const {
    model: { showDeleteModal, selectedIds, subTenant, isDisabled },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange,
      handleSwitchChange,
      setIsDisabled
    }
  } = useValetsTable();
  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        ids={selectedIds}
        mutation={DELETE_USERS}
        customVariableName="userIds"
        onClose={onDeleteModalClose}
        onSuccess={onDeleteSuccess}
        title="Delete user(s)"
        description="This action cannot be undone."
      />
      <DataTable
        subTenantFilter={{
          show: true
        }}
        customArea={
          <>
            <FormControlLabel
              control={<Switch color="primary" onChange={handleSwitchChange} />}
              label="Show Disabled"
            />
          </>
        }
        includeTenants={true}
        variables={{ isDisabled }}
        dateColumns={[{ name: "createdAt", label: "Created At" }]}
        fetchMore={setFetchMore}
        columns={columns}
        query={GET_VALETS_PAGINATED}
        onDelete={onDelete}
        options={{
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 30],
          download: false,
          print: false,
          filter: false,
          selectToolbarPlacement: canDelete ? "replace" : "none"
        }}
      />
    </>
  );
};

export default ValetsTable;
