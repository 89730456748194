import { MUIDataTableColumn } from "mui-datatables";
import React from "react";
import { TIME_OFF_REQUEST_TABLE } from "../../../apollo/timeOffRequests/queries";
import Link from "../../../navigation/Link";
import DataTable from "../../../shared/components/Datatable";

const columns: MUIDataTableColumn[] = [
  {
    name: "valetId",
    options: {
      display: "excluded"
    }
  },
  {
    name: "coverValetId",
    options: {
      display: "excluded"
    }
  },
  //   {
  //     name: "supervisorId",
  //     options: {
  //       display: "excluded",
  //     },
  //   },
  {
    name: "id",
    label: "View",

    options: {
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <Link to={`/dashboard/timeOffRequests/${value}`}>View</Link>;
      }
    }
  },

  //   {
  //     name: "supervisor",
  //     label: "Supervised By",
  //     options: {
  //       customBodyRender: (value, tableMeta) => {
  //         return (
  //           <Link to={`/dashboard/users/${tableMeta.rowData[2]}`}>{value}</Link>
  //         );
  //       },
  //     },
  //   },
  {
    name: "valet",
    label: "Requested By",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Link to={`/dashboard/valets/${tableMeta.rowData[0]}`}>{value}</Link>
        );
      }
    }
  },
  {
    name: "coverValet",
    label: "Covered By",
    options: {
      customBodyRender: (value, tableMeta) => {
        if (!value) return "Not specified";
        return (
          <Link to={`/dashboard/valets/${tableMeta.rowData[1]}`}>{value}</Link>
        );
      }
    }
  },
  {
    name: "status",
    label: "Status"
  }
];

const TimeOffRequestsTable: React.FC = ({}) => {
  return (
    <DataTable
      // title={
      //   renderFilter ? (
      //     <Box marginY={5}>
      //       <SubTenantSearchBox
      //         label="Filter by Sub-Franchise"
      //         onOptionChange={onSubTenantChange}
      //         value={subTenant}
      //       />
      //     </Box>
      //   ) : null
      // }
      //   includeTenants={true}
      dateColumns={[
        { name: "statusChangeDate", label: "Status changed at" },
        { name: "createdAt", label: "Requested at" }
      ]}
      // fetchMore={setFetchMore}
      columns={columns}
      query={TIME_OFF_REQUEST_TABLE}
      // variables={subTenant ? { subTenantId: subTenant.id } : undefined}
      // onDelete={onDelete}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
        selectToolbarPlacement: "none"
      }}
    />
  );
};

export default TimeOffRequestsTable;
