import { IconButton, InputAdornment } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";

interface InputClearButtonProps {
  visible?: boolean;
  onClick?: () => void;
}
const InputClearButton: React.FC<InputClearButtonProps> = ({
  onClick,
  visible
}) => {
  const _onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) onClick();
  };

  if (!visible) return null;
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="clears input"
        onClick={_onClick}
        // onMouseDown={e => e.preventDefault()}
      >
        <Clear />
      </IconButton>
    </InputAdornment>
  );
};

export default InputClearButton;
