import { gql, useMutation } from "@apollo/client";
import {
  CreateTimeOffRequest,
  TimeOffRequest,
  TimeOffRequestStatus,
  UpdateTimeOffRequest
} from "./interfaces";

const CREATE_TIME_OFF_REQUEST = gql`
  mutation createTimeOffRequest($request: CreateTimeOffRequestDTO!) {
    createTimeOffRequest(request: $request) {
      id
    }
  }
`;

const UPDATE_TIME_OFF_REQUEST = gql`
  mutation updateTimeOffRequest($request: UpdateTimeOffRequestDTO!) {
    updateTimeOffRequest(request: $request) {
      id
    }
  }
`;

export const useCreateUpdateTimeOffRequest = (isEdit = false) => {
  const [_submit, { data, loading, error }] = useMutation<
    {
      createTimeOffRequest: TimeOffRequest;
      updateTimeOffRequest: TimeOffRequest;
    },
    { request: UpdateTimeOffRequest | CreateTimeOffRequest }
  >(isEdit ? UPDATE_TIME_OFF_REQUEST : CREATE_TIME_OFF_REQUEST);

  const submit = (request: UpdateTimeOffRequest | CreateTimeOffRequest) => {
    _submit({ variables: { request } });
  };
  return {
    submit,
    request: isEdit ? data?.updateTimeOffRequest : data?.createTimeOffRequest,
    submitLoading: loading,
    submitError: error
  };
};

const CHANGE_REQUEST_STATUS = gql`
  mutation changeTimeOffRequestStatus($requestId: String!, $status: String!) {
    changeTimeOffRequestStatus(requestId: $requestId, status: $status) {
      id
      status
    }
  }
`;

export const useChangeRequestStatus = () => {
  const [change, { data, error, loading }] = useMutation<
    { changeTimeOffRequestStatus: TimeOffRequest },
    { requestId: string; status: TimeOffRequestStatus }
  >(CHANGE_REQUEST_STATUS);

  const changeStatus = (requestId: string, status: TimeOffRequestStatus) => {
    change({
      variables: {
        requestId,
        status
      }
    });
  };

  return {
    changeStatus,
    changedStatus: data?.changeTimeOffRequestStatus.status,
    changeStatusError: error,
    changeStatusLoading: loading
  };
};
