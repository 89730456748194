import React, { useState } from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Box } from "@material-ui/core";
import useValue from "../../../shared/hooks/useValue";
import { SubTenant } from "../../../apollo/tenants/interfaces";
import DataTable from "../../../shared/components/Datatable";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import Link from "../../../navigation/Link";
import {
  SERVICE_ROUTES_DATATABLE,
  SHARED_SERVICE_ROUTES_DATATABLE
} from "../../../apollo/serviceRoutes/queries";
import useAuthStore from "../../../shared/hooks/useAuthStore";

const getColumns = (includeProperty = true) => {
  let columns: MUIDataTableColumn[] = [
    {
      name: "id",
      options: {
        display: "excluded"
      }
    },

    {
      name: "customerId",
      options: {
        display: "excluded"
      }
    },
    {
      name: "valetId",
      options: {
        display: "excluded"
      }
    },
    {
      name: "subTenant",
      label: "Belongs To"
    },
    {
      name: "routeCode",
      label: "Route Code",

      options: {
        customBodyRender: (value, tableMeta) => (
          <Link to={`/dashboard/serviceRoutes/${tableMeta.rowData[0]}`}>
            {value}
          </Link>
        )
      }
    }
  ];

  if (includeProperty) {
    columns = [
      ...columns,
      {
        name: "propertyName",
        label: "Property Name"
      }
    ];
  }
  columns = [
    ...columns,
    {
      name: "type",
      label: "Type"
    },
    {
      name: "valetName",
      label: "Default Valet",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (!value) return "Not assigned";
          return (
            <Link to={`/dashboard/valets/${tableMeta.rowData[2]}`}>
              {value}
            </Link>
          );
        }
      }
    }
  ];

  return columns;
};

const useSharedServiceRoutesTable = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fetchMore, setFetchMore] = useValue<null | Function>(null);
  const user = useAuthStore(s => s.user);
  const [subTenantId, setSubTenantId] = useState<null | string>(
    user ? user.subTenantId : null
  );
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onDelete = (ids: string[]) => {
    setSelectedIds(ids);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    if (fetchMore) fetchMore();

    setSelectedIds([]);
  };

  const onSubTenantChange = (subTenant: SubTenant | null) => {
    setSubTenantId(subTenant ? subTenant.id : null);
  };

  return {
    model: {
      showDeleteModal,
      selectedIds,
      subTenantId
    },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange
    }
  };
};

interface SharedServiceRoutesTableProps {
  canDelete?: boolean;
  renderFilter?: boolean;
  includeTenants?: boolean;
  type?: "REGULAR" | "SPECIAL";
  propertyId?: string;
}

const getVariables = (
  type?: "REGULAR" | "SPECIAL",
  propertyId?: string,
  sharedSubTenantId?: string | null
) => {
  if (!type && !propertyId && !sharedSubTenantId) return undefined;

  let variables: any = {};
  if (type) variables = { type };
  if (propertyId) variables = { ...variables, propertyId };
  if (sharedSubTenantId) variables = { ...variables, sharedSubTenantId };

  return variables;
};
const SharedServiceRoutesTable: React.FC<SharedServiceRoutesTableProps> = ({
  canDelete,
  renderFilter,
  includeTenants,
  type,
  propertyId
}) => {
  const {
    model: { showDeleteModal, selectedIds, subTenantId },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange
    }
  } = useSharedServiceRoutesTable();

  return (
    <>
      {/* <DeleteModal
        open={showDeleteModal}
        ids={selectedIds}
        mutation={DELETE_USERS}
        customVariableName="userIds"
        onClose={onDeleteModalClose}
        onSuccess={onDeleteSuccess}
        title="Delete user(s)"
        description="This action cannot be undone."
      /> */}
      <DataTable
        // includeSubTenantFilter={renderFilter}
        subTenantFilterLabel="Sub-Franchises"
        subTenantFilter={{
          show: true,
          variableKey: 'sharedSubTenantId',
          label: 'Sub-Franchises'
        }}
        includeTenants={includeTenants}
        dateColumns={[{ name: "createdAt", label: "Created At" }]}
        fetchMore={setFetchMore}
        columns={getColumns(!!!propertyId)}
        query={SHARED_SERVICE_ROUTES_DATATABLE}
        // variables={getVariables(type, propertyId, subTenantId)}
        onDelete={onDelete}
        options={{
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 30],
          download: false,
          print: false,
          filter: false,
          selectToolbarPlacement: canDelete ? "above" : "none"
        }}
      />
    </>
  );
};

export default SharedServiceRoutesTable;
