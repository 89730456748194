import { Button, Container } from "@material-ui/core";
import React from "react";
import Link from "../../navigation/Link";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import useAbility from "../../shared/hooks/useAbility";
import TimeOffRequestsTable from "./components/TimeOffRequestsTable";

const TimeOffRequestsPage: React.FC = ({}) => {
  const ability = useAbility();
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Time-Off Requests" }
        ]}
      />

      <PageHeader
        title={"Time-Off Requests"}
        actions={
          <>
            {ability.can("create", "SupplyRequests") && (
              <Link to="/dashboard/timeOffRequests/form">
                <Button
                  variant="contained"
                  color="primary"
                  
                >
                  Generate Request
                </Button>
              </Link>
            )}
          </>
        }
      />
    
      <TimeOffRequestsTable />
    </Container>
  );
};

export default TimeOffRequestsPage;
