import { useContext } from "react";
import AppContext from "../contexts/AppContext";

export default () => {
  const {
    model: { ongoingProcess },
    commands: { setOngoingProcess },
  } = useContext(AppContext);

  return {
    ongoingProcess,
    setOngoingProcess,
  };
};
