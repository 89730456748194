import React from "react";
import { useTheme } from "@material-ui/core";

const SignalSearchingIllustration: React.FC = ({}) => {
    const theme = useTheme()
  return (
    <svg

      id="a90f9a71-1dd7-46d2-a7ac-5b91320676f6"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
     style={{width: '100%',}}
      viewBox="0 0 880.29143 774.24555"
    >
      <title>signal_searching</title>
      <circle cx="644.48936" cy="528.52437" r="216.46236" fill="#f2f2f2" />
      <ellipse
        cx="652.70058"
        cy="736.56641"
        rx="205.41211"
        ry="37.67914"
        fill={theme.palette.primary.dark}
      />
      <path
        d="M995.00669,798.37371c-.21205-.34648-5.21175-8.69467-6.94509-26.02987-1.58988-15.90415-.56756-42.71182,13.33526-80.10631,26.33846-70.84136-6.06985-128.00008-6.401-128.56944l1.59889-.92758c.08392.14437,8.44654,14.7176,13.3867,37.92325a165.44656,165.44656,0,0,1-6.85216,92.218c-26.29335,70.72046-6.74568,104.19892-6.54536,104.52917Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.dark}
      />
      <circle cx="824.85342" cy="484.61964" r="12.01157" fill={theme.palette.primary.dark} />
      <circle cx="862.73606" cy="528.97005" r="12.01157" fill={theme.palette.primary.dark} />
      <circle cx="836.86499" cy="558.53699" r="12.01157" fill={theme.palette.primary.main} />
      <circle cx="868.27986" cy="583.48409" r="12.01157" fill={theme.palette.primary.main} />
      <circle cx="827.62532" cy="622.2907" r="12.01157" fill={theme.palette.primary.dark} />
      <path
        d="M1002.26307,798.81585s-12.01156-29.56694,24.02314-51.74215Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.dark}
      />
      <path
        d="M987.49071,798.27951s-5.46658-31.442-47.77694-31.17249Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.dark}
      />
      <polygon
        points="77.182 389.554 266.793 518.392 284.849 504.474 300.826 505.86 270.44 535.408 77.182 413.863 77.182 389.554"
        fill={theme.palette.primary.dark}
      />
      <polygon
        points="465.471 329.389 545.74 424.194 529.885 443.641 435.742 337.897 465.471 329.389"
        fill="#2f2e41"
      />
      <ellipse
        cx="68.67384"
        cy="362.81361"
        rx="18.23184"
        ry="20.66276"
        fill={theme.palette.primary.main}
      />
      <ellipse
        cx="491.14501"
        cy="343.26282"
        rx="229.17912"
        ry="164.39492"
        transform="matrix(0.25749, -0.96628, 0.96628, 0.25749, -126.8639, 666.58129)"
        fill={theme.palette.primary.dark}
      />
      <ellipse
        cx="491.14501"
        cy="343.26282"
        rx="209.8153"
        ry="150.50485"
        transform="translate(-126.8639 666.58129) rotate(-75.07862)"
        fill="#fff"
        opacity="0.1"
      />
      <circle cx="297.17962" cy="272.86984" r="3.64637" fill={theme.palette.primary.main} />
      <circle cx="276.51686" cy="359.16724" r="3.64637" fill={theme.palette.primary.main} />
      <circle cx="378.61519" cy="280.16258" r="3.64637" fill={theme.palette.primary.main} />
      <circle cx="357.95243" cy="367.67543" r="3.64637" fill={theme.palette.primary.main} />
      <path
        d="M243.1136,482.81728l-11.96012,2.39441-.19444.0365-27.95549-7.29274-8.5082-55.911,5.15352-2.40665,13.07833-6.10154c23.09366,0,27.95549,13.37,27.95549,13.37Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.dark}
      />
      <path
        d="M231.15348,485.21169l-.19444.0365-27.95549-7.29274-8.5082-55.911,5.15352-2.40665c10.65958,1.41,23.13017,4.43644,27.6638,10.91485Z"
        transform="translate(-159.85428 -62.87722)"
        opacity="0.1"
      />
      <path
        d="M288.68965,346.079a3.633,3.633,0,0,1-1.86295-.51455c-35.95685-21.42242-70.91665-21.42242-106.8735,0a3.64635,3.64635,0,0,1-3.733-6.26482c37.93553-22.60108,76.404-22.60108,114.33954,0a3.6468,3.6468,0,0,1-1.87007,6.77937Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M274.7119,376.29749a3.62555,3.62555,0,0,1-1.86294-.51455c-26.96082-16.06207-53.17265-16.06207-80.13347,0a3.64635,3.64635,0,0,1-3.733-6.26482c29.06413-17.31551,58.53538-17.31551,87.5995,0a3.6468,3.6468,0,0,1-1.87007,6.77937Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M299.62876,312.04626a3.62558,3.62558,0,0,1-1.863-.51455c-35.88326-21.3785-96.51482-21.3785-132.39808,0a3.64635,3.64635,0,1,1-3.733-6.26482c37.907-22.58446,101.95707-22.58446,139.86412,0a3.6468,3.6468,0,0,1-1.87007,6.77937Z"
        transform="translate(-159.85428 -62.87722)"
        fill={theme.palette.primary.main}
      />
      <polygon
        points="738.679 664.625 746.378 695.42 712.504 704.659 709.424 672.324 738.679 664.625"
        fill="#fbbebe"
      />
      <polygon
        points="615.5 703.119 615.5 732.374 581.625 738.533 575.466 723.135 587.784 696.96 615.5 703.119"
        fill="#fbbebe"
      />
      <path
        d="M843.10277,447.26918l-4.61923,41.57307,3.07948,80.06664s4.61923,0,4.61923,4.61923-4.61923,3.07949,0,6.159a79.99322,79.99322,0,0,0,7.69872,4.61923L903.15275,733.6614s-12.31795,15.39743-35.41409,10.77821l-4.61923-15.39744s-9.23846-9.23845-9.23846-13.85768,3.07949-6.159,0-10.77821-49.27178-98.54356-49.27178-98.54356l-21.5564-38.49357-6.159,52.35126s12.31795,66.209,9.23846,97.00382-9.23846,63.12947-9.23846,63.12947l-36.95383-6.159a25.709,25.709,0,0,0-4.61923-13.85768c-4.61923-6.159-10.7782-4.61923-4.61923-9.23846s13.85769,0,7.69872-6.159-6.159-12.31795-6.159-12.31795v-4.61923L723.0028,608.94221,706.06563,453.42816Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#2f2e41"
      />
      <circle cx="578.54595" cy="62.58565" r="41.57306" fill="#fbbebe" />
      <polygon
        points="614.73 63.356 631.667 104.929 591.634 134.184 566.998 91.071 614.73 63.356"
        fill="#fbbebe"
      />
      <path
        d="M776.89381,157.79748l15.39743-7.69872,9.23846,21.5564s60.05,18.47692,55.43076,103.16279S841.563,391.83843,841.563,391.83843s26.17564,55.43075,10.77821,61.58973-146.2756,29.25511-149.35509,7.69871,10.77821-147.81534,10.77821-147.81534-4.61923-90.84484,33.87434-109.32176L739.94,187.0526Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#d0cde1"
      />
      <path
        d="M692.20794,456.50764s-35.41409,36.95384-15.39743,46.1923,32.33461-41.57307,32.33461-41.57307Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#fbbebe"
      />
      <path
        d="M764.57587,787.55241l16.93717-6.159S799.99,822.9665,784.59253,822.9665H744.55921s-23.09615-7.69871-35.41409-7.69871-35.4141-23.09615-18.47692-33.87435,38.49358-7.69872,38.49358-7.69872l6.159-13.85768,7.69871,21.5564S752.25792,796.79087,764.57587,787.55241Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#2f2e41"
      />
      <path
        d="M889.29506,753.67806s9.64212-6.08408,14.82939-3.81191,8.26676,26.90806,8.26676,26.90806,15.39743,18.47692-18.47692,21.5564-41.57306-3.07948-44.65255-12.31794,3.07949-18.47692,3.07949-18.47692l9.23846-3.07948s10.7782-7.69872,7.69871-10.77821,0-9.23845,0-9.23845S880.0566,765.996,889.29506,753.67806Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#2f2e41"
      />
      <path
        d="M752.61989,124.79668a20.40127,20.40127,0,0,0-.90426-3.956c-2.23442-4.68772-10.95254-3.31949-12.506-8.27471-.304-.9698-.24045-2.02472-.53836-2.99641-.75965-2.47777-3.67379-3.70661-6.26265-3.58768s-5.01321,1.22531-7.50635,1.93286c-6.091,1.72863-12.57122,1.06877-18.86678.39488-2.78327-.29792-5.67123-.63036-8.0554-2.097s-4.102-4.41914-3.234-7.08034c.83218-2.55153,3.62434-3.95357,4.87979-6.32564,1.99352-3.7666-.44338-8.63131,1.17386-12.57415,2.59432-6.32494,12.41222-5.05493,17.4002-9.72991,1.21073-1.13476,2.08257-2.58539,3.243-3.7715,3.443-3.51907,8.88036-4.20029,13.77956-3.71481s9.71351,1.92413,14.63594,1.83708c4.66821-.08256,9.56162-1.50415,13.863.31161,2.60966,1.10162,4.6035,3.25645,6.512,5.34963l11.03273,12.10017a13.598,13.598,0,0,1,3.41006,5.13643c.66377,2.31844.05281,4.80763.336,7.20254.64582,5.46193,5.75929,9.58746,6.44923,15.044a19.56262,19.56262,0,0,1-.24336,5.06659L789.67275,126.459a8.47928,8.47928,0,0,1-.87073,3.193c-.44946.76783-1.12894,1.37427-1.63489,2.10612-1.42855,2.06634-1.29813,4.79771-2.00808,7.20737-.70986,2.40939-3.25553,4.794-5.48239,3.6327-1.45278-.75763-2.41395-7.02313-4.17876-8.64982-2.45817-2.26578-3.74238-1.98653-6.77654-1.94818C762.64456,132.077,754.56427,132.59805,752.61989,124.79668Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#2f2e41"
      />
      <path
        d="M759.95664,214.768s46.19229,18.47692,32.3346,60.05S741.47972,444.1897,741.47972,444.1897l-21.5564,10.7782-6.159,16.93717-29.25512-10.7782,4.61923-23.09615s1.53974-1.53974,1.53974-7.69871-6.159-7.69872,1.53974-7.69872,7.69872-7.69871,7.69872-7.69871L727.622,242.48335S727.622,213.22823,759.95664,214.768Z"
        transform="translate(-159.85428 -62.87722)"
        fill="#d0cde1"
      />
      <polygon
        points="634.747 220.409 587.015 383.622 566.998 403.639 578.546 382.852 634.747 220.409"
        opacity="0.1"
      />
    </svg>
  );
};

export default SignalSearchingIllustration;
