import React, { useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { MoreVertRounded, CloseRounded } from "@material-ui/icons";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  fab: {
    // bottom: 30,
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "-30px",
      right: 30,
    },
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(1),
    },
  },
}));
export interface FabMenuAction {
  name: string;
  icon: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}
export interface FabMenuProps {

  hidden?: boolean;
  className?: string;
  direction?: "left" | "right" | "up" | "down";
  actions?: FabMenuAction[];
}
const FabMenu: React.FC<FabMenuProps> = ({ actions, className, direction, hidden }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const finalDirection = direction ? direction : matchesXS ? "up" : "left";
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      className={clsx(classes.fab, className)}
      hidden={hidden}
      icon={
        <SpeedDialIcon icon={<MoreVertRounded />} openIcon={<CloseRounded />} />
      }
      onClose={onClose}
      onOpen={onOpen}
      open={isOpen}
      direction={finalDirection}
    >
      {actions?.map((action) =>
        action.disabled ? null : (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        )
      )}
    </SpeedDial>
  );
};

export default FabMenu;
