import React, { useCallback } from "react";
import { PropertyManager } from "../../../apollo/propertyManagers/interfaces";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { EmailRounded, PhoneRounded } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },

    // maxHeight: 200
  },
  content: {
    flex: 1,
  },
  contactList: {
    maxHeight: 200,
  },
}));

interface DescriptionCardProps {
  item: PropertyManager;
  onEditClick?: (item: PropertyManager) => void;
  onDeleteClick?: (item: PropertyManager) => void;
  canDelete?: boolean;
  canEdit?: boolean;
}
const DescriptionCard: React.FC<DescriptionCardProps> = ({
  item,
  onEditClick,
  onDeleteClick,
  canDelete,
  canEdit,
}) => {
  const isLong = item.contacts.length > 2;
  const classes = useStyles();
  const onEdit = useCallback(() => {
    if (onEditClick) {
      onEditClick(item);
    }
  }, [onEditClick, item]);

  const onDelete = useCallback(() => {
    if (onDeleteClick) {
      onDeleteClick(item);
    }
  }, [onDeleteClick, item]);

  return (
    <Card className={classes.root} variant="elevation">
      <CardContent className={classes.content}>
        <Typography variant="h6">{item.name}</Typography>
        <List className={classes.contactList} disablePadding={true}>
          {item.contacts.map((contact, index) => (
            <ListItem disableGutters={true} key={index}>
              <ListItemIcon>
                {contact.type === "email" ? (
                  <EmailRounded color="action" />
                ) : (
                  <PhoneRounded color="primary" />
                )}
              </ListItemIcon>
              <ListItemText primary={contact.value} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        {canEdit && (
          <Button color="primary" onClick={onEdit}>
            Edit
          </Button>
        )}
        {canDelete && (
          <Button color="primary" onClick={onDelete}>
            Delete
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default DescriptionCard;
