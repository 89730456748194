import React, { useState } from "react";
import {
  Container,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  ListItemAvatar,
  Box
} from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import { useParams, useHistory } from "react-router-dom";
import {
  useGetFullCustomer,
  useGetCustomerView,
  useGetPropertyView
} from "../../apollo/customers/queries";
import { Skeleton } from "@material-ui/lab";
import {
  EditRounded,
  DeleteRounded,
  AddLocationRounded
} from "@material-ui/icons";

import CustomerDetailsSection from "./components/CustomerDetailsSection";
import PropertyDetailsSection from "./components/PropertyDetailsSection";
import FabMenu from "../../shared/components/FabMenu";
import useAbility from "../../shared/hooks/useAbility";
import {
  DELETE_CUSTOMERS,
  DELETE_PROPERTIES
} from "../../apollo/customers/mutations";
import DeleteModal from "../../shared/components/DeleteModal";
import AgreementDetailsSection from "./components/AgreementDetailsSection";
import ServiceRoutesTable from "../ServiceRoutes/components/ServiceRoutesTable";
import Link from "../../navigation/Link";
import ErrorPage from "../../shared/components/ErrorPage";
import PointsFormModal from "./components/PointsFormModal";
import { MapApiWrapper } from "../../shared/components/Map";
import ReactStickies from "react-stickies";
import "./styles.scss";
import moment from "moment";
import EditableText from "../../shared/components/EditableText";
import SpecialNoticeSection from "./components/SpecialNoticeSection";
import { property } from "lodash";
import SharedSubTenantsView from "./components/SharedSubTenantsView";
import RouteSelectionModal from "../ServiceRoutes/components/RouteSelectionModal";
import PropertyIncidentAlert from "../../shared/components/PropertyIncidentAlert";
import PropertyInicidentAlertBanner from "../../shared/components/PropertyIncidentAlert/PropertyInicidentAlertBanner";

const useStyles = makeStyles(theme => ({
  customerSectionContainer: {
    position: "relative",
    height: "100%"
  }
}));

const LoadingSkeleton: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={30} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Grid item xs={8} sm={4}>
            <Skeleton animation="wave" height={50} />
          </Grid>
        </Grid>
        <Grid xs={12} item container>
          <Skeleton
            animation="wave"
            width="100%"
            height="50vh"
            style={{ marginTop: -80 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
const usePropertyPage = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { property, loading, error, refetch } = useGetPropertyView(propertyId);
  const [hasIncidentAlert, setHasIncidentAlert] = useState(false);
  const ability = useAbility();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPointsOfInterestModal, setShowPointsOfInterestModal] = useState(
    false
  );
  const handleEditClick = () => {
    history.push("/dashboard/properties/" + propertyId + "/edit");
  };

  const onDeleteSuccess = () => {
    history.replace("/dashboard/properties/");
  };
  const onDeleteFormModalClose = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };
  const handlePointsClick = () => {
    setShowPointsOfInterestModal(true);
  };
  const onPointsModalClose = () => {
    setShowPointsOfInterestModal(false);
  };

  const onPointsSubmitSuccess = () => {
    refetch();
  };

  const checkCanCreateServiceRoutes = () => {
    if (
      ability.cannot("create", "ServiceRoutes") &&
      ability.cannot("manage", "Admin")
    )
      return false;

    if (!property) return false;
    if (property.paperContract) return true;
    const { agreement } = property;
    return !!agreement?.supervisorSignature && !!agreement?.customerSignature;
  };

  const onPaperContractSubmitSuccess = () => {
    refetch();
  };

  return {
    model: {
      error,
      property,
      loading,
      ability,
      showDeleteModal,
      showPointsOfInterestModal,
      hasIncidentAlert,
      canCreateServiceRoutes: checkCanCreateServiceRoutes()
    },
    commands: {
      onDeleteSuccess,
      onDeleteFormModalClose,
      handleEditClick,
      handleDeleteClick,
      handlePointsClick,
      onPointsModalClose,
      onPointsSubmitSuccess,
      onPaperContractSubmitSuccess,
      setHasIncidentAlert
    }
  };
};

const PropertyPage: React.FC = ({}) => {
  const {
    model: {
      property,
      error,
      loading,
      ability,
      showDeleteModal,
      showPointsOfInterestModal,
      canCreateServiceRoutes,
      hasIncidentAlert
    },
    commands: {
      setHasIncidentAlert,
      handlePointsClick,
      handleEditClick,
      handleDeleteClick,
      onDeleteSuccess,
      onDeleteFormModalClose,
      onPointsModalClose,
      onPointsSubmitSuccess,
      onPaperContractSubmitSuccess
    }
  } = usePropertyPage();
  const [showRouteSelection, setShowRouteSelection] = useState(false);
  const canManage =
    !property?.isShared ||
    (property.isShared && ability.can("manage", "SubTenants"));
  const onRouteSelectionClose = () => {
    setShowRouteSelection(false);
  };

  const classes = useStyles();
  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (!property) {
    return <LoadingSkeleton />;
  }

  return (
    <MapApiWrapper>
      <Container>
        <DeleteModal
          open={showDeleteModal}
          mutation={DELETE_PROPERTIES}
          ids={[property.id]}
          description="Are you sure you want to delete this Property? This cannot be undone."
          title={"Delete Property"}
          onSuccess={onDeleteSuccess}
          onClose={onDeleteFormModalClose}
          successMessage="Property deleted successfully."
        />
        <PointsFormModal
          propertyId={property.id}
          title={"Points of Interest"}
          open={showPointsOfInterestModal}
          onClose={onPointsModalClose}
          initialPoints={property.pointsOfInterest}
          onSubmitSuccess={onPointsSubmitSuccess}
          mapCenter={
            property.address.latitude && property.address.longitude
              ? {
                  latitude: property.address.latitude,
                  longitude: property.address.longitude
                }
              : undefined
          }
        />

        <BreadcrumbsSection
          sections={[
            { label: "Home", link: "/dashboard" },
            { label: "Properties", link: "/dashboard/properties" },
            { label: "Property Page" }
          ]}
        />

        <RouteSelectionModal
          open={showRouteSelection}
          propertyId={property.id}
          onClose={onRouteSelectionClose}
        />

        <PageHeader
          title={
            <>
              <Typography variant="h3">Property Details</Typography>
              {!canManage && (
                <Typography variant="h6" color="textSecondary">
                  Belongs to the "{property.subTenant.name}" Sub-Franchise
                </Typography>
              )}
            </>
          }
        />
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={9}>
            <Paper className={classes.customerSectionContainer}>
              <FabMenu
                hidden={!canManage}
                actions={[
                  {
                    icon: <AddLocationRounded />,
                    name: "Points of Interest",
                    onClick: handlePointsClick,
                    disabled: !ability.can("update", "Customers")
                  },
                  {
                    icon: <EditRounded />,
                    name: "Edit",
                    onClick: handleEditClick,
                    disabled: !ability.can("update", "Customers")
                  },
                  {
                    icon: <DeleteRounded />,
                    name: "Delete",
                    onClick: handleDeleteClick,
                    disabled: !ability.can("delete", "Customers")
                  }
                ]}
              />
              <Container>
                <PropertyInicidentAlertBanner visible={hasIncidentAlert} />

                <PropertyDetailsSection property={property} />
                {/* <CustomerDetailsSection customer={customer} /> */}
              </Container>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <SpecialNoticeSection
              propertyId={property.id}
              specialNotice={property.specialNotice}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          alignItems="stretch"
          spacing={3}
        >
          {canManage && (
            <Grid item xs={12} md={5} container direction={"column"}>
              <PageHeader
                title="Agreement"
                variant="h6"
                containerStyle={{ marginTop: 30 }}
              />
              <Paper style={{ flex: 1 }}>
                <Container style={{ height: "100%" }}>
                  <AgreementDetailsSection
                    paperContract={property.paperContract}
                    onPaperContractSubmitSuccess={onPaperContractSubmitSuccess}
                    propertyId={property.id}
                    agreement={property.agreement}
                  />
                </Container>
              </Paper>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={canManage ? 7 : 12}
            container
            direction={"column"}
          >
            <PageHeader
              title="Service Routes"
              variant="h6"
              containerStyle={{ marginTop: 30 }}
              actions={
                <>
                  {canCreateServiceRoutes && canManage && (
                    <Button
                      variant={"contained"}
                      color="primary"
                      onClick={() => setShowRouteSelection(true)}
                    >
                      New Route
                    </Button>
                  )}
                </>
              }
            />
            <ServiceRoutesTable propertyId={property.id} />
          </Grid>

          {ability.can("manage", "SubTenants") && (
            <Grid item xs={12}>
              <SharedSubTenantsView propertyId={property.id} />
            </Grid>
          )}
          <Grid item xs={12}>
            <PropertyIncidentAlert
              propertyId={property.id}
              onIncidentAlertStatusChange={setHasIncidentAlert}
            />
          </Grid>
        </Grid>
      </Container>
    </MapApiWrapper>
  );
};

export default PropertyPage;
