import React from "react";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  BackdropProps,
} from "@material-ui/core";
interface Props extends BackdropProps {}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const LoadingBackdrop: React.FC<Props> = ({open}) => {
  const classes = useStyles();
  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
