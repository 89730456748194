import React from "react";
import {
  TextField,
  Grid,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import AddressForm, {
  addressFormValidator,
} from "../../../shared/components/AddressForm";
import {
  SubTenantFormProps,
  SubTenantFormValues,
  SubTenantFormErrors,
} from "./interfaces";
import TenantSearchbox from "./TenantSearchbox";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginTop: theme.spacing(3),
  },
}));

export const getSubTenantFormValidator = (requiresTenantId = false) => (
  values: SubTenantFormValues
) => {
  const errors: SubTenantFormErrors = {};
  if (!values.name) {
    errors.name = "This field is required.";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "This field is required.";
  }

  if (!values.tenantId && requiresTenantId) {
    errors.tenantId = "This field is required.";
  }
  const addressErrors = addressFormValidator(values.address);
  if (Object.keys(addressErrors).length) {
    errors.address = addressErrors;
  }

  console.log({ errors });
  return errors;
};

const SubTenantForm: React.FC<SubTenantFormProps> = ({
  handleChange,
  setFieldValue,
  values,
  errors,
  onSubmit,
  renderSubmitButton,
  renderTenantSelection,
  isEdit,
  
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h5" gutterBottom>
            Basic Information
          </Typography>
        </Grid>
        {renderTenantSelection && (
          <Grid item xs={12}>
            <TenantSearchbox
              onChange={handleChange}
              name="tenantId"
              error={errors.tenantId}
              label="Choose the owner"
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            id="name"
            label="Name"
            variant="outlined"
            color="primary"
            fullWidth
            onChange={handleChange}
            value={values.name}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="phoneNumber"
            id="phoneNumber"
            label="Phone Number"
            variant="outlined"
            color="primary"
            fullWidth
            onChange={handleChange}
            value={values.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h5" gutterBottom>
            Address Information
          </Typography>
        </Grid>
      </Grid>
      <AddressForm
        handleChange={handleChange}
        values={values.address}
        setFieldValue={setFieldValue}
        errors={errors.address}
        parentForm={'address'}
      />
      {renderSubmitButton && (
        <Grid container justify="flex-end" spacing={2}>
          <Grid
            item
            xs={12}
            sm={5}
            md={2}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
            >
              {isEdit ? "Update" : "Create"}
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default SubTenantForm;
