import React, { useState } from "react";
import Map from "../../shared/components/Map";
import { Container, Button, Box } from "@material-ui/core";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import PageHeader from "../../shared/components/PageHeader";
import ValetsTable from "../Valets/components/ValetsTable";
import Link from "../../navigation/Link";
import ServiceRoutesTable from "./components/ServiceRoutesTable";
import SharedServiceRoutesTable from "./components/SharedServiceRoutesTable";
import useAbility from "../../shared/hooks/useAbility";
import useIsAdmin from "../../shared/hooks/useIsAdmin";
import RouteSelectionModal from "./components/RouteSelectionModal";

const ServiceRoutesListPage: React.FC = () => {
  // const isAdmin = useIsAdmin();
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const onSelectionModalClose = () => {
    setShowSelectionModal(false);
  };

  const onCreateRouteClick = () => {
    setShowSelectionModal(true);
  };
  return (
    <Container>
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Service Routes" }
        ]}
      />

      <PageHeader
        title={"Service Routes"}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateRouteClick}
          >
            Create Route
          </Button>
        }
      />
      <RouteSelectionModal
        onClose={onSelectionModalClose}
        open={showSelectionModal}
      />
      <ServiceRoutesTable renderFilter={true} includeTenants />

      <Box marginTop={8}>
        <PageHeader
          title={"Shared Service Routes"}
          // actions={
          //   <Link to="/dashboard/valets/create">
          //     {/* <Button variant="contained" color="primary">
          //       Create Valet
          //     </Button> */}
          //   </Link>
          // }
        />
      </Box>

      <SharedServiceRoutesTable />
    </Container>
  );
};

export default ServiceRoutesListPage;
