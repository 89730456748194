import { useSnackbar as notiUseSnackbar, OptionsObject } from 'notistack';

const commonConfig: OptionsObject = {
    anchorOrigin: { horizontal: "center", vertical: "top" },
}
export default () => {
    const { enqueueSnackbar } = notiUseSnackbar();

    const sendSuccessMessage = (text: string, options?: OptionsObject) => {
        enqueueSnackbar(text, {
            ...commonConfig,
            ...options,
             variant: "success",
        });
    }
  
    const sendErrorMessage = (text: string, options?: OptionsObject) => {
        enqueueSnackbar(text, {
            ...commonConfig,
            ...options,
             variant: "error",
        });
    }
    return {
        sendSuccessMessage,
        sendErrorMessage
    }
}