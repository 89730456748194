import { MUIDataTableColumn } from "mui-datatables";
import React from "react";
import { GET_SUPPLY_REQUESTS_TABLE } from "../../../apollo/supplyRequests/queries";
import Link from "../../../navigation/Link";
import DataTable from "../../../shared/components/Datatable";

const columns: MUIDataTableColumn[] = [
  {
    name: "routeId",
    options: {
      display: "excluded"
    }
  },
  {
    name: "valetId",
    options: {
      display: "excluded"
    }
  },
  {
    name: "supervisorId",
    options: {
      display: "excluded"
    }
  },
  {
    name: "id",
    label: "View",

    options: {
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <Link to={`/dashboard/supplyRequests/${value}`}>View</Link>;
      }
    }
  },
  {
    name: "status",
    label: "Status"
  },
  {
    name: "propertyName",
    label: "Property"
  },

  {
    name: "routeCode",
    label: "Service Route",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Link to={`/dashboard/serviceRoutes/${tableMeta.rowData[0]}`}>
            {value}
          </Link>
        );
      }
    }
  },
  {
    name: "supervisor",
    label: "Supervised By",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Link to={`/dashboard/users/${tableMeta.rowData[2]}`}>{value}</Link>
        );
      }
    }
  },
  {
    name: "valet",
    label: "Requested By",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Link to={`/dashboard/valets/${tableMeta.rowData[1]}`}>{value}</Link>
        );
      }
    }
  }
];

const SupplyRequestsTable: React.FC = ({}) => {
  return (
    <DataTable
      // title={
      //   renderFilter ? (
      //     <Box marginY={5}>
      //       <SubTenantSearchBox
      //         label="Filter by Sub-Franchise"
      //         onOptionChange={onSubTenantChange}
      //         value={subTenant}
      //       />
      //     </Box>
      //   ) : null
      // }
      includeTenants={true}
      subTenantFilter={{
        show: true
      }}
      dateColumns={[{ name: "createdAt", label: "Requested at" }]}
      // fetchMore={setFetchMore}
      columns={columns}
      query={GET_SUPPLY_REQUESTS_TABLE}
      // variables={subTenant ? { subTenantId: subTenant.id } : undefined}
      // onDelete={onDelete}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
        selectToolbarPlacement: "none"
      }}
    />
  );
};

export default SupplyRequestsTable;
