import React from "react";
import { Property } from "../../../apollo/customers/interfaces";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import {
  PersonRounded,
  PinDropRounded,
  DomainRounded,
  PhoneRounded,
  EmailRounded
} from "@material-ui/icons";
import { formatGender, formatAddress } from "../../../shared/utils";
import { mdiAccountHardHat, mdiHomeCity } from "@mdi/js";
import Icon from "../../../shared/components/Icon";
import PropertyManagerSetter from "./PropertyManagerSetter";
import useAbility from "../../../shared/hooks/useAbility";

interface PropertyDetailSectionProps {
  property: Property;
}
const PropertyDetailsSection: React.FC<PropertyDetailSectionProps> = ({
  property
}) => {
  const ability = useAbility();
  return (
    <List>
      <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Item
            icon={<Icon size={1} path={mdiHomeCity} color="primary" />}
            title="Name"
            description={property.name}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Item
            icon={<PinDropRounded color="primary" />}
            title="Address"
            description={property.address.formatted}
          />
        </Grid>

        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Item
            icon={<PhoneRounded color="primary" />}
            title="Main phone"
            description={property.mainPhone}
          />
        </Grid>

        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Item
            icon={<PhoneRounded color="primary" />}
            title="Maintenance phone"
            description={property.maintenancePhone}
          />
        </Grid>

        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Item
            icon={<EmailRounded color="primary" />}
            title="Email"
            description={property.email}
          />
        </Grid>

        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Item
            icon={<PersonRounded color="primary" />}
            title="Contact name"
            description={property.contactName}
          />
        </Grid>
        {ability.can("update", "Customers") && (
          <Grid item xs={12} md={6} sm={12} lg={6}>
            <Item
              icon={<DomainRounded color="primary" />}
              title="Assigned Property Manager"
              content={
                <PropertyManagerSetter
                  propertyId={property.id}
                  subTenantId={property.subTenantId}
                  propertyManager={property.propertyManager}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Item
            icon={<DomainRounded color="primary" />}
            title="Assigned Management Company"
            description={property.managementCompany?.name}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Item
            icon={<Icon size={1} color="primary" path={mdiAccountHardHat} />}
            title="Assigned Regional Manager"
            description={property.regionalManager?.name}
          />
        </Grid>
      </Grid>
    </List>
  );
};

interface ItemProps {
  icon: React.ReactNode;
  title: string;
  description?: string | null;
  content?: React.ReactNode;
}

const Item: React.FC<ItemProps> = ({ icon, title, description, content }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          color: "textSecondary"
        }}
        secondary={
          content ? (
            content
          ) : (
            <Typography component="span" variant="body2" color="textPrimary">
              {description ? description : "Not Specified"}
            </Typography>
          )
        }
      />
    </ListItem>
  );
};
export default PropertyDetailsSection;
