import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TopBar from "./TopBar";
import AppDrawer from "./Drawer";
import PanelRoutes from "./PanelRoutes";
import useNotifications from "../shared/hooks/useNotifications";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      // paddingBottom: theme.spacing(10),
      // minHeight: '100%'
    },
  })
);

const Main: React.FC = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen((o) => !o);
  };
  const { requestPermission } = useNotifications();
  useEffect(() => {
    console.log('registering')
    requestPermission();
  }, []);

  return (
    <div className={classes.root}>
      <ReactNotification/>
      <div>
        <AppDrawer open={drawerOpen} onClose={toggleDrawer} />
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div>
          <TopBar onMenuClick={toggleDrawer} />
        </div>

        <main className={classes.content}>
          <PanelRoutes />
        </main>
      </div>
    </div>
  );
};

export default Main;
