import React, { useState } from "react";
import { SketchPicker, ColorResult } from "react-color";
import {
  makeStyles,
  Popover,
  IconButton,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import { FieldInputProps, FieldMetaProps, FormikProps } from "formik";

const useStyles = makeStyles((theme) => ({
  color: {
    width: "30px",
    height: "30px",
    borderRadius: "30px",
  },
}));
interface ColorPickerProps {
  size?: number;
  color?: string;
  onChange?: (color: string) => void;
  helperText?: string;
  error?: boolean;
}
const ColorPicker: React.FC<ColorPickerProps> = ({
  color = "red",
  onChange,
  helperText,
  error,
  size = 30,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const classes = useStyles();
  const onButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(e.currentTarget);
  };
  const onColorChange = (color: ColorResult) => {
    if (onChange) onChange(color.hex);
  };

  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={onButtonClick}>
        <div
         
          style={{
            background: color,
            width: size,
            height: size,
            borderRadius: size,
          }}
        />
      </IconButton>
      <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
        <SketchPicker color={color} onChange={onColorChange} />
      </Popover>
    </>
  );
};

export interface ColorPickerFieldProps<V = any, FormValues = any> {
  field: FieldInputProps<V>;
  form: FormikProps<FormValues>;
  meta: FieldMetaProps<V>;
  label?: string;
}

export const ColorPickerField: React.FC<ColorPickerFieldProps> = ({
  field,
  form,
  meta,
  label,
}) => {
  const handleChange = (color: string) => {
    form.setFieldValue(field.name, color);
  };
  return (
    <FormControl error={!!form.errors[field.name]} component="fieldset">
      <FormLabel
        component="label"
        // className={classes.label}
        htmlFor="colorPicker"
      >
        {label}
      </FormLabel>
      <ColorPicker color={field.value? field.value : 'red'} onChange={handleChange} />
      <FormHelperText>{form.errors[field.name]}</FormHelperText>
    </FormControl>
  );
};

export default ColorPicker;
