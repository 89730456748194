import { Container, makeStyles, Paper } from "@material-ui/core";
import { Formik, Form } from "formik";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useCreateUpdateProperty } from "../../apollo/customers/mutations";
import { useGetPropertyForEdit } from "../../apollo/customers/queries";
import BreadcrumbsSection from "../../shared/components/BreadcrumbsSection";
import ErrorPage from "../../shared/components/ErrorPage";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import PageHeader from "../../shared/components/PageHeader";
import useAbility from "../../shared/hooks/useAbility";
import useSnackbar from "../../shared/hooks/useSnackbar";
import LoadingPage from "../Home/LoadingPage";
import { PropertyFormValues } from "./components/interfaces";
import PropertyForm, {
  getPropertyFormInitialValues,
  getPropertyFormValidationSchema
} from "./components/PropertyForm";

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(3, 0)
  }
}));

const PropertyFormPage: React.FC = ({}) => {
  const classes = useStyles();
  const { propertyId } = useParams<{ propertyId?: string }>();
  const { getProperty, error, property } = useGetPropertyForEdit();
  const {
    submitProperty,
    property: createdProperty,
    loading: submitPropertyLoading,
    error: submitPropertyError
  } = useCreateUpdateProperty();
  const history = useHistory();

  const { sendErrorMessage, sendSuccessMessage } = useSnackbar();

  const onSubmit = (values: PropertyFormValues) => {
    submitProperty(values);
  };
  const ability = useAbility();

  useEffect(() => {
    if (propertyId) {
      getProperty(propertyId);
    }
  }, [propertyId]);

  useEffect(() => {
    if (submitPropertyError) {
      sendErrorMessage(submitPropertyError.message);
    }

    if (createdProperty) {
      sendSuccessMessage(
        `Property ${propertyId ? "updated" : "created"} successfully.`
      );
      history.push("/dashboard/properties/" + createdProperty.id);
    }
  }, [submitPropertyError, createdProperty]);

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (propertyId && !property) {
    return <LoadingPage />;
  }
  return (
    <Container>
      <LoadingBackdrop open={submitPropertyLoading} />
      <BreadcrumbsSection
        sections={[
          { label: "Home", link: "/dashboard" },
          { label: "Properties", link: "/dashboard/properties" },
          { label: "Property Form" }
        ]}
      />
      <PageHeader title={propertyId ? "Edit Property" : "Create Property"} />
      <Paper className={classes.paperContainer}>
        <Container>
          <Formik
            onSubmit={onSubmit}
            initialValues={getPropertyFormInitialValues(property)}
            validationSchema={getPropertyFormValidationSchema(
              ability.can("manage", "SubTenants")
            )}
          >
            <Form>
              <PropertyForm isEdit={!!property} />
            </Form>
          </Formik>
        </Container>
      </Paper>
    </Container>
  );
};

export default PropertyFormPage;
