import React, { useState } from "react";
import { GET_SHARED_CUSTOMERS_PAGINATED } from "../../../apollo/customers/queries";
import DataTable from "../../../shared/components/Datatable";
import { MUIDataTableColumn } from "mui-datatables";
import Link from "../../../navigation/Link";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import { Box } from "@material-ui/core";
import useAbility from "../../../shared/hooks/useAbility";

interface SharedCustomersTableProps {}
const columns: MUIDataTableColumn[] = [
  {
    name: "id",
    options: {
      display: "excluded"
    }
  },
  {
    name: "subTenant",
    label: "Belongs to"
  },

  {
    name: "name",
    label: "Property",
    options: {
      customBodyRender: (value, tableMeta) => {
        // updateValue(tableMeta.rowData[1] + " " + tableMeta.rowData[2]);
        return (
          <Link to={"/dashboard/properties/" + tableMeta.rowData[0]}>
            {value}
          </Link>
        );
      }
    }
  },

  {
    name: "mainPhone",
    label: "Property Contact Phone",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => value
    }
  },
  {
    name: "email",
    label: "Property Contact Email",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return "Not specified";
        return value;
      }
    }
  },

  {
    name: "regionalManager",
    label: "Regional Manager"
  },
  {
    name: "managementCompany",
    label: "Management Company"
  }
];

const SharedCustomersTable: React.FC<SharedCustomersTableProps> = () => {
  const [subTenantId, setSubTenantId] = useState<null | string>(null);
  const ability = useAbility();
  return (
    <DataTable
      title={
        ability.can("manage", "SubTenants") ? (
          <Box marginTop={4}>
            <SubTenantSearchBox
              label="Sub-Franchises"
              onOptionChange={subTenant =>
                setSubTenantId(subTenant ? subTenant.id : null)
              }
            />
          </Box>
        ) : (
          undefined
        )
      }
      query={GET_SHARED_CUSTOMERS_PAGINATED}
      columns={columns}
      variables={{ sharedSubTenantId: subTenantId }}
      dateColumns={[{ name: "createdAt", label: "Created At" }]}
      options={{
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30],
        download: false,
        print: false,
        filter: false,
        selectToolbarPlacement: "none"
      }}
    />
  );
};

export default SharedCustomersTable;
