import React from "react";
import SearchBox, {
  BaseSearchBoxProps,
} from "../../../shared/components/SearchBox";
import { RegionalManager } from "../../../apollo/propertyManagers/interfaces";
import { GET_MANAGEMENT_COMPANIES_SELECT } from "../../../apollo/propertyManagers/queries";

interface ManagementCompaniesSelectProps
  extends BaseSearchBoxProps<RegionalManager> {
    subTenantId?: string | null
  }

const ManagementCompaniesSelect: React.FC<ManagementCompaniesSelectProps> = (
  props
) => {
  return (
    <SearchBox<RegionalManager>
      {...props}
      variables={
        props.subTenantId ? { subTenantId: props.subTenantId } : undefined
      }
      query={GET_MANAGEMENT_COMPANIES_SELECT}
      variable="managementCompanies"
      labelKey="name"
    />
  );
};

export default ManagementCompaniesSelect;
