import { gql } from "@apollo/client";

export const GET_TENANTS = gql`
  query GetTenants(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
  ) {
    tenants(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
    ) {
      nodes {
        id
        name
        createdAt
      }
      currentCount
      totalsCount
    }
  }
`;
export const GET_TENANTS_SIMPLE = gql`
  query tenantsSimple {
    tenantsSimple {
      name
      id
    }
  }
`;

export const GET_TENANT = gql`
  query GetTenant($tenantId: String!) {
    tenant(tenantId: $tenantId) {
      name
    }
  }
`;

export const GET_SUBTENANTS_FOR_SELECT = gql`
  query subTenants{
    subTenants {
      id
      name
      tenant {
        name
      }
    }
  }
`
export const GET_SUB_TENANTS_PAGINATED = gql`
  query GetSubTenantsPaginated(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String
    $tenantId: String
  ) {
    subTenantsPaginated(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
      tenantId: $tenantId
    ) {
      totalsCount
      nodes {
        id
        createdAt
        name
      }
    }
  }
`;

export const GET_SUB_TENANT = gql`
  query GetSubTenant($subTenantId: String!) {
    subTenant(subTenantId: $subTenantId) {
      id
      name
      phoneNumber
      createdAt
      address {
        line1
        line2
        city
        zip
        state
      }
    }
  }
`;
