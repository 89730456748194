import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  IconButton,
  CircularProgress,
  Fade
} from "@material-ui/core";
import SigningModal from "../../../shared/components/SigningModal";
import { useFormikContext } from "formik";
import { DeleteRounded } from "@material-ui/icons";
import * as Yup from "yup";
import { FormValues } from "./interfaces";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { ValetAgreement } from "../../../apollo/valetAgreements/interface";
import { useGetValetAgreementPreview, useGetValetAgreementPreviewLazy } from "../../../apollo/valetAgreements/query";
import { getMappedValues } from "../ValetAgreementFormPage";
export interface SigningFormValues {
  valetSignature: string | null;
  supervisorSignature: string | null;
  valetSsn: string | undefined | null;
}
export const getSigningStepValidationSchema = () => {
  return Yup.object<SigningFormValues>({
    valetSignature: Yup.string()
      .nullable()
      .defined(),
    supervisorSignature: Yup.string()
      .nullable()
      .defined(),
    valetSsn: Yup.string()
      .optional()
      .nullable()
  });
};

export const getSigningFormInitialValues = (
  agreement?: ValetAgreement
): SigningFormValues => {
  return {
    valetSignature: agreement?.valetSignatureBase64
      ? agreement?.valetSignatureBase64
      : null,
    supervisorSignature: agreement?.supervisorSignatureBase64
      ? agreement?.supervisorSignatureBase64
      : null,
    valetSsn: ""
  };
};
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 1)
  }
}));

const useSigningStep = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [showSigningModal, setShowSigningModal] = useState(false);
  const [currentSignature, setCurrentSignature] = useState<
    "VALET" | "SUPERVISOR"
  >("VALET");

  const {
    generatePreview,
    agreementPreview,
    previewError,
    previewLoading
  } = useGetValetAgreementPreviewLazy();

  useEffect(() => {
    generatePreview(getMappedValues(values));
    return () => {
      
    }
  }, [values.valetSignature, values.supervisorSignature])

  const onAddValetSignature = () => {
    setCurrentSignature("VALET");
    setShowSigningModal(true);
  };
  const onAddSupervisorSignature = () => {
    setCurrentSignature("SUPERVISOR");
    setShowSigningModal(true);
  };
  const onValetSignatureChange = (image: string) => {
    setFieldValue("valetSignature", image);
  };
  const onSupervisorSignatureChange = (image: string) => {
    setFieldValue("supervisorSignature", image);
  };
  const onSigningModalClose = () => {
    setShowSigningModal(false);
  };

  return {
    model: {
      valetSignature: values.valetSignature,
      supervisorSignature: values.supervisorSignature,
      showSigningModal,
      currentSignature,
      agreementPreview,
      previewError,
      previewLoading
    },
    commands: {
      onSigningModalClose,
      onValetSignatureChange,
      onSupervisorSignatureChange,
      onAddValetSignature,
      onAddSupervisorSignature
    }
  };
};

interface SigningStepProps {
  agreement?: ValetAgreement;
}
const SigningStep: React.FC<SigningStepProps> = ({ agreement }) => {
  const {
    model: {
      valetSignature,
      supervisorSignature,
      showSigningModal,
      currentSignature,
      agreementPreview,
      previewError,
      previewLoading
    },
    commands: {
      onSigningModalClose,
      onValetSignatureChange,
      onSupervisorSignatureChange,
      onAddSupervisorSignature,
      onAddValetSignature
    }
  } = useSigningStep();

  const classes = useStyles();

  return (
    <Grid container alignItems="stretch" spacing={2} className={classes.root}>
      <SigningModal
        title={
          currentSignature === "VALET"
            ? "Valet Signature"
            : "Supervisor Signature"
        }
        open={showSigningModal}
        onSave={
          currentSignature === "VALET"
            ? onValetSignatureChange
            : onSupervisorSignatureChange
        }
        onClose={onSigningModalClose}
        isValet={currentSignature === "VALET"}
      />
      <Grid item xs={12}>
        <Typography variant="body2">
          Here you can preview the agreement and add the signatures. Click
          'Finish' to save the contract with the current information.
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Fade in={!!agreementPreview && !previewLoading} unmountOnExit={true}>
          <img
            src={agreementPreview}
            style={{ width: "100%" }}
            alt="agreement-preview"
          />
        </Fade>

        {previewLoading && <CircularProgress size={50} />}
        {previewError && (
          <Alert severity="error">
            Someting went wrong trying to generate the preview!
          </Alert>
        )}
      </Grid>

      {!!!agreement?.valetSignature && (
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="flex-end"
        >
          <Signature
            isFinal={!!agreement?.valetSignature}
            date={agreement?.valetSignature?.createdAt}
            src={valetSignature}
            createLabel="Add Contractor Signature"
            title="Contractor's Signature"
            onClose={() => onValetSignatureChange("")}
            onCreateClick={onAddValetSignature}
          />
        </Grid>
      )}

      {!!!agreement?.supervisorSignature && (
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="flex-end"
        >
          <Signature
            isFinal={!!agreement?.supervisorSignature}
            date={agreement?.supervisorSignature?.createdAt}
            src={supervisorSignature}
            createLabel="Add Supervisor Signature"
            title="Supervisor/Manager's Signature"
            onClose={() => onSupervisorSignatureChange("")}
            onCreateClick={onAddSupervisorSignature}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface SignatureProps {
  isFinal?: boolean;
  name?: string;
  title?: string;
  createLabel: string;
  src?: string | null;
  date?: string | Date;
  onCreateClick?: () => void;
  onClose?: () => void;
}
const Signature: React.FC<SignatureProps> = ({
  src,
  title,
  onClose,
  onCreateClick,
  createLabel,
  date,
  isFinal
}) => {
  if (isFinal) {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: 10, width: "100%" }}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <img
            src={src === null ? "" : src}
            alt={title}
            style={{ width: "100%" }}
          />
          <hr />
        </div>
        <div>
          {/* <Typography variant="h6">{name}</Typography>
          <hr /> */}
          <Typography align="center" color="textSecondary">
            {moment(date).format("L")}
          </Typography>
        </div>
      </div>
    );
  }
  if (src) {
    return (
      <>
        <Typography variant="h6">{title}</Typography>
        <ImageContainer src={src} onClose={onClose} />
      </>
    );
  }
  return (
    <Button variant="contained" color="secondary" onClick={onCreateClick}>
      {createLabel}
    </Button>
  );
};

interface ImageContainerProps {
  onClose?: () => void;
  src?: string;
}
const useImageContainerStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  image: {
    width: "100%",
    borderColor: theme.palette.secondary.main,
    border: "solid 3px",
    borderRadius: theme.shape.borderRadius
  },
  icon: {
    top: -theme.spacing(2),
    right: -theme.spacing(2),
    background: theme.palette.secondary.main,
    position: "absolute"
  }
}));
const ImageContainer: React.FC<ImageContainerProps> = ({ src, onClose }) => {
  const classes = useImageContainerStyles();

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="delete"
        className={classes.icon}
        onClick={onClose}
      >
        <DeleteRounded />
      </IconButton>
      <img src={src} className={classes.image} />
    </div>
  );
};

export default SigningStep;
