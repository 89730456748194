import { Box, IconButton, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { mdiGooglePlay } from "@mdi/js";
import moment from "moment";
import React from "react";
import { ValetPreCreation } from "../../../apollo/preCreations/interfaces";
import Icon from "../../../shared/components/Icon";
import InfoCard from "../../../shared/components/InfoCard";
import theme from "../../../theme";

interface ValetPreCreationDetailsProps {
  preCreation: ValetPreCreation;
}
const ValetPreCreationDetails: React.FC<ValetPreCreationDetailsProps> = ({
  preCreation
}) => {
  const theme = useTheme();
  const {
    createdValetUser,
    agreementStartDate,
    createdAgreementId,
    createdAgreement,
    firstName,
    lastName,
    email,
    assignedRoute
  } = preCreation;
  const completedSteps = () => {
    if (!createdValetUser) return false;

    return true;
  };

  return (
    <InfoCard color={theme.palette.secondary.main} fixedColorWidth={10}>
      <Typography variant="h5" gutterBottom>
        Your Information
      </Typography>
      <Typography color="textSecondary">Name</Typography>
      <Typography gutterBottom>{firstName + " " + lastName}</Typography>

      <Typography color="textSecondary">Email</Typography>
      <Typography gutterBottom>{email}</Typography>

      {createdValetUser && (
        <>
          <Typography color="textSecondary">Username</Typography>
          <Typography gutterBottom>{createdValetUser.username}</Typography>

          <Typography color="textSecondary">Address</Typography>
          <Typography gutterBottom>
            {createdValetUser.address?.formatted}
          </Typography>
        </>
      )}

      {assignedRoute && (
        <>
          <Typography color="textSecondary"> Assigned Service Route</Typography>
          <Typography gutterBottom>
            {`${assignedRoute.routeCode} - ${assignedRoute.property.name}`}{" "}
          </Typography>

          <Typography color="textSecondary">Property Address</Typography>
          <Typography gutterBottom>
            {assignedRoute.property.address.formatted}
          </Typography>
        </>
      )}

      {!!createdAgreement && (
        <>
          <Typography color="textSecondary"> Agreement Signed On</Typography>
          <Typography gutterBottom>
            {moment(createdAgreement.createdAt).format("MMM Do YYYY")}
          </Typography>
        </>
      )}

      {completedSteps() && (
        <Box marginTop="3rem">
          <Typography>
            You have completed all the steps needed to start working with us.
            You can sign in into the Disposal Valet App.
          </Typography>
          <Typography color="textSecondary">
            If you have an iOS device you must ask your manager for the download
            link.
          </Typography>

          <IconButton size="medium">
            <a
              href="https://play.google.com/store/apps/details?id=com.pushmotion.disposalvalet&hl=en&gl=US"
              target="_blank"
              style={{ height: "1.5rem", width: "1.5rem" }}
            >
              <Icon path={mdiGooglePlay} color="primary" />
            </a>
          </IconButton>

          {!createdAgreementId && (
            <Alert color="warning" variant="filled">
              You still need to complete the agreement's process.
            </Alert>
          )}
        </Box>
      )}
    </InfoCard>
  );
};

export default ValetPreCreationDetails;
