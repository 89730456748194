import { UserFormValues } from "./components/interfaces";
import { AddressFormValues } from "../../shared/components/AddressForm/interfaces";
import { useState, useEffect, useMemo } from "react";
import useSnackbar from "../../shared/hooks/useSnackbar";
import { useHistory, useParams } from "react-router-dom";
import { User, CreateUser, UserForForm } from "../../apollo/users/interfaces";
import { ApolloError } from "@apollo/client";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { CREATE_USER, UPDATE_USER } from "../../apollo/users/mutations";
import { ADMIN_GET_USER_FOR_FORM, useCheckUsername } from "../../apollo/users/queries";
import { useFormik } from "formik";
import { getUserFormValidationSchema } from "./components/UserForm";
import { getAddressFormValidationSchema } from "../../shared/components/AddressForm";
import { BreadcrumbSection } from "../../shared/components/BreadcrumbsSection";
import useAbility from "../../shared/hooks/useAbility";

const initialUserFormValues: UserFormValues = {
  subTenantId: "",
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  password: "",
  roleId: "",
  phone: "",
  emergencyPhone: ""
};

const initialAddressFormValues: AddressFormValues = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
  latitude: null,
  longitude: null,
};
const useUsersFormPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { sendSuccessMessage, sendErrorMessage } = useSnackbar();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const isEdit = !!userId;
  const ability = useAbility();
  //CallBacks & Actions

  const onUserLoadSuccess = ({ user }: { user: UserForForm }) => {
    const { address, roles, ...userData } = user;
    userForm.setValues({ ...userData, roleId: roles[0].id, password: "" });
    if (address) addressForm.setValues(address);
  };

  const onUserLoadError = (error: ApolloError) => {
    sendErrorMessage("There was an error loading the user's data.");
    console.warn(error);
  };

  const onSubmitSuccess = ({
    createUser,
    updateUser,
  }: {
    createUser: User;
    updateUser: User;
  }) => {
    sendSuccessMessage(
      isEdit ? "User updated successfully" : "User created successfully."
    );
    const userId = isEdit ? updateUser.id : createUser.id;

    history.replace("/dashboard/users/" + userId);
  };

  const onSubmitError = (error: ApolloError) => {
    sendErrorMessage(error.message);
  };

  const onUserFormSubmit = (values: UserFormValues) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const onAddressFormSubmit = (values: AddressFormValues) => {
    const { roleId, ...user } = userForm.values;
    const address = addressForm.values;

    submitUser({
      variables: {
        user: {
          ...user,
          roles: roleId? [roleId] : [],
          address,
        },
        userId: userId,
      },
    });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      userForm.submitForm();
    }

    if (activeStep === 1) {
      addressForm.submitForm();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //CallBacks & Actions

  //Apollo hooks
  const [submitUser, { loading }] = useMutation<
    { createUser: User; updateUser: User },
    { user: CreateUser; userId?: String }
  >(isEdit ? UPDATE_USER : CREATE_USER, {
    onCompleted: onSubmitSuccess,
    onError: onSubmitError,
  });

  const [loadUser, loadUserMeta] = useLazyQuery<
    { user: UserForForm },
    { userId: string }
  >(ADMIN_GET_USER_FOR_FORM, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
    onCompleted: onUserLoadSuccess,
    onError: onUserLoadError,
  });

  const checkUsername = useCheckUsername()
  //Apollo hooks

  //Formik Hooks
  const userForm = useFormik<UserFormValues>({
    initialValues: initialUserFormValues,
    onSubmit: onUserFormSubmit,
    validationSchema: getUserFormValidationSchema(
      isEdit,
      checkUsername,
      ability.can("manage", "SubTenants"),
      !isEdit
    ),
    validateOnChange: false,
  });

  const addressForm = useFormik<AddressFormValues>({
    initialValues: initialAddressFormValues,
    onSubmit: onAddressFormSubmit,
    validationSchema: getAddressFormValidationSchema(false),
    validateOnChange: false,
  });
  //Formik Hooks

  //Effects
  useEffect(() => {
    if (isEdit) {
      loadUser();
    }
  }, [isEdit]);
  //Effects

  const sections = useMemo<BreadcrumbSection[]>(
    () => getLinkSections(loadUserMeta.data?.user),
    [loadUserMeta.data?.user]
  );

  return {
    model: {
      activeStep,
      userForm,
      addressForm,
      processLoading: loading || loadUserMeta.loading,
      isEdit,
      sections,
      ability
    },
    commands: {
      handleBack,
      handleNext,
    },
  };
};

const getLinkSections = (user?: UserForForm) => {
  const sections: BreadcrumbSection[] = [
    { label: "Home", link: "/dashboard" },
    { label: "Users", link: "/dashboard/users" },
  ];

  console.log(user)
  if (user) {
    sections.push({ label: user.username, link: "/dashboard/users/" + user.id });
  }
  sections.push({ label: "User Form" });

  return sections;
};
export default useUsersFormPage;
