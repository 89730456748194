import { FieldPolicy, FieldReadFunction, gql } from "@apollo/client";
import { useQuery, QueryHookOptions, useLazyQuery } from "@apollo/react-hooks";
import { property } from "lodash";
import { IncidentCategory, IncidentSubCategory, Incident } from "./interfaces";
const INCIDENT_CATEGORIES_FOR_SELECT = gql`
  query incidentCategoriesForSelect {
    incidentCategories {
      id
      name
      shouldSendAlert
      requiresPictures
      description
      order
    }
  }
`;
export const useGetIncidentCategoriesForSelect = (
  options?: QueryHookOptions<
    { incidentCategories: IncidentCategory[] },
    Record<string, any>
  >
) => {
  const { data, loading, error, refetch } = useQuery<{
    incidentCategories: IncidentCategory[];
  }>(INCIDENT_CATEGORIES_FOR_SELECT, options);

  return {
    categories: data?.incidentCategories,
    categoriesLoading: loading,
    categoriesError: error,
    refetch
  };
};

const INCIDENT_CATEGORY_DETAILS = gql`
  query getIncidentCategoryDetails($id: String!) {
    incidentCategory(id: $id) {
      id
      name
      description
      requiresPictures
      shouldSendAlert
      subCategories {
        id
        name
        description
        requiresPictures
        order
        shouldSendAlert
      }
    }
  }
`;

export const useGetIncidentCategoryDetails = (id: string) => {
  const { data, loading, error } = useQuery<
    { incidentCategory: IncidentCategory },
    { id: string }
  >(INCIDENT_CATEGORY_DETAILS, {
    variables: { id },
    fetchPolicy: "cache-and-network"
  });

  return {
    category: data?.incidentCategory,
    categoryLoading: loading,
    categoryError: error
  };
};

const INCIDENT_CATEGORY_SUBS = gql`
  query getIncidentCategorySubs($id: String!) {
    incidentCategory(id: $id) {
      subCategories {
        id
        name
        description
        requiresPictures
        order
        shouldSendAlert
      }
    }
  }
`;

export const useGetIncidentCategorySubsLazy = (id: string) => {
  const [getSubCategories, { data, loading, error }] = useLazyQuery<
    { incidentCategory: IncidentCategory },
    { id: string }
  >(INCIDENT_CATEGORY_SUBS, {
    variables: { id },
    fetchPolicy: "cache-and-network"
  });

  return {
    getSubCategories,
    subCategories: data?.incidentCategory.subCategories,
    subCategoriesLoading: loading,
    subCategoriesError: error
  };
};

export const GET_INCIDENTS_TABLE = gql`
  query getIncidentsTable(
    $offset: Int!
    $limit: Int!
    $sortProperty: String
    $sortDirection: String
    $search: String # $subTenantId: String
  ) {
    incidentsTable(
      offset: $offset
      limit: $limit
      sortProperty: $sortProperty
      sortDirection: $sortDirection
      search: $search
    ) {
      totalsCount
      nodes {
        createdAt
        valet
        valetId
        subTenantId
        tenantId
        subTenant
        tenant
        apartmentNumber
        notes
        category
        subCategory
        id
        routeId
        routeName
      }
    }
  }
`;

const GET_INCIDENT_VIEW = gql`
  query getIncidentView($id: String!) {
    incident(id: $id, markAsRead: true) {
      id
      createdAt
      isBuilding
      category {
        id
        name
      }
      subCategory {
        id
        name
      }
      pictures {
        id
        url
      }
      route {
        id
        routeCode
        property {
          id
          name
        }
      }
      notes
      apartmentNumber
      valet {
        name
        id
      }
      reporter {
        name
        id
      }
      serviceReportId
    }
  }
`;
export const useGetIncidentViewLazy = () => {
  const [get, { data, loading, error }] = useLazyQuery<
    { incident: Incident },
    { id: string }
  >(GET_INCIDENT_VIEW, {
    fetchPolicy: "cache-and-network"
  });

  return {
    getIncident: get,
    incident: data?.incident,
    incidentLoading: loading,
    incidentError: error
  };
};
export const useGetIncidentView = (id: string) => {
  const { data, loading, error } = useQuery<
    { incident: Incident },
    { id: string }
  >(GET_INCIDENT_VIEW, {
    fetchPolicy: "cache-and-network",
    variables: {
      id
    }
  });

  return {
    incident: data?.incident,
    incidentLoading: loading,
    incidentError: error
  };
};

export const GET_INCIDENTS_PAGINATED = gql`
  query getIncidentsPaginated(
    $cursor: DateTime
    $limit: Int!
    $search: String
    $filter: IncidentPaginatedFilterDTO!
  ) {
    incidentsPaginated(
      cursor: $cursor
      limit: $limit
      search: $search
      filter: $filter
    ) {
      cursor
      nodes {
        id
        createdAt
        apartmentNumber
        isBuilding
        read
        reporter {
          id
          name
        }
        valet {
          id
          name
        }
        route {
          id
          routeColor
          routeCode
          property {
            name
          }
        }
        category {
          id
          name
        }
        subCategory {
          id
          name
        }
      }
    }
  }
`;

interface IncidentsPaginatedResult {
  cursor: Date;
  nodes: Incident[];
}

export const InicidentPaginatedPolicy: {
  incidentsPaginated:
    | FieldPolicy<
        IncidentsPaginatedResult,
        IncidentsPaginatedResult,
        IncidentsPaginatedResult
      >
    | FieldReadFunction<IncidentsPaginatedResult, IncidentsPaginatedResult>;
} = {
  incidentsPaginated: {
    keyArgs: false,
    merge: (existing, incoming, { args }) => {
      //if initial or refetch
      if (args && !args.cursor) {
        return incoming;
      }
      const exisitingIncidents = existing ? existing.nodes : [];
      return {
        cursor: incoming.cursor,
        nodes: [...exisitingIncidents, ...incoming.nodes]
      };
    }
  }
};

export const GET_PROPERTY_INCIDENT_ALERT_QUERY = gql`
  query getPropertyIncidentAlert($propertyId: String!) {
    propertyIncidentAlert(propertyId: $propertyId) {
      id
      isBuilding
      apartmentNumber
      reporter {
        id
        name
      }
      category {
        id
        name
      }
      subCategory {
        id
        name
      }
      route {
        id
        routeCode
      }
      createdAt
    }
  }
`;

export const useGetPropertyIncidentAlert = (propertyId: string) => {
  const { data, loading, error } = useQuery<
    { propertyIncidentAlert: Incident[] },
    { propertyId: string }
  >(GET_PROPERTY_INCIDENT_ALERT_QUERY, { variables: { propertyId } });
  return { incidents: data?.propertyIncidentAlert, loading, error };
};
