import React, { useState } from "react";
import { GET_USERS } from "../../../apollo/users/queries";
import DataTable from "../../../shared/components/Datatable";
import Link from "../../../navigation/Link";
import { MUIDataTableColumn } from "mui-datatables";
import DeleteModal from "../../../shared/components/DeleteModal";
import useValue from "../../../shared/hooks/useValue";
import { DELETE_USERS } from "../../../apollo/users/mutations";
import SubTenantSearchBox from "../../Tenants/components/SubTenantsSearchbox";
import { Box } from "@material-ui/core";
import { SubTenant } from "../../../apollo/tenants/interfaces";

const columns: MUIDataTableColumn[] = [
  {
    name: "id",
    options: {
      display: "excluded",
    },
  },
  {
    name: "username",
    label: "Username",
    options: {
      customBodyRender: (value, tableMeta) => {
        return <Link to={`users/${tableMeta.rowData[0]}`}>{value}</Link>;
      },
    },
  },
  {
    name: "firstName",
    label: "Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },

  {
    name: "email",
    label: "Email",
  },
  {
    name: "createdAt",
    label: "Created At",
  },
];

const useUsersTable = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fetchMore, setFetchMore] = useValue<null | Function>(null);
  const [subTenant, setSubTenant] = useState<null | SubTenant>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const onDelete = (ids: string[]) => {
    setSelectedIds(ids);
    setShowDeleteModal(true);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    if (fetchMore) fetchMore();

    setSelectedIds([]);
  };

  const onSubTenantChange = (subTenant: SubTenant | null) => {
   setSubTenant(subTenant)
  };

  return {
    model: {
      showDeleteModal,
      selectedIds,
      subTenant,
    },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange,
    },
  };
};

interface UsersTableProps {
  canDelete?: boolean;
  renderFilter?: boolean;
}
const UsersTable: React.FC<UsersTableProps> = ({ canDelete, renderFilter }) => {
  const {
    model: { showDeleteModal, selectedIds, subTenant },
    commands: {
      onDelete,
      onDeleteModalClose,
      setFetchMore,
      onDeleteSuccess,
      onSubTenantChange,
    },
  } = useUsersTable();
  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        ids={selectedIds}
        mutation={DELETE_USERS}
        customVariableName="userIds"
        onClose={onDeleteModalClose}
        onSuccess={onDeleteSuccess}
        title="Delete user(s)"
        description="This action cannot be undone."
      />
      <DataTable
       
        subTenantFilter={{
          show: !!renderFilter
        }}
        fetchMore={setFetchMore}
        columns={columns}
        query={GET_USERS}
        includeTenants
        // variables={subTenant ? { subTenantId: subTenant.id } : undefined}
        onDelete={onDelete}
        options={{
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 30],
          download: false,
          print: false,
          filter: false,
          disableToolbarSelect: !canDelete,
        }}
      />
    </>
  );
};

export default UsersTable;
