import React, { useEffect } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePickerProps } from "@material-ui/pickers";
import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import { ToggleButtonGroup } from "formik-material-ui-lab";
import DateFnsUtils from "@date-io/moment";
import { Field, useFormikContext, useField } from "formik";
import { TextField } from "formik-material-ui";
import { Moment } from "moment";
import { ToggleButton } from "@material-ui/lab";
import * as Yup from "yup";
import { WorkingDay, FormValues } from "./interfaces";
import {
  UnitsStepUpStepFormValues,
  UnitStepUpMetadata,
} from "./UnitsStepUpStep";
import { CustomerAgreement } from "../../../apollo/agreements/interface";
import moment from "moment";

export interface DateSelectionFormValues {
  currentTerm: "MONTH-TO-MONTH" | "FIXED-PERIOD";
  serviceStartDate: Moment | null;
  serviceStartTime: Moment | null;
  expirationDate: Moment | null;
  offPropertyTime: Moment | null;
  workingDays: WorkingDay[];
}

export const getInitialDateSelectionValues = (
  agreement?: CustomerAgreement
): DateSelectionFormValues => {

  return {
    serviceStartDate: agreement? moment(agreement.serviceStartDate) : null,
    serviceStartTime: agreement? moment(agreement.serviceStartTime) : moment("8:00 PM", 'h:mm A'),
    expirationDate: agreement? moment(agreement.expirationDate) : null,
    offPropertyTime: agreement? moment(agreement.offPropertyTime) : null,
    currentTerm: agreement? agreement.currentTerm :"FIXED-PERIOD",
    workingDays: agreement? agreement.workingDays: [],
  };
};

export const getDateSelectionValidationSchema = () => {
  const requiredField = "This field is required.";
  return Yup.object<DateSelectionFormValues>({
    serviceStartDate: Yup.object<Moment>()
      .required(requiredField)
      .nullable()
      .test(
        "same-date",
        "The service start date cannot be the same as the expiration date.",
        function (value: Moment | null) {
          const { expirationDate } = this.parent as FormValues;
          if (!value || !expirationDate) return true;
          console.log(expirationDate.isSame(value, "day"));
          return !expirationDate.isSame(value, "day");
        }
      ),
    serviceStartTime: Yup.object<Moment>()
      .required(requiredField)
      .nullable()
      .test(
        "same-time",
        "Start time and off property time cannot be the same.",
        function (value: Moment | null) {
          const { offPropertyTime } = this.parent as FormValues;
          if (!value || !offPropertyTime) return true;

          return !offPropertyTime.isSame(value, "hour");
        }
      ),
    expirationDate: Yup.object<Moment>().required(requiredField).nullable(),
    offPropertyTime: Yup.object<Moment>().notRequired().nullable().defined(),
    currentTerm: Yup.string()
      .oneOf(["MONTH-TO-MONTH", "FIXED-PERIOD"])
      .required(requiredField),
    workingDays: Yup.array(
      Yup.string()
        .oneOf([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ])
        .required()
    )
      .min(1, "You must specify at least one working day.")
      .required(),
  });
};

const workingDays: WorkingDay[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const useDateSelectionStep = () => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
  } = useFormikContext<FormValues>();
  const { currentTerm, serviceStartDate, workingDays, type, metadata } = values;
  const isStepUp = type === "STEP-UP";

  const calculateEndDate = () => {
    const newEndDate = serviceStartDate?.clone();
    newEndDate?.add(1, "M");
    setFieldValue("expirationDate", newEndDate);
  };

  useEffect(() => {
    if (isStepUp) {
      const meta = metadata as UnitStepUpMetadata;
      if (meta && meta.steps) {
        const startDate = meta.steps[0].startDate;
        const endDate = meta.steps[3].endDate ? meta.steps[3].endDate : null;
        setValues({
          ...values,
          serviceStartDate: startDate,
          expirationDate: endDate,
          currentTerm: "FIXED-PERIOD",
        });
      }
    }
  }, []);

  useEffect(() => {
    if (currentTerm === "MONTH-TO-MONTH" && serviceStartDate) {
      calculateEndDate();
    }
  }, [
    currentTerm,
    serviceStartDate,
    touched.currentTerm,
    touched.serviceStartDate,
    setFieldValue,
  ]);

  return {
    model: {
      errors,
      disableExpDate: currentTerm === "MONTH-TO-MONTH" || isStepUp,
      disableStartDate: isStepUp,
      disableTermType: isStepUp,
    },
  };
};

const DateSelectionStep: React.FC = () => {
  const {
    model: { disableExpDate, errors, disableStartDate, disableTermType },
  } = useDateSelectionStep();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            select
            component={TextField}
            fullWidth
            variant="outlined"
            name="currentTerm"
            label="Term Type*"
            inputProps={{
              readOnly: disableTermType,
            }}
          >
            <MenuItem value="FIXED-PERIOD">Fixed Period</MenuItem>
            <MenuItem value="MONTH-TO-MONTH">Monthly Renewal</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            format="l"
            component={DatePicker}
            fullWidth
            inputVariant="outlined"
            name="serviceStartDate"
            label="Service Start Date*"
            readOnly={disableStartDate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            format="l"
            component={DatePicker}
            fullWidth
            readOnly={disableExpDate}
            inputVariant="outlined"
            name="expirationDate"
            label={`Contract ${
              !disableExpDate ? "Expiration" : "Renewal"
            } Date*`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={TimePicker}
            fullWidth
            inputVariant="outlined"
            name="serviceStartTime"
            label="Service Start Time*"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={TimePicker}
            fullWidth
            inputVariant="outlined"
            name="offPropertyTime"
            label="Off Property Time"
          />
        </Grid>
        <Grid item xs={12} container direction="column">
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
         
            Working Days*
          </Typography>
          <Field
            component={ToggleButtonGroup}
            name="workingDays"
            type="checkbox"
          >
            {workingDays.map((day) => (
              <ToggleButton key={day} value={day}>
                {day}
              </ToggleButton>
            ))}
          </Field>
          {errors?.workingDays && (
            <Typography variant="caption" color="error">
              {errors?.workingDays}
            </Typography>
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateSelectionStep;
